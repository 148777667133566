import React from "react";
import { Table, Input, Button, Space, Divider, Col, Row, Checkbox } from 'antd';
import { withRouter } from "react-router";
import Highlighter from 'react-highlight-words';
import { SearchOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { withTranslation } from "react-i18next";
import Utils from "../../../../../../../utilities/Utils/Utils";
import SpinLoader from "../../../../../../../component/SpinLoader/SpinLaoder";


class ReferentielList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            pagination: {
                current: 1,
                pageSize: 10,
            },
            visible: false,
            activeReferenciel: {},
            action: ''
        };

    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, minWidth: '300px' }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        shape="round"
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 110 }}
                    >
                        {this.props.t('agent.user-list.filterDropdown.search')}
                    </Button>
                    <Button shape="round" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 110 }}>
                        {this.props.t('agent.user-list.filterDropdown.reset')}
                    </Button>
                    <Button
                        shape="round"
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        {this.props.t('agent.user-list.filterDropdown.filter')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    getFrlabel_fromArray = (recordKey, array) => {
        return array.filter(obj => obj.key === recordKey).map((obj) => obj.label_fr);
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    renderTableColumns = () => {
        const { t } = this.props;

        const columns = []

        columns.push(
            {
                title: t('agent.referentiel.table.columns.action'),
                dataIndex: 'action',
                key: Math.random,
                render: (text, record, index) => (
                    <Row align='middle'>
                        <Col span={12}>
                            <a onClick={() => this.props.handleDetails(record)}><EyeOutlined /> </a>
                        </Col>

                        <Col span={12}>
                            <a onClick={() => this.props.handleUpdates(record)}><EditOutlined /> </a>
                        </Col>
                    </Row>
                ),
            },
            {
                title: t('agent.referentiel.table.columns.description'),
                dataIndex: 'description',
                key: 'description',
                ...this.getColumnSearchProps('description'),
            },
            {
                title: t('agent.referentiel.table.columns.label-fr'),
                dataIndex: 'label_fr',
                key: 'label_fr',
                ...this.getColumnSearchProps('label_fr'),
            },
            {
                title: t('agent.referentiel.table.columns.label-ar'),
                dataIndex: 'label_ar',
                key: 'label_ar',
                ...this.getColumnSearchProps('label_ar'),
            },
        );

        return columns;
    }



    render() {
        const { t, data, loadingTable } = this.props;

        const getDeviderTitle = () => t('agent.referentiel.title.modeFormation');

        return <>
            <Divider>
                {getDeviderTitle()}
            </Divider>
            <Col lg={24} md={24} sm={24} xs={24}>
                <Table columns={this.renderTableColumns()} scroll={{ x: 500 }} dataSource={data} loading={loadingTable} pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }} />
            </Col>
        </>;
    }
}

export default withTranslation()(withRouter(ReferentielList));