import React, { useState, useEffect } from "react";
import { Form, Button, Typography, Row, Col, Space, Card, Alert } from "antd";
import { connect, useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { authentication, forgotPassword, clearErrorMessage } from './../Action';
import Utils from "../../../utilities/Utils/Utils";
import Layout from 'antd/lib/layout/layout';
import { LoginPasswordInputWithDoubleLabel, LoginInputPhoneNumberWithDoubleLabel } from '../../../utilities/FormFields/FormFields';
import { useTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import LanguageSelector from "../../../component/LanguageSelector/LanguageSelector";
import history from '../../../history'
import Logo from "../../Logo/Logo";
const { Title, Text } = Typography;


const ForgotPassword = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        props.clearErrorMessage();
    }, []);

    const [isPasswordSend, setPasswordSend] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(false);

    const goBack = () => {
        history.push('/login');
        window.location.reload();
    }
    
    const onFinish = (values) => {

        setPhoneNumber( process.env.REACT_APP_PROFIL === "ARTISAN" ? Utils.mapNumberToPhoneNumber(values.username) : values.username);

        if (isPasswordSend) {
            props.authentication({
                ...values,
                username: process.env.REACT_APP_PROFIL === "ARTISAN" ? Utils.mapNumberToPhoneNumber(values.username) : values.username
            })
        } else {
            props.forgotPassword({
                ...values,
                username: process.env.REACT_APP_PROFIL === "ARTISAN" ? Utils.mapNumberToPhoneNumber(values.username) : values.username
            }).then((result) => setPasswordSend(true));
        }

    };

    const regenratePassword = () => {
        props.forgotPassword({
            username: phoneNumber
        });
    }

    const alertMessage = (errorMessage) => {

        if (errorMessage === "USER_NOT_VALIDATED") {
            history.push('/new-account', { currentFromLogin: 1, phoneNumberFromLogin: phoneNumber })
            window.location.reload();
        } else {
            return <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                <Col span={24}>
                    <Alert
                        style={{ textAlign: 'center', width: '100%' }}
                        message={t(props.error)}
                        type="error"
                        closable
                    />
                </Col>
            </Row>
        }
    }

    return (
        <div className="background">
            <Layout style={{ minHeight: '100vh', padding: "20px", }}>
                <Row type="flex" align="middle">
                    <Col span="24" align="middle" style={{ alignItems: "center" }} ><Logo height="23px" /></Col>
                </Row>
                {process.env.REACT_APP_PROFIL === "ARTISAN" &&
                    <Row >
                        <Col span="4" align="middle" style={{ right: 30, position: "absolute", top: "10px" }}>
                            <LanguageSelector />
                        </Col>

                        <Col span="4" align="middle" style={{ left: 30, position: "absolute", top: "10px" }}>
                            <Button type="default" shape="round" size={"large"} onClick={() => window.location.assign(process.env.REACT_APP_PORTAIL_URL)} icon={<ArrowLeftOutlined />}
                                style={{ backgroundColor: "white" }}>
                                {t("go-back")}
                            </Button>
                        </Col>
                    </Row>

                }

                <Row type="flex" justify="center" align="top" style={{ width: '70%', margin: 'auto' }}>
                    <Space direction="vertical" size="large" style={{ marginTop: '10%' }}>
                        <Col span={24} type="flex" justify="center" align="center"  >
                            <Card className="glassmorphism " style={{ padding: '20px' }}
                                cover={<i className="fas fa-sms  fa fa-7x"></i>}
                            >
                                <Title level={3} style={{ textAlign: 'center' }}> {t("forgot-password.titre")} </Title>

                                {props.error && alertMessage(props.error)}
                                {props.success &&
                                    <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                                        <Col span={24}>
                                            <Alert
                                                style={{ textAlign: 'center' }}
                                                message={t(props.success)}
                                                type="success"
                                                closable
                                            />
                                        </Col>
                                    </Row>
                                }
                                {isPasswordSend
                                    ? <>
                                        <Text>{t("forgot-password.description-apres")}</Text>
                                        <Text>{t("forgot-password.description-apres-ligne2")}</Text><a onClick={() => regenratePassword()}>{t("forgot-password.link-resend-password")}</a>
                                    </>
                                    : <Text>{t("forgot-password.description-avant")}</Text>
                                }

                                <Form name="forgot-password-form" onFinish={onFinish} id="forgot-password-form" style={{ marginTop: '30px' }}>
                                {process.env.REACT_APP_PROFIL === "ARTISAN"
                                    ? <LoginInputPhoneNumberWithDoubleLabel name="username" label={t("forgot-password.form.phone-number.label")} placeholder={t("forgot-password.form.phone-number.placeholder")} errorMessage={t("forgot-password.form.phone-number.error-message")} disabled={isPasswordSend} />
                                    : <LoginInputPhoneNumberWithDoubleLabel name="username" label={t("forgot-password.form.login.label")} placeholder={t("forgot-password.form.login.placeholder")} errorMessage={t("forgot-password.form.login.error-message")} disabled={isPasswordSend} />
                                }
                                    {isPasswordSend && <LoginPasswordInputWithDoubleLabel name="password" label={t("forgot-password.form.password.label")} placeholder={t("forgot-password.form.password.placeholder")} errorMessage={t("forgot-password.form.password.error-message")} />}
                                    <Row style={{ padding: '21px' }} gutter={[8, 16]}>
                                        <Col type='flex' lg={{ span: 12, order: 1 }} md={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }} style={{ textAlign: 'right', justifyContent: 'space-between' }} >
                                            <Button shape="round" type="default" size="large" style={{ width: '100%' }} onClick={() => { goBack() }}>
                                                {t('forgot-password.form.back.label')}
                                            </Button>
                                        </Col>
                                        <Col type='flex' lg={{ span: 12, order: 2 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} style={{ textAlign: 'right', justifyContent: 'space-between' }} >
                                            <Form.Item >
                                                {isPasswordSend
                                                    ?
                                                    <Button shape="round" type="primary" htmlType="submit" size="large" style={{ width: '100%', minWidth: '20%', float: 'right' }}>
                                                        {t("forgot-password.form.submit.verifier-password")}
                                                    </Button>
                                                    :
                                                    <Button shape="round" type="primary" htmlType="submit" size="large" style={{ width: '100%', minWidth: '20%', float: 'right' }}>
                                                        {t("forgot-password.form.submit.recuperer-password")}
                                                    </Button>

                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                        <Col span={24} type="flex" justify="center" align="center" style={{ margin: '0', fontWeight: '500' }} >
                            <span>{t('new-account.footer')}</span>
                        </Col>
                    </Space>

                </Row>
            </Layout >
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        error: state.loginReducer.errorMessage,
        success: state.loginReducer.successMessage,
        loading: state.loginReducer.loading
    };
};

const mapDispatchToProps = (dispatch) => ({
    authentication: (user) => dispatch(authentication(user)),
    forgotPassword: (values) => dispatch(forgotPassword(values)),
    clearErrorMessage: () => dispatch(clearErrorMessage())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));