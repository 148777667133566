import types from './ActionTypes';
import Api from '../../utilities/services/Api';

export const getAttestationArtisan = (artisanId,attestationId) => {
  return ({
    type: types.GET_ATTESTATION_ARTISAN,
    payload: Api.artisan.getAttestationArtisan(artisanId,attestationId),
  })
};

