import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_ALL_SETTING: 'GET_ALL_SETTING',
    GET_ALL_SETTING_PENDING: `GET_ALL_SETTING_${ActionType.Pending}`,
    GET_ALL_SETTING_FULFILLED: `GET_ALL_SETTING_${ActionType.Fulfilled}`,
    GET_ALL_SETTING_REJECTED: `GET_ALL_SETTING_${ActionType.Rejected}`,

    UPDATE_SETTING: 'UPDATE_SETTING',
    UPDATE_SETTING_PENDING: `UPDATE_SETTING_${ActionType.Pending}`,
    UPDATE_SETTING_FULFILLED: `UPDATE_SETTING_${ActionType.Fulfilled}`,
    UPDATE_SETTING_REJECTED: `UPDATE_SETTING_${ActionType.Rejected}`,

};

export default ActionTypes;
