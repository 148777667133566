import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Tag } from 'antd';
import { getLoggers, changeLogLevel } from '../Action';
import Title from 'antd/lib/typography/Title';
import Search from 'antd/lib/input/Search';

export const Logs = (props) => {
  const [filter, setFilter] = useState('');

  useEffect(() => {
    props.getLoggers();
  }, []);


  const changeLvl = (loggerName, level) => () => {
    props.changeLogLevel(loggerName, level).then(() => {
      props.getLoggers();
    });
  }

  const changeFilter = value => setFilter(value);

  const filterFn = l => l.name.toUpperCase().includes(filter.toUpperCase());

  const getClassName = (level, check) => (level === check ? getLevelColor(level) : '#bfbfbf');

  const { logs, isFetching } = props;
  const loggers = logs ? Object.entries(logs.loggers).map(e => ({ name: e[0], level: e[1].effectiveLevel })) : [];

  const getLevelColor = (level) => {
    switch (level) {
      case 'TRACE':
        return '#1890ff';
      case 'DEBUG':
        return '#a0d911';
      case 'INFO':
        return '#13c2c2';
      case 'WARN':
        return '#fa8c16';
      case 'ERROR':
        return '#f5222d';
      case 'OFF':
        return '#595959';
      default:
        return '#595959'
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '65%',
    },
    {
      title: 'Level',
      key: 'level',
      dataIndex: 'level',
      render: (level, logger, i) => {
        return (
          <div>
            <Tag color={getClassName(level.toUpperCase(), "TRACE")} key={`log-trace-${i}`} onClick={changeLvl(logger.name, "TRACE")} style={{ cursor: 'pointer' }}>
              TRACE
                </Tag>
            <Tag color={getClassName(level.toUpperCase(), "DEBUG")} key={`log-debug-${i}`} onClick={changeLvl(logger.name, "DEBUG")} style={{ cursor: 'pointer' }}>
              DEBUG
                </Tag>
            <Tag color={getClassName(level.toUpperCase(), "INFO")} key={`log-info-${i}`} onClick={changeLvl(logger.name, "INFO")} style={{ cursor: 'pointer' }}>
              INFO
                </Tag>
            <Tag color={getClassName(level.toUpperCase(), "WARN")} key={`log-warn-${i}`} onClick={changeLvl(logger.name, "WARN")} style={{ cursor: 'pointer' }}>
              WARN
                </Tag>
            <Tag color={getClassName(level.toUpperCase(), "ERROR")} key={`log-error-${i}`} onClick={changeLvl(logger.name, "ERROR")} style={{ cursor: 'pointer' }}>
              ERROR
                </Tag>
            <Tag color={getClassName(level.toUpperCase(), "OFF")} key={`log-off-${i}`} onClick={changeLvl(logger.name, "OFF")} style={{ cursor: 'pointer' }}>
              OFF
                </Tag>
          </div>
        );
      }
    },
  ]

  return (
    <div>
      <Title span={3}> Logs </Title>
      <Search placeholder="Filtrer par nom" onSearch={changeFilter} enterButton />
      <Table columns={columns} dataSource={loggers.filter(filterFn)}  pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}/>
    </div>
  );
};

const mapStateToProps = (state) => ({
  logs: state.administrationReducer.logs,
  isFetching: state.administrationReducer.loading,
});

const mapDispatchToProps = { getLoggers, changeLogLevel };

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
