import React, { useEffect, useState } from "react"
import { getAllForArtisan, resetMessages, getAllForAgent } from '../Action';
import { getGlobalParam } from '../../GlobalParams/Action'
import { Form, Table, Tag, Button, Divider, Col, Row, Tooltip, Radio } from 'antd';
import { EyeOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SpinLoader from "../../../component/SpinLoader/SpinLaoder";
import Utils from "../../../utilities/Utils/Utils";
import { FieldWithDoubleLabel, SeletWithDoubleLabel } from "../../../utilities/FormFields/FormFields";
import history from "../../../history";
import moment from "moment";

const ListeReclamation = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const reclamations = useSelector(state => state.reclamationReducer?.reclamations);
    const error = useSelector(state => state.reclamationReducer?.error);
    const success = useSelector(state => state.reclamationReducer?.success);

    const artisantId = useSelector(state => state.loginReducer?.artisan?.artisan_id);
    const userType = useSelector(state => state.loginReducer?.type);

    const userLevel = useSelector(state => state.loginReducer?.profil?.niveau);
    const agentRegion = useSelector(state => state.loginReducer?.user?.region);
    const agentProvince = useSelector(state => state.loginReducer?.user?.userProvinces?.map(prov => prov.id));
    const params = useSelector(state => state.globalParamReducer?.params);

    const [searchValues, setSearchValues] = useState({ type: "RECLAMATION" });
    const [villes, setVilles] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [status, setStatus] = useState(Utils.statusReclamation);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        dispatch(resetMessages());
        if (userType === Utils.USER_TYPES.AGENT && userLevel === Utils.USER_LEVEL.LOCAL) {
            getDataForAgentLocal({ type: "RECLAMATION" });
        } else if (userType === Utils.USER_TYPES.AGENT) {
            dispatch(getGlobalParam())
            getDataForAgentCentral({ type: "RECLAMATION" });
        } else if (userType === Utils.USER_TYPES.ARTISAN) {
            getDataForArtisan("RECLAMATION");
        }
    }, []);

    const onChangeTypeDemande = e => {
        if(e && e === "DEMANDE_INFORMATION"){
            setStatus(Utils.statusDemandeInformation)
        }else {
            setStatus(Utils.statusReclamation)
        }
    }

    const onChangeRegion = e => {
        if (e) {
            setProvinces(params.provinces.filter(province => province.regionId === e));
            setVilles(params.villes.filter(ville => ville.regionId === e));
        } else {
            setProvinces(params.provinces)
            setVilles(params.villes)
        }

        form.setFieldsValue({ province: '' })
        form.setFieldsValue({ ville: '' })
    };

    const onChangeProvince = e => {
        if (e) {
            setVilles(params.villes.filter(ville => ville.provinceId === e));
            var province = params.provinces.filter(province => province.id === e);

            if (province) {
                form.setFieldsValue({ region: province[0].regionId })
            }
        } else {
            var region = form.getFieldValue('region')
            setVilles(params.villes.filter(ville => ville.regionId === region));
        }

        form.setFieldsValue({ ville: '' })
    };

    const onChangeVille = e => {
        var ville = params.villes.filter(ville => ville.id === e);

        if (ville && ville.length !== 0) {
            form.setFieldsValue({ region: ville[0].regionId })
            form.setFieldsValue({ province: ville[0].provinceId })

            setProvinces(params.provinces.filter(p => p.regionId === ville[0].regionId));
            setVilles(params.villes.filter(v => v.provinceId === ville[0].provinceId))
        }
    }

    const getDataForAgentCentral = (values) => {
        let searchCiteria = values && values.statut
            ? { ...values, statut: [values.statut] }
            : { ...values };
        dispatch(getGlobalParam())
            .then((res) => dispatch(getAllForAgent({ ...searchCiteria }))
                .then((res) => setLoading(false)))

    }

    const getDataForAgentLocal = (values) => {
        let searchCiteria = values && values.statut
            ? { ...values, statut: [values.statut], 'province': agentProvince }
            : { ...values, 'province': agentProvince };

        searchCiteria = values && values.province
            ? { ...values, 'province': [values.province] }
            : { ...values, 'province': agentProvince };

        dispatch(getGlobalParam())
            .then((res) => dispatch(getAllForAgent({ ...searchCiteria }))
                .then((res) => setLoading(false)))

    }

    const getDataForArtisan = (type) => {
        dispatch(getAllForArtisan(artisantId, type))
            .then((res) => setLoading(false));
    }

    const handleDetails = (reclamationId) => {
        if (userType === Utils.USER_TYPES.AGENT) {
            history.push('/app/agent/reclamations/' + reclamationId)
        } else if (userType === Utils.USER_TYPES.ARTISAN) {
            history.push('/app/artisan/reclamations/mes-reclamation/' + reclamationId)
        }
    }

    const renderStatus = (status) => {
        if (status === 'RECLAMATION_SAISIE' || status === 'DEMANDE_INFORMATION_SAISIE') {
            return (<Tag color="#faad14">Saisie</Tag>);
        } else if (status === 'RECLAMATION_ENCOURS_DE_TRAITEMENT' || status === 'DEMANDE_INFORMATION_ENCOURS_DE_TRAITEMENT') {
            return (<Tag color="#1890ff">En cours de traitement</Tag>);
        } else if (status === 'RECLAMATION_TRAITE'|| status === 'DEMANDE_INFORMATION_TRAITE') {
            return (<Tag color="#52c41a">Traitée</Tag>);
        }
    }

    const onChangeType = (e) => {
        getDataForArtisan(e.target.value)
    }

    const getDelaiStatus = record => {
        const given = moment(record.updatedAt, "DD-MM-YYYYY");
        const current = moment().startOf('day');
        const numberOfDay = moment.duration(current.diff(given)).asDays();

        if (numberOfDay < 15) {
            return (<span> <Tooltip title="Date Création < 15jours" >
                <ClockCircleOutlined style={{ color: 'green' }} />
            </Tooltip>
            </span>)
        } else if (numberOfDay > 15 && numberOfDay < 30) {
            return (<span> <Tooltip title="Date Création entre 15 et 30 jours" >
                <ClockCircleOutlined style={{ color: 'orange' }} />
            </Tooltip></span>)
        } else {
            return (<span> <Tooltip title="Date Création > 30 jours" >
                <ClockCircleOutlined style={{ color: 'red' }} />
            </Tooltip></span>)
        }


    }

    const getColumns = () => {
        let columns = [
            {
                title: t('reclamation.reclamations-list.table.action'),
                dataIndex: 'action',
                key: Math.random,
                render: (text, record, index) => (
                    <Row align='middle'>
                        <Col span={12}>
                            <a onClick={() => handleDetails(record.id)}><EyeOutlined /> </a>
                        </Col>
                    </Row>
                ),
            },
            {
                title: t('reclamation.reclamations-list.table.delaiStatut'),
                dataIndex: 'timeStatut',
                key: 'timeStatut',
                render: (text, record, index) => (
                    <div>
                        {getDelaiStatus(record)}
                    </div>
                )
            },
            {
                title: t('reclamation.reclamations-list.table.statut'),
                dataIndex: 'statut',
                key: 'statut',
                render: (text, record, index) => (
                    renderStatus(record.statut)
                ),
            },
            {
                title: t('reclamation.reclamations-list.table.reference'),
                dataIndex: 'reference',
                key: 'reference'
            }
        ];

        if (userType === Utils.USER_TYPES.AGENT) {
            columns.push(
                {
                    title: t('reclamation.reclamations-list.table.createdAt'),
                    dataIndex: 'createdAt',
                    key: 'createdAt'
                },
                {
                    title: t('reclamation.reclamations-list.table.artisanType'),
                    dataIndex: 'artisanType',
                    key: 'artisanType'
                },
                {
                    title: t('reclamation.reclamations-list.table.nom'),
                    dataIndex: 'nom',
                    key: 'nom'
                },
                {
                    title: t('reclamation.reclamations-list.table.prenom'),
                    dataIndex: 'prenom',
                    key: 'prenom'
                },
                {
                    title: t('reclamation.reclamations-list.table.nomCooperative'),
                    dataIndex: 'nomCooperative',
                    key: 'nomCooperative'
                },
                {
                    title: t('reclamation.reclamations-list.table.nomEntreprise'),
                    dataIndex: 'nomEntreprise',
                    key: 'nomEntreprise'
                },
                {
                    title: t('reclamation.reclamations-list.table.telephone'),
                    dataIndex: 'telephone',
                    key: 'telephone'
                }
            )
        }

        // columns.push(
        //     {
        //         title: t('reclamation.reclamations-list.table.action'),
        //         dataIndex: 'action',
        //         key: Math.random,
        //         render: (text, record, index) => (
        //             <Row align='middle'>
        //                 <Col span={12}>
        //                     <a onClick={() => handleDetails(record.id)}><EyeOutlined /> </a>
        //                 </Col>
        //             </Row>
        //         ),
        //     }
        // )

        return columns;
    }

    const searchReclamations = (values) => {
        setSearchValues(values)
        if (userType === Utils.USER_TYPES.AGENT && userLevel === Utils.USER_LEVEL.LOCAL) {
            getDataForAgentLocal(values);
        } else if (userType === Utils.USER_TYPES.AGENT) {
            getDataForAgentCentral(values);
        }
    }

    const getRegions = () => {
        return params.regions;
    }

    const getProvinces = () => {
        return provinces && provinces.length !== 0 ? provinces : (userLevel === Utils.USER_LEVEL.LOCAL ? params.provinces?.filter(province => agentProvince?.includes(province.id)) : params.provinces);
    }

    const getVilles = () => {
        return villes && villes.length !== 0 ? villes : (userLevel === Utils.USER_LEVEL.LOCAL ? params.villes?.filter(ville => ville.regionId === agentRegion.id) : params.villes);
    }

    return (<>
        {!loading
            ? <>
                {userType === Utils.USER_TYPES.AGENT &&
                    <>
                        <Divider orientation="left">{t('reclamation.reclamations-list.search-from.title')}</Divider>
                        <Form
                            form={form}
                            name="advanced_search_reclamation"
                            className="ant-advanced-search-form"
                            onFinish={searchReclamations}
                            preserve={true}
                            initialValues={searchValues}
                        >
                            <Row gutter={24} style={{ width: '100%', justifyContent: 'space-between' }}>
                                {userLevel === Utils.USER_LEVEL.CENTRAL && <>
                                    <Col lg={11} md={24} sm={24} xs={24} >
                                        <SeletWithDoubleLabel required={false} name="region" label={t('reclamation.reclamations-list.search-from.region')} options={getRegions()} onChangeAction={onChangeRegion} />
                                    </Col>
                                </>
                                }
                                <Col lg={11} md={24} sm={24} xs={24} >
                                    <SeletWithDoubleLabel required={false} name="province" label={t('reclamation.reclamations-list.search-from.province')} options={getProvinces()} onChangeAction={onChangeProvince} />
                                </Col>
                                <Col lg={11} md={24} sm={24} xs={24} >
                                    <SeletWithDoubleLabel required={false} type="text" name="ville" label={t('reclamation.reclamations-list.search-from.ville')} options={getVilles()} onChangeAction={onChangeVille} />
                                </Col>
                                <Col lg={11} md={24} sm={24} xs={24} >
                                    <SeletWithDoubleLabel required={false} type="text" name="statut" label={t('reclamation.reclamations-list.search-from.statut')} options={status} />
                                </Col>
                                <Col lg={11} md={24} sm={24} xs={24} >
                                    <SeletWithDoubleLabel type="text" name="type" label={t('reclamation.reclamations-list.search-from.type.label')} errorMessage={t('reclamation.reclamations-list.search-from.type.error-message')} options={Utils.typeDemande} onChangeAction={onChangeTypeDemande}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    <Button
                                        shape="round"
                                        style={{ margin: '0 8px' }}
                                        onClick={() => {
                                            form.resetFields();
                                            setSearchValues({});
                                        }}
                                    >
                                        {t('reclamation.reclamations-list.search-from.reset')}
                                    </Button>
                                    <Button shape="round" type="primary" htmlType="submit">
                                        {t('reclamation.reclamations-list.search-from.search')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }
                {userType === Utils.USER_TYPES.ARTISAN &&
                    <>
                        <Divider orientation="left">{t('reclamation.reclamations-list.search-from.title')}</Divider>
                        <Form
                            form={form}
                            name="advanced_search_reclamation"
                            className="ant-advanced-search-form"
                            preserve={true}
                        >
                            <FieldWithDoubleLabel name="type" label={t('reclamation.create-reclamation.form.type.label')} errorMessage={t('reclamation.create-reclamation.form.message.error-message')}>
                                <Radio.Group style={{ width: '100%' }} onChange={onChangeType} defaultValue={"RECLAMATION"}>
                                    <Radio value="RECLAMATION">{t('reclamation.create-reclamation.form.type.reclamation')}</Radio>
                                    <Radio value="DEMANDE_INFORMATION" >{t('reclamation.create-reclamation.form.type.information')}</Radio>
                                </Radio.Group>
                            </FieldWithDoubleLabel>
                        </Form>
                    </>
                }
                {userType === Utils.USER_TYPES.AGENT
                    ? <Divider>{t('reclamation.reclamations-list.title-agent')}</Divider>
                    : <Divider>{t('reclamation.reclamations-list.title-artisan')}</Divider>
                }
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Table columns={getColumns()} dataSource={reclamations} scroll={{ x: 500 }} pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }} />
                    </Col>

                </Row>

            </>

            : <SpinLoader />

        }


    </>);
}

export default ListeReclamation;