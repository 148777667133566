import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_DUREE: 'GET_DUREE',
    GET_DUREE_PENDING: `GET_DUREE_${ActionType.Pending}`,
    GET_DUREE_FULFILLED: `GET_DUREE_${ActionType.Fulfilled}`,
    GET_DUREE_REJECTED: `GET_DUREE_${ActionType.Rejected}`,

    GET_DIPLOME: 'GET_DIPLOME',
    GET_DIPLOME_PENDING: `GET_DIPLOME_${ActionType.Pending}`,
    GET_DIPLOME_FULFILLED: `GET_DIPLOME_${ActionType.Fulfilled}`,
    GET_DIPLOME_REJECTED: `GET_DIPLOME_${ActionType.Rejected}`,

    GET_DIPLOME_DUREE: 'GET_DIPLOME_DUREE',
    GET_DIPLOME_DUREE_PENDING: `GET_DIPLOME_DUREE_${ActionType.Pending}`,
    GET_DIPLOME_DUREE_FULFILLED: `GET_DIPLOME_DUREE_${ActionType.Fulfilled}`,
    GET_DIPLOME_DUREE_REJECTED: `GET_DIPLOME_DUREE_${ActionType.Rejected}`,

    ADD_DIPLOME_DUREE: 'ADD_DIPLOME_DUREE',
    ADD_DIPLOME_DUREE_PENDING: `ADD_DIPLOME_DUREE_${ActionType.Pending}`,
    ADD_DIPLOME_DUREE_FULFILLED: `ADD_DIPLOME_DUREE_${ActionType.Fulfilled}`,
    ADD_DIPLOME_DUREE_REJECTED: `ADD_DIPLOME_DUREE_${ActionType.Rejected}`,

    UPDATE_DIPLOME_DUREE: 'UPDATE_DIPLOME_DUREE',
    UPDATE_DIPLOME_DUREE_PENDING: `UPDATE_DIPLOME_DUREE_${ActionType.Pending}`,
    UPDATE_DIPLOME_DUREE_FULFILLED: `UPDATE_DIPLOME_DUREE_${ActionType.Fulfilled}`,
    UPDATE_DIPLOME_DUREE_REJECTED: `UPDATE_DIPLOME_DUREE_${ActionType.Rejected}`,
};

export default ActionTypes;
