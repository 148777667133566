import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Typography, Alert, Divider, Card, Space } from 'antd';
import { getGlobalParam } from '../../../../../../GlobalParams/Action';
import { getPublicPrive, getDepartementFormation, addDepartementFormation, updateDepartementFormation } from '../../Action'
import { useTranslation } from 'react-i18next';
import { InputWithDoubleLabel, SeletWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import Utils from "../../../../../../../utilities/Utils/Utils";
import SpinLoader from '../../../../../../../component/SpinLoader/SpinLaoder';
const { Title } = Typography;


const ReferentielDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);


    const { handleClose, activeReferenciel, action, refType } = props;
    const loadedpublicPrive = useSelector(state => state.referentielParamsReducer?.referentielDepartementFormation?.publicPrive);
    const [publicPrive, setPublicPrive] = useState(loadedpublicPrive);


    useEffect(() => {
        setPublicPrive(getSelectData(loadedpublicPrive))

    }, []);

    const getSelectData = (data) => {
        return data.map(line => {
            return {
                id: line.key,
                name: {
                    AR: line.label_ar,
                    FR: line.label_fr
                }
            }
        })
    }

    const onFinish = (values) => {
        const { loadDataOrRedirect, setSuccesseMessage, setErrorMessage } = props;
        const payload = {
            entity_type: Utils.REFERENTIEL_ENTITY_TYPES.DEPARTEMENT_FORMATION,
            payload: values
        }
        if (action === 'ADD') {
            dispatch(addDepartementFormation(payload))
                .then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.departementFormation.add'))
                    loadDataOrRedirect();
                })
                .catch(err => {
                    setErrorMessage(err)
                })
        } else {
            dispatch(updateDepartementFormation(activeReferenciel.key, payload))
                .then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.departementFormation.update'))
                    loadDataOrRedirect();
                })
                .catch(err => {
                    setErrorMessage(err)
                })
        }
        handleClose();
    };

    const getInitData = () => {
        return action !== 'ADD' ? activeReferenciel : {};
    }

    const isConsultation = () => {
        return action === 'CONSULTATION';
    }

    const getTitle = () => {
        switch (props.action) {
            case 'CONSULTATION':
                return t('agent.referentiel.consultation.departementFormation');
            case 'UPDATE':
                return t('agent.referentiel.update.departementFormation');
            case 'ADD':
                return t('agent.referentiel.add.departementFormation');
            default:
                break;
        }
    }
    const onChangepublicPrive = (e) => {
        const filtredPublicPrive = publicPrive.filter(p => p.id === e);
        form.setFieldsValue({ code_public_prive_id: filtredPublicPrive[0].id })
    }

    return (
        <>
            {loading ? <SpinLoader /> :
                <Modal visible={true} width={1500} onCancel={handleClose}
                    footer=
                    {
                        !isConsultation() &&
                        <>
                            <Button shape="round" key="back" onClick={handleClose}>
                                {t('agent.referentiel.modal.cancel.label')}
                            </Button>
                            <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                                {t('agent.referentiel.modal.submit.label')}
                            </Button>
                        </>
                    }
                >

                    <Form form={form} onFinish={onFinish} initialValues={getInitData()}>
                        <Title level={5} style={{ paddingBottom: '20px' }}>
                            {getTitle()}
                        </Title >
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <SeletWithDoubleLabel type="text" name="codepublicPriveId" label={t('agent.referentiel.modal.publicPrive.label')} errorMessage={t('agent.referentiel.modal.publicPrive.error-message')} options={publicPrive} onChangeAction={onChangepublicPrive} disabled={isConsultation()} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="label_fr" label={t('agent.referentiel.modal.label-fr.label')} errorMessage={t('agent.referentiel.modal.label-fr.error-message')} disabled={isConsultation()} />
                            </Col>
                            <Divider type="vertical" />
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="label_ar" label={t('agent.referentiel.modal.label-ar.label')} errorMessage={t('agent.referentiel.modal.label-ar.error-message')} disabled={isConsultation()} />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ReferentielDetail;
