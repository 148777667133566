import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
  GET_ATTESTATION_ARTISAN: 'GET_ATTESTATION_ARTISAN',
  GET_ATTESTATION_ARTISAN_PENDING: `GET_ATTESTATION_ARTISAN_${ActionType.Pending}`,
  GET_ATTESTATION_ARTISAN_FULFILLED: `GET_ATTESTATION_ARTISAN_${ActionType.Fulfilled}`,
  GET_ATTESTATION_ARTISAN_REJECTED: `GET_ATTESTATION_ARTISAN_${ActionType.Rejected}`
}

export default ActionTypes;
