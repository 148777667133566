import { Alert, Col, Row, Space, Steps } from 'antd';
import React from 'react';
import RepresentativePersonalData from '../components/RepresentativePersonalData/RepresentativePersonalData';
import ProfessionalData from '../components/ProfessionalData/ProfessionalData';
import RegistrationResult from '../components/RegistrationResult/RegistrationResult';
import UploadDocument from '../components/UploadDocument/UploadDocument';
import SpinLoader from '../../../../component/SpinLoader/SpinLaoder';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getArtisanDataById } from '../Action';

const { Step } = Steps;

const ArtisanP4 = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const { params, referentiel, artisanId, artisanType, artisantData, documents, uploadArtisanFile, removeArtisanFile, finalizeSubscription, finalizeArtisanStatus } = props;
    const [current, setCurrent] = React.useState(0);
    const loading = useSelector(state => state.registration?.loading);
    const error = useSelector(state => state.registration?.error);
    const pathname = history.location?.pathname;
    const dispatch = useDispatch();

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
        dispatch(getArtisanDataById(artisanId));
    };

    const validateSubscription = () => {
        if (pathname.includes('souscrire')) {
            finalizeSubscription(artisanId).then((result) => {
                next();
                finalizeArtisanStatus();
            }).catch((err) => {
            });;
        } else {
            next();
        }
    }
    const steps = [
        {
            title: t('artisan.registration.artisanP4.steps.professional-data'),
            content: <ProfessionalData params={params} referentiel={referentiel} initData={artisantData ? artisantData.donnees_professionnelle : {}} next={next} artisanType={artisanType} />,
        },
        {
            title: t('artisan.registration.artisanP4.steps.representative-personal-data'),
            content: <RepresentativePersonalData params={params} referentiel={referentiel} initData={artisantData ? artisantData.donnees_representant : {}} prev={prev} next={next} />,
        },
        {
            title: t('artisan.registration.artisanP4.steps.upload-document'),
            content: <UploadDocument artisanType={artisanType} artisanId={artisanId} documents={documents} uploadArtisanFile={uploadArtisanFile} removeArtisanFile={removeArtisanFile} validateSubscription={validateSubscription} prev={prev} />,
        },
        {
            title: t('artisan.registration.artisanP4.steps.registration-result'),
            content: <RegistrationResult />,
        },
    ];

    return (<>
        <Space size={"middle"} direction='vertical' style={{ width: '100%' }}>
            <Row type="flex">
                <Space size={"middle"} direction='vertical' style={{ width: '100%' }}>
                    <Col span={24} ><h2>{t('artisan.registration.artisanP4.title')}</h2></Col>
                    <Col span={24}>
                        <Steps current={current} size="small">
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                    </Col>
                </Space>
            </Row>
            {error &&
                <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                    <Col span={24}>
                        <Alert
                            style={{ textAlign: 'center', width: '100%', fontSize: 'large' }}
                            message={t(error)}
                            type="error"
                            closable
                        />
                    </Col>
                </Row>
            }
            {loading ? <SpinLoader /> :
                <Row>
                    <Col span={24}><div className="steps-content">{steps[current].content}</div></Col>
                </Row>
            }
        </Space>
    </>)
}

export default ArtisanP4;