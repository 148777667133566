import React, { useEffect, useRef, useState } from 'react';
import {
    Row, Col, Form,
    Space,
    Card,
    Divider,
} from "antd";
import { InputWithDoubleLabel, InputNumberWithDoubleLabel, DateWithDoubleLabel, CustomSeletWithDoubleLabel, SeletWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getReferentiels, addNewEstablshment, getEtablissement, getDuree, getDiplome } from './../../../../../../Referentiels/Action';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Utils from '../../../../../../../utilities/Utils/Utils';

const EducationData = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const ref = useRef(null);
    const params = useSelector(state => state.referentielReducer?.params);
    const etablissement = useSelector(state => state.referentielReducer?.etablissement);
    const diplome = useSelector(state => state.referentielReducer?.diplome);
    const duree = useSelector(state => state.referentielReducer?.duree);
    const [provinces, setProvinces] = useState(props.params.provinces);
    const [regions, setRegions] = useState(props.params.regions);
    const [showOtherEtablishement, setShowOtherEtablishement] = useState(props.initData?.autre_etablissement || false);
    const [showOtherDiplome, setShowOtherDiplome] = useState(props.initData?.autre_formation || false);

    useEffect(() => {
        dispatch(getDiplome());
        dispatch(getEtablissement());
        dispatch(getDuree());
        // dispatch(getReferentiels());
    }, [])

    const getInitData = () => {
        if (!isEmpty(props.initData) && props.initData.date_diplome) {
            return { ...props.initData, date_diplome: moment(props.initData.date_diplome, "YYYY") }
        }
        return props.initData;
    }

    return (
        <Card  >
            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }}>
                <Space direction="vertical" size="middle" className="w-100">
                    <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
                        <Form form={form}
                            name="registration"
                            style={{ width: '100%' }}
                            size='small'
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            layout="horizontal"
                            scrollToFirstError
                            initialValues={getInitData()}
                        >
                            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Col lg={11} md={24} sm={24} xs={24}>
                                    <SeletWithDoubleLabel type="text" name="region" label={t('component.personal-data.form.region.label')} placeholder={t('component.personal-data.form.region.placeholder')} options={regions} errorMessage={t('component.personal-data.form.region.error-message')} disabled />
                                </Col>
                                <Divider type="vertical" />
                                <Col lg={11} md={24} sm={24} xs={24}>
                                    <SeletWithDoubleLabel type="text" name="province" label={t('component.personal-data.form.province.label')} placeholder={t('component.personal-data.form.province.placeholder')} options={provinces} errorMessage={t('component.personal-data.form.province.error-message')} disabled />
                                </Col>
                            </Row>

                            <Col span={24}>
                                <SeletWithDoubleLabel type="text" name="etablissement" label={t('component.education-data.form.etablissement.label')} placeholder={t('component.education-data.etablissement.form.formation.placeholder')} options={etablissement} errorMessage={t('component.education-data.form.etablissement.error-message')} disabled={true} />
                            </Col>
                            {showOtherEtablishement &&
                                <Col span={24}>
                                    <InputWithDoubleLabel disabled form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="autre_etablissement" label={t('component.education-data.form.autre-etablissement.label')} placeholder={t('component.education-data.form.autre-etablissement.placeholder')} errorMessage={t('component.education-data.form.autre-etablissement.error-message')} />
                                </Col>
                            }
                            <Col span={24}>
                                <SeletWithDoubleLabel name="formation" label={t('component.education-data.form.formation.label')} disabled={true} placeholder={t('component.education-data.form.formation.placeholder')} errorMessage={t('component.education-data.form.formation.error-message')} options={diplome} />
                            </Col>
                            {showOtherDiplome &&
                                <Col span={24}>
                                    <InputWithDoubleLabel disabled form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="autre_formation" label={t('component.education-data.form.autre-formation.label')} placeholder={t('component.education-data.form.autre-formation.placeholder')} errorMessage={t('component.education-data.form.autre-formation.error-message')} />
                                </Col>
                            }
                            <Col span={24}>
                                <DateWithDoubleLabel type="text" name="date_diplome" label={t('component.education-data.form.date-diplome.label')} disabled={true} picker="year" />
                            </Col>

                            <Col span={24}>
                                <SeletWithDoubleLabel name="duree_de_formation" label={t('component.education-data.form.duree-de-formation.label')} errorMessage={t('component.education-data.form.duree-de-formation.error-message')} options={duree} disabled={true} />
                            </Col>
                            <Col span={24}>
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALPHABETIC_WITH_SPACE} name="specialite" label={t('component.education-data.form.specialite.label')} disabled={true} />
                            </Col>
                        </Form>
                    </Col>
                </Space>
            </Row >
        </Card>
    );

};


export default EducationData;