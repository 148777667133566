import types from './ActionTypes';
import Api from '../../../utilities/services/Api';

export const savePersonalData = (artisanId, payload) => {
  return ({
    type: types.REGISTER_ARTISAN_PERSONNAL_DATA,
    payload: Api.accounts.savePersonalData(artisanId, payload),
  })
};

export const saveProfessionalData = (artisanId, payload) => {
  return ({
    type: types.REGISTER_ARTISAN_PROFESSIONAL_DATA,
    payload: Api.accounts.saveProfessionalData(artisanId, payload),
  })
};

export const saveEducationData = (artisanId, payload) => {
  return ({
    type: types.REGISTER_ARTISAN_EDUCATIONAL_DATA,
    payload: Api.accounts.saveEducationData(artisanId, payload),
  })
};

export const saveRepresentantData = (artisanId, payload) => {
  return ({
    type: types.REGISTER_ARTISAN_REPRESENTANT_DATA,
    payload: Api.accounts.saveRepresentantData(artisanId, payload),
  })
};
export const updatePersonalData = (artisanId, payload) => {
  return ({
    type: types.UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA,
    payload: Api.accounts.updatePersonalData(artisanId, payload),
  })
};

export const updateProfessionalData = (artisanId, payload) => {
  return ({
    type: types.UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA,
    payload: Api.accounts.updateProfessionalData(artisanId, payload),
  })
};

export const updateEducationData = (artisanId, payload) => {
  return ({
    type: types.UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA,
    payload: Api.accounts.updateEducationData(artisanId, payload),
  })
};

export const updateRepresentantData = (artisanId, payload) => {
  return ({
    type: types.UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA,
    payload: Api.accounts.updateRepresentantData(artisanId, payload),
  })
};

export const getArtisanFile = (artisanId, documentId) => {
  return ({
    type: types.GET_ARTISANT_FILE,
    payload: Api.accounts.getArtisanFile(artisanId, documentId),
  })
};

export const getArtisanFilesList = (artisanId) => {
    return ({
      type: types.GET_ARTISANT_FILES_LIST,
      payload: Api.accounts.getArtisanFilesList(artisanId),
    })
};

export const uploadArtisanFile = (artisanId, file) => {
  return ({
    type: types.UPLOAD_ARTISAN_FILE,
    payload: Api.accounts.uploadArtisanFile(artisanId, file),
  })
};

export const removeArtisanFile = (artisanId, file) => {
  return ({
    type: types.REMOVE_ARTISAN_FILE,
    payload: Api.accounts.removeArtisanFile(artisanId, file),
  })
};

export const getDocumentTypeByArtisanId = artisanId => {
  return ({
    type: types.GET_DOCUMENT_TYPES,
    payload: Api.accounts.getDocumentTypeByArtisanId(artisanId),
  })
};
export const finalizeSubscription = artisanId => {
  return ({
    type: types.FINALIZE_SUBSCRIPTION,
    payload: Api.accounts.finalizeSubscription(artisanId),
  })
};

export const getArtisanDataById = artisanId => {
  return ({
    type: types.GET_ARTISANT_BY_ID,
    payload: Api.accounts.getArtisanDataById(artisanId),
  })
};

export const loadDgsnAuthInfos = (citizenId) => ({
   type: types.LOAD_DGSN_AUTH_INFOS,
   payload: Api.dgsn.loadInfos(citizenId)
});