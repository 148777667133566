import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { EndpointsRequestsMetrics } from '../../../../component/EndpointsRequestsMetrics';
import { HttpRequestMetrics } from '../../../../component/HttpRequestMetrics';
import { JvmMemory } from '../../../../component/JvmMemory';
import { JvmThreads } from '../../../../component/JvmThreads';
import { SystemMetrics } from '../../../../component/SystemMetrics';
import { APP_TIMESTAMP_FORMAT, APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT, APP_WHOLE_NUMBER_FORMAT } from '../constants';
import { systemMetrics, systemThreadDump } from '../Action';
import { Button, Tabs } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

export const Metrics = (props) => {

  useEffect(() => {
    props.systemMetrics();
    // props.systemThreadDump();
  }, []);

  const getMetrics = () => {
    if (!props.isFetching) {
      props.systemMetrics();
      // props.systemThreadDump();
    }
  };

  const { metrics, threadDump, isFetching } = props;
  const { TabPane } = Tabs;

  return (
    <div>
      <h2 id="metrics-page-heading" data-cy="metricsPageHeading">
        Application Metrics
            <Button shape="round" onClick={getMetrics} icon={<ReloadOutlined />} disabled={isFetching} style={{ float: 'right' }}>
          &nbsp;
          Refresh
            </Button>
      </h2>

      <hr />

      {metrics && metrics.services ? (
        <EndpointsRequestsMetrics endpointsRequestsMetrics={metrics.services} wholeNumberFormat={APP_WHOLE_NUMBER_FORMAT} />
      ) : (
        ''
      )}

      {metrics && metrics['http.server.requests'] ? (
        <HttpRequestMetrics
          requestMetrics={metrics['http.server.requests']}
          twoDigitAfterPointFormat={APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT}
          wholeNumberFormat={APP_WHOLE_NUMBER_FORMAT}
        />
      ) : (
        ''
      )}

      <h3>JVM Metrics</h3>
      <Tabs defaultActiveKey="1" type="card" size="large">
        <TabPane tab="Memory" key="1">
          {metrics && metrics.jvm ? <JvmMemory jvmMetrics={metrics.jvm} wholeNumberFormat={APP_WHOLE_NUMBER_FORMAT} /> : ''}
        </TabPane>
        <TabPane tab="Threads" key="2">
          {threadDump ? <JvmThreads jvmThreads={threadDump} wholeNumberFormat={APP_WHOLE_NUMBER_FORMAT} /> : ''}
        </TabPane>
        <TabPane tab="Systeme" key="3">
          {metrics && metrics.processMetrics ? (
            <SystemMetrics
              systemMetrics={metrics.processMetrics}
              wholeNumberFormat={APP_WHOLE_NUMBER_FORMAT}
              timestampFormat={APP_TIMESTAMP_FORMAT}
            />
          ) : (
            ''
          )}
        </TabPane>
      </Tabs>

    </div>
  );
};

const mapStateToProps = (state) => ({
  metrics: state.administrationReducer.metrics,
  isFetching: state.administrationReducer.loading,
  threadDump: state.administrationReducer.threadDump,
});

const mapDispatchToProps = { systemMetrics, systemThreadDump };

export default connect(mapStateToProps, mapDispatchToProps)(Metrics);
