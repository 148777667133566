module.exports = `<html>
<body>

<div >

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><b><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577;&#1575;&#1604;&#1571;&#1608;&#1604;&#1609;1:
&#1578;&#1602;&#1583;&#1610;&#1605; &#1604;&#1605;&#1608;&#1602;&#1593;<span
style='mso-spacerun:yes'>  </span>&#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; </span></b><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;<b><o:p></o:p></b></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1578;&#1608;&#1601;&#1585; &#1604;&#1603;&#1605; &#1608;&#1586;&#1575;&#1585;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1608;&#1575;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; <span
class=GramE>&#1608; &#1575;&#1604;&#1575;&#1602;&#1578;&#1589;&#1575;&#1583;</span>
&#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;
&#1608;&#1575;&#1604;&#1578;&#1590;&#1575;&#1605;&#1606;&#1610; &#1605;&#1608;&#1602;&#1593;&#1607;&#1575;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; </span><a
href="http://rna.gov.ma"><span dir=LTR style='font-size:14.0pt;line-height:
115%;mso-bidi-language:AR-MA'>rna.gov.ma</span></a><span dir=LTR
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'> </span><span
dir=RTL></span><span lang=AR-MA style='font-size:14.0pt;line-height:115%;
mso-bidi-language:AR-MA'><span dir=RTL></span>(&#1575;&#1604;&#1605;&#1588;&#1575;&#1585;
&#1573;&#1604;&#1610;&#1607; &#1576;&#1593;&#1583;&#1607;
&#1576;&#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;)&#1548;
&#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603; &#1603;&#1604;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
&#1608;&#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1610;&#1575;&#1578;&nbsp;&#1608;&#1575;&#1604;&#1576;&#1585;&#1605;&#1580;&#1610;&#1575;&#1578;&#1548;
&#1605;&#1593; &#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;
&#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1577; &#1608;&#1603;&#1604;
&#1575;&#1604;&#1608;&#1579;&#1575;&#1574;&#1602; &#1575;&#1604;&#1608;&#1575;&#1585;&#1583;&#1577;
&#1601;&#1610;&#1607;&#1575;:<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><b><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 2:
&#1575;&#1604;&#1608;&#1604;&#1608;&#1580; &#1573;&#1604;&#1609;
&#1605;&#1608;&#1602;&#1593; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; </span></b><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;<b>
&#1608;&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;&#1607;<o:p></o:p></b></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1573;&#1606; &#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;
&#1605;&#1606;&#1589;&#1577; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;
&#1605;&#1578;&#1575;&#1581; &#1604;&#1603;&#1604; &#1588;&#1582;&#1589;
&#1610;&#1578;&#1608;&#1601;&#1585; &#1593;&#1604;&#1609; &#1575;&#1604;&#1585;&#1576;&#1591;
&#1576;&#1575;&#1604;&#1571;&#1606;&#1578;&#1585;&#1606;&#1578;. &#1601;&#1575;&#1604;&#1608;&#1604;&#1608;&#1580;
&#1573;&#1604;&#1609; &#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1605;&#1580;&#1575;&#1606;&#1610;
&#1608;&#1604;&#1575; &#1610;&#1578;&#1585;&#1578;&#1576; &#1593;&#1606;&#1607;
&#1571;&#1610; &#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
&#1576;&#1575;&#1604;&#1588;&#1585;&#1575;&#1569;&#1548; &#1573;&#1604;&#1575;
&#1571;&#1606;&#1607; &#1576;&#1575;&#1604;&#1605;&#1602;&#1575;&#1576;&#1604; &#1610;&#1593;&#1606;&#1610;
&#1575;&#1604;&#1602;&#1576;&#1608;&#1604; &#1575;&#1604;&#1603;&#1604;&#1610;
&#1608;&#1576;&#1583;&#1608;&#1606; &#1578;&#1581;&#1601;&#1592; &#1604;&#1603;&#1604;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
&#1604;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1576;&#1575;&#1604;&#1573;&#1590;&#1575;&#1601;&#1577;
&#1573;&#1604;&#1609; &#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
&#1604;&#1604;&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;&#1548;
&#1610;&#1580;&#1576; &#1593;&#1604;&#1609;
&#1605;&#1587;&#1578;&#1593;&#1605;&#1604; &#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; (&#1575;&#1604;&#1605;&#1588;&#1575;&#1585;
&#1573;&#1604;&#1610;&#1607; &#1576;&#1593;&#1583;&#1607; «&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&quot;)
&#1602;&#1576;&#1608;&#1604; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577; &#1601;&#1610;
&#1573;&#1591;&#1575;&#1585; &#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578; &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1608;&#1601;&#1585;&#1577; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1585;&#1602;&#1605;&#1610;&#1577;
&#1604;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><b><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 3:
&#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
&#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;<o:p></o:p></span></b></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1605;&#1608;&#1602;&#1593; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1607;&#1608; &#1605;&#1604;&#1603;&#1610;&#1577;
&#1581;&#1589;&#1585;&#1610;&#1577; &#1604;&#1608;&#1586;&#1575;&#1585;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1608;&#1575;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1608;
&#1575;&#1604;&#1575;&#1602;&#1578;&#1589;&#1575;&#1583;
&#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;
&#1608;&#1575;&#1604;&#1578;&#1590;&#1575;&#1605;&#1606;&#1610;. <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1593;&#1578;&#1576;&#1585; &#1575;&#1604;&#1578;&#1602;&#1583;&#1610;&#1605;
&#1575;&#1604;&#1582;&#1575;&#1589; &#1576;&#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;
&#1608;&#1603;&#1584;&#1575; &#1605;&#1590;&#1605;&#1608;&#1606;&#1607;&#1575; &#1593;&#1605;&#1604;&#1575;
&#1571;&#1589;&#1604;&#1610;&#1575; &#1578;&#1581;&#1605;&#1610;&#1607;
&#1575;&#1604;&#1602;&#1608;&#1575;&#1606;&#1610;&#1606; &#1608;&#1575;&#1604;&#1605;&#1593;&#1575;&#1607;&#1583;&#1575;&#1578;
&#1575;&#1604;&#1583;&#1608;&#1604;&#1610;&#1577; &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
&#1576;&#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
&#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;&#1548;
&#1608;&#1582;&#1575;&#1589;&#1577; &#1578;&#1604;&#1603;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577;
&#1576;&#1581;&#1602;&#1608;&#1602; &#1575;&#1604;&#1605;&#1572;&#1604;&#1601; &#1608;&#1605;&#1575;
&#1588;&#1575;&#1576;&#1607; &#1584;&#1604;&#1603;
&#1603;&#1575;&#1604;&#1585;&#1587;&#1608;&#1605;
&#1608;&#1575;&#1604;&#1606;&#1605;&#1575;&#1584;&#1580;
&#1608;&#1575;&#1604;&#1593;&#1604;&#1575;&#1605;&#1575;&#1578;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1578;&#1581;&#1578;&#1608;&#1610; &#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1593;&#1604;&#1609;
&#1606;&#1589;&#1608;&#1589; &#1608;&#1589;&#1608;&#1585;
&#1608;&#1601;&#1608;&#1578;&#1608;&#1594;&#1585;&#1575;&#1601;&#1610;&#1575;
&#1608;&#1588;&#1593;&#1575;&#1585;&#1575;&#1578;
&#1608;&#1593;&#1604;&#1575;&#1605;&#1575;&#1578; &#1607;&#1610; &#1601;&#1610;
&#1605;&#1604;&#1603;&#1610;&#1577; &#1608;&#1586;&#1575;&#1585;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1608;&#1575;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1608;
&#1575;&#1604;&#1575;&#1602;&#1578;&#1589;&#1575;&#1583;
&#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;
&#1608;&#1575;&#1604;&#1578;&#1590;&#1575;&#1605;&#1606;&#1610; &#1608;&#1605;&#1606;
&#1576;&#1610;&#1606;&#1607;&#1575; &#1578;&#1604;&#1603;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1603;&#1608;&#1606;
&#1605;&#1608;&#1590;&#1608;&#1593; &#1578;&#1585;&#1582;&#1610;&#1589;
&#1610;&#1605;&#1606;&#1581; &#1581;&#1602;
&#1575;&#1587;&#1578;&#1594;&#1604;&#1575;&#1604;&#1607;&#1575; &#1605;&#1606;
&#1591;&#1585;&#1601; &#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1569;
&#1575;&#1604;&#1605;&#1575;&#1604;&#1603;&#1610;&#1606; &#1604;&#1607;&#1575;
&#1604;&#1601;&#1575;&#1574;&#1583;&#1577;
&#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577;. &#1608;&#1607;&#1584;&#1607;
&#1575;&#1604;&#1593;&#1606;&#1575;&#1589;&#1585; &#1607;&#1610;
&#1571;&#1610;&#1590;&#1575; &#1605;&#1581;&#1605;&#1610;&#1577; &#1576;&#1602;&#1575;&#1606;&#1608;&#1606;
&#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577; &#1575;&#1604;&#1589;&#1606;&#1575;&#1593;&#1610;&#1577;
&#1608;&#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1603;&#1604; &#1573;&#1593;&#1575;&#1583;&#1577;
&#1573;&#1606;&#1578;&#1575;&#1580; &#1571;&#1608;
&#1578;&#1586;&#1610;&#1610;&#1601; &#1571;&#1608; &#1606;&#1588;&#1585;
&#1571;&#1608; &#1575;&#1587;&#1578;&#1594;&#1604;&#1575;&#1604;
&#1576;&#1588;&#1603;&#1604; &#1593;&#1575;&#1605;&#1548; &#1587;&#1608;&#1575;&#1569;
&#1603;&#1575;&#1606; &#1603;&#1604;&#1610;&#1575; &#1571;&#1605;
&#1580;&#1586;&#1574;&#1610;&#1575;&#1548; &#1608;&#1576;&#1571;&#1610;
&#1588;&#1603;&#1604; &#1605;&#1606;
&#1575;&#1604;&#1571;&#1588;&#1603;&#1575;&#1604;&#1548; &#1604;&#1603;&#1604;
&#1575;&#1604;&#1593;&#1606;&#1575;&#1589;&#1585;
&#1575;&#1604;&#1605;&#1603;&#1608;&#1606;&#1577;
&#1604;&#1605;&#1606;&#1589;&#1577; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1571;&#1608;
&#1580;&#1586;&#1569; &#1605;&#1606;&#1607;&#1575;&#1548; &#1583;&#1608;&#1606;
&#1578;&#1585;&#1582;&#1610;&#1589; &#1605;&#1587;&#1576;&#1602; &#1605;&#1606;
&#1608;&#1586;&#1575;&#1585;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1608;&#1575;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1608;
&#1575;&#1604;&#1575;&#1602;&#1578;&#1589;&#1575;&#1583; &#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;
&#1608;&#1575;&#1604;&#1578;&#1590;&#1575;&#1605;&#1606;&#1610; &#1608;&#1571;&#1589;&#1581;&#1575;&#1576;
&#1575;&#1604;&#1581;&#1602;&#1608;&#1602;&#1548;
&#1605;&#1605;&#1606;&#1608;&#1593;&#1577; &#1605;&#1606;&#1593;&#1575;
&#1603;&#1604;&#1610;&#1575; &#1608;&#1578;&#1588;&#1603;&#1604;
&#1578;&#1586;&#1608;&#1610;&#1585;&#1575; &#1610;&#1593;&#1575;&#1602;&#1576;
&#1593;&#1604;&#1610;&#1607; &#1576;&#1605;&#1608;&#1580;&#1576;
&#1575;&#1604;&#1602;&#1608;&#1575;&#1606;&#1610;&#1606;
&#1575;&#1604;&#1605;&#1606;&#1592;&#1605;&#1577; &#1604;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
&#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;. <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1578;&#1581;&#1578;&#1601;&#1592;
&#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577; &#1576;&#1581;&#1602;&#1607;&#1575;
&#1601;&#1610; &#1605;&#1578;&#1575;&#1576;&#1593;&#1577; &#1603;&#1604;
&#1605;&#1606; &#1610;&#1582;&#1575;&#1604;&#1601;
&#1575;&#1581;&#1578;&#1585;&#1575;&#1605; &#1581;&#1602;&#1608;&#1602; &#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
&#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1607;&#1575;. &#1608;&#1578;&#1603;&#1608;&#1606;
&#1607;&#1584;&#1607; &#1575;&#1604;&#1580;&#1585;&#1610;&#1605;&#1577;
&#1593;&#1585;&#1590;&#1577; &#1604;&#1604;&#1593;&#1602;&#1608;&#1576;&#1577;
&#1575;&#1604;&#1580;&#1606;&#1575;&#1574;&#1610;&#1577; &#1608;&#1575;&#1604;&#1605;&#1583;&#1606;&#1610;&#1577;&#1548;
&#1604;&#1575; &#1587;&#1610;&#1605;&#1575; <span class=SpellE>&#1605;&#1575;&#1610;&#1578;&#1593;&#1604;&#1602;</span>
&#1576;&#1575;&#1604;&#1578;&#1586;&#1610;&#1610;&#1601; &#1571;&#1608; &#1575;&#1604;&#1605;&#1587;
&#1576;&#1581;&#1602;&#1608;&#1602;
&#1575;&#1604;&#1578;&#1571;&#1604;&#1610;&#1601;
&#1608;&#1575;&#1604;&#1581;&#1602;&#1608;&#1602; &#1575;&#1604;&#1605;&#1588;&#1575;&#1576;&#1607;&#1577;
&#1604;&#1607;&#1575;&#1548; &#1608;&#1575;&#1604;&#1578;&#1610; &#1610;&#1578;&#1585;&#1578;&#1576;
&#1593;&#1606;&#1607;&#1575;&#1548; &#1593;&#1606;&#1583;
&#1575;&#1604;&#1575;&#1602;&#1578;&#1590;&#1575;&#1569;&#1548; &#1583;&#1601;&#1593;
&#1575;&#1604;&#1578;&#1593;&#1608;&#1610;&#1590;&#1575;&#1578; &#1593;&#1606;
&#1575;&#1604;&#1590;&#1585;&#1585;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><b><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 4:
&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
&#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577;<o:p></o:p></span></b></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1578;&#1576;&#1584;&#1604;
&#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577;
&#1602;&#1589;&#1575;&#1585;&#1609; &#1580;&#1607;&#1583;&#1607;&#1575;
&#1604;&#1603;&#1610; &#1578;&#1603;&#1608;&#1606; &#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1602;&#1575;&#1576;&#1604;&#1577;
&#1604;&#1604;&#1608;&#1604;&#1608;&#1580; &#1591;&#1610;&#1604;&#1577;
&#1575;&#1604;&#1610;&#1608;&#1605; &#1608;&#1603;&#1604; &#1571;&#1610;&#1575;&#1605;
&#1575;&#1604;&#1571;&#1587;&#1576;&#1608;&#1593; &#1608;&#1601;&#1610;
&#1571;&#1581;&#1587;&#1606; &#1575;&#1604;&#1592;&#1585;&#1608;&#1601; &#1590;&#1605;&#1575;&#1606;&#1575;
&#1604;&#1585;&#1575;&#1581;&#1577;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;. <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1604;&#1575; &#1578;&#1590;&#1605;&#1606;
&#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577; &#1582;&#1604;&#1608;
&#1605;&#1606;&#1589;&#1577; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1605;&#1606;
&#1575;&#1604;&#1593;&#1610;&#1608;&#1576;&#1548;
&#1608;&#1576;&#1584;&#1604;&#1603;&#1548; &#1610;&#1605;&#1603;&#1606;
&#1571;&#1606; &#1610;&#1603;&#1608;&#1606;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1593;&#1585;&#1590;&#1577; &#1604;&#1605;&#1588;&#1575;&#1603;&#1604;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1610;&#1577; &#1571;&#1608;
&#1582;&#1604;&#1604; &#1605;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604; &#1576;&#1575;&#1604;&#1571;&#1606;&#1578;&#1585;&#1606;&#1610;&#1578;&#1548;
&#1608;&#1610;&#1605;&#1603;&#1606; &#1571;&#1606; &#1610;&#1572;&#1584;&#1610;
&#1584;&#1604;&#1603; &#1573;&#1604;&#1609;
&#1575;&#1604;&#1578;&#1608;&#1602;&#1601;
&#1575;&#1604;&#1605;&#1572;&#1602;&#1578; &#1604;&#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610;
&#1604;&#1604;&#1589;&#1606;&#1575;&#1593;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1610;&#1606;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1580;&#1608;&#1586;
&#1604;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577; &#1571;&#1606; &#1578;&#1602;&#1608;&#1605;
&#1601;&#1610; &#1571;&#1610; &#1608;&#1602;&#1578; &#1576;&#1578;&#1594;&#1610;&#1610;&#1585;
&#1571;&#1608; &#1578;&#1608;&#1602;&#1610;&#1601;&#1548; &#1576;&#1589;&#1601;&#1577;
&#1580;&#1586;&#1574;&#1610;&#1577; &#1571;&#1608;
&#1603;&#1604;&#1610;&#1577;&#1548; &#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;
&#1587;&#1608;&#1575;&#1569; &#1576;&#1588;&#1603;&#1604;
&#1605;&#1572;&#1602;&#1578; &#1571;&#1608; &#1604;&#1605;&#1583;&#1577;
&#1594;&#1610;&#1585; &#1605;&#1581;&#1583;&#1583;&#1577; &#1604;&#1573;&#1580;&#1585;&#1575;&#1569;
&#1593;&#1605;&#1604;&#1610;&#1575;&#1578; &#1575;&#1604;&#1589;&#1610;&#1575;&#1606;&#1577;
&#1608;\&#1571;&#1608; &#1578;&#1581;&#1587;&#1610;&#1606;&#1575;&#1578;
&#1571;&#1608; &#1578;&#1594;&#1610;&#1610;&#1585;&#1575;&#1578;
&#1593;&#1604;&#1610;&#1607;&#1575;</span><span dir=LTR></span><span dir=LTR
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><span
dir=LTR></span>.</span><span dir=RTL></span><span style='font-size:14.0pt;
line-height:115%;mso-bidi-language:AR-MA'><span dir=RTL></span> <span
lang=AR-MA><o:p></o:p></span></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1608;&#1576;&#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1548;
&#1604;&#1575; &#1610;&#1605;&#1603;&#1606;</span><span dir=LTR></span><span
lang=AR-MA dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><span dir=LTR></span> </span><span dir=RTL></span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><span
dir=RTL></span><span style='mso-spacerun:yes'> </span>&#1576;&#1571;&#1610;
&#1581;&#1575;&#1604; &#1605;&#1606;
&#1575;&#1604;&#1571;&#1581;&#1608;&#1575;&#1604; &#1578;&#1581;&#1605;&#1610;&#1604;
&#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577; &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
&#1593;&#1606; &#1575;&#1604;&#1571;&#1590;&#1585;&#1575;&#1585;
&#1575;&#1604;&#1578;&#1610; &#1610;&#1605;&#1603;&#1606; &#1571;&#1606; &#1578;&#1602;&#1593;
&#1587;&#1608;&#1575;&#1569; &#1576;&#1587;&#1576; &#1578;&#1608;&#1602;&#1601;
&#1593;&#1605;&#1604; &#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1571;&#1608; &#1590;&#1593;&#1601;
&#1571;&#1583;&#1575;&#1574;&#1607;&#1575; &#1571;&#1608;
&#1580;&#1608;&#1583;&#1577;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1575;&#1604;&#1605;&#1578;&#1590;&#1605;&#1606;&#1577;
&#1576;&#1607;&#1575;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1593;&#1604;&#1575;&#1608;&#1577; &#1593;&#1604;&#1609;
&#1584;&#1604;&#1603;&#1548; &#1604;&#1575; &#1610;&#1605;&#1603;&#1606;
&#1578;&#1581;&#1605;&#1610;&#1604; &#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577;
&#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577; &#1593;&#1606;
&#1575;&#1604;&#1571;&#1601;&#1593;&#1575;&#1604;
&#1575;&#1604;&#1589;&#1575;&#1583;&#1585;&#1577; &#1593;&#1606;
&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1577; &#1608;&#1575;&#1604;&#1578;&#1610;
&#1578;&#1578;&#1606;&#1575;&#1601;&#1610; &#1605;&#1593;
&#1575;&#1604;&#1602;&#1608;&#1575;&#1593;&#1583;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1608;&#1589;
&#1593;&#1604;&#1610;&#1607;&#1575; &#1601;&#1610; &#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577; &#1604;&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1577;&#1548; &#1608;&#1604;&#1575;
&#1575;&#1604;&#1571;&#1590;&#1585;&#1575;&#1585;
&#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;&#1577; &#1571;&#1608;
&#1594;&#1610;&#1585; &#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;&#1577;
&#1575;&#1604;&#1606;&#1575;&#1580;&#1605;&#1577; &#1593;&#1606; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1605;&#1606;&#1589;&#1577; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1548;
&#1608;&#1604;&#1571;&#1610; &#1587;&#1576;&#1576; &#1603;&#1575;&#1606;&#1548;
&#1604;&#1575;&#1587;&#1610;&#1605;&#1575; &#1601;&#1610;
&#1581;&#1575;&#1604;&#1577; &#1575;&#1604;&#1571;&#1582;&#1591;&#1575;&#1569;
&#1575;&#1604;&#1605;&#1591;&#1576;&#1593;&#1610;&#1577; &#1571;&#1608;
&#1575;&#1604;&#1605;&#1588;&#1575;&#1603;&#1604; &#1575;&#1604;&#1605;&#1578;&#1585;&#1578;&#1576;&#1577;
&#1593;&#1583;&#1605; &#1575;&#1604;&#1583;&#1602;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1577;
&#1603;&#1575;&#1604;&#1601;&#1610;&#1585;&#1608;&#1587;&#1575;&#1578;
&#1571;&#1608; &#1582;&#1604;&#1604; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1610;
&#1571;&#1608; &#1578;&#1587;&#1604;&#1604; &#1588;&#1601;&#1585;&#1575;&#1578;
&#1590;&#1575;&#1585;&#1577; &#1571;&#1608; &#1593;&#1591;&#1604;
&#1601;&#1610; &#1575;&#1604;&#1605;&#1606;&#1589;&#1577;
&#1582;&#1575;&#1585;&#1580; &#1593;&#1606; &#1587;&#1610;&#1591;&#1585;&#1577;
&#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1578;&#1608;&#1601;&#1585;&#1577; &#1593;&#1604;&#1609;
&#1605;&#1606;&#1589;&#1577; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1610;&#1578;&#1605;
&#1578;&#1581;&#1578;
&#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
&#1575;&#1604;&#1603;&#1575;&#1605;&#1604;&#1577;
&#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1604;&#1575; &#1610;&#1581;&#1605;&#1604;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1608;&#1606;
&#1604;&#1605;&#1606;&#1589;&#1577; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1571;&#1610;
&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
&#1604;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577;
&#1576;&#1582;&#1589;&#1608;&#1589;
&#1575;&#1604;&#1571;&#1590;&#1585;&#1575;&#1585; &#1575;&#1604;&#1578;&#1610;
&#1610;&#1605;&#1603;&#1606;&#1607;&#1575; &#1571;&#1606;
&#1578;&#1606;&#1578;&#1580; &#1576;&#1588;&#1603;&#1604;
&#1605;&#1576;&#1575;&#1588;&#1585; &#1571;&#1608; &#1594;&#1610;&#1585;
&#1605;&#1576;&#1575;&#1588;&#1585; &#1576;&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;&#1607;&#1605;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1577;
&#1608;&#1603;&#1610;&#1601;&#1605;&#1575; &#1603;&#1575;&#1606;&#1578;
&#1591;&#1576;&#1610;&#1593;&#1577; &#1607;&#1584;&#1607; &#1575;&#1604;&#1571;&#1590;&#1585;&#1575;&#1585;.
<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><b><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 5:
&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;<o:p></o:p></span></b></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1589;&#1585;&#1581;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1571;&#1606;&#1607; &#1593;&#1604;&#1609;
&#1593;&#1604;&#1605; &#1576;&#1575;&#1604;&#1571;&#1606;&#1578;&#1585;&#1606;&#1610;&#1578;
&#1580;&#1610;&#1583;&#1575; &#1608;&#1576;&#1605;&#1605;&#1610;&#1586;&#1575;&#1578;&#1607;
&#1608;&#1581;&#1583;&#1608;&#1583;&#1607;
&#1608;&#1610;&#1593;&#1578;&#1585;&#1601; &#1576;&#1603;&#1608;&#1606;&#1607;
&#1610;&#1593;&#1585;&#1601; &#1591;&#1576;&#1610;&#1593;&#1577;
&#1588;&#1576;&#1603;&#1577; &#1575;&#1604;&#1571;&#1606;&#1578;&#1585;&#1606;&#1610;&#1578;
&#1608;&#1582;&#1589;&#1608;&#1589;&#1575; &#1605;&#1605;&#1610;&#1586;&#1575;&#1578;&#1607;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1577;
&#1608;&#1571;&#1608;&#1602;&#1575;&#1578; &#1575;&#1604;&#1573;&#1580;&#1575;&#1576;&#1577;
&#1605;&#1606; &#1571;&#1580;&#1604;
&#1575;&#1604;&#1575;&#1587;&#1578;&#1588;&#1575;&#1585;&#1577; &#1571;&#1608;
&#1575;&#1604;&#1575;&#1587;&#1578;&#1601;&#1587;&#1575;&#1585; &#1571;&#1608;
&#1573;&#1585;&#1587;&#1575;&#1604;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;. <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1578;&#1593;&#1607;&#1583;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1576;&#1605;&#1575;
&#1610;&#1604;&#1610;:<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0cm;margin-right:
36.0pt;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l0 level1 lfo1;direction:
rtl;unicode-bidi:embed'><![if !supportLists]><span style='font-size:14.0pt;
line-height:115%;mso-bidi-language:AR-MA'><span style='mso-list:Ignore'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=RTL></span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1578;&#1608;&#1601;&#1610;&#1585;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1589;&#1581;&#1610;&#1581;&#1577;
<span class=SpellE>&#1608;&#1605;&#1581;&#1610;&#1617;&#1606;&#1577;</span>&#1548;</span><span
dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0cm;margin-right:
36.0pt;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l0 level1 lfo1;direction:
rtl;unicode-bidi:embed'><![if !supportLists]><span style='font-size:14.0pt;
line-height:115%;mso-bidi-language:AR-MA'><span style='mso-list:Ignore'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=RTL></span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1593;&#1583;&#1605;
&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604; &#1571;&#1610; &#1580;&#1607;&#1575;&#1586;&#1548;
&#1571;&#1608; &#1576;&#1585;&#1606;&#1575;&#1605;&#1580;
&#1601;&#1585;&#1593;&#1610; &#1605;&#1606; &#1588;&#1571;&#1606;&#1607;
&#1593;&#1585;&#1602;&#1604;&#1577; &#1571;&#1608;
&#1605;&#1581;&#1575;&#1608;&#1604;&#1577; &#1593;&#1585;&#1602;&#1604;&#1577; &#1581;&#1587;&#1606;
&#1587;&#1610;&#1585; &#1593;&#1605;&#1604; &#1575;&#1604;&#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1577;
&#1604;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;.</span><span
dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0cm;margin-right:
36.0pt;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l0 level1 lfo1;direction:
rtl;unicode-bidi:embed'><![if !supportLists]><span style='font-size:14.0pt;
line-height:115%;mso-bidi-language:AR-MA'><span style='mso-list:Ignore'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=RTL></span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1575;&#1604;&#1575;&#1605;&#1578;&#1606;&#1575;&#1593;
&#1593;&#1606; &#1578;&#1582;&#1586;&#1610;&#1606; &#1571;&#1608;
&#1578;&#1581;&#1605;&#1610;&#1604; &#1571;&#1608;
&#1573;&#1585;&#1587;&#1575;&#1604; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1581;&#1592;&#1608;&#1585;&#1577; &#1608;&#1594;&#1610;&#1585;
&#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;
&#1575;&#1604;&#1605;&#1606;&#1575;&#1601;&#1610;&#1577; &#1604;&#1604;&#1570;&#1583;&#1575;&#1576;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577; &#1571;&#1608;
&#1575;&#1604;&#1606;&#1592;&#1575;&#1605; &#1575;&#1604;&#1593;&#1575;&#1605;
&#1571;&#1608; &#1575;&#1604;&#1578;&#1610; &#1578;&#1605;&#1587;
&#1576;&#1581;&#1602;&#1608;&#1602; &#1575;&#1604;&#1594;&#1610;&#1585;&#1548;
&#1604;&#1575; &#1587;&#1610;&#1605;&#1575; &#1605;&#1606;&#1607;&#1575; &#1581;&#1602;&#1608;&#1602;
&#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
&#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;. </span><span dir=LTR
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1589;&#1585;&#1581;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1576;&#1571;&#1606;&#1607;
&#1593;&#1604;&#1609; &#1593;&#1604;&#1605; &#1576;&#1571;&#1606; &#1575;&#1604;&#1571;&#1606;&#1578;&#1585;&#1606;&#1610;&#1578;
&#1594;&#1610;&#1585; &#1570;&#1605;&#1606; &#1608;&#1571;&#1606;
&#1587;&#1585;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1585;&#1575;&#1587;&#1604;&#1575;&#1578;
&#1604;&#1610;&#1587;&#1578; &#1605;&#1590;&#1605;&#1608;&#1606;&#1577;.
&#1608;&#1571;&#1606;&#1607; &#1593;&#1604;&#1609; &#1593;&#1604;&#1605; &#1603;&#1584;&#1604;&#1603;
&#1576;&#1571;&#1606; &#1573;&#1585;&#1587;&#1575;&#1604; &#1571;&#1610;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1577; &#1587;&#1608;&#1575;&#1569;
&#1603;&#1575;&#1606;&#1578; &#1587;&#1585;&#1610;&#1577; &#1571;&#1605;
&#1604;&#1575; &#1610;&#1578;&#1605;
&#1578;&#1583;&#1575;&#1608;&#1604;&#1607;&#1575; &#1593;&#1576;&#1585;
&#1575;&#1604;&#1571;&#1606;&#1578;&#1585;&#1606;&#1578; &#1607;&#1610;
&#1605;&#1593;&#1585;&#1590;&#1577; &#1604;&#1604;&#1582;&#1591;&#1585;
&#1608;&#1610;&#1578;&#1581;&#1605;&#1604; &#1588;&#1582;&#1589;&#1610;&#1575; &#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
&#1581;&#1605;&#1575;&#1610;&#1577;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1607; &#1571;&#1608;
&#1576;&#1585;&#1575;&#1605;&#1580;&#1607; &#1605;&#1606; &#1571;&#1582;&#1591;&#1575;&#1585;
&#1607;&#1580;&#1608;&#1605;
&#1575;&#1604;&#1601;&#1610;&#1585;&#1608;&#1587;&#1575;&#1578;. <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><b><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 6:
&#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601; &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;<o:p></o:p></span></b></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1606;&#1607;&#1609; &#1573;&#1604;&#1609; &#1593;&#1604;&#1605;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1571;&#1606;&#1607;
&#1582;&#1604;&#1575;&#1604; &#1586;&#1610;&#1575;&#1585;&#1578;&#1607;
&#1604;&#1605;&#1606;&#1589;&#1577; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;&#1548;
&#1610;&#1605;&#1603;&#1606; &#1604;&#1605;&#1604;&#1601;&#1575;&#1578;
&#1578;&#1593;&#1585;&#1610;&#1601; &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
&#1571;&#1606; &#1578;&#1581;&#1605;&#1604;
&#1576;&#1591;&#1585;&#1610;&#1602;&#1577;
&#1571;&#1608;&#1578;&#1608;&#1605;&#1575;&#1578;&#1610;&#1603;&#1610;&#1577;
&#1593;&#1604;&#1609; &#1605;&#1581;&#1585;&#1603;
&#1575;&#1604;&#1576;&#1581;&#1579; &#1575;&#1604;&#1582;&#1575;&#1589;
&#1576;&#1607;. <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
&#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591; &#1607;&#1608;
&#1605;&#1604;&#1601; &#1606;&#1589;&#1610; &#1610;&#1579;&#1576;&#1578;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1602;&#1585;&#1589;
&#1575;&#1604;&#1589;&#1604;&#1576;
&#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;. &#1608;&#1610;&#1605;&#1603;&#1617;&#1606;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1582;&#1589;&#1608;&#1589; &#1605;&#1606;
&#1578;&#1580;&#1605;&#1610;&#1593; &#1608;&#1578;&#1582;&#1586;&#1610;&#1606;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604; &#1603;&#1610;&#1601;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1581;&#1579; &#1605;&#1606; &#1582;&#1604;&#1575;&#1604;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586;
&#1575;&#1604;&#1605;&#1608;&#1589;&#1608;&#1604; &#1576;&#1575;&#1604;&#1571;&#1606;&#1578;&#1585;&#1606;&#1578;.
&#1604;&#1575; &#1610;&#1605;&#1603;&#1606; &#1604;&#1605;&#1604;&#1601;
&#1578;&#1593;&#1585;&#1610;&#1601;
&#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591; &#1605;&#1593;&#1585;&#1601;&#1577;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1548; &#1573;&#1604;&#1575;
&#1571;&#1606;&#1607; &#1610;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577;
&#1576;&#1575;&#1604;&#1576;&#1581;&#1579; &#1593;&#1604;&#1609;
&#1605;&#1608;&#1602;&#1593; &#1605;&#1575;&#1548; &#1604;&#1575;
&#1587;&#1610;&#1605;&#1575; &#1575;&#1604;&#1589;&#1601;&#1581;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1605;&#1578;
&#1586;&#1610;&#1575;&#1585;&#1578;&#1607;&#1575;
&#1608;&#1578;&#1575;&#1585;&#1610;&#1582; &#1608;&#1587;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1586;&#1610;&#1575;&#1585;&#1577;. <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1578;&#1587;&#1578;&#1593;&#1605;&#1604; &#1608;&#1586;&#1575;&#1585;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1608;&#1575;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1608;
&#1575;&#1604;&#1575;&#1602;&#1578;&#1589;&#1575;&#1583;
&#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;
&#1608;&#1575;&#1604;&#1578;&#1590;&#1575;&#1605;&#1606;&#1610; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1576;&#1588;&#1603;&#1604; &#1593;&#1575;&#1605;&#1548;
&#1576;&#1594;&#1585;&#1590; &#1601;&#1607;&#1605;
&#1603;&#1610;&#1601;&#1610;&#1577; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1585;&#1602;&#1605;&#1610;&#1577;
&#1604;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1608;&#1575;&#1604;&#1578;&#1605;&#1603;&#1606;
&#1605;&#1606; &#1578;&#1581;&#1587;&#1610;&#1606;&#1607;&#1575;.
&#1608;&#1604;&#1593;&#1604;&#1605;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1601;&#1573;&#1606;&#1607; &#1604;&#1575; &#1610;&#1578;&#1605; &#1575;&#1604;&#1603;&#1588;&#1601;
&#1593;&#1606; &#1571;&#1610; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1588;&#1582;&#1589;&#1610;&#1577;. <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1605;&#1603;&#1606;
&#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1548; &#1593;&#1604;&#1575;&#1608;&#1577;
&#1593;&#1604;&#1609; &#1584;&#1604;&#1603;&#1548; &#1593;&#1583;&#1605;
&#1578;&#1587;&#1580;&#1610;&#1604; &#1605;&#1604;&#1601;&#1575;&#1578;
&#1578;&#1593;&#1585;&#1610;&#1601;
&#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
&#1576;&#1578;&#1594;&#1610;&#1610;&#1585;
&#1575;&#1604;&#1582;&#1610;&#1575;&#1585;&#1575;&#1578; &#1593;&#1604;&#1609;
&#1605;&#1581;&#1585;&#1603; &#1575;&#1604;&#1576;&#1581;&#1579;.
&#1604;&#1603;&#1606; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1578;&#1594;&#1610;&#1610;&#1585; &#1610;&#1605;&#1603;&#1606;
&#1571;&#1606; &#1610;&#1603;&#1608;&#1606; &#1604;&#1607; &#1578;&#1571;&#1579;&#1610;&#1585;
&#1593;&#1604;&#1609; &#1587;&#1607;&#1608;&#1604;&#1577;
&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;
&#1575;&#1604;&#1605;&#1581;&#1585;&#1603;
&#1608;&#1575;&#1587;&#1578;&#1585;&#1580;&#1575;&#1593;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1593;&#1604;&#1609; &#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><b><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 7:
&#1575;&#1604;&#1585;&#1608;&#1575;&#1576;&#1591;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1577; &#1575;&#1604;&#1605;&#1578;&#1588;&#1593;&#1576;&#1577;
&#1608;&#1575;&#1604;&#1601;&#1610;&#1585;&#1608;&#1587;&#1575;&#1578;<o:p></o:p></span></b></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1610;&#1575;&#1578; &#1575;&#1604;&#1578;&#1610;
&#1578;&#1608;&#1601;&#1585;&#1607;&#1575;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1585;&#1602;&#1605;&#1610;&#1577;
&#1604;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1604;&#1607;&#1575;
&#1591;&#1575;&#1576;&#1593; &#1573;&#1593;&#1604;&#1575;&#1605;&#1610;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1608;&#1580;&#1608;&#1583; &#1585;&#1575;&#1576;&#1591;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1585;&#1602;&#1605;&#1610;&#1577;
&#1604;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1605;&#1593;
&#1605;&#1608;&#1602;&#1593; &#1575;&#1582;&#1585; &#1604;&#1575;
&#1610;&#1605;&#1579;&#1604; &#1573;&#1602;&#1585;&#1575;&#1585;&#1575;
&#1576;&#1605;&#1589;&#1583;&#1575;&#1602;&#1610;&#1577; &#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1571;&#1608; &#1576;&#1605;&#1581;&#1578;&#1608;&#1575;&#1607;.
&#1610;&#1580;&#1576; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1571;&#1606; &#1610;&#1578;&#1593;&#1575;&#1605;&#1604;
&#1605;&#1593; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1576;&#1581;&#1584;&#1585; &#1608;&#1576;&#1581;&#1587;
&#1606;&#1602;&#1583;&#1610;. &#1604;&#1575; &#1610;&#1605;&#1603;&#1606; &#1578;&#1581;&#1605;&#1610;&#1604;
&#1575;&#1604;&#1606;&#1575;&#1588;&#1585; &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
&#1601;&#1610;&#1605;&#1575; &#1610;&#1578;&#1593;&#1604;&#1602; &#1576;&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1608;&#1575;&#1604;&#1570;&#1585;&#1575;&#1569;
&#1608;&#1575;&#1604;&#1578;&#1608;&#1589;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1593;&#1576;&#1585; &#1593;&#1606;&#1607;&#1575; &#1605;&#1606;
&#1591;&#1585;&#1601; &#1575;&#1604;&#1594;&#1610;&#1585;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1604;&#1575; &#1576;&#1583; &#1605;&#1606;
&#1575;&#1604;&#1578;&#1584;&#1603;&#1610;&#1585;&#1548; &#1576;&#1571;&#1606;
&#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577; &#1604;&#1610;&#1587;&#1578; &#1604;&#1583;&#1610;&#1607;&#1575;
&#1571;&#1610; &#1605;&#1585;&#1575;&#1602;&#1576;&#1577; &#1571;&#1608;
&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577; &#1601;&#1610;&#1605;&#1575;
&#1610;&#1578;&#1593;&#1604;&#1602; &#1576;&#1582;&#1604;&#1602; &#1585;&#1608;&#1575;&#1576;&#1591;
&#1605;&#1593; &#1605;&#1608;&#1575;&#1602;&#1593; &#1582;&#1575;&#1585;&#1580;&#1610;&#1577;
&#1571;&#1608; &#1593;&#1604;&#1609; &#1605;&#1581;&#1578;&#1608;&#1610;&#1575;&#1578;
&#1607;&#1584;&#1607; &#1575;&#1604;&#1605;&#1608;&#1575;&#1602;&#1593;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1603;&#1604; &#1578;&#1608;&#1575;&#1589;&#1604; &#1605;&#1593;
&#1605;&#1608;&#1602;&#1593; &#1570;&#1582;&#1585; &#1593;&#1576;&#1585;
&#1575;&#1604;&#1585;&#1608;&#1575;&#1576;&#1591; &#1575;&#1604;&#1601;&#1575;&#1574;&#1602;&#1577;
&#1610;&#1593;&#1578;&#1576;&#1585;
&#1575;&#1582;&#1578;&#1610;&#1575;&#1585;&#1575;
&#1588;&#1582;&#1589;&#1610;&#1575;
&#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1608;&#1610;&#1578;&#1581;&#1605;&#1604;
&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1578;&#1607;
&#1575;&#1604;&#1603;&#1575;&#1605;&#1604;&#1577; &#1601;&#1610;
&#1584;&#1604;&#1603;. &#1608;&#1603;&#1604; &#1590;&#1585;&#1585;
&#1605;&#1576;&#1575;&#1588;&#1585; &#1571;&#1608; &#1594;&#1610;&#1585;
&#1605;&#1576;&#1575;&#1588;&#1585; &#1606;&#1575;&#1578;&#1580; &#1593;&#1606;
&#1575;&#1604;&#1608;&#1604;&#1608;&#1580; &#1573;&#1604;&#1609;
&#1605;&#1608;&#1602;&#1593; &#1570;&#1582;&#1585; &#1605;&#1578;&#1589;&#1604;
&#1576;&#1585;&#1575;&#1576;&#1591; &#1601;&#1575;&#1574;&#1602;&#1548;
&#1604;&#1575; &#1610;&#1605;&#1603;&#1606; &#1571;&#1606; &#1610;&#1602;&#1593;
&#1576;&#1571;&#1610; &#1581;&#1575;&#1604; &#1605;&#1606;
&#1575;&#1604;&#1571;&#1581;&#1608;&#1575;&#1604; &#1578;&#1581;&#1578;
&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
&#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1580;&#1576; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1571;&#1606;
&#1610;&#1578;&#1582;&#1584;
&#1575;&#1604;&#1575;&#1581;&#1578;&#1610;&#1575;&#1591;&#1575;&#1578;
&#1575;&#1604;&#1604;&#1575;&#1586;&#1605;&#1577; &#1604;&#1604;&#1578;&#1571;&#1603;&#1583;
&#1605;&#1606; &#1571;&#1606; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1605;&#1585;&#1575;&#1583; &#1578;&#1589;&#1601;&#1581;&#1607;
&#1604;&#1575; &#1610;&#1581;&#1578;&#1608;&#1610; &#1593;&#1604;&#1609; &#1571;&#1610;
&#1601;&#1610;&#1585;&#1608;&#1587;&#1575;&#1578; &#1571;&#1608;
&#1594;&#1610;&#1585;&#1607;&#1575; &#1605;&#1606;
&#1575;&#1604;&#1571;&#1588;&#1610;&#1575;&#1569; &#1575;&#1604;&#1583;&#1582;&#1610;&#1604;&#1577;
&#1575;&#1604;&#1578;&#1610; &#1604;&#1607;&#1575; &#1591;&#1575;&#1576;&#1593;
&#1605;&#1583;&#1605;&#1585;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1576;&#1589;&#1601;&#1577; &#1593;&#1575;&#1605;&#1577;&#1548;
&#1604;&#1575; &#1610;&#1605;&#1603;&#1606; &#1578;&#1581;&#1605;&#1610;&#1604;
&#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577;
&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
&#1575;&#1604;&#1571;&#1590;&#1585;&#1575;&#1585; &#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;&#1577;
&#1608;&#1594;&#1610;&#1585;
&#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;&#1577;
&#1575;&#1604;&#1606;&#1575;&#1578;&#1580;&#1577; &#1593;&#1606;
&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604; &#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1571;&#1608;
&#1605;&#1608;&#1575;&#1602;&#1593; &#1571;&#1582;&#1585;&#1609;
&#1605;&#1585;&#1578;&#1576;&#1591;&#1577; &#1576;&#1607;&#1575;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1580;&#1576; &#1575;&#1604;&#1581;&#1589;&#1608;&#1604;
&#1593;&#1604;&#1609; &#1578;&#1585;&#1582;&#1610;&#1589;
&#1604;&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;
&#1585;&#1608;&#1575;&#1576;&#1591; &#1601;&#1575;&#1574;&#1602;&#1577; &#1606;&#1581;&#1608;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1585;&#1602;&#1605;&#1610;&#1577;
&#1604;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;. <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><b><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 8: &#1571;&#1605;&#1606;
&#1608;&#1587;&#1604;&#1575;&#1605;&#1577; &#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610; </span></b><span
lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;<b><o:p></o:p></b></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1604;&#1590;&#1605;&#1575;&#1606; &#1571;&#1605;&#1606;
&#1605;&#1606;&#1589;&#1577; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610; &#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1548;
&#1578;&#1571;&#1582;&#1584; &#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577;
&#1603;&#1604; &#1575;&#1604;&#1575;&#1580;&#1585;&#1575;&#1569;&#1575;&#1578; &#1575;&#1604;&#1590;&#1585;&#1608;&#1585;&#1610;&#1577;
&#1608;&#1575;&#1604;&#1605;&#1604;&#1575;&#1574;&#1605;&#1577; &#1576;&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;
&#1575;&#1604;&#1608;&#1587;&#1575;&#1574;&#1604;
&#1575;&#1604;&#1578;&#1603;&#1606;&#1608;&#1604;&#1608;&#1580;&#1610;&#1577; &#1608;&#1575;&#1578;&#1582;&#1575;&#1584;
&#1575;&#1604;&#1578;&#1583;&#1575;&#1576;&#1610;&#1585; &#1575;&#1604;&#1578;&#1610;
&#1605;&#1606; &#1588;&#1571;&#1606;&#1607;&#1575; &#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1605;&#1606; &#1603;&#1604;
&#1578;&#1604;&#1601; &#1571;&#1608; &#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;
&#1605;&#1587;&#1610;&#1569; &#1571;&#1608; &#1608;&#1604;&#1608;&#1580;
&#1594;&#1610;&#1585; &#1605;&#1585;&#1582;&#1589; &#1571;&#1608;
&#1578;&#1594;&#1610;&#1610;&#1585; &#1571;&#1608; &#1578;&#1604;&#1601;&#1548;
&#1591;&#1576;&#1602;&#1575;
&#1604;&#1605;&#1602;&#1578;&#1590;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; 08-09
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1571;&#1588;&#1582;&#1575;&#1589;
&#1575;&#1604;&#1584;&#1575;&#1578;&#1610;&#1610;&#1606;
&#1601;&#1610;&#1605;&#1575; &#1610;&#1582;&#1589; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1584;&#1575;&#1578; &#1575;&#1604;&#1591;&#1575;&#1576;&#1593;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;. &#1573;&#1604;&#1575;
&#1571;&#1606; &#1575;&#1604;&#1608;&#1586;&#1575;&#1585;&#1577; &#1604;&#1610;&#1587;
&#1576;&#1575;&#1587;&#1578;&#1591;&#1575;&#1593;&#1578;&#1607;&#1575;
&#1578;&#1608;&#1601;&#1610;&#1585; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1571;&#1605;&#1606; &#1576;&#1588;&#1603;&#1604;
&#1605;&#1591;&#1604;&#1602; &#1604;&#1603;&#1608;&#1606; &#1571;&#1606;
&#1575;&#1604;&#1575;&#1606;&#1578;&#1585;&#1606;&#1578;
&#1588;&#1576;&#1603;&#1577; &#1605;&#1601;&#1578;&#1608;&#1581;&#1577;
&#1608;&#1605;&#1593;&#1585;&#1590;&#1577; &#1604;&#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1582;&#1575;&#1591;&#1585;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><b><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 9:
&#1575;&#1604;&#1605;&#1593;&#1591;&#1610;&#1575;&#1578; &#1584;&#1575;&#1578;
&#1575;&#1604;&#1591;&#1575;&#1576;&#1593;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;<o:p></o:p></span></b></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1578;&#1593;&#1578;&#1576;&#1585; &#1608;&#1586;&#1575;&#1585;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1608;&#1575;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1608; &#1575;&#1604;&#1575;&#1602;&#1578;&#1589;&#1575;&#1583;
&#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;
&#1608;&#1575;&#1604;&#1578;&#1590;&#1575;&#1605;&#1606;&#1610;_
&#1602;&#1591;&#1575;&#1593; &#1575;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577; &#1608;
&#1575;&#1604;&#1575;&#1602;&#1578;&#1589;&#1575;&#1583;
&#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;
&#1608;&#1575;&#1604;&#1578;&#1590;&#1575;&#1605;&#1606;&#1610;_<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1578;&#1578;&#1605; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1605;&#1593;&#1591;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1610;&#1578;&#1605;
&#1578;&#1580;&#1605;&#1610;&#1593;&#1607;&#1575; &#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
</span><a href="http://rna.gov.ma"><span dir=LTR style='font-size:14.0pt;
line-height:115%;mso-bidi-language:AR-MA'>rna.gov.ma</span></a><span
dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><span
style='mso-spacerun:yes'>  </span></span><span dir=RTL></span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><span
dir=RTL></span><span style='mso-spacerun:yes'> </span>&#1605;&#1606;
&#1571;&#1580;&#1604; &#1578;&#1581;&#1583;&#1610;&#1583;
&#1605;&#1582;&#1578;&#1604;&#1601; &#1601;&#1574;&#1575;&#1578;
&#1575;&#1604;&#1589;&#1606;&#1575;&#1593; &#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1610;&#1606;
&#1575;&#1604;&#1605;&#1586;&#1575;&#1608;&#1604;&#1610;&#1606;
&#1604;&#1606;&#1588;&#1575;&#1591; &#1605;&#1606;
&#1571;&#1606;&#1588;&#1591;&#1577;
&#1575;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577; &#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;&#1548;
&#1604;&#1578;&#1581;&#1587;&#1610;&#1606; &#1608;&#1587;&#1575;&#1574;&#1604;
&#1575;&#1587;&#1578;&#1607;&#1583;&#1575;&#1601;
&#1576;&#1585;&#1575;&#1605;&#1580; &#1575;&#1604;&#1578;&#1606;&#1605;&#1610;&#1577;
&#1575;&#1604;&#1575;&#1602;&#1578;&#1589;&#1575;&#1583;&#1610;&#1577;
&#1608;&#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1575;&#1604;&#1602;&#1591;&#1575;&#1593;
(&#1575;&#1604;&#1583;&#1593;&#1605;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1548;
&#1575;&#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;&#1577; ...)<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span class=SpellE><span lang=AR-MA style='font-size:14.0pt;line-height:
115%;mso-bidi-language:AR-MA'>&#1740;&#1605;&#1603;&#1606;&#1603;&#1605;</span></span><span
lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'> &#1605;&#1605;&#1575;&#1585;&#1587;&#1577;
&#1581;&#1602; &#1575;&#1604;&#1608;&#1604;&#1608;&#1580; &#1608; &#1578;&#1589;&#1581;&#1610;&#1581;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1603;&#1605;
&#1608;&#1601;&#1602;&#1575; &#1604;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; </span><span
dir=LTR></span><span dir=LTR style='font-size:14.0pt;line-height:115%;
mso-bidi-language:AR-MA'><span dir=LTR></span>08-09 </span><span dir=RTL></span><span
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><span
dir=RTL></span><span style='mso-spacerun:yes'> </span><span lang=AR-MA>&#1575;&#1604;&#1605;&#1589;&#1575;&#1583;&#1602;
&#1593;&#1604;&#1610;&#1607; <span class=SpellE>&#1576;&#1575;&#1604;&#1592;&#1726;&#1740;&#1585;</span>
<span class=SpellE>&#1575;&#1604;&#1588;&#1585;&#1740;&#1601;</span> 1-09-15
&#1575;&#1604;&#1589;&#1575;&#1583;&#1585; &#1601;&#1610; 18 <span
class=SpellE>&#1601;&#1576;&#1585;&#1575;&#1740;&#1585;</span> 2009 &#1608;&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1581;&#1575;&#1605;&#1610;&#1577;
&#1575;&#1604;&#1571;&#1588;&#1582;&#1575;&#1589; <span class=SpellE>&#1575;&#1604;&#1584;&#1575;&#1578;&#1740;&#1740;&#1606;</span>
&#1578;&#1580;&#1575;&#1607; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577; <span
class=SpellE>&#1575;&#1604;&#1605;&#1593;&#1591;&#1740;&#1575;&#1578;</span>
&#1584;&#1575;&#1578; &#1575;&#1604;&#1591;&#1575;&#1576;&#1593; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1548;
&#1608;&#1584;&#1604;&#1603; &#1593;&#1576;&#1585; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; </span></span><a
href="http://rna.gov.ma"><span dir=LTR style='font-size:14.0pt;line-height:
115%;mso-bidi-language:AR-MA'>rna.gov.ma</span></a><span dir=LTR
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><span
style='mso-spacerun:yes'>  </span></span><span dir=RTL></span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><span
dir=RTL></span><span style='mso-spacerun:yes'> </span>&#1571;&#1608;
&#1605;&#1585;&#1575;&#1587;&#1604;&#1577; &#1575;&#1604;&#1587;&#1610;&#1583;
&#1593;&#1576;&#1583; &#1575;&#1604;&#1573;&#1604;&#1607; &#1610;&#1585;&#1608;
&#1593;&#1576;&#1585; &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;<span
style='mso-spacerun:yes'>  </span></span><a
href="mailto:ayerrou@artesnet.gov.ma"><span dir=LTR style='font-size:14.0pt;
line-height:115%;mso-bidi-language:AR-MA'>ayerrou@artesnet.gov.ma</span></a><span
dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1603;&#1575;&#1605; <span class=SpellE>&#1740;&#1605;&#1603;&#1606;&#1603;&#1605;</span>
&#1575;&#1604;&#1578;&#1593;&#1585;&#1590;&#1548;
&#1604;&#1571;&#1587;&#1576;&#1575;&#1576;
&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;&#1548; &#1593;&#1604;&#1609; <span
class=SpellE>&#1575;&#1604;&#1602;&#1740;&#1575;&#1605;</span></span><span
dir=LTR></span><span lang=AR-MA dir=LTR style='font-size:14.0pt;line-height:
115%;mso-bidi-language:AR-MA'><span dir=LTR></span> </span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1576;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
<span class=SpellE>&#1575;&#1604;&#1605;&#1593;&#1591;&#1740;&#1575;&#1578;</span>
&#1575;&#1604;&#1578;&#1610; &#1578;&#1582;&#1589;&#1603;&#1605;.</span><span
dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span dir=RTL></span><span style='font-size:14.0pt;line-height:115%;
mso-bidi-language:AR-MA'><span dir=RTL></span><span
style='mso-spacerun:yes'> </span><span lang=AR-MA>&#1578;&#1605;
&#1575;&#1604;&#1573;&#1588;&#1593;&#1575;&#1585; &#1576;&#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; <span class=SpellE>&#1608;&#1575;&#1604;&#1578;&#1585;&#1582;&#1740;&#1589;</span>
<span class=SpellE>&#1604;&#1726;&#1575;</span> &#1605;&#1606;
&#1591;&#1585;&#1601; &#1575;&#1604;&#1604;&#1580;&#1606;&#1577;</span></span><span
dir=LTR></span><span lang=AR-MA dir=LTR style='font-size:14.0pt;line-height:
115%;mso-bidi-language:AR-MA'><span dir=LTR></span> </span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1575;&#1604;&#1608;&#1591;&#1606;&#1610;&#1577;
&#1604;&#1605;&#1585;&#1575;&#1602;&#1576;&#1577; &#1581;&#1605;&#1575;&#1610;&#1577;
<span class=SpellE>&#1575;&#1604;&#1605;&#1593;&#1591;&#1740;&#1575;&#1578;</span>
&#1584;&#1575;&#1578; &#1575;&#1604;&#1591;&#1575;&#1576;&#1593;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610; &#1578;&#1581;&#1578;
&#1585;&#1602;&#1605; &#1575;&#1604;&#1573;&#1584;&#1606;</span><span dir=LTR></span><span
dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><span
dir=LTR></span> : A-PO-486/2021</span><span class=SpellE><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1576;&#1578;&#1575;&#1585;&#1740;&#1582;</span></span><span
lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>
20/01/2021<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1591;&#1576;&#1602;&#1575;
&#1604;&#1605;&#1602;&#1578;&#1590;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; 08-09 &#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1571;&#1588;&#1582;&#1575;&#1589;
&#1575;&#1604;&#1584;&#1575;&#1578;&#1610;&#1610;&#1606;
&#1601;&#1610;&#1605;&#1575; &#1610;&#1582;&#1589; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1584;&#1575;&#1578; &#1575;&#1604;&#1591;&#1575;&#1576;&#1593;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1548; &#1610;&#1602;&#1576;&#1604;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1571;&#1606;
&#1603;&#1604; &#1575;&#1604;&#1605;&#1593;&#1591;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
(&#1575;&#1604;&#1575;&#1587;&#1605; &#1575;&#1604;&#1593;&#1575;&#1574;&#1604;&#1610;
&#1608;&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;
&#1608;&#1575;&#1604;&#1593;&#1606;&#1608;&#1575;&#1606;...)
&#1575;&#1604;&#1578;&#1610; &#1610;&#1583;&#1604;&#1610; &#1576;&#1607;&#1575;
&#1571;&#1608; &#1575;&#1604;&#1578;&#1610; &#1578;&#1608;&#1580;&#1583;
&#1604;&#1583;&#1609; &#1575;&#1604;&#1605;&#1608;&#1585;&#1583;
&#1571;&#1579;&#1606;&#1575;&#1569;
&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;&#1548;
&#1607;&#1610; &#1605;&#1608;&#1580;&#1607;&#1577;
&#1604;&#1604;&#1605;&#1608;&#1585;&#1583; &#1608;&#1578;&#1603;&#1608;&#1606;
&#1605;&#1608;&#1590;&#1608;&#1593; &#1593;&#1605;&#1604;&#1610;&#1575;&#1578;
&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1576;&#1606;&#1575;&#1569;
&#1593;&#1604;&#1609; &#1591;&#1604;&#1576;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1608;&#1576;&#1606;&#1575;&#1569;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1593;&#1602;&#1583; &#1575;&#1604;&#1605;&#1585;&#1578;&#1576;&#1591;
&#1576;&#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1590;&#1608;&#1593;
&#1605;&#1579;&#1604;: <o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0cm;margin-right:
36.0pt;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l0 level1 lfo1;direction:
rtl;unicode-bidi:embed'><![if !supportLists]><span style='font-size:14.0pt;
line-height:115%;mso-bidi-language:AR-MA'><span style='mso-list:Ignore'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=RTL></span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1578;&#1580;&#1605;&#1610;&#1593;.</span><span
dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0cm;margin-right:
36.0pt;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l0 level1 lfo1;direction:
rtl;unicode-bidi:embed'><![if !supportLists]><span style='font-size:14.0pt;
line-height:115%;mso-bidi-language:AR-MA'><span style='mso-list:Ignore'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=RTL></span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1581;&#1601;&#1592;.</span><span
dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0cm;margin-right:
36.0pt;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l0 level1 lfo1;direction:
rtl;unicode-bidi:embed'><![if !supportLists]><span style='font-size:14.0pt;
line-height:115%;mso-bidi-language:AR-MA'><span style='mso-list:Ignore'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=RTL></span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1578;&#1582;&#1586;&#1610;&#1606;
&#1578;&#1581;&#1578; &#1571;&#1588;&#1603;&#1575;&#1604;
&#1605;&#1582;&#1578;&#1604;&#1601;&#1577; &#1603;&#1610;&#1601;&#1605;&#1575;
&#1603;&#1575;&#1606;&#1578; &#1575;&#1604;&#1605;&#1583;&#1577;.</span><span
dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0cm;margin-right:
36.0pt;margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l0 level1 lfo1;direction:
rtl;unicode-bidi:embed'><![if !supportLists]><span style='font-size:14.0pt;
line-height:115%;mso-bidi-language:AR-MA'><span style='mso-list:Ignore'>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=RTL></span><span lang=AR-MA
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'>&#1571;&#1610;
&#1588;&#1603;&#1604; &#1570;&#1582;&#1585; &#1605;&#1606;
&#1571;&#1588;&#1603;&#1575;&#1604;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1576;&#1589;&#1601;&#1577; &#1593;&#1575;&#1605;&#1577;&#1548;
&#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603; &#1603;&#1604;
&#1573;&#1585;&#1587;&#1575;&#1604; &#1605;&#1606; &#1591;&#1585;&#1601;
&#1575;&#1604;&#1605;&#1608;&#1585;&#1583;
&#1604;&#1601;&#1585;&#1608;&#1593;&#1607; &#1608;&#1604;&#1588;&#1585;&#1603;&#1575;&#1574;&#1607;
&#1575;&#1604;&#1578;&#1580;&#1575;&#1585;&#1610;&#1610;&#1606; <span
class=SpellE>&#1608;&#1604;&#1605;&#1606;&#1575;&#1608;&#1604;&#1610;&#1607;</span>
&#1608;&#1604;&#1604;&#1587;&#1604;&#1591;&#1575;&#1578;
&#1575;&#1604;&#1602;&#1590;&#1575;&#1574;&#1610;&#1577;. </span><span dir=LTR
style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1607;&#1584;&#1607; &#1575;&#1604;&#1605;&#1593;&#1591;&#1610;&#1575;&#1578;
&#1604;&#1607; &#1571;&#1607;&#1583;&#1575;&#1601;: &#1578;&#1602;&#1606;&#1610;&#1577;&#1548;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578; &#1575;&#1604;&#1605;&#1578;&#1608;&#1601;&#1585;&#1577;
&#1571;&#1608; &#1575;&#1604;&#1605;&#1606;&#1578;&#1608;&#1580;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1603;&#1578;&#1587;&#1576;&#1577;&#1548; &#1605;&#1604;&#1575;&#1574;&#1605;&#1577;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1578;&#1575;&#1581;&#1577;
&#1608;&#1575;&#1604;&#1573;&#1583;&#1575;&#1585;&#1610;&#1577;&#1548; &#1578;&#1587;&#1578;&#1580;&#1610;&#1576;
&#1576;&#1589;&#1601;&#1577; &#1593;&#1575;&#1605;&#1577;
&#1604;&#1605;&#1590;&#1575;&#1605;&#1610;&#1606; &#1575;&#1604;&#1593;&#1602;&#1583;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1602;&#1585;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1589;&#1585;&#1575;&#1581;&#1577;
&#1608;&#1576;&#1583;&#1608;&#1606; &#1578;&#1581;&#1601;&#1592; &#1576;&#1571;&#1606;&#1607;
&#1571;&#1581;&#1610;&#1591; &#1593;&#1604;&#1605;&#1575; &#1605;&#1587;&#1576;&#1602;&#1575;
&#1576;&#1571;&#1607;&#1583;&#1575;&#1601;
&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1607;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1608;&#1576;&#1603;&#1604;
&#1575;&#1604;&#1581;&#1602;&#1608;&#1602; &#1575;&#1604;&#1578;&#1610;
&#1610;&#1581;&#1601;&#1592;&#1607;&#1575; &#1604;&#1607;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1585;&#1602;&#1605; 08-09
&#1608;&#1603;&#1584;&#1575; &#1593;&#1604;&#1609; &#1593;&#1604;&#1605;
&#1576;&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1610;&#1580;&#1576; &#1571;&#1606;
&#1610;&#1585;&#1587;&#1604;&#1607;&#1575; &#1605;&#1587;&#1576;&#1602;&#1575;
&#1608;&#1575;&#1604;&#1605;&#1584;&#1603;&#1608;&#1585;&#1577;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1608;\&#1571;&#1608; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602; &#1593;&#1605;&#1604;&#1575;
&#1576;&#1605;&#1602;&#1578;&#1590;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
&#1575;&#1604;&#1605;&#1588;&#1575;&#1585; &#1573;&#1604;&#1610;&#1607;. <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1593;&#1604;&#1606;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1548;
&#1589;&#1585;&#1575;&#1581;&#1577; &#1608;&#1576;&#1583;&#1608;&#1606;
&#1578;&#1581;&#1601;&#1592;&#1548;
&#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1607;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1576;&#1571;&#1606;&#1607;
&#1593;&#1604;&#1609; &#1593;&#1604;&#1605;
&#1576;&#1571;&#1607;&#1583;&#1575;&#1601;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1584;&#1603;&#1608;&#1585;&#1577;
&#1608;&#1593;&#1604;&#1609; &#1575;&#1604;&#1582;&#1589;&#1608;&#1589; &#1601;&#1610;&#1605;&#1575;
&#1610;&#1578;&#1593;&#1604;&#1602; &#1576;&#1573;&#1585;&#1587;&#1575;&#1604;&#1607;&#1575;
&#1605;&#1576;&#1575;&#1588;&#1585;&#1577; &#1593;&#1576;&#1585;
&#1575;&#1604;&#1607;&#1575;&#1578;&#1601;&#1548;
&#1601;&#1575;&#1603;&#1587;&#1548; &#1576;&#1585;&#1610;&#1583;
&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1548; &#1571;&#1608;
&#1571;&#1610; &#1608;&#1587;&#1610;&#1604;&#1577;
&#1578;&#1587;&#1578;&#1593;&#1605;&#1604; &#1606;&#1601;&#1587;
&#1575;&#1604;&#1578;&#1603;&#1606;&#1608;&#1604;&#1608;&#1580;&#1610;&#1575;
&#1608;&#1584;&#1604;&#1603; &#1604;&#1573;&#1582;&#1576;&#1575;&#1585;&#1607; &#1576;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1578;&#1608;&#1601;&#1585;&#1577; &#1571;&#1608;
&#1575;&#1604;&#1605;&#1603;&#1578;&#1587;&#1576;&#1577;
&#1608;&#1601;&#1602;&#1575; &#1604;&#1607;&#1584;&#1607;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
&#1604;&#1604;&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;. <span
style='mso-spacerun:yes'> </span><o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1610;&#1578;&#1605; &#1573;&#1576;&#1604;&#1575;&#1594;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1571;&#1606;
&#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
&#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
&#1578;&#1581;&#1601;&#1592; &#1576;&#1593;&#1590;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1608;&#1610;&#1578;&#1605; &#1578;&#1582;&#1586;&#1610;&#1606;&#1607;&#1575;
&#1601;&#1610; &#1584;&#1575;&#1603;&#1585;&#1577; &#1602;&#1585;&#1589;&#1607;
&#1575;&#1604;&#1579;&#1575;&#1576;&#1578;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1578;&#1608;&#1601;&#1585; &#1604;&#1604;&#1605;&#1608;&#1585;&#1583;
&#1575;&#1604;&#1573;&#1581;&#1589;&#1575;&#1574;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
&#1576;&#1580;&#1605;&#1607;&#1608;&#1585;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;.
&#1603;&#1605;&#1575; &#1571;&#1606; &#1605;&#1604;&#1601;&#1575;&#1578;
&#1578;&#1593;&#1585;&#1610;&#1601;
&#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591; <o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1604;&#1575; &#1578;&#1581;&#1578;&#1608;&#1610; &#1593;&#1604;&#1609;
&#1571;&#1610; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1587;&#1585;&#1610;&#1577; &#1582;&#1575;&#1589;&#1577;
&#1576;&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1593;&#1591;&#1610;&#1575;&#1578; &#1584;&#1608;
&#1575;&#1604;&#1591;&#1575;&#1576;&#1593;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610; &#1575;&#1604;&#1578;&#1610;
&#1578;&#1607;&#1605; &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1578;&#1605;&#1603;&#1606;&#1607; &#1605;&#1606; &#1581;&#1602;
&#1575;&#1604;&#1608;&#1604;&#1608;&#1580;
&#1608;&#1575;&#1604;&#1578;&#1593;&#1583;&#1610;&#1604;
&#1608;&#1575;&#1604;&#1578;&#1593;&#1585;&#1590; &#1591;&#1576;&#1602;&#1575;
&#1604;&#1605;&#1602;&#1578;&#1590;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; 08-09.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 10:
&#1575;&#1604;&#1578;&#1593;&#1583;&#1610;&#1604;&#1575;&#1578;<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1573;&#1606; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
&#1604;&#1604;&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;
&#1607;&#1584;&#1607;&#1548; &#1610;&#1605;&#1603;&#1606;
&#1578;&#1593;&#1583;&#1610;&#1604;&#1607;&#1575;
&#1608;&#1578;&#1581;&#1610;&#1610;&#1606;&#1607;&#1575; &#1601;&#1610;
&#1571;&#1610; &#1608;&#1602;&#1578; &#1608;&#1583;&#1608;&#1606;
&#1587;&#1575;&#1576;&#1602; <span class=SpellE>&#1573;&#1606;&#1584;&#1575;&#1585;&#1608;&#1584;&#1604;&#1603;</span>
&#1576;&#1607;&#1583;&#1601;
&#1605;&#1604;&#1575;&#1574;&#1605;&#1578;&#1607;&#1575; &#1605;&#1593;
&#1575;&#1604;&#1578;&#1593;&#1583;&#1610;&#1604;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1588;&#1585;&#1610;&#1593;&#1610;&#1577;
&#1608;&#1575;&#1604;&#1578;&#1606;&#1592;&#1610;&#1605;&#1610;&#1577;&#1548; &#1603;&#1605;&#1575;
&#1610;&#1605;&#1603;&#1606; &#1578;&#1593;&#1583;&#1610;&#1604;
&#1575;&#1604;&#1608;&#1592;&#1575;&#1574;&#1601;
&#1575;&#1604;&#1605;&#1593;&#1585;&#1608;&#1590;&#1577; &#1601;&#1610;
&#1605;&#1606;&#1589;&#1577; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1575;&#1604;&#1608;&#1591;&#1606;&#1610;
&#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;
&#1603;&#1604;&#1605;&#1575; &#1601;&#1585;&#1590;&#1578;
&#1575;&#1604;&#1592;&#1585;&#1601;&#1610;&#1577; &#1584;&#1604;&#1603;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1578;&#1583;&#1582;&#1604; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1578;&#1593;&#1583;&#1610;&#1604;&#1575;&#1578; <span
class=SpellE>&#1608;&#1575;&#1604;&#1578;&#1581;&#1610;&#1610;&#1606;&#1575;&#1578;</span>
&#1576;&#1605;&#1580;&#1585;&#1583; &#1606;&#1588;&#1585;&#1607;&#1575;
&#1593;&#1604;&#1609; &#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610;
&#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577; &#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;.<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; </span><span dir=LTR></span><span
dir=LTR style='font-size:14.0pt;line-height:115%;mso-bidi-language:AR-MA'><span
dir=LTR></span>11</span><span dir=RTL></span><span lang=AR-MA style='font-size:
14.0pt;line-height:115%;mso-bidi-language:AR-MA'><span dir=RTL></span>:
&#1575;&#1604;&#1578;&#1588;&#1585;&#1610;&#1593;
&#1575;&#1604;&#1605;&#1591;&#1576;&#1602;
&#1608;&#1575;&#1604;&#1580;&#1607;&#1577;
&#1575;&#1604;&#1602;&#1590;&#1575;&#1574;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1582;&#1578;&#1589;&#1577;<o:p></o:p></span></p>

<p class=MsoNormal dir=RTL style='text-align:justify;direction:rtl;unicode-bidi:
embed'><span lang=AR-MA style='font-size:14.0pt;line-height:115%;mso-bidi-language:
AR-MA'>&#1578;&#1582;&#1590;&#1593; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577; &#1604;&#1575;&#1587;&#1578;&#1593;&#1605;&#1575;&#1604;<span
style='mso-spacerun:yes'>  </span>&#1575;&#1604;&#1605;&#1606;&#1589;&#1577;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1577;
&#1604;&#1604;&#1587;&#1580;&#1604; &#1575;&#1604;&#1608;&#1591;&#1606;&#1610;
&#1604;&#1604;&#1589;&#1606;&#1575;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;&#1577;
&#1604;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
&#1575;&#1604;&#1605;&#1594;&#1585;&#1576;&#1610;&#1548; &#1608;&#1601;&#1610;
&#1581;&#1575;&#1604;&#1577; &#1593;&#1583;&#1605;
&#1575;&#1604;&#1578;&#1608;&#1589;&#1604; &#1604;&#1575;&#1578;&#1601;&#1575;&#1602;
&#1608;&#1583;&#1610;&#1548; &#1578;&#1576;&#1602;&#1609;
&#1575;&#1604;&#1605;&#1581;&#1603;&#1605;&#1577;
&#1575;&#1604;&#1578;&#1580;&#1575;&#1585;&#1610;&#1577;
&#1576;&#1575;&#1604;&#1583;&#1575;&#1585; &#1575;&#1604;&#1576;&#1610;&#1590;&#1575;&#1569;
&#1589;&#1575;&#1581;&#1576;&#1577;
&#1575;&#1604;&#1575;&#1582;&#1578;&#1589;&#1575;&#1589;
&#1604;&#1604;&#1606;&#1592;&#1585; &#1601;&#1610; &#1603;&#1604;
&#1606;&#1586;&#1575;&#1593; &#1610;&#1578;&#1593;&#1604;&#1602; &#1576;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1571;&#1608; &#1578;&#1601;&#1587;&#1610;&#1585; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;. <o:p></o:p></span></p>

</div>

</body>

</html>`;
