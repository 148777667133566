import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Typography, Alert, Divider, Card, Space } from 'antd';
import { getGlobalParam } from '../../../../../../GlobalParams/Action';
import { getMotifDeRejet, addMotifDeRejet, updateMotifDeRejet } from '../../Action'
import { useTranslation } from 'react-i18next';
import { CheckBoxWithDoubleLabel, InputWithDoubleLabel, TextAreaWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import Utils from "../../../../../../../utilities/Utils/Utils";
import SpinLoader from '../../../../../../../component/SpinLoader/SpinLaoder';
const { Title } = Typography;


const ReferentielDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [shouldShouldSmsFiedls, setShouldShouldSmsFiedls] = useState(false)

    const [loading, setLoading] = useState(false);

    const { handleClose, activeReferenciel, action, refType } = props;


    useEffect(() => {
        setShouldShouldSmsFiedls(activeReferenciel ? activeReferenciel.sendSms : shouldShouldSmsFiedls)
        dispatch(getGlobalParam())
            .then((res) => setLoading(false))
            .catch((err) => setLoading(false))
    }, []);



    const onFinish = (values) => {
        const { loadDataOrRedirect, setSuccesseMessage, setErrorMessage } = props;
        const payload = {
            entity_type: Utils.REFERENTIEL_ENTITY_TYPES.MOTIF_REJET,
            payload: values
        }
        if (action === 'ADD') {
            dispatch(addMotifDeRejet(payload))
                .then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.motifDeRejet.add'))
                    loadDataOrRedirect();
                })
                .catch(err => {
                    setErrorMessage(err)
                })
        } else {
            dispatch(updateMotifDeRejet(activeReferenciel.key, payload))
                .then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.motifDeRejet.update'))
                    loadDataOrRedirect();
                })
                .catch(err => {
                    setErrorMessage(err)
                })
        }
        handleClose();
    };



    const getInitData = () => {
        let initData = {};
        if (action !== 'ADD') {
            initData = activeReferenciel;
        }
        return initData;
    }

    const isConsultation = () => {
        return action === 'CONSULTATION';
    }
    const renderSMSfields = () => {
        return;
    }
    const showFieldsSMS = (e) => {
        if (!e.target.checked) {
            form.setFieldsValue({ sms_label_fr: '' })
            form.setFieldsValue({ sms_label_ar: '' })
        }
        setShouldShouldSmsFiedls(!activeReferenciel ? !activeReferenciel.sendSms : !shouldShouldSmsFiedls)
    }

    const getTitle = () => {
        if (refType === Utils.REFERENTIEL_TYPES.MOTIF_REJET) {
            switch (action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.motifDeRejet');
                case 'UPDATE':
                    return t('agent.referentiel.update.motifDeRejet');
                case 'ADD':
                    return t('agent.referentiel.add.motifDeRejet');
                default:
                    break;
            }
        }
    }

    return (
        <>
            {loading ? <SpinLoader /> :
                <Modal visible={true} width={1500} onCancel={handleClose}
                    footer=
                    {
                        !isConsultation() &&
                        <>
                            <Button shape="round" key="back" onClick={handleClose}>
                                {t('agent.referentiel.modal.cancel.label')}
                            </Button>
                            <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                                {t('agent.referentiel.modal.submit.label')}
                            </Button>
                        </>
                    }
                >
                    <Form form={form} onFinish={onFinish} initialValues={getInitData()}>
                        <Title level={5} style={{ paddingBottom: '20px' }}>
                            {getTitle()}
                        </Title >
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="label_fr" label={t('agent.referentiel.modal.label-fr.label')} errorMessage={t('agent.referentiel.modal.label-fr.error-message')} disabled={isConsultation()} />
                            </Col>
                            <Divider type="vertical" />
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="label_ar" label={t('agent.referentiel.modal.label-ar.label')} errorMessage={t('agent.referentiel.modal.label-ar.error-message')} disabled={isConsultation()} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <TextAreaWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALPHABETIC_WITH_SPACE} name="description" label={t('agent.referentiel.modal.description.label')} errorMessage={t('agent.referentiel.modal.description.error-message')} disabled={isConsultation()} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <CheckBoxWithDoubleLabel onChange={showFieldsSMS} form={form} name="sendSms" label={t('agent.referentiel.modal.sendSms.label')} errorMessage={t('agent.referentiel.modal.sendSms.error-message')} disabled={isConsultation()} />
                            </Col>
                            {/* {!activeReferenciel ? shouldShouldSmsFiedls : sett() && <> */}
                            {shouldShouldSmsFiedls && <>
                                < Col lg={11} md={24} sm={24} xs={24} >
                                    <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="sms_label_fr" label={t('agent.referentiel.modal.sms-label-fr.label')} errorMessage={t('agent.referentiel.modal.sms-label-fr.error-message')} disabled={isConsultation()} />
                                </Col>
                                <Divider type="vertical" />
                                < Col lg={11} md={24} sm={24} xs={24} >
                                    <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="sms_label_ar" label={t('agent.referentiel.modal.sms-label-ar.label')} errorMessage={t('agent.referentiel.modal.sms-label-ar.error-message')} disabled={isConsultation()} />
                                </Col>
                            </>}
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ReferentielDetail;
