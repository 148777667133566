import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: '',
};

function reducer(state = initialeState, action) {
  switch (action.type) {
    case types.CREATE_ACCOUNT_PENDING: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.CREATE_ACCOUNT_FULFILLED: {
      return {
        ...state,
        loading: false,
        error: '',
      };
    }
    case types.CREATE_ACCOUNT_REJECTED: {
      return {
        ...state,
        error: action.payload.response.data.code,
        loading: false,
      };
    }
    case types.VALIDATE_OTP_PENDING: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.VALIDATE_OTP_FULFILLED: {
      return {
        ...state,
        loading: false,
        error: '',
      };
    }
    case types.VALIDATE_OTP_REJECTED: {
      return {
        ...state,
        error: action.payload.response.data.code,
        loading: false,
      };
    }
    case types.REGENERATE_OTP_PENDING: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.REGENERATE_OTP_FULFILLED: {
      return {
        ...state,
        loading: false,
        error: '',
      };
    }
    case types.REGENERATE_OTP_REJECTED: {
      return {
        ...state,
        error: action.payload.response.data.code,
        loading: false,
      };
    }
    case types.CLEAR_ERROR_MESSAGE: {
      return {
        ...state,
        error: '',
      };
    }

    default:
      return state;
  }
}

export default reducer;
