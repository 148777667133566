import React from "react";
import "./App.less";
import { Switch, BrowserRouter, Redirect, Route, Router } from "react-router-dom";
import { ConfigProvider, Layout } from "antd";
import history from "./history"
import Login from "./modules/login/Login";
import ForgotPassword from "./modules/login/forgotPassword/ForgotPassword";
import UpperMenu from "./component/UpperMenu";
import NewAccount from "./modules/NewAccount/NewAccount";
import ArtisanApp from "./modules/ArtisanApp/ArtisanApp";
import AgentApp from "./modules/AgentApp/AgentApp";
import frFR from 'antd/lib/locale/fr_FR';
import arEG from 'antd/lib/locale/ar_EG';
import AuthRoute from "./component/AuthRoute/AuthRoute";
import { useIdleTimer } from 'react-idle-timer'
import storage from "redux-persist/lib/storage";
import AttestationVerification from "./modules/AttestationVerification/AttestationVerification";
import localforage from "localforage";

const App = props => {
  const curerntLAnguage = localStorage.getItem('i18nextLng')
    ? localStorage.getItem('i18nextLng')
    : 'fr';
  const timeout = 900000
  const handleOnIdle = () => {
    logOut();
  }
  const logOut = () => {
    localforage.clear();
    localStorage.removeItem('token');
    window.location.reload();
  };
  const {
    isIdle,
    isLeader
  } = useIdleTimer({
    timeout,
    // onActive: handleOnActive,
    onIdle: handleOnIdle,
    debounce: 60000
  })

  const token = localStorage.getItem('token');
  return <ConfigProvider locale={curerntLAnguage === 'fr' ? frFR : arEG} direction={curerntLAnguage === 'fr' ? "ltl" : "rtl"}>
    <>
      {token ? (
        <Router history={history}>
          <Layout style={{ minHeight: "100%" }} className="background">
            <UpperMenu />
            <Layout className="site-layout" >
              <Switch>
                {process.env.REACT_APP_PROFIL === "ARTISAN"
                  ? <>
                    <AuthRoute path={["/app/artisan", "/app", "/"]} component={ArtisanApp} />
                  </>
                  : <>
                    <AuthRoute path={["/app/agent", "/app", "/"]} component={AgentApp} />
                  </>
                }
              </Switch>
            </Layout>

          </Layout>
        </Router>
      ) : <BrowserRouter>

        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/new-account/:dgsn_id?" component={NewAccount} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/attestationverification/:artisanId/:attestationId" component={AttestationVerification} />
          <Redirect to="/login" />
        </Switch>

      </BrowserRouter>}

    </>
  </ConfigProvider>

}
export default App;
