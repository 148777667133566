import Utils from '../../utilities/Utils/Utils';
import types from './ActionTypes';
import RegistrationTypes from '../ArtisanApp/Registration/ActionTypes';
import { store } from '../../configureStore';

const initialeState = {
  language: 'fr',
  userMetadata: {
    id: '',
    login: '',
    createdBy: null,
    createdAt: null,
    updatedBy: null,
    updatedAt: null,
    status: '',
  },
  user: {},
  token: '',
  profil: [],
  menus: [],
  artisan: { statut: '' },
  loading: false,
  errorMessage: "",
  successMessage: "",
  changePassword: { loading: false, success: '', error: '' },
};

function mapDataToUser(data) {

  console.log(data)
  console.log(data)

  const {
    login,
    status,
    users_detail: { profiles, id, user_info: { firstName, first_name_ar, lastName, last_name_ar, region, ville, province }, user_provinces: userProvinces },
  } = data;

  return {
    id,
    ...data.users_detail.userInfo,
    firstName,
    first_name_ar,
    lastName,
    last_name_ar,
    login,
    status,
    region,
    ville,
    province,
    userProvinces,
    profile: profiles[0].code,
  };
}

function mapDataToUpdateUser(data) {
  const {
    login,
    status,
    profiles,
    id,
    user_info: { firstName, lastName, first_name_ar, last_name_ar },
  } = data;

  return {
    id,
    ...data.userInfo,
    firstName,
    first_name_ar,
    last_name_ar,
    lastName,
    login,
    status,
    profile: profiles[0].code,
  };
}

function reducer(state = initialeState, action) {
  switch (action.type) {
    case types.CLEAR_ERROR_MESSAGE: {
      return {
        ...state,
        loading: false,
        errorMessage: "",
        successMessage: "",
      };
    }

    case types.CHANGE_ARTISAN_STATUS: {
      return {
        ...state,
        artisan: { ...state.artisan, statut: Utils.ARTISANT_STATUS.FINALISE }
      };
    }

    case RegistrationTypes.UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA_FULFILLED: {
      return {
        ...state,
        artisan: { ...state.artisan, statut: action.payload.data.data?.statut }
      };
    }
    case RegistrationTypes.UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA_FULFILLED: {
      return {
        ...state,
        artisan: { ...state.artisan, statut: action.payload.data.data?.statut }
      };
    }
    case RegistrationTypes.UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA_FULFILLED: {
      return {
        ...state,
        artisan: { ...state.artisan, statut: action.payload.data.data?.statut }
      };
    }
    case RegistrationTypes.UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA_FULFILLED: {
      return {
        ...state,
        artisan: { ...state.artisan, statut: action.payload.data.data?.statut }
      };
    }

    case types.CHANGE_PASSWORD_PENDING: {
      return {
        ...state,
        changePassword: { loading: true, success: '', error: '' }
      };
    }

    case types.CHANGE_PASSWORD_FULFILLED: {
      return {
        ...state,
        changePassword: { loading: false, success: 'PASSWORD_CHANGED', error: '' }
      };
    }

    case types.CHANGE_PASSWORD_REJECTED: {
      return {
        ...state,
        changePassword: { loading: false, success: '', error: action.payload.response.data.code }
      };
    }

    case types.FORGOT_PASSWORD_PENDING: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: ""
      };
    }

    case types.FORGOT_PASSWORD_REJECTED: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.response.data.code,
        successMessage: ""
      };
    }

    case types.FORGOT_PASSWORD_FULFILLED: {
      return {
        ...state,
        loading: false,
        errorMessage: "",
        successMessage: "FORGOT_PASSWORD_FULFILLED"
      };
    }

    case types.CLEAR_CHANGE_PASSWORD: {
      return {
        ...state,
        changePassword: { loading: false, success: '', error: '' }
      };
    }

    case types.AUTHENTICATION_PENDING: {
      return {
        ...state,
        loading: true,
        errorMessage: "",
        successMessage: ""
      };
    }
    case types.AUTHENTICATION_FULFILLED: {
      const data = action.payload.data.data;

      return {
        ...state,
        loading: false,
        token: data.token_id,
        user: { ...mapDataToUser(data), login: data.users_detail.login, status: data.users_detail.status, operateur: data.users_detail.operateur },
        dgsn_id: data.users_detail.dgsn_id,
        profil: data.users_detail.profiles[0],
        artisan: data.users_detail.artisan_summary,
        type: data.users_detail.type,
        menus: data.users_detail.profiles[0].menus,
        errorMessage: "",
        successMessage: ""
      };
    }

    case types.UPDATE_ARTISAN_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        user: { ...mapDataToUpdateUser(data), login: data.login, status: data.status, operateur: data?.operateur },
        artisan: data.artisan_summary,
        errorMessage: '',
      };
    }

    case types.AUTHENTICATION_REJECTED: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.response.data.code,
        successMessage: ""
      };
    }
    case types.CHANGE_APP_LANGUAGE: {
      const newLanguage = action.payload;
      return {
        ...state,
        language: newLanguage
      };
    }

    default:
      return state;
  }
}

export default reducer;
