import React from "react";
import { Table, Button, Divider, Row, Col } from 'antd';
import ExportJsonExcel from 'js-export-excel';
import { FileExcelOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import Utils from "../../utilities/Utils/Utils";

const TableWithExport = props => {
    const { t } = useTranslation()

    const { data, columns, title, showCnssExport, pagination, loading = false, onChange, total } = props;

    const getExportFileName = () => {
        let today = new Date();
        let date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date + '_' + time;
        return 'export-' + dateTime;
    }

    const downloadExcel = (data, columns) => {
        let columnsToIgnore = ['id', 'key', 'action'];
        var option = {};
        let dataTable = [];
        const sheetHeader = columns.filter((c) => !columnsToIgnore.includes(c.dataIndex)).map((c) => c.title);
        const sheetFilter = columns.filter((c) => !columnsToIgnore.includes(c.dataIndex)).map((c) => c.key);

        if (data) {
            for (let i in data) {
                let lineData = data[i];
                let obj = {}
                for (let c in lineData) {
                    obj[c] = lineData[c];
                }
                dataTable.push(obj);
            }
        }

        option.fileName = getExportFileName()
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',
                sheetFilter: sheetFilter,
                sheetHeader: sheetHeader,
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    const exportCnssData = () => {
        const { downloadCnssReport } = props;
        downloadCnssReport();
    }

    return (<>
        {title && <Divider>{title}</Divider>}
        <Row justify="end" align="middle" style={{ paddingBottom: '15px' }}>
            {showCnssExport &&
                <Button shape='round' type="primary" htmlType="submit" onClick={exportCnssData} icon={<FileExcelOutlined />}>
                    {t('table.cnss-export')}
                </Button>}
            <Button shape='round' type="primary" htmlType="submit" onClick={() => downloadExcel(data, columns)} icon={<FileExcelOutlined />}>
                {t('table.export')}
            </Button>
        </Row>
        <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
                <Table
                    onChange={onChange}
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 500 }}
                    loading={loading}
                    pagination={
                        {
                            defaultPageSize: Utils.DEFAULT_PAGINATION.defaultPageSize,
                            showSizeChanger: true,
                            pageSizeOptions: Utils.DEFAULT_PAGINATION.pageSizeOptions,
                            ...pagination,
                            total: total,
                        }} />
            </Col>

        </Row>
    </>);

}

TableWithExport.defaultProps = {
    showCnssExport: false
}

export default TableWithExport;