import React, { useState } from "react";
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { useTranslation } from 'react-i18next';
import { Progress, Row, Col, Alert } from "antd";

const getBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const UploadFile = (props) => {
    const { t } = useTranslation();
    const { artisanId, uploadArtisanFile, description, documentTypeId, changeUploadedFileStatus, onRemoveAction, handleRemove, documentId } = props;
    const [defaultFileList, setDefaultFileList] = useState(documentId ? [{ name: documentId.filename, status: 'done' }] : []);
    const [progress, setProgress] = useState(0);
    const [previewVisible, setPreviewVisible] = useState('');
    const [localeError, setLocaleError] = useState('');
    const maxFileSize = process.env.REACT_APP_FILE_SIZE;

    const uploadImage = async options => {

        const { onSuccess, onError, file, onProgress } = options;

        const isNotGoodSize = file.size / 1024 / 1024 > maxFileSize;


        if (!['image/png', 'image/jpeg', 'application/pdf'].includes(file.type)) {
            setLocaleError(t('upload-file.error-type'));
        } else if (isNotGoodSize) {
            setLocaleError(t('upload-file.error-size', { size: maxFileSize }));
        } else {
            const fmData = new FormData();
            fmData.append('file', file);
            fmData.append('document_type_id', documentTypeId);
            try {
                await uploadArtisanFile(artisanId, fmData);
                onSuccess("Ok");
                changeUploadedFileStatus(documentTypeId);
            } catch (err) {
                const error = new Error("Some error");
                onError({ err });
            }
            setLocaleError('')
        }

    };

    const handleOnChange = ({ file, fileList, event }) => {
        const isNotGoodSize = file.size / 1024 / 1024 > maxFileSize;
        if (['image/png', 'image/jpeg', 'application/pdf'].includes(file.type) && !isNotGoodSize) {
            setDefaultFileList(fileList);
        }

    };
    const handleOnRemove = () => {
        if (onRemoveAction && documentId) {
            handleRemove(artisanId, documentId.id).then(() => {
                onRemoveAction(documentTypeId)
                setDefaultFileList([])
            });
            
        } else {
            handleRemove(artisanId, documentTypeId);
        }
    }
    return (
        <>
            {localeError &&
                <Row >
                    <Col span={24}>
                        <Alert
                            message={t('component.upload-document.alert-upload-file')}
                            description={localeError}
                            type="error"
                        />
                    </Col>
                </Row>}

            <>
                <Dragger
                    customRequest={e => setTimeout(() => { uploadImage(e) }, 2000)}
                    onChange={handleOnChange}
                    onRemove={handleOnRemove}
                    progress={{
                        strokeColor: {
                            '0%': '#108ee9',
                            '100%': '#87d068',
                        },
                        strokeWidth: 3,
                        format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
                    }}
                    multiple={false}
                    name='ok.png'
                    listType="picture"
                    maxCount={1}
                    fileList={defaultFileList}
                    className="image-upload-grid" >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{description}</p>
                </Dragger>
                {progress > 0 ? <Progress percent={progress} /> : null}

            </>
        </>
    );
};

export default UploadFile;
