import Api from '../../../utilities/services/Api';
import types from "./ActionTypes"

export const systemHealth = () => ({
  type: types.ADMINISTRATION_FETCH_HEALTH,
  payload: Api.admin.systemHealth(),
});

export const systemMetrics = () => ({
  type: types.ADMINISTRATION_FETCH_METRICS,
  payload: Api.admin.systemMetrics(),
});

export const systemThreadDump = () => ({
  type: types.ADMINISTRATION_FETCH_THREAD_DUMP,
  payload: Api.admin.systemThreadDump(),
});

export const getLoggers = () => ({
  type: types.ADMINISTRATION_FETCH_LOGS,
  payload: Api.admin.getLoggers(),
});

export const changeLogLevel = (name, configuredLevel) => ({
  type: types.ADMINISTRATION_FETCH_LOGS_CHANGE_LEVEL,
  payload: Api.admin.changeLogLevel(name, configuredLevel),
});

export const getConfigurations = () => ({
  type: types.ADMINISTRATION_FETCH_CONFIGURATIONS,
  payload: Api.admin.getConfigurations(),
});

export const getEnv = () => ({
  type: types.ADMINISTRATION_FETCH_ENV,
  payload: Api.admin.getEnv(),
});