import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  successAcceptation: '',
  successRejet: '',
  artisans: [],
  artisanListMetadata: {}
};

const mapDataToArtisan = data => {
  const artisanList = [];
  data.map(artisan => {
    artisanList.push(
      {
        key: artisan.artisan_id,
        nom: artisan.nom,
        prenom: artisan.prenom,
        telephone: artisan.telephone,
        nom_cooperative: artisan.nom_cooperative,
        nom_entreprise: artisan.nom_entreprise,
        artisanTypeFR: artisan.artisan_type.labels.FR,
        artisanTypeAR: artisan.artisan_type.labels.AR,
        cnie: artisan.cnie,
        statut: artisan.statut,
        dateDemandeInscription: artisan.date_demande_inscription,
        dateDerniereSoumission: artisan.date_derniere_soumission,
        datePremiereValidation: artisan.date_premiere_validation,
        dateDerniereValidation: artisan.date_derniere_validation,
        num_identite: artisan.reference,
        activite: artisan.activite,
        date_radiation: artisan.date_radiation
      })
  })
  return artisanList;
}

function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.GET_ARTISAN_BY_SEARCH_CRITERIA_PENDING: {
      return {
        ...state,
        error: "",
        loading: true,
      };
    }
    case types.GET_ARTISAN_BY_SEARCH_CRITERIA_FULFILLED: {
      const metaData = action.payload.data?.meta_data;
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        artisans: mapDataToArtisan(data),
        artisanListMetadata: metaData,
        loading: false,
      };
    }

    case types.GET_ARTISAN_BY_SEARCH_CRITERIA_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }

    case types.VALIDATE_ARTISAN_SUBSCRIPTION_FULFILLED: {
      return {
        ...state,
        error: '',
        successAcceptation: 'Artisan validé avec succès',
        loading: false,
      };
    }

    case types.REJECT_ARTISAN_SUBSCRIPTION_FULFILLED: {
      return {
        ...state,
        error: '',
        successRejet: 'Artisan rejeté avec succès',
        loading: false,
      };
    }

    case types.VALIDATE_ARTISAN_SUBSCRIPTION_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        successAcceptation: '',
        successRejet: '',
        loading: false,
      };
    }

    case types.REJECT_ARTISAN_SUBSCRIPTION_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        successAcceptation: '',
        successRejet: '',
        loading: false,
      };
    }

    case types.RESET_MESSAGES_ARTISAN_DETAIL: {
      return {
        ...state,
        error: '',
        successAcceptation: '',
        successRejet: '',
        loading: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
