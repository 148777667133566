import types from './ActionTypes';
import Api from '../../../utilities/services/Api';
import fileDownload from 'js-file-download';

export const downloadCertification = (artisanId) => {
  return ({
    type: types.DOWNLOAD_CERTIFICATION,
    payload: Api.artisan.getCertification(artisanId).then( res => {
      const fileName = res.headers.x_file_name &&  res.headers.x_file_name !== "" 
          ? res.headers.x_file_name
          : "certification_artisan.pdf" ;

      fileDownload(res.data, fileName);
      return res;
    }),
  })
};

export const clearMessages = () => {
  return ({
    type: types.CLEAR_DOWNLOAD_CERTIFICATION_MESSAGES,
  })
};
