import React, { useState } from 'react';
import {
  Row, Col, Form,
  Button, Typography,
  Modal,
  Divider
} from "antd";
import './CreateAccountForm.less'
import ReCAPTCHA from "react-google-recaptcha";
import { InputWithDoubleLabel, SeletWithDoubleLabel, InputPhoneNumberWithDoubleLabel, InputPasswordWithDoubleLabelAndConfirmation, CheckBoxWithDoubleLabel } from '../../../../utilities/FormFields/FormFields'
import Utils from './../../../../utilities/Utils/Utils'
import { useTranslation } from 'react-i18next';
import history from '../../../../history'
import CGU_FR from '../../../../assets/CGU_FR.js'
import CGU_AR from '../../../../assets/CGU_AR.js'
import { Util } from 'reactstrap';
import i18next from 'i18next';


const { Text } = Typography;

const CreateAccountForm = (props) => {

  const { t } = useTranslation();
  const curerntLanguage = localStorage.getItem('i18nextLng')

  const [form] = Form.useForm();
  const [captcha, setCaptcha] = useState(undefined);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const onChangeCaptcha = (value) => {
    setCaptcha(old => value);
  };

  if(props.dgsnData){
    if(props.dgsnData.given_name_Fr && props.dgsnData.family_name_Fr){
      form.setFieldsValue({
         first_name: props.dgsnData.given_name_Fr,
         first_name_ar: props.dgsnData.given_name_Ar,
         last_name: props.dgsnData.family_name_Fr,
         last_name_ar: props.dgsnData.family_name_Ar,
      });
    }
  }

  const onchangePrivacyPolicy = e => {
    setPrivacyPolicy(e.target.checked);
  };

  const goBack = () => {
    history.push('/login');
    window.location.reload();
  }

  const onFinish = formValue => {
    const { createAccount } = props;
    createAccount({ ...formValue, phone_number: Utils.mapNumberToPhoneNumber(formValue.phone_number) });
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };
  const getDirection = () => i18next.language === 'fr' ? 'rtl' : 'ltr';

  return (
    <>
      <Row id="createAccountForm" style={{ justifyContent: 'center', alignSelf: 'center', padding: '20px' }} md={12} sm={12} xs={12} align="bottom" >

        <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
          <Form form={form}
            name="register"
            style={{ justifyContent: 'center', alignSelf: 'center' }}
            onFinish={onFinish}
            size='middle'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            scrollToFirstError
          >
            <Col span={24} md={20} sm={20}>
              <Row style={{ width: '100%', justifyContent: 'space-between', direction: 'ltr' }} reve>
                <Col lg={11} md={24} sm={24} xs={24} >
                  <InputWithDoubleLabel forceFr={true} form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="last_name" label="Nom" placeholder={t('component.personal-data.form.nom.placeholder')} errorMessage={t('component.personal-data.form.nom.error-message')} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="last_name_ar" label="الاسم العائلي" placeholder={t('component.personal-data.form.nom.placeholder')} errorMessage={t('component.personal-data.form.nom.error-message')} />
                </Col>
              </Row>
              <Row style={{ width: '100%', justifyContent: 'space-between', direction: 'ltr' }}>
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel forceFr={true} form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="first_name" label="Prénom" placeholder={t('component.personal-data.form.prenom.placeholder')} errorMessage={t('component.personal-data.form.prenom.error-message')} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="first_name_ar" label="الاسم الشخصي" placeholder={t('component.personal-data.form.prenom.placeholder')} errorMessage={t('component.personal-data.form.prenom.error-message')} />
                </Col>
              </Row>
            </Col>
            {/* <Col span={24} md={20} sm={20}>
              <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALPHABETIC_WITH_SPACE} name="first_name" label={t('new-account.form.create-account.first-name.label')} placeholder="" errorMessage={t('new-account.form.create-account.first-name.error-message')} />
            </Col>
            <Col span={24} md={20} sm={20}>
              <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALPHABETIC_WITH_SPACE} name="last_name" label={t('new-account.form.create-account.last-name.label')} placeholder="" errorMessage={t('new-account.form.create-account.last-name.error-message')} />
            </Col> */}
            <Col span={24} md={20} sm={20}>
              <SeletWithDoubleLabel name="operateur" label={t('new-account.form.create-account.operateur.label')} placeholder={t('new-account.form.create-account.operateur.placeholder')} errorMessage={t('new-account.form.create-account.operateur.error-message')} options={Utils.Operateurs} />
            </Col>
            <Col span={24} md={20} sm={20}>
              <InputPhoneNumberWithDoubleLabel name="phone_number" label={t('new-account.form.create-account.phone-number.label')} placeholder={t('new-account.form.create-account.phone-number.placeholder')} errorMessage={t('new-account.form.create-account.phone-number.error-message')} />
            </Col>
            <Col span={24} md={20} sm={20}>
              <SeletWithDoubleLabel name="artisan_type" label={t('new-account.form.create-account.artisan-type.label')} placeholder={t('new-account.form.create-account.artisan-type.placeholder')} errorMessage={t('new-account.form.create-account.artisan-type.error-message')} options={props.globalParam ? props.globalParam.artisanTypes : []} />
            </Col>
            <Col span={24} md={20} sm={20}>
              <InputPasswordWithDoubleLabelAndConfirmation
                getFieldValue={form.getFieldValue}
                type="password"
                name="password"
                passwordLabel={t('new-account.form.create-account.password.label')}
                repeatPasswordLabel={t('new-account.form.create-account.repeat-password.label')}
                placeholder={t('new-account.form.create-account.password.error-message')}
                errorMessage={t('new-account.form.create-account.password.error-message')} />
            </Col>
            <Col span={24} md={20} sm={20} style={{ margin: " 0px 0 5% 0" }}>
              <CheckBoxWithDoubleLabel cssClass={'ant-checkbox-inner-bigger'} style={{ margin: 0 }} name={"privacy_policy"} label={t('new-account.form.create-account.privacy-policy.label')} errorMessage={t('new-account.form.create-account.privacy-policy.label')} onChange={onchangePrivacyPolicy} />
              <Text>{t('new-account.form.create-account.cndp-validation')}</Text>
              <Text>{t('new-account.form.create-account.cndp-validation-num')}</Text>
            </Col>
            <Col span={24} md={20} sm={20} className="gutter-row">
              <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Text className='col' >{t('new-account.form.create-account.privacy-policy-label-1')}</Text><a onClick={showModal}>{t('new-account.form.create-account.privacy-policy-label-2')}</a>
              </Form.Item>
            </Col>
            <Col span={12} md={10} sm={10} >
              <ReCAPTCHA
                sitekey={process.env.RECAPTCHA_SITEKEY}
                onChange={onChangeCaptcha}
              />
            </Col>
            <Row>
              <Col type='flex' span={12} style={{ textAlign: 'right', padding: '21px', justifyContent: 'space-between' }} >
                <Button shape="round" type="default" style={{ width: '100%' }} onClick={() => { goBack() }}>
                  {t('new-account.form.create-account.back.label')}
                </Button>
              </Col>
              <Col type='flex' span={12} style={{ textAlign: 'right', padding: '21px', justifyContent: 'space-between' }} >
                <Button shape="round" type="primary" htmlType="submit" disabled={false/*captcha === undefined || privacyPolicy === false*/} style={{ width: '100%' }} >
                  {t('new-account.form.create-account.submit.label')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row >


      <Modal title={t('cgu-modal-title')} visible={isModalVisible} onCancel={handleClose} footer={null} centered width={1000}>
        <div dangerouslySetInnerHTML={{ __html: curerntLanguage === 'fr' ? CGU_FR : CGU_AR }} />
      </Modal></>
  );

};

export default CreateAccountForm;
