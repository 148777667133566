import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  profiles: []
};

function mapProfiles(data) {
    let profiles =[];
    data.map( profil => {
            profiles.push(
                {
                    ...profil,
                    name: profil.description
                }
            )
        }
    )
    return profiles;
}


function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.RESET_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case types.GET_PROFILES_PENDING: {
      return {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
    }

    case types.GET_PROFILES_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        profiles: mapProfiles(data),
        loading: false,
      };
    }

    case types.GET_PROFILES_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
