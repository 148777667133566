import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  duree: [],
};

const mapDataToDuree = data => {
  return data.map(line => {
    return {
      key: line.id,
      label_fr: line.labels?.FR,
      label_ar: line.labels?.AR,
      code: line.code,
      description: line.description,
      value: line.value
    };
  })
};


function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case (types.GET_DUREE_PENDING || types.ADD_DUREE_PENDING || types.UPDATE_DUREE_PENDING): {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case types.GET_DUREE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        duree: mapDataToDuree(data),
        loading: false,
      };
    }


    case types.ADD_DUREE_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "Motif de rejet créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_DUREE_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "Motif de rejet modifié avec succès",
        loading: false
      };
    }


    case (types.GET_DUREE_REJECTED || types.ADD_DUREE_REJECTED || types.UPDATE_DUREE_REJECTED): {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
