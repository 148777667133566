import types from './ActionTypes';
import Api from '../../utilities/services/Api';
import Utils from '../../utilities/Utils/Utils';


export const getReferentiels = () => {
  return ({
    type: types.GET_REFERENTIELS,
    payload: Api.referentiels.getReferentiels(),
  })
};
export const addNewEstablshment = establishment => {
  return ({
    type: types.ADD_NEW_ESTABLISHMENT,
    payload: Api.referentiels.addNewEstablshment(establishment),
  })
};

export const getAnnexe = () => ({
  type: types.GET_ANNEXE_ADMINISTRATIVE,
  payload: Api.referentiels.annexe.getAll(),
});


export const getEtablissement = () => ({
  type: types.GET_ETABLISSEMENT,
  payload: Api.referentiels.etablissements.getAll(),
});

export const getDuree = () => ({
  type: types.GET_DUREE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.DUREE),
});

export const getActiviteBranche = () => ({
  type: types.GET_ACTIVITE_BRANCHE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_BRANCHE),
});

export const getActivite = () => ({
  type: types.GET_ACTIVITE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE),
});

export const getCouvertureSociale = () => ({
  type: types.GET_COUVERTURE_SOCIALE,
  payload: Api.referentiels.couvertureSociale.getAll(),
});

export const getDiplome = () => ({
  type: types.GET_DIPLOME,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.DIPLOME),
});

export const getDiplomeDuree = () => ({
  type: types.GET_DIPLOME_DUREE,
  payload: Api.referentiels.referentiel.getAll(Utils.REFERENTIEL_ENTITY_TYPES.DIPLOME_DUREE),
});