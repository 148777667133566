import types from './ActionTypes';


const initialeState = {
  loading: false,
  error: "",
  success: "",
  smsList: []
};


const mapDataToSmsLIst = data => {
  const list = [];
  data.map(sms => {
    list.push(
      {
        key: sms.id,
        label_fr: sms.label_fr,
        label_ar: sms.label_ar,
        code: sms.code
      })
  })
  return list;
}


function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case types.GET_ALL_SMS_PENDING: {
      return {
        ...state,
        error: "",
        success: "",
        loading: true,
        smsList: []
      };
    }

    case types.GET_ALL_SMS_FULFILLED: {
      const smsList = action.payload.data.data;

      return {
        ...state,
        error: "",
        success: "",
        loading: false,
        smsList: mapDataToSmsLIst(smsList)
      };
    }

    case types.GET_ALL_SMS_REJECTED: {

      return {
        ...state,
        error: "error get all sms",
        success: "",
        loading: false,
        smsList: []
      };
    }

    default:
      return state;
  }
}

export default reducer;


