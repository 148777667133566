import React from "react";
import { Table, Input, Button, Space, Divider, Col, Row, Alert } from 'antd';
import { withRouter } from "react-router";
import Highlighter from 'react-highlight-words';
import history from '../../../../../history';
import { SearchOutlined, EyeOutlined, EditOutlined, DeleteOutlined, LockOutlined } from '@ant-design/icons';
import { withTranslation } from "react-i18next";

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
        };
    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        shape="round"
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {this.props.t('agent.user-list.filterDropdown.search')}
                    </Button>
                    <Button shape="round" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {this.props.t('agent.user-list.filterDropdown.reset')}
                    </Button>
                    <Button
                        shape="round"
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        {this.props.t('agent.user-list.filterDropdown.filter')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleDetails = (record) => {
        history.push('/app/agent/users/' + record + '/details');
    }
    handleUpdates = (record) => {
        history.push('/app/agent/users/' + record + '/update');
    }
    handleDeletes = (record) => {
        this.props.deleteUser(record)
    }
    handleDeactivate = (record) => {
        this.props.deactivateUser(record)
    }

    render() {
        const { users: data, t, error, success } = this.props;
        const columns = [
            {
                title: t('agent.user-list.columns.action'),
                dataIndex: 'action',
                key: Math.random,
                render: (text, record, index) => (
                    <Row align='middle'>
                        <Col style={{width: 18}}>
                            <a onClick={() => this.handleDetails(record.key)}><EyeOutlined /> </a>
                        </Col>

                        <Col style={{width: 18}}>
                            <a onClick={() => this.handleUpdates(record.key)}><EditOutlined /> </a>
                        </Col>

                        <Col style={{width: 18}}>
                            <a onClick={() => this.handleDeletes(record.key)}><DeleteOutlined /> </a>
                        </Col>
                        <Col  style={{width: 18}}>
                            <a onClick={() => this.handleDeactivate(record.key)}><LockOutlined /> </a>
                        </Col>
                    </Row>
                ),
            },
            {
                title: t('agent.user-list.columns.nom'),
                dataIndex: 'nom',
                key: 'nom',
                ...this.getColumnSearchProps('nom'),
            },
            {
                title: t('agent.user-list.columns.prenom'),
                dataIndex: 'prenom',
                key: 'prenom',
                ...this.getColumnSearchProps('prenom'),
            },
            {
                title: t('agent.user-list.columns.login'),
                dataIndex: 'login',
                key: 'login',
                ...this.getColumnSearchProps('login'),
            },
            {
                title: t('agent.user-list.columns.profile'),
                dataIndex: 'profile',
                key: 'profile',
                ...this.getColumnSearchProps('profile'),
            },
            {
                title: t('agent.user-list.columns.email'),
                dataIndex: 'email',
                key: 'email',
                ...this.getColumnSearchProps('email'),
            },
            {
                title: t('agent.user-list.columns.province'),
                dataIndex: 'province',
                key: 'province',
                ...this.getColumnSearchProps('province'),
            },
            {
                title: t('agent.user-list.columns.region'),
                dataIndex: 'region_label',
                key: 'region_label',
                ...this.getColumnSearchProps('region'),
            }
        ];

        return <>
            <Divider>{t('agent.user-list.divider.search')}</Divider>
            {error &&
                <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                    <Col span={24}>
                        <Alert
                            style={{ textAlign: 'center', width: '100%' }}
                            message={t(error)}
                            type="error"
                            closable
                        />
                    </Col>
                </Row>
            }
            {success &&
                <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                    < Col span={24}>
                        <Alert
                            style={{ textAlign: 'center', width: '100%' }}
                            message={t(success)}
                            type="success"
                            closable
                        />
                    </Col>
                </Row>
            }

            <Table columns={columns} dataSource={data} scroll={{ x: 500 }} pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }} />
        </>;
    }
}

export default withTranslation()(withRouter(UserList));