import { combineReducers } from 'redux';
import loginReducer from './modules/login/reducer';
import logoReducer from './modules/Logo/reducer';
import newAccountReducer from './modules/NewAccount/reducer';
import administrationReducer from './modules/AgentApp/administration/reducer';
import usersReducer from './modules/AgentApp/Users/reducer';
import reportReducer from './modules/AgentApp/report/reducer';
import artisanReducer from './modules/AgentApp/Artisan/reducer';
import artisanEventReducer from './modules/ArtisanApp/ArtisanNotification/reducer';
import certificationReducer from './modules/ArtisanApp/Certification/reducer';
import registration from './modules/ArtisanApp/Registration/reducer';
import globalParamReducer from './modules/GlobalParams/reducer';
import profilesReducer from './modules/Profiles/reducer';
import habilitationReducer from './modules/AgentApp/Habilitation/reducer';
import referentielParamsReducer from './modules/AgentApp/Referentiels/reducer';
import referentielReducer from './modules/Referentiels/reducer';
import artisanDetailReducer from './modules/AgentApp/Artisan/components/ArtisanDetails/reducer';
import reclamationReducer from './modules/Reclamation/reducer';
import SmsReducer from './modules/AgentApp/Sms/reducer';
import SettingsReducer from './modules/AgentApp/Settings/reducer';
import deleteAccountReducer from './modules/ArtisanApp/DeleteAccount/reducer';
import deleteArtisanAccountReducer from './modules/AgentApp/DeleteArtisanAccount/reducer';
import attestationVerificationReducer from './modules/AttestationVerification/reducer';


const reducer = combineReducers({
  loginReducer,
  logoReducer,
  artisanReducer,
  artisanEventReducer,
  newAccountReducer,
  administrationReducer,
  registration,
  globalParamReducer,
  profilesReducer,
  referentielReducer,
  artisanDetailReducer,
  certificationReducer,
  usersReducer,
  reportReducer,
  habilitationReducer,
  referentielParamsReducer,
  reclamationReducer,
  SmsReducer,
  deleteAccountReducer,
  deleteArtisanAccountReducer,
  attestationVerificationReducer,
  SettingsReducer
});

export default reducer;
