import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Typography, Alert, Divider, Card, Space } from 'antd';
import { getGlobalParam } from '../../../../../../GlobalParams/Action';
import { addRegion, addProvince, addVille, updateRegion, updateProvince, updateVille } from './../../Action'
import { useTranslation } from 'react-i18next';
import { InputWithDoubleLabel, SeletWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import Utils from "../../../../../../../utilities/Utils/Utils";
import SpinLoader from '../../../../../../../component/SpinLoader/SpinLaoder';
const { Title } = Typography;


const ReferentielDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const { handleClose, activeReferenciel, action, refType } = props;

    const loadedRegions = useSelector(state => state.globalParamReducer?.params?.regions);
    const loadedProvinces = useSelector(state => state.globalParamReducer?.params?.provinces);

    const [regions, setRegions] = useState(loadedRegions);
    const [provinces, setProvinces] = useState(loadedProvinces);

    useEffect(() => {
        dispatch(getGlobalParam())
            .then((res) => setLoading(false))
            .catch((err) => setLoading(false))
    }, []);


    const onFinish = (values) => {
        const { loadDataOrRedirect, setSuccesseMessage, setErrorMessage } = props;
        if (action === 'ADD') {
            if (refType === Utils.REFERENTIEL_TYPES.REGIONS) {
                dispatch(addRegion(values)).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.region.add'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.PROVINCES) {
                dispatch(addProvince(values)).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.province.add'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.VILLES) {
                dispatch(addVille(values))
            }
        } else {
            if (refType === Utils.REFERENTIEL_TYPES.REGIONS) {
                dispatch(updateRegion(activeReferenciel.key, values)).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.region.update'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.PROVINCES) {
                dispatch(updateProvince(activeReferenciel.key, values)).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.province.update'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.VILLES) {
                dispatch(updateVille(activeReferenciel.key, values)).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.ville.update'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            }
        }
        handleClose();
    };

    const onChangeRegion = (e) => {
        setProvinces(loadedProvinces.filter(p => p.regionId === e));
        form.setFieldsValue({ province: '' })
    }

    const onChangeProvince = e => {
        var province = provinces.filter(province => province.id === e);
        form.setFieldsValue({ region_id: province[0].regionId })
    };

    const getInitData = () => {
        return action !== 'ADD' ? activeReferenciel : {};
    }

    const isConsultation = () => {
        return action === 'CONSULTATION';
    }

    const getTitle = () => {
        if (refType === Utils.REFERENTIEL_TYPES.REGIONS) {
            switch (props.action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.region');
                case 'UPDATE':
                    return t('agent.referentiel.update.region');
                case 'ADD':
                    return t('agent.referentiel.add.region');
                default:
                    break;
            }
        } else if (refType === Utils.REFERENTIEL_TYPES.PROVINCES) {
            switch (props.action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.province');
                case 'UPDATE':
                    return t('agent.referentiel.update.province');
                case 'ADD':
                    return t('agent.referentiel.add.province');
                default:
                    break;
            }
        } else if (refType === Utils.REFERENTIEL_TYPES.VILLES) {
            switch (props.action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.ville');
                case 'UPDATE':
                    return t('agent.referentiel.update.ville');
                case 'ADD':
                    return t('agent.referentiel.add.ville');
                default:
                    break;
            }
        }


    }


    return (
        <>
            {loading ? <SpinLoader /> :
                <Modal visible={true} width={1500} onCancel={handleClose}
                    footer=
                    {
                        !isConsultation() &&
                        <>
                            <Button shape="round" key="back" onClick={handleClose}>
                                {t('agent.referentiel.modal.cancel.label')}
                            </Button>
                            <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                                {t('agent.referentiel.modal.submit.label')}
                            </Button>
                        </>
                    }
                >

                    <Form form={form} onFinish={onFinish} initialValues={getInitData()}>
                        <Title level={5} style={{ paddingBottom: '20px' }}>
                            {getTitle()}
                        </Title >
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            {
                                (refType === Utils.REFERENTIEL_TYPES.PROVINCES || refType === Utils.REFERENTIEL_TYPES.VILLES) &&
                                < Col lg={11} md={24} sm={24} xs={24} >
                                    <SeletWithDoubleLabel type="text" name="region_id" label={t('agent.referentiel.modal.region.label')} errorMessage={t('agent.referentiel.modal.region.error-message')} options={regions} onChangeAction={onChangeRegion} disabled={isConsultation()} />
                                </Col>
                            }
                            {
                                refType === Utils.REFERENTIEL_TYPES.VILLES &&
                                <>
                                    <Divider type="vertical" />
                                    < Col lg={11} md={24} sm={24} xs={24} >
                                        <SeletWithDoubleLabel type="text" name="province_id" label={t('agent.referentiel.modal.province.label')} errorMessage={t('agent.referentiel.modal.province.error-message')} options={provinces} onChangeAction={onChangeProvince} disabled={isConsultation()} />
                                    </Col>
                                </>
                            }
                        </Row>
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="label_fr" label={t('agent.referentiel.modal.label-fr.label')} errorMessage={t('agent.referentiel.modal.label-fr.error-message')} disabled={isConsultation()} />
                            </Col>
                            <Divider type="vertical" />
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="label_ar" label={t('agent.referentiel.modal.label-ar.label')} errorMessage={t('agent.referentiel.modal.label-ar.error-message')} disabled={isConsultation()} />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ReferentielDetail;
