import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: {},
  annexe: [],
  etablissement: [],
  activite: [],
  activiteBranche: [],
  couvertureSociale: [],
  diplomeDuree: [],
  diplome: [],
  params: [
    { activiteBranches: [] },
    { activiteFilieres: [] },
    { activites: [] },
    { etablissements: [] },
  ]
};

function mapParams(data) {

  const activiteBranches = data.activite_branches.map((type) => {
    return { 'id': type.id, 'name': type.labels };
  });

  const artisanStatuts = data.artisan_statuts.map((status) => {
    return { 'id': status.id, 'name': status.code };
  });

  const activiteFilieres = data.activite_filieres.map((type) => {
    return { 'id': type.id, 'activiteBrancheId': type.activite_branche_id, 'name': type.labels };
  });

  const activites = data.activites.map((type) => {
    return { 'id': type.id, 'activiteBrancheId': type.activite_branche_id, 'activiteFiliereId': type.activite_filiere_id, 'name': type.labels };
  });

  const etablissements = data.etablissements.map((type) => {
    return { 'id': type.id, 'regionId': type.region_id, 'name': type.labels, code: type.code };
  });

  return { activiteBranches, activiteFilieres, activites, etablissements, artisanStatuts };
}


const mapData = data => {
  return data.map(line => {
    return {
      id: line.id,
      name: {
        FR: line.labels?.FR,
        AR: line.labels?.AR,
      }
    };
  })
};
const mapDataToCouvertureSociale = data => {
  return data.map(line => {
    return {
      id: line.id,
      name: {
        FR: line.labels?.FR,
        AR: line.labels?.AR,
      },
      code: line.code,
      description: line.description
    };
  })
};
const mapDataToActivites = data => {
  return data.map(line => {
    return {
      id: line.id,
      activite_branche_id: line.activite_branche_id,
      name: {
        FR: line.labels?.FR,
        AR: line.labels?.AR,
      }
    };
  })
};
const mapDataToEtablissement = data => {
  return data.map(line => {
    return {
      id: line.id,
      region: line.region_id,
      province: line.province_id,
      name: {
        FR: line.labels?.FR,
        AR: line.labels?.AR,
      },
      code: line.code
    };
  })
};

const mapDataToDiplome = data => {
  return data.map(line => {
    const code = line.id + '_' + line.etablissement_id + '_' + line.diplome_distinct_id + '_' + line.departement_formation_id;
    return {
      id: line.id,
      name: {
        FR: line.labels?.FR,
        AR: line.labels?.AR,
      },
      code: code,
      diplomeDistinct: line.diplome_distinct_id,
      diplomeCategorie: line.diplome_categorie_id,
      departementFormation: line.departement_formation_id,
      modeFormation: line.mode_formation_id,
      etablissement: line.etablissement_id,
      publicPrive: line.publicprive_id,
      region: line.region_id,
      province: line.province_id,
      ville: line.ville_id
    };
  })
};

const mapDataToDiplomeDuree = data => {
  return data.map(line => {
    return {
      key: line.id,
      duree_id: line.duree_id,
      diplome_id: line.diplome_id,
    };
  })
};

function reducer(state = initialeState, action) {
  switch (action.type) {
    case types.GET_COUVERTURE_SOCIALE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        couvertureSociale: mapDataToCouvertureSociale(data),
        loading: false,
      };
    }
    case types.GET_DIPLOME_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        diplome: mapDataToDiplome(data),
        loading: false,
      };
    }
    case types.GET_DIPLOME_DUREE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        diplomeDuree: mapDataToDiplomeDuree(data),
        loading: false,
      };
    }
    case types.GET_ACTIVITE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        activite: mapDataToActivites(data),
        loading: false,
      };
    }
    case types.GET_ACTIVITE_BRANCHE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        activiteBranche: mapData(data),
        loading: false,
      };
    }

    case types.GET_ANNEXE_ADMINISTRATIVE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        annexe: mapData(data),
        loading: false,
      };
    }
    case types.GET_ETABLISSEMENT_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        etablissement: mapDataToEtablissement(data),
        loading: false,
      };
    }

    case types.GET_DUREE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        duree: mapData(data),
        loading: false,
      };
    }

    case types.GET_REFERENTIELS_PENDING: {
      return {
        ...state,
        error: {},
        loading: true,
      };
    }
    case types.GET_REFERENTIELS_FULFILLED: {
      return {
        ...state,
        params: mapParams(action.payload.data.data),
        error: {},
        loading: false,
      };
    }

    case types.GET_REFERENTIELS_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }

    case types.ADD_NEW_ESTABLISHMENT_PENDING: {
      return {
        ...state,
        error: {},
        loading: true,
      };
    }
    case types.ADD_NEW_ESTABLISHMENT_FULFILLED: {
      return {
        ...state,
        // params: { ...state.params, etablissements: state.params.etablissements.push(action.payload.data.data) },
        error: {},
        loading: false,
      };
    }

    case types.ADD_NEW_ESTABLISHMENT_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;