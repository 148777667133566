import React, { useEffect, useState } from 'react';
import {
  Row, Col, Form,
  Space,
  Card,
} from "antd";
import './RepresentativePersonalData.less'
import { InputCNIEWithDoubleLabel, InputWithDoubleLabel, InputPhoneNumberWithDoubleLabel, InputCNIEWithTypeIdentity } from '../../../../../../../utilities/FormFields/FormFields'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Utils from '../../../../../../../utilities/Utils/Utils';
import { isEmpty } from 'lodash';

const RepresentativePersonalData = props => {
  const { t } = useTranslation();
  const artisanType = useSelector(state => state.registration?.artisantData?.artisan_type?.code);
  const [form] = Form.useForm();

  let actualTypeIdentity;

  const getInitData = () => {

    const { initData } = props;
    let data = initData;

    if (!isEmpty(data)) {
      if (!data.type_identite) {
        data.type_identite = Utils.TYPE_IDENTITIES.CNIE;
      }
    }

    actualTypeIdentity = data.type_identite;
    return data;
  }
  return (
    <Card  >
      <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }} align="middle">
        <Space direction="vertical" size="middle" className="w-100">
          <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
            <Form form={form}
              name="registration"
              style={{ width: '100%' }}
              size='small'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              scrollToFirstError
              initialValues={getInitData()}
            >
              <Col span={24}>
                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="nom" label={t(`component.representative-personal-data.form.nom.label.${artisanType === 'ENTREPRISE_ARTISANAT' ? 'entreprise' : 'cooperative'}`)} disabled={true} />
              </Col>

              <Col span={24}>
                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="prenom" label={t(`component.representative-personal-data.form.prenom.label.${artisanType === 'ENTREPRISE_ARTISANAT' ? 'entreprise' : 'cooperative'}`)} disabled={true} />
              </Col>

              <Col span={24}>
                <InputWithDoubleLabel form={form} type="text" name="poste" label={t(`component.representative-personal-data.form.poste.label.${artisanType === 'ENTREPRISE_ARTISANAT' ? 'entreprise' : 'cooperative'}`)} disabled={true} />
              </Col>

              <Col span={24}>
                <InputCNIEWithTypeIdentity
                  disabled
                  options={Utils.identites}
                  cnieName="cnie"
                  typeIdentityName="type_identite"
                  label={t(`component.representative-personal-data.form.${actualTypeIdentity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.label`)}
                  typeIdentityErrorMessage={t('component.personal-data.form.typeIdentite.error-message')}
                />
              </Col>

              <Col span={24}>
                <InputWithDoubleLabel form={form} type="text" name="adresse" label={t('component.representative-personal-data.form.adresse.label')} disabled={true} />
              </Col>

              <Col span={24}>
                <InputPhoneNumberWithDoubleLabel type="text" name="numero_telephone" label={t('component.representative-personal-data.form.numero_telephone.label')} disabled={true} />
              </Col>

            </Form>
          </Col>
        </Space>
      </Row >
    </Card>
  );

};

export default RepresentativePersonalData;
