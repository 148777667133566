import React, { useEffect, useRef, useState } from "react";
import {
    Row, Col, Form,
    Input,
    Radio,
    Select,
    DatePicker,
    Typography,
    InputNumber,
    Button,
    Divider,
    Upload
} from "antd";
import locale from "antd/lib/date-picker/locale/en_US";
import TextArea from "antd/lib/input/TextArea";
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import './FormFields.less'
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { PlusOutlined } from '@ant-design/icons';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Utils from "../Utils/Utils";
import Checkbox from "antd/lib/checkbox/Checkbox";
import i18next from "i18next";


const { Option } = Select;
const { Text } = Typography;


export const FieldWithDoubleLabel = ({ rules, type, name, label, required = true, errorMessage, children }) => (
    <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row gutter={16}>
            <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    rules={rules?.length > 0 ? rules : [{ required: required, message: errorMessage }]}
                >
                    {children}
                </Form.Item>
            </Col>
        </Row>
    </Form.Item >
);
export const SimpleInputWithDoubleLabel = ({ type, name, label, placeholder, required = true, errorMessage, value, disabled = false, form }) => {
    let rules = [
        {
            required: required,
            message: errorMessage,
        }

    ];
    return (<Form.Item className={name} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
        <Row gutter={16}>
            <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    rules={rules}
                >
                    <Input
                        style={{ width: '100%' }} size="large" placeholder={placeholder} type={type} defaultValue={value} disabled={disabled} />
                </Form.Item>
            </Col>
        </Row>

    </Form.Item >
    );
}

export const CheckBoxWithDoubleLabel = ({ name, label, required = true, errorMessage, disabled = false, form, onChange, style }) => {

    return (<Form.Item className={name} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0, ...style }}>
        <Row>
            <Col className="gutter-row" lg={9} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={2} md={2} sm={2} xs={2} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    valuePropName="checked"
                >
                    <Checkbox disabled={disabled} onChange={onChange} />
                </Form.Item>
            </Col>
        </Row>

    </Form.Item >
    );
}

export const InputWithDoubleLabel = ({ forceFr = false, formListName, type, name, label, placeholder, required = true, errorMessage, value, disabled = false, form, globalStyle = {} }) => {
    const [showIcon, setShowIcon] = useState(false);
    const wrapperRef = useRef(null);
    const [keyLayout, setKeyLayout] = useState('');
    const language = type === Utils.TEXT_TYPE.ARABIC ? 'ar' : i18n.language;
    const keyboard = useRef(null);
    const inputName = typeof name === 'object' ? formListName + '.' + name[0] + '.' + name[1] : name;
    let rules = [
        {
            required: required,
            message: errorMessage,
        }
    ];
    const shouldbeActivatedOnlyOnArabic = type => ![Utils.TEXT_TYPE.ALPHANUMERIC, Utils.TEXT_TYPE.NUMERIC, Utils.TEXT_TYPE.SPECIAL, Utils.TEXT_TYPE.ALPHABETIC].includes(type) && !forceFr;

    if ((type != Utils.TEXT_TYPE.ANY_LANGUAGE) && (type != Utils.TEXT_TYPE.ANY_LANGUAGE_WITH_SPACE) 
        && language == 'ar' && shouldbeActivatedOnlyOnArabic(type)) {
        rules.push({
            pattern: /^[-\u0621-\u064A0-9\s]+$/,
            message: i18n.t('fields.InputWithDoubleLabel.rules.arabic')
        })
    } else {

        switch (type) {
            case Utils.TEXT_TYPE.ANY_LANGUAGE:
                rules.push({
                    pattern: /^[-\u0621-\u064A0-9\sa-zA-Z0-9]+$/,
                    message: i18n.t('fields.InputWithDoubleLabel.rules.alphanumeric')
                })
                break;
            case Utils.TEXT_TYPE.ANY_LANGUAGE_WITH_SPACE:
                rules.push({
                    pattern: /^[-\u0621-\u064A0-9\sa-zA-Z0-9]+$/,
                    message: i18n.t('fields.InputWithDoubleLabel.rules.alphanumeric')
                })
                break;
            case Utils.TEXT_TYPE.ALPHANUMERIC:
                rules.push({
                    pattern: /^[a-zA-Z0-9]+$/,
                    message: i18n.t('fields.InputWithDoubleLabel.rules.alphanumeric')
                })
                break;
            case Utils.TEXT_TYPE.NUMERIC:
                rules.push({
                    pattern: /^[0-9]+$/,
                    message: i18n.t('fields.InputWithDoubleLabel.rules.numeric')
                })
                break;
            case Utils.TEXT_TYPE.SPECIAL:
                rules.push({
                    pattern: /^[À-úa-zA-Z0-9/]+$/,
                    message: i18n.t('fields.InputWithDoubleLabel.rules.special')
                })
                break;
            case Utils.TEXT_TYPE.ALL_SPECIAL:
                rules.push({
                    /*pattern: /^[a-zA-ZÀ-ú -_0-9/]+$/,*/
                    pattern: /^[a-zA-ZÀ-ú -_0-9/]+$/,
                    message: i18n.t('fields.InputWithDoubleLabel.rules.special')
                })
                break;
            case Utils.TEXT_TYPE.ALL_SPECIAL_WITHOUT_SPACE:
                rules.push({
                    pattern: /^[a-zA-ZÀ-ú-_0-9/]+$/,
                    message: i18n.t('fields.InputWithDoubleLabel.rules.special')
                })
                break;
            case Utils.TEXT_TYPE.ALPHABETIC_WITH_SPACE:
                rules.push({
                    pattern: /^[A-zÀ-ú ]*$/,
                    message: i18n.t('fields.InputWithDoubleLabel.rules.alphabetic')
                })
                break;
            case Utils.TEXT_TYPE.NAME_WITH_SPACE:
                rules.push({
                    pattern: /^[A-z-À-úàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' ]*$/,
                    message: i18n.t('fields.InputWithDoubleLabel.rules.nom')
                })
                break;
            default:
                rules.push({
                    pattern: /^[A-Za-z]+$/,
                    message: i18n.t('fields.InputWithDoubleLabel.rules.alphabetic')
                })
                break;
        }
    }

    useEffect(() => {
        setShowIcon(false);
    }, [])

    const getUpdateFormListItemValue = (name, input) => {
        let formListData = form.getFieldsValue(true)[formListName];
        const itemIndex = name[0];
        const updatedItemValue = { ...formListData[itemIndex], [name[1]]: input };
        formListData[itemIndex] = updatedItemValue;
        return [...formListData];

    }

    const onChange = (input) => {
        if (form) {
            typeof name === 'object' ?
                form.setFields([{ name: formListName, value: getUpdateFormListItemValue(name, input) }]) :
                form.setFieldsValue({ [inputName]: input });
        }
    };

    const handleShift = () => {
        const newLayoutName = keyLayout === "default" ? "shift" : "default";
        setKeyLayout(newLayoutName);
    };

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
    };

    const onChangeInput = event => {

        const input = event.target.value;
        if (form) {
            form.setFieldsValue({
                [inputName]: input
            });
        }
        language === 'ar' && shouldbeActivatedOnlyOnArabic(type) && keyboard.current && keyboard.current.setInput(input);
    };

    if (language === "ar" && shouldbeActivatedOnlyOnArabic(type)) {
        window.addEventListener('click', function (e) {
            if (showIcon && e && e.target && !document?.getElementsByClassName(name)[0]?.contains(e?.target)) {
                setShowIcon(false)
            }
        })
    }

    return (<Form.Item className={name} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
        <Row gutter={16}>

            <Col className="gutter-row" order={language !== 'fr' && i18n.language === 'fr' && '1'} lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    rules={rules}
                >
                    <Input suffix={language == 'ar' && form && !forceFr && !disabled && <i className="fas fa-keyboard" style={{ cursor: 'pointer' }} onClick={() => setShowIcon(state => !state)} />}
                        style={{ width: '100%' }} size="large" placeholder={placeholder} type={type} onChange={form && onChangeInput} defaultValue={value} disabled={disabled}
                        onClick={() => setShowIcon(state => !state)}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row>
            {language === "ar" && showIcon && shouldbeActivatedOnlyOnArabic(type) && <Keyboard
                id="keyboard"
                ref={wrapperRef}
                useMouseEvents={true}
                style={{ padding: "20px" }}
                keyboardRef={r => (keyboard.current = r)}
                layoutName={keyLayout}
                onChange={onChange}
                onKeyPress={onKeyPress}
                {...Utils.KEYBOARDO_PTIONS}
            />}
        </Row>
    </Form.Item >
    );
}
export const AddBoutonWithDoubleLabel = ({ label, frLabel, arLabel, action, disabled }) => (
    <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row gutter={16}>
            <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'initial' }} span={10}>
                <Text className='col' >{label}</Text>
            </Col>
            <Col className="gutter-row" style={{ alignSelf: "flex-start" }}>
                <Form.Item>
                    <Button shape="round" type="primary" disabled={disabled} onClick={() => action()} icon={<PlusOutlined style={{ fontSize: '15px', color: 'white' }} />} />
                </Form.Item>
            </Col>
            {/* <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'end' }} span={10}>
                <Text className='col' style={{ textAlign: "center" }}>{arLabel}</Text>
            </Col> */}
        </Row>
    </Form.Item >
);
export const InputEmailWithDoubleLabel = ({ type, name, label, placeholder, required = true, errorMessage, disabled = false }) => (
    <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row gutter={16}>
            <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    // noStyle
                    rules={[
                        { type: 'email', message: i18n.t('fields.inputEmailWithDoubleLabel.rules.message') }
                    ]}
                >
                    <Input size="large" style={{ width: '100%' }} placeholder={placeholder} type={type} disabled={disabled} />
                </Form.Item>
            </Col>
        </Row>
    </Form.Item >
);
export const TextAreaWithDoubleLabel = ({ forceFr = false, input, type, name, label, placeholder, required = true, errorMessage, disabled = false, form, maxLength = 100 }) => {

    const [showIcon, setShowIcon] = useState(false);
    const [keyLayout, setKeyLayout] = useState('');
    const [value, setValue] = useState(' ');
    const language = type === Utils.TEXT_TYPE.ARABIC ? 'ar' : i18n.language;
    const keyboard = useRef();

    let rules = [
        {
            required: required,
            message: errorMessage,
        }
    ];

    if (language === 'ar' && !forceFr) {
        const arabicMessage = i18n.t('fields.InputWithDoubleLabel.rules.arabic');
        rules.push({
            //pattern: /^[-\u0621-\u064A0-9\s]+$/,
            // copied arab text may carry a hidden character 'U+200F'  refering to right-te-left marker
            pattern: /^[-\u0621-\u064A0-9‏\s]+$/,
            message: arabicMessage
        });
        window.addEventListener('click', function (e) {
            if (showIcon && e && e.target && !document?.getElementsByClassName(name)[0]?.contains(e?.target)) {
                setShowIcon(false)
            }
        })
    }

    const onChange = (input) => {
        if (form) {
            form.setFieldsValue({
                [name]: input
            });
        }
        setValue(input)
    };

    const handleShift = () => {
        const newLayoutName = keyLayout === "default" ? "shift" : "default";
        setKeyLayout(newLayoutName);
    };

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
    };

    const onChangeInput = event => {
        const input = event.target.value;
        setValue(input)
        if (form) {
            form.setFieldsValue({
                [name]: input
            });
        }
        language === "ar" && keyboard.current && keyboard.current.setInput(input);
    };

    return (<Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} className={name}>

        <Row gutter={16} justify="space-between">
            <Col className="gutter-row" order={language !== 'fr' && i18n.language === 'fr' && '1'} lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col lg={18} md={24} sm={24} xs={24} style={{ width: 'auto', alignSelf: "flex-start", flexDirection: 'row' }}>
                <Col>
                    <Form.Item
                        name={name}
                        // noStyle
                        rules={rules}
                    >
                        <TextArea {...input} size="large" showCount maxLength={maxLength} rows={2} style={{ width: '100%' }}
                            onClick={() => setShowIcon(state => !state && !forceFr)}
                            onChange={onChangeInput}
                            placeholder={placeholder}
                            disabled={disabled}
                            value={value}
                        />
                    </Form.Item>
                </Col>
                {language === "ar" && !disabled && !forceFr && <Col style={{
                    position: "absolute",
                    width: "16px",
                    height: "16px",
                    left: "25px",
                    bottom: "10px",
                }}><i className="fas fa-keyboard" style={{ cursor: 'pointer' }} onClick={() => setShowIcon(state => !state && !forceFr)} /></Col>}
            </Col>
        </Row>
        <Row>
            {language === "ar" && showIcon && <Keyboard
                style={{ padding: "20px" }}
                keyboardRef={r => (keyboard.current = r)}
                layoutName={keyLayout}
                onChange={onChange}
                onKeyPress={onKeyPress}
                {...Utils.KEYBOARDO_PTIONS}
            />}
        </Row>
    </Form.Item >
    )
}
export const InputPasswordWithDoubleLabel = ({ name, required = true, label, placeholder, errorMessage }) => (
    <>
        <Col span={24}>
            <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
                <Row gutter={16}>
                    <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }}>
                        <Text className='col'>{label}</Text>
                        {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                    </Col>
                    <Col className="gutter-row" lg={18} md={24} sm={24} xs={24}>
                        <Form.Item
                            name={name}
                            rules={[
                                {
                                    required: required,
                                    message: errorMessage,
                                }
                            ]}
                            hasFeedback
                        >
                            <Input.Password size="large" placeholder={placeholder} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item >
        </Col>
    </>
);
export const InputPasswordWithDoubleLabelAndConfirmation = ({ getFieldValue, type, name, confirmationName = "confirm", required = true, repeatPasswordLabel, passwordLabel, passwordPlaceholder, repeatPasswordPlaceholder, errorMessage }) => (
    <>
        <Col span={24}>
            <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
                <Row gutter={16}>
                    <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }}>
                        <Text className='col'>{passwordLabel}</Text>
                        {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                    </Col>
                    <Col className="gutter-row" lg={18} md={24} sm={24} xs={24}>
                        <Form.Item
                            name={name}
                            rules={[
                                {
                                    required: required,
                                    message: errorMessage,
                                },
                                {
                                    pattern: /^[A-Za-zÀ-ú\d@$!% -_0-9*#?&]{8,}$/,
                                    message: i18n.t('fields.inputPasswordWithDoubleLabelAndConfirmation.password.rules.message')
                                }
                            ]}
                            hasFeedback
                        >
                            <Input.Password size="large" placeholder={passwordPlaceholder} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item >
        </Col>
        <Col span={24}>
            <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
                <Row gutter={16}>
                    <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }}>
                        <Text className='col'>{repeatPasswordLabel}</Text>
                        {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                    </Col>
                    <Col className="gutter-row" lg={18} md={24} sm={24} xs={24}>
                        <Form.Item
                            name={confirmationName}
                            dependencies={[name]}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: errorMessage,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue(name) === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(i18n.t('fields.inputPasswordWithDoubleLabelAndConfirmation.repeatPassword.rules.message')));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password size="large" placeholder={repeatPasswordPlaceholder} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item >
        </Col>
    </>
);

export const InputComplexePasswordWithDoubleLabelAndConfirmation = ({ getFieldValue, type, name, confirmationName = "confirm", required = true, repeatPasswordLabel, passwordLabel, passwordPlaceholder, repeatPasswordPlaceholder, errorMessage }) => (
    <>
        <Col span={24}>
            <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
                <Row gutter={16}>
                    <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }}>
                        <Text className='col'>{passwordLabel}</Text>
                        {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                    </Col>
                    <Col className="gutter-row" lg={18} md={24} sm={24} xs={24}>
                        <Form.Item
                            name={name}
                            rules={[
                                {
                                    required: required,
                                    message: errorMessage,
                                },
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})[A-Za-zÀ-ú\d@$!% -_0-9*#?&]{12,}$/,
                                    message: i18n.t('fields.inputPasswordWithDoubleLabelAndConfirmation.password.rules.message_complexe_pwd')
                                }
                            ]}
                            hasFeedback
                        >
                            <Input.Password size="large" placeholder={passwordPlaceholder} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item >
        </Col>
        <Col span={24}>
            <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
                <Row gutter={16}>
                    <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }}>
                        <Text className='col'>{repeatPasswordLabel}</Text>
                        {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                    </Col>
                    <Col className="gutter-row" lg={18} md={24} sm={24} xs={24}>
                        <Form.Item
                            name={confirmationName}
                            dependencies={[name]}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: errorMessage,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue(name) === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(i18n.t('fields.inputPasswordWithDoubleLabelAndConfirmation.repeatPassword.rules.message')));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password size="large" placeholder={repeatPasswordPlaceholder} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item >
        </Col>
    </>
);

export const RadioWithDoubleLabel = ({ type, name, label, required = true, errorMessage, children, disabled = false }) => {
    const language = i18n.language;

    return (
        <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
            <Row gutter={16}>
                <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                    <Text className='col' >{label}</Text>
                    {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                </Col>
                <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                    <Form.Item
                        name={name}
                        // noStyle
                        rules={[{ required: required, message: errorMessage }]}
                    >
                        <Radio.Group size="large" style={{ width: '100%' }} disabled={disabled}>
                            <Radio value="M">{i18n.t('fields.radioWithDoubleLabel.group.m')}</Radio>
                            <Radio value="F">{i18n.t('fields.radioWithDoubleLabel.group.f')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item >)
};

export const DateWithDoubleLabel = ({ type, name, label, placeholder, required = true, errorMessage, picker, disabled = false }) => (
    <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row gutter={16}>
            <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    // noStyle
                    rules={[{ required: required, message: errorMessage }]}
                >
                    {picker
                        ? <DatePicker size="large" style={{ width: '100%' }} locale={locale} disabledDate={(current) => current && current > moment().endOf('day')} format="YYYY" picker={picker} disabled={disabled} placeholder={placeholder} />
                        : <DatePicker size="large" style={{ width: '100%' }} locale={locale} disabledDate={(current) => current && current > moment().endOf('day')} format="DD/MM/YYYY" disabled={disabled} placeholder={placeholder} />
                    }

                </Form.Item>
            </Col>
        </Row>
    </Form.Item >
);
export const SimpleDateWithDoubleLabel = ({ type, name, label, placeholder, required = true, errorMessage, picker, disabled = false }) => (
    <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row gutter={16}>
            <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    // noStyle
                    rules={[{ required: required, message: errorMessage }]}
                >
                    <DatePicker size="large" style={{ width: '100%' }} locale={locale} format="DD/MM/YYYY" disabled={disabled} placeholder={placeholder} />
                </Form.Item>
            </Col>
        </Row>
    </Form.Item >
);
export const BirthDayDateWithDoubleLabel = ({ type, name, label, placeholder, required = true, errorMessage, picker, disabled = false, controlAge }) => (
    <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row gutter={16}>
            <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial', flexDirection: 'column' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item

                    name={name}
                    // noStyle
                    rules={[{ required: required, message: errorMessage },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            const now = moment();
                            if (now.diff(value, 'years') < controlAge) {
                                return Promise.reject(new Error(i18n.t('fields.birthDayDateWithDoubleLabel.rules.message', { age: controlAge })));
                            } else {
                                return Promise.resolve();
                            }

                        },
                    }),]}
                >
                    <DatePicker size="large" style={{ width: '100%' }} disabledDate={(current) => current && current > moment().endOf('day')} locale={locale} format="DD/MM/YYYY" picker={picker} disabled={disabled} placeholder={placeholder} />
                </Form.Item>
            </Col>
        </Row>
    </Form.Item >
);
export const ExperianceDateWithDoubleLabel = ({ type, name, label, placeholder, required = true, errorMessage, picker, disabled = false, minExp }) => {
    const rules = [{ required: false, message: errorMessage }];

    if (required) {
        rules.push(
            ({ getFieldValue }) => ({
                validator(_, value) {
                    const now = moment();
                    if (!value && required) {
                        return Promise.reject(new Error(i18n.t('fields.rules.required.message')));
                    } else {
                        return Promise.resolve();
                    }

                },
            })
        )
        rules.push(
            ({ getFieldValue }) => ({
                validator(_, value) {
                    const now = moment();
                    if (now.diff(value, 'years') < minExp && required) {
                        return Promise.reject(new Error(i18n.t('fields.experianceDateWithDoubleLabel.rules.message', { exp: minExp })));
                    } else {
                        return Promise.resolve();
                    }

                },
            })
        )
    }

    return (<Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row gutter={16}>
            <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial', flexDirection: 'column' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    // noStyle
                    rules={rules}
                >
                    <DatePicker size="large" style={{ width: '100%' }} locale={locale} format="DD/MM/YYYY" picker={picker} disabled={disabled} placeholder={placeholder} />
                </Form.Item>
            </Col>
        </Row>
    </Form.Item >
    );
}

export const YearsWithDoubleLabel = ({ type, name, label, required = true, errorMessage, picker }) => (
    <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row gutter={16}>
            <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    // noStyle
                    rules={[{ required: required, message: errorMessage }]}
                >
                    <DatePicker size="large" style={{ width: '100%' }} picker="year" />
                </Form.Item>
            </Col>
        </Row>
    </Form.Item >
);
export const InputWithTooltipAndDoubleLabel = ({ type, name, frLabel, arLabel, placeholder, required = true, errorMessage }) => (
    <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row gutter={16}>
            <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{frLabel}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    // noStyle
                    tooltip="What do you want others to call you?"
                    rules={[{ required: required, message: errorMessage }]}
                >
                    <Input size="large" style={{ width: '100%' }} placeholder={placeholder} type={type} />
                </Form.Item>
            </Col>
            <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'end' }}>
                <Text className='col' style={{ textAlign: "center" }}>{arLabel}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
        </Row>
    </Form.Item >
);
export const InputPhoneNumberWithDoubleLabel = ({ name, label, placeholder, required = true, errorMessage, disabled = false, value }) => {
    return (
        <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
            <Row gutter={16}>
                <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                    <Text className='col' >{label}</Text>
                    {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                </Col>
                <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                    <Form.Item
                        name={name}
                        // noStyle
                        rules={[{
                            required: required, message: errorMessage,
                        },
                        {
                            pattern: /^\d{10}$/,
                            message: i18n.t('fields.inputPhoneNumberWithDoubleLabel.rules.message'),
                        },
                        ]}
                    >
                        <Input size="large" style={{ width: '100%' }} placeholder={placeholder} type="text" disabled={disabled} defaultValue={value} />
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item >
    )
}
export const InputCNIEWithTypeIdentity = ({ isCnie = true, typeIdentitValue, cnieName, typeIdentityName, typeIdentityErrorMessage, options, value, initValue, label, onChangeAction, placeholder, required = true, errorMessage, disabled = false }) => {
    const finalRe = Utils.TYPE_IDENTITIES.CNIE === typeIdentitValue ? Utils.REGEXS.cnie : Utils.REGEXS.ALL_SPECIAL;
    return (
        <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
            <Row gutter={16}>
                <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }}>
                    <Text className='col'>{label}</Text>
                    {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                </Col>
                <Col className="gutter-row" lg={18} md={24} sm={24} xs={24}>
                    <Input.Group compact className='cnie-group' style={{ direction: 'ltr' }} >
                        <Form.Item
                            name={typeIdentityName}
                            noStyle
                            rules={[{ required: required, message: typeIdentityErrorMessage }]}
                        >
                            <Select
                                className='cnie-group-select'
                                size="large"
                                disabled={disabled}
                                allowClear
                                showSearch
                                notFoundContent={null}
                                onChange={onChangeAction}
                                defaultValue={typeIdentitValue}
                                filterOption={(input, option) => {
                                    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                            >
                                {options && options.map(option => {
                                    return (
                                        <Option key={option.id} value={option.id} >
                                            {
                                                typeof option.name === 'object' && option.name !== null ?
                                                    i18n.language === 'ar' ? option.name?.AR ? option.name.AR : option.name.FR : option.name.FR
                                                    : i18n.t(option.name)}
                                        </Option>
                                    )
                                })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={cnieName}
                            noStyle
                            rules={[{
                                required: required, message: errorMessage,
                            },
                            {
                                pattern: finalRe,
                                message: i18n.t(`fields.inputCNIEWithDoubleLabel.rules.${Utils.TYPE_IDENTITIES.CNIE === typeIdentitValue ? 'cnie' : 'sejour'}.message`),
                            },
                            ]}>
                            <Input className="cnie-group-input" disabled={disabled} size="large" />
                        </Form.Item>
                    </Input.Group>
                </Col>
            </Row>
        </Form.Item>
    )
}
export const InputCNIEWithDoubleLabel = ({ isCnie = true, acceptBothTypes = false, name, label, placeholder, required = true, errorMessage, disabled = false }) => {
    const cnie = new RegExp(/^([A-Za-z]{1,2}[0-9]{2,6})$/);
    const sejour = new RegExp(/^[a-zA-ZÀ-ú -_0-9/]+$/);
    const cnieOrSejour = new RegExp(/^[a-zA-ZÀ-ú -_0-9/]{10}$|^([A-Za-z]{1,2}[0-9]{2,6})$/);
    const finalRe = acceptBothTypes ? cnieOrSejour : isCnie ? cnie : sejour;
    return (
        <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
            <Row gutter={16}>
                <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                    <Text className='col' >{label}</Text>
                    {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                </Col>
                <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                    <Form.Item
                        name={name}
                        // noStyle
                        rules={[{
                            required: required, message: errorMessage,
                        },
                        {
                            pattern: finalRe,
                            message: acceptBothTypes ? i18n.t(`fields.inputCNIEWithDoubleLabel.rules.cnieOrSejour.message`) : i18n.t(`fields.inputCNIEWithDoubleLabel.rules.${isCnie ? 'cnie' : 'sejour'}.message`),
                        },
                        ]}
                    >
                        <Input size="large" style={{ width: '100%' }} placeholder={placeholder} type="text" disabled={disabled} />
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item >
    )
}
export const LoginInputPhoneNumberWithDoubleLabel = ({ name, label, placeholder, errorMessage, disabled = false }) => {
    const rules = [{
        required: true, message: errorMessage,
    }
    ];
    if (process.env.REACT_APP_PROFIL === "ARTISAN") {
        rules.push({
            pattern: /^0\d{9}$/,
            message: i18n.t('fields.loginInputPhoneNumberWithDoubleLabel.rules.message'),
        })
    }
    return (
        <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
            <Row style={{ paddingBottom: '10px' }} span={24}>
                <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'initial' }} span={24}>
                    <Text className='col' >{label}</Text>
                </Col>
                <Col className="gutter-row" span={24} style={{ alignSelf: "flex-start" }}>
                    <Form.Item
                        name={name}
                        // noStyle
                        rules={rules}
                    >
                        <Input size="large" prefix={<UserOutlined className="site-form-item-icon" />} size="large" style={{ width: '100%' }} placeholder={placeholder} type="text" disabled={disabled} />
                    </Form.Item>
                </Col>

            </Row>
        </Form.Item >
    )
}
export const LoginPasswordInputWithDoubleLabel = ({ name, label, placeholder, errorMessage }) => {

    return (<Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row style={{ paddingBottom: '10px' }}>
            <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'initial' }} span={12}>
                <Text className='col' >{label}</Text>
            </Col>
        </Row>
        <Row>
            <Col className="gutter-row" span={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item

                    name={name}
                    noStyle
                    rules={[{ required: true, message: errorMessage }]}
                >
                    <Input.Password size="large" prefix={<LockOutlined className="site-form-item-icon" />} style={{ width: '100%' }} placeholder={placeholder} type='password' />

                </Form.Item>
            </Col>

        </Row>
    </Form.Item >
    );
}
export const InputNumberWithDoubleLabel = ({ name, label, placeholder, required = true, errorMessage, disabled = false, min, max }) => {
    return (<Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
        <Row gutter={16}>
            <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }} >
                <Text className='col' >{label}</Text>
                {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
            </Col>
            <Col className="gutter-row" lg={18} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start" }}>
                <Form.Item
                    name={name}
                    // noStyle
                    rules={[{
                        required: required, message: errorMessage,
                    }
                    ]}
                >
                    <InputNumber size="large" min={min} max={max} placeholder={placeholder} style={{ width: '100%' }} disabled={disabled} />

                </Form.Item>
            </Col>
        </Row>
    </Form.Item >)
}
export const UpaloadFile = ({ label, name, fromEvent, uploadProps, errorMessage, required }) => {
    const language = i18n.language;
    return (
        <Row justify="center">
            <Form.Item
                rules={[
                    {
                        required: required,
                        message: errorMessage,
                    }]}
                wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}
                name={name}
                valuePropName={name}
                getValueFromEvent={fromEvent}
            >
                <Col lg={24} md={24} sm={24} xs={24} order={language !== 'fr' && i18n.language === 'fr' && '1'} style={{ width: 'auto', alignSelf: "flex-start", flexDirection: 'row' }}>
                    <Upload name="logo" listType="picture" {...uploadProps}>
                        <Button shape='round' icon={<UploadOutlined />}>{label}</Button>
                    </Upload>
                </Col>
            </Form.Item>
        </Row>
    )
}
export const SeletWithDoubleLabel = ({ name, label, options, errorMessage, required = true, onChangeAction, onSelectAction, index, value, disabled = false, initValue, onClearAction }) => {
    const language = i18n.language;
    const { t } = useTranslation();
    return (
        <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
            <Row gutter={16}>
                <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }}>
                    <Text className='col'>{label}</Text>
                    {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                </Col>
                <Col className="gutter-row" lg={18} md={24} sm={24} xs={24}>
                    <Form.Item
                        name={name}
                        rules={[{ required: required, message: errorMessage }]}
                    >
                        {<Select
                            initialValue={initValue}
                            size="large"
                            disabled={disabled}
                            allowClear
                            onClear={onClearAction}
                            showSearch
                            notFoundContent={null}
                            onChange={onChangeAction}
                            defaultValue={value}
                            filterOption={(input, option) => {
                                return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                            }
                        >
                            {options && options.map(option => {
                                const name = typeof option.name === 'object' && option.name !== null ?
                                    language === 'ar' ? option.name?.AR ? option.name.AR : option.name.FR : option.name.FR
                                    : t(option.name);
                                return (
                                    <Option key={option.id} value={option.id} >
                                        {name}
                                    </Option>
                                )
                            })}
                        </Select>}
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item >
    );
}

export const SeletMultipleWithDoubleLabel = ({ name, label, options, errorMessage, required = true, onChangeAction, onSelectAction, index, value, disabled = false }) => {
    const language = i18n.language;
    const { t } = useTranslation();

    return (
        <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
            <Row gutter={16}>
                <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }}>
                    <Text className='col'>{label}</Text>
                    {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                </Col>
                <Col className="gutter-row" lg={18} md={24} sm={24} xs={24}>
                    <Form.Item
                        name={name}
                        rules={[{ required: required, message: errorMessage }]}
                    >
                        {<Select
                            size="large"
                            mode="multiple"
                            disabled={disabled}
                            allowClear
                            showSearch
                            notFoundContent={null}
                            onChange={onChangeAction}
                            defaultValue={value}
                            filterOption={(input, option) => {
                                return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                            }
                        >
                            {options && options.map(option => {
                                return (
                                    <Option key={option.id} value={option.id} >
                                        {
                                            typeof option.name === 'object' && option.name !== null ?
                                                language === 'ar' ? option.name.AR : option.name.FR
                                                : t(option.name)}
                                    </Option>
                                )
                            })}
                        </Select>}
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item >
    );
}
export const CustomSeletWithDoubleLabel = ({ name, label, options, errorMessage, required = true, onChangeAction, index, addItem, onNameChange, newItem, defaultValue, ref, disabled = false }) => {
    const language = i18n.language;

    return (
        <Form.Item wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }} >
            <Row gutter={16}>
                <Col className="gutter-row" lg={6} md={24} sm={24} xs={24} style={{ alignSelf: "flex-start", textAlign: 'initial' }}>
                    <Text className='col'>{label}</Text>
                    {required ? <Text style={{ color: 'red' }}>*</Text> : ''}
                </Col>
                <Col className="gutter-row" lg={18} md={24} sm={24} xs={24}>
                    <Form.Item
                        name={name}
                        rules={[{ required: true, message: errorMessage }]}
                    >
                        <Select
                            size="large"
                            disabled={disabled}
                            allowClear
                            showSearch
                            onChange={onChangeAction}
                            filterOption={(input, option) => {
                                return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                            }
                            ref={(select) => ref = select}
                            dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Input style={{ flex: 'auto' }} value={newItem} onChange={onNameChange} />
                                        <a
                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                            onClick={() => {
                                                addItem();
                                                ref.blur();
                                            }}
                                        >
                                            <PlusOutlined /> <span>{i18n.t('fields.customSeletWithDoubleLabel.add-items')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}

                        >
                            {options && options.map((option, index) => {
                                return (
                                    <Option key={option.id} >
                                        {typeof option.name === 'object' && option.name !== null ?
                                            language === 'ar' ? option.name.AR : option.name.FR
                                            : option.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item >
    );
}