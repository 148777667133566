import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  entiteAdministrative: [],
  regions: [],
};

const mapDataToEntiteAdministrative = data => {
  return data.map(line => {
    return {
      key: line.id,
      label_fr: line.labels?.FR,
      label_ar: line.labels?.AR,
      code: line.code,
      parent: line.parent,
      regionId: line.region_id
    };
  })
};

const mapDataToRegions = data => {
  const list = [];
  data.map(region => {
    list.push(
      {
        key: region.id,
        label_fr: region.labels.FR,
        label_ar: region.labels.AR,
        code: region.code
      })
  })
  return list;
}


function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case (types.GET_REGIONS_PENDING || types.GET_ENTITE_ADMINISTRATIVE_PENDING || types.ADD_ENTITE_ADMINISTRATIVE_PENDING || types.UPDATE_ENTITE_ADMINISTRATIVE_PENDING): {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case types.GET_ENTITE_ADMINISTRATIVE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        entiteAdministrative: mapDataToEntiteAdministrative(data),
        loading: false,
      };
    }

    case types.GET_REGIONS_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        regions: mapDataToRegions(data),
        loading: false,
      };
    }


    case types.ADD_ENTITE_ADMINISTRATIVE_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "Public Prive créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_ENTITE_ADMINISTRATIVE_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "Public Prive modifié avec succès",
        loading: false
      };
    }


    case (types.GET_ENTITE_ADMINISTRATIVE_REJECTED || types.ADD_ENTITE_ADMINISTRATIVE_REJECTED || types.UPDATE_ENTITE_ADMINISTRATIVE_REJECTED): {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
