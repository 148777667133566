import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import history from "../../../history"
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, Row, Alert, Space, Typography, Radio } from "antd";
import Form from 'antd/lib/form/Form';
import { create, resetMessages } from '../Action';
import { useTranslation } from "react-i18next";
import { FieldWithDoubleLabel, TextAreaWithDoubleLabel } from "../../../utilities/FormFields/FormFields";
const { Title, Text } = Typography;


const CreateReclamation = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [showForward, setShowForward] = useState(false)
    const error = useSelector(state => state.reclamationReducer?.error);
    const success = useSelector(state => state.reclamationReducer?.success);
    const artisantId = useSelector(state => state.loginReducer?.artisan?.artisan_id);

    useEffect(() => {
        dispatch(resetMessages())
    }, []);


    const onFinish = (values) => {
        let payload = {
            ...values,
            artisan_id: artisantId
        }
        dispatch(create(payload))
            .then((res) => { setShowForward(true); form.resetFields() });
    };


    return (<>

        <Space size="large" direction="vertical" style={{ width: '100%' }}  >
            <Title span={3} className="aligne-left">{t('reclamation.create-reclamation.title')}</Title>

            {error &&
                <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                    <Col>
                        <Alert
                            style={{ textAlign: 'center' }}
                            message={error}
                            type="error"
                            closable
                        />
                    </Col>
                </Row>
            }

            <Form form={form} name="cree-reclamation" style={{ width: '100%' }} onFinish={onFinish}>
                {showForward
                    ? <>
                        <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px', paddingBottom: '10px' }}>
                            <Text style={{ fontSize: 'large' }}>{t('reclamation.create-reclamation.forward-reclamations-list.paragraph')}  </Text>
                        </Col>
                        <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
                            <Button shape="round" type="primary" size="large" onClick={() => history.push('/app/artisan/reclamations/mes-reclamation')}>
                                {t('reclamation.create-reclamation.forward-reclamations-list.button')}
                                <ArrowRightOutlined />
                            </Button>
                        </Col>
                    </>
                    : <>
                        <FieldWithDoubleLabel name="type" label={t('reclamation.create-reclamation.form.type.label')} errorMessage={t('reclamation.create-reclamation.form.message.error-message')}>
                            <Radio.Group style={{ width: '100%' }}  >
                                <Radio value="RECLAMATION" >{t('reclamation.create-reclamation.form.type.reclamation')}</Radio>
                                <Radio value="DEMANDE_INFORMATION" >{t('reclamation.create-reclamation.form.type.information')}</Radio>
                            </Radio.Group>
                        </FieldWithDoubleLabel>
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Col span={24}>
                                <TextAreaWithDoubleLabel form={form} type="text" name="message" label={t('reclamation.create-reclamation.form.message.label')} placeholder={t('reclamation.create-reclamation.form.message.placeholder')} errorMessage={t('reclamation.create-reclamation.form.message.error-message')} maxLength={3000} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Button shape='round' type="primary" htmlType="submit" size='large' >
                                    {t('reclamation.create-reclamation.form.submit.label')}
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            </Form>

        </Space>
    </>);

}

export default CreateReclamation;