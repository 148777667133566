import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: {},
  params: {}
};

function mapParams(data) {

  const artisanTypes = data.artisan_types.map( (type) => {
    return { 'id': type.id, 'name': {...type.labels}};
  });

  const regions = data.regions.map( (type) => {
    return { 'id': type.id, 'name': type.labels};
  });

  const provinces = data.provinces.map( (type) => {
    return { 'id': type.id, 'regionId': type.region_id, 'name': type.labels};
  });

  const villes = data.villes.map( (type) => {
    return { 'id': type.id, 'regionId': type.region_id, 'provinceId': type.province_id, 'name': type.labels};
  });

  const annexesAdministratives = data.annexes_administratives.map( (type) => {
    return { 'id': type.id, 'name': {...type.labels}};
  });

  const typeAffiliationCouverture = data.type_affiliation_couverture.map( (type) => {
    return { 'id': type.code, 'name': {...type.labels}};
  });

  return {artisanTypes,regions,provinces,villes,annexesAdministratives,typeAffiliationCouverture};
}

function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.GET_GLOBAL_PARAMS_PENDING: {
      return {
        ...state,
        params: {},
        error: {},
        loading: true,
      };
    }

    case types.GET_GLOBAL_PARAMS_FULFILLED: {
      return {
        ...state,
        params:  mapParams(action.payload.data.data),
        error: {},
        loading: false,
      };
    }

    case types.GET_GLOBAL_PARAMS_REJECTED: {
        const { message } = action.payload.response.data;
        return {
          ...state,
          error:  message,
          loading: false,
        };
      }

    default:
      return state;
  }
}

export default reducer;
