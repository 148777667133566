import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  diplome: [],
  modeFormation: [],
  diplomeDistinct: [],
  categorieDiplome: [],
  publicPrive: [],
  departementFormation: [],
  etablissement: [],
};

const mapDataToDiplome = data => {
  return data.map(line => {
    return {
      key: line.id,
      label_fr: line.labels?.FR,
      label_ar: line.labels?.AR,
      code: line.code,
      diplomeDistinct: line.diplome_distinct_id,
      diplomeCategorie: line.diplome_categorie_id,
      departementFormation: line.departement_formation_id,
      modeFormation: line.mode_formation_id,
      etablissement: line.etablissement_id,
      publicPrive: line.publicprive_id,
      region: line.region_id,
      province: line.province_id,
      ville: line.ville_id
    };
  })
};

const mapData = data => {
  return data.map(line => {
    return {
      id: line.id,
      name: {
        FR: line.labels?.FR,
        AR: line.labels?.AR,
      }
    };
  })
};
const mapDataToDepartementFormation = data => {
  return data.map(line => {
    return {
      id: line.id,
      codepublicPriveId: line.code_public_prive_id,
      name: {
        FR: line.labels?.FR,
        AR: line.labels?.AR,
      }
    };
  })
};


const mapEtablissement = data => {
  return data.map(line => {
    return {
      id: line.id,
      region: line.region_id,
      province: line.province_id,
      ville: line.ville_id,
      name: {
        FR: line.labels?.FR,
        AR: line.labels?.AR,
      }
    };
  })
};



function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case (types.GET_DIPLOME_PENDING || types.ADD_DIPLOME_PENDING || types.UPDATE_DIPLOME_PENDING): {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }
    case types.GET_DEPARTEMENT_FORMATION_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        departementFormation: mapDataToDepartementFormation(data),
        loading: false,
      };
    }
    case types.GET_ETABLISSMENT_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        etablissement: mapEtablissement(data),
        loading: false,
      };
    }
    case types.GET_DIPLOME_DISTINCT_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        diplomeDistinct: mapData(data),
        loading: false,
      };
    }
    case types.GET_CATEGORIE_DIPLOME_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        categorieDiplome: mapData(data),
        loading: false,
      };
    }
    case types.GET_MODE_FORMATION_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        modeFormation: mapData(data),
        loading: false,
      };
    }
    case types.GET_PUBLIC_PRIVE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        publicPrive: mapData(data),
        loading: false,
      };
    }

    case types.GET_DIPLOME_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        diplome: mapDataToDiplome(data),
        loading: false,
      };
    }


    case types.ADD_DIPLOME_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "Motif de rejet créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_DIPLOME_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "Motif de rejet modifié avec succès",
        loading: false
      };
    }


    case (types.GET_DIPLOME_REJECTED || types.ADD_DIPLOME_REJECTED || types.UPDATE_DIPLOME_REJECTED): {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
