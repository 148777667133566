import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_COUVERTURE_SOCIALE: 'GET_COUVERTURE_SOCIALE',
    GET_COUVERTURE_SOCIALE_PENDING: `GET_COUVERTURE_SOCIALE_${ActionType.Pending}`,
    GET_COUVERTURE_SOCIALE_FULFILLED: `GET_COUVERTURE_SOCIALE_${ActionType.Fulfilled}`,
    GET_COUVERTURE_SOCIALE_REJECTED: `GET_COUVERTURE_SOCIALE_${ActionType.Rejected}`,

    ADD_COUVERTURE_SOCIALE: 'ADD_COUVERTURE_SOCIALE',
    ADD_COUVERTURE_SOCIALE_PENDING: `ADD_COUVERTURE_SOCIALE_${ActionType.Pending}`,
    ADD_COUVERTURE_SOCIALE_FULFILLED: `ADD_COUVERTURE_SOCIALE_${ActionType.Fulfilled}`,
    ADD_COUVERTURE_SOCIALE_REJECTED: `ADD_COUVERTURE_SOCIALE_${ActionType.Rejected}`,

    UPDATE_COUVERTURE_SOCIALE: 'UPDATE_COUVERTURE_SOCIALE',
    UPDATE_COUVERTURE_SOCIALE_PENDING: `UPDATE_COUVERTURE_SOCIALE_${ActionType.Pending}`,
    UPDATE_COUVERTURE_SOCIALE_FULFILLED: `UPDATE_COUVERTURE_SOCIALE_${ActionType.Fulfilled}`,
    UPDATE_COUVERTURE_SOCIALE_REJECTED: `UPDATE_COUVERTURE_SOCIALE_${ActionType.Rejected}`,
};

export default ActionTypes;
