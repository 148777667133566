import React, { useEffect } from 'react';
import { Row, Col, Form, Button, } from "antd";
import { InputWithDoubleLabel, SeletWithDoubleLabel, InputPhoneNumberWithDoubleLabel } from '../../../utilities/FormFields/FormFields'
import Utils from '../../../utilities/Utils/Utils'
import { connect, useSelector } from 'react-redux';
import { getGlobalParam } from '../../GlobalParams/Action';
import { updateArtisant } from '../../login/Action'
import history from '../../../history'
import Title from 'antd/lib/typography/Title';
import SpinLoader from '../../../component/SpinLoader/SpinLaoder';
import { useTranslation } from 'react-i18next';


const RegistrationStep0 = props => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { user, params, artisanTypeId } = props
    const loading = useSelector(state => state.registration?.loading)

    useEffect(() => {
        props.getGlobalParam();
    }, []);

    const onFinish = (formvalues) => {
        const { artisanId, updateArtisant } = props

        const values = {
            ...formvalues,
            phone_number: Utils.mapNumberToPhoneNumber(formvalues.phone_number)
        }

        updateArtisant(artisanId, values).then((result) => history.push('/app/artisan/souscrire/donnees'));
    }
    return (<>
        {loading ? <SpinLoader /> : <>
            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }} align="bottom" >
                <Title level={3}>{t('artisan.registration-step-0.title')}</Title>
            </Row>

            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '20px' }} align="bottom" >
                <Col span={20} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }}>
                    <Form
                        form={form}
                        name="update"
                        onFinish={onFinish}
                        style={{ width: '100%' }}
                        size='middle'
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        scrollToFirstError
                        initialValues={{ first_name_ar: user.first_name_ar, last_name_ar: user.last_name_ar, first_name: user.firstName, last_name: user.lastName, phone_number: Utils.mapPhoneNumberToNumber(user.login), artisan_type: artisanTypeId, operateur: user.operateur }}
                    >
                        <Row style={{ width: '100%', justifyContent: 'space-between', direction: 'ltr' }}>
                            <Col span={11}>
                                <InputWithDoubleLabel forceFr={true} form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="last_name" label={Utils.staticInternationalization.nomFR} required={false} errorMessage={t('component.registration-step-0.form.last_name.label')} />
                            </Col>
                            <Col span={11}>
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="last_name_ar" label={Utils.staticInternationalization.nomAR} required={false} errorMessage={t('component.registration-step-0.form.last_name.label')} />
                            </Col>
                        </Row>

                        <Row style={{ width: '100%', justifyContent: 'space-between', direction: 'ltr' }}>
                            <Col span={11}>
                                <InputWithDoubleLabel forceFr={true} form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="first_name" label={Utils.staticInternationalization.prenomFR} required={false} errorMessage={t('component.registration-step-0.form.first_name.label')} />
                            </Col>
                            <Col span={11}>
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="first_name_ar" label={Utils.staticInternationalization.prenomAR} required={false} errorMessage={t('component.registration-step-0.form.first_name.label')} />
                            </Col></Row>

                        <Col span={24}>
                            <SeletWithDoubleLabel name="operateur" label={t('artisan.registration-step-0.form.operateur.label')} required={false} disabled={true} errorMessage={t('artisan.registration-step-0.form.operateur.error-message')} options={Utils.Operateurs} />
                        </Col>
                        <Col span={24}>
                            <InputPhoneNumberWithDoubleLabel type="phoneNumber" form={form} name="phone_number" label={t('artisan.registration-step-0.form.phone-number.label')} required={false} disabled={true} errorMessage={t('artisan.registration-step-0.form.phone-number.error-message')} />
                        </Col>
                        <Col span={24}>
                            <SeletWithDoubleLabel name="artisan_type" label={t('artisan.registration-step-0.form.artisan-type.label')} required={false} options={params ? params.artisanTypes : []} errorMessage={t('artisan.registration-step-0.form.artisan-type.error-message')} />
                        </Col>

                        <Col type='flex' span={12} style={{ textAlign: 'right', padding: '21px', justifyContent: 'space-between' }} >
                            <Button shape="round" type="primary" htmlType="submit" style={{ width: '100%' }}>
                                {t('artisan.registration-step-0.form.submit.label')}
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row >
        </>}

    </>)

}

const mapStateToProps = (state) => {
    return {
        loading: state.registration.loading,
        artisanId: state.loginReducer.artisan.artisan_id,
        artisanTypeId: state.loginReducer?.artisan?.artisan_type?.id,
        user: state.loginReducer.user,
        params: state.globalParamReducer.params,
    };
};

const mapDispatchToProps = { getGlobalParam, updateArtisant };


export default connect(mapStateToProps, mapDispatchToProps)(RegistrationStep0);
