import React from "react";
import { Result, Button } from 'antd';
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";

const RegistrationResult = ({ registration }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const pathname = history.location?.pathname;

    return (
        <Result
            status="success"
            title={pathname.includes('souscrire') ? t('component.registration-result.title.souscrire') : t('component.registration-result.title.modification')}
            extra={[
                <Button shape="round" type="primary" key="console" onClick={() => history.push("/app/artisan/home")} style={{ width: '100%' }} size={"middle"} >
                    {t('component.registration-result.action')}
                </Button>
            ]}
        />
    )
}

export default RegistrationResult;