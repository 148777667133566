import React, { useEffect, useState } from "react"
import { getOneForArtisan, resetMessages, getOneForAgent, handle } from '../Action';
import { useParams } from 'react-router';
import { Form, Button, Divider, Col, Row, Typography, Card, Alert } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SpinLoader from "../../../component/SpinLoader/SpinLaoder";
import Utils from "../../../utilities/Utils/Utils";
import { InputWithDoubleLabel, TextAreaWithDoubleLabel } from "../../../utilities/FormFields/FormFields";
const { Text } = Typography;


const ReclamationDetails = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const activeReclamation = useSelector(state => state.reclamationReducer?.activeReclamation);
    const error = useSelector(state => state.reclamationReducer?.error);

    const artisantId = useSelector(state => state.loginReducer?.artisan?.artisan_id);
    const userType = useSelector(state => state.loginReducer?.type);


    useEffect(() => {
        dispatch(resetMessages());
        if (userType === Utils.USER_TYPES.AGENT) {
            getDataForAgent();
        } else if (userType === Utils.USER_TYPES.ARTISAN) {
            getDataForArtisan();
        }
    }, []);

    const getDataForAgent = () => {
        setLoading(true);
        dispatch(getOneForAgent(id))
            .then((res) => setLoading(false))
            .catch((err) => setLoading(false));
    }

    const getDataForArtisan = () => {
        setLoading(true);
        dispatch(getOneForArtisan(artisantId, id))
            .then((res) => setLoading(false))
            .catch((err) => setLoading(false));
    }

    const justifyMessage = (messageSource) => {
        if (userType === Utils.USER_TYPES.AGENT) {
            return messageSource === 'FROM_ARTISAN' ? 'start' : 'end'
        } else if (userType === Utils.USER_TYPES.ARTISAN) {
            return messageSource === 'FROM_ARTISAN' ? 'end' : 'start'
        }
    }

    const onFinish = (values) => {
        form.resetFields();
        dispatch(handle(id, values))
            .then((res) => getDataForAgent())
            .catch((err) => setLoading(false));
    };

    const getStatus = (s, t) => {
        return t === "DEMANDE_INFORMATION" 
            ?  Utils.statusDemandeInformation.filter(status => status.id === s).map(status => status.name.FR)
            : Utils.statusReclamation.filter(status => status.id === s).map(status => status.name.FR);
    }
    
    const getInitValues = () => {
        if (userType === Utils.USER_TYPES.AGENT) {
            return {
                ...activeReclamation,
                statut: getStatus(activeReclamation.statut),
                artisan_type: activeReclamation.artisan?.artisan_type?.labels?.FR,
                nom: activeReclamation.artisan?.nom,
                prenom: activeReclamation.artisan?.prenom,
                nomCooperative: activeReclamation.artisan?.nom_cooperative,
                nomEntreprise: activeReclamation.artisan?.nom_entreprise,
                telephone: activeReclamation.artisan?.telephone
            }
        }
        return {
            ...activeReclamation,
            statut: getStatus(activeReclamation.statut, activeReclamation.type)
        }
    }
    return (<>
        {loading ? <SpinLoader /> :
            <>
                <>
                    <Divider orientation="left">{t('reclamation.reclamation-details.reclamation-details-title')}</Divider>
                    <Form
                        name="advanced_search_reclamation"
                        className="ant-advanced-search-form"
                        preserve={true}
                        initialValues={getInitValues()}
                    >
                        <Row gutter={[48, 8]}>
                            <Col lg={12} md={24} sm={24} xs={24}  >
                                <InputWithDoubleLabel required={false} name="reference" label={t('reclamation.reclamation-details.reclamation-details-form.reference')} disabled={true} />
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <InputWithDoubleLabel required={false} name="statut" label={t('reclamation.reclamation-details.reclamation-details-form.statut')} disabled={true} />
                            </Col>
                        </Row>
                        <Row gutter={[48, 8]}>
                            <Col lg={12} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel required={false} name="created_at" label={t('reclamation.reclamation-details.reclamation-details-form.createdAt')} disabled={true} />
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <InputWithDoubleLabel required={false} name="updated_at" label={t('reclamation.reclamation-details.reclamation-details-form.updatedAt')} disabled={true} />
                            </Col>
                        </Row>
                        {userType === Utils.USER_TYPES.AGENT &&
                            <>
                                <Row gutter={[48, 8]}>
                                    <Col lg={12} md={24} sm={24} xs={24} >
                                        <InputWithDoubleLabel required={false} name="artisan_type" label={t('reclamation.reclamation-details.reclamation-details-form.artisanType')} disabled={true} />
                                    </Col>
                                    <Col lg={12} md={24} sm={24} xs={24}>
                                        <InputWithDoubleLabel required={false} name="telephone" label={t('reclamation.reclamation-details.reclamation-details-form.telephone')} disabled={true} />
                                    </Col>
                                </Row>
                                <Row gutter={[48, 8]} >
                                    <Col lg={12} md={24} sm={24} xs={24} >
                                        <InputWithDoubleLabel required={false} name="nom" label={t('reclamation.reclamation-details.reclamation-details-form.nom')} disabled={true} />
                                    </Col>
                                    <Col lg={12} md={24} sm={24} xs={24}>
                                        <InputWithDoubleLabel required={false} name="prenom" label={t('reclamation.reclamation-details.reclamation-details-form.prenom')} disabled={true} />
                                    </Col>
                                </Row>
                                <Row gutter={[48, 8]}>
                                    <Col lg={12} md={24} sm={24} xs={24} >
                                        <InputWithDoubleLabel required={false} name="nomCooperative" label={t('reclamation.reclamation-details.reclamation-details-form.nomCooperative')} disabled={true} />
                                    </Col>
                                    <Col lg={12} md={24} sm={24} xs={24}>
                                        <InputWithDoubleLabel required={false} name="nomEntreprise" label={t('reclamation.reclamation-details.reclamation-details-form.nomEntreprise')} disabled={true} />
                                    </Col>
                                </Row>
                            </>
                        }
                    </Form>
                </>
                {error &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={error}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                <Divider orientation="center">{t('reclamation.reclamation-details.messages-title')}</Divider>






                {activeReclamation.messages
                    .map((message) => {
                        return (
                            <Row justify={justifyMessage(message.message_source)}>
                                <Col lg={12} md={16} sm={16} xs={16} >
                                    <Card className="glassmorphism">
                                        <Text >{message.message} </Text>
                                    </Card>
                                    <Divider orientation="right" style={{ fontSize: 'smaller' }}>{message.created_at}</Divider>
                                </Col>
                            </Row>
                        );
                    }
                    )}
                {userType === Utils.USER_TYPES.AGENT &&
                    <Form
                        shape="round"
                        form={form}
                        name="advanced_search"
                        onFinish={onFinish}
                        preserve={true}
                    >
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Col span={24}>
                                <TextAreaWithDoubleLabel form={form} type="text" name="message" label={t('reclamation.reclamation-details.handle-form.message.label')} placeholder={t('reclamation.reclamation-details.handle-form.message.placeholder')} errorMessage={t('reclamation.reclamation-details.handle-form.message.error-message')} maxLength={3000} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Button shape='round' type="primary" htmlType="submit" size='large' >
                                    {t('reclamation.reclamation-details.handle-form.submit.label')}
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                }
            </>
        }
    </>);
}

export default ReclamationDetails;