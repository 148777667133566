import React from 'react';
import { Row, Col, Button, Typography, Space, Card, Badge, Avatar } from "antd";
import { UserAddOutlined, ArrowLeftOutlined, ClockCircleOutlined } from '@ant-design/icons';
import "./LoginForm.less";
import LoginForm from './LoginForm';
import form from '../../assets/form-creation.svg'
import { withRouter } from 'react-router';
import Layout, { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../component/LanguageSelector/LanguageSelector';
import './Login.less';
import Logo from '../Logo/Logo';
import Api from '../../utilities/services/Api';

const { Title } = Typography;


const Login = props => {

  const { t } = useTranslation();

  return (
    <Layout style={{ minHeight: '100vh', padding: "20px", }} className="background">
      <Row type="flex" align="middle">
        <Col span="24" align="middle" style={{ alignItems: "center" }} >
          <Logo height="15%" />
        </Col>
      </Row>
      {process.env.REACT_APP_PROFIL === "ARTISAN" &&
        <Row >
          <Col span="4" align="middle" style={{ right: 30, position: "absolute", top: "10px" }}>
            <LanguageSelector />
          </Col>
          
          <Col span="4" align="middle" style={{ left: 30, position: "absolute", top: "10px" }}>
            <Button type="default" shape="round" size={"large"} onClick={() => window.location.assign(process.env.REACT_APP_PORTAIL_URL)} icon={<ArrowLeftOutlined />}
              style={{ backgroundColor: "white" }}>
              {t("go-back")}
            </Button>
          </Col>
          
        </Row>
      }
      <Content style={{ alignItems: "center" }} align="middle" style={{ width: "100% !important" }}>
        <Space direction="vertical" size="large" align="center" style={{ width: "100%" }} >
          <Row justify="center" style={{ width: "100% !important" }}>
            <Title level={1} align='middle' style={{ color: " rgba(0, 0, 0, 0.65)" }}>{t('login.title')}</Title>
          </Row>
          <Row align="middle" justify="space-between" gutter={[16, 48]} style={{ width: "100%" }} className="w-100">
            {process.env.REACT_APP_PROFIL === "ARTISAN" ?
              <Col lg={{ order: 1, span: 10 }} md={{ order: 1, span: 24 }} sm={{ order: 1, span: 24 }} xs={{ order: 1, span: 24 }} align="middle" justify="center" >
                <Card className="glassmorphism" style={{ width: "100%" }}>
                  <Space direction="vertical" size="large" align="center">
                    <Title level={2} align='middle' > {t('login.nouveau-compte-title')}</Title>
                    <img src={form} alt="rna-logo" className="logo-login" style={{ marginBottom: 0 }} />
                    <Button style={{ width: "100%" }} shape="round" type="primary" className="create-account-button" size="large" onClick={() => props.history.push('/new-account')} >
                      <span style={{ fontSize: "17px", fontWeight: "bold" }}><UserAddOutlined />{t('login.nouveau-compte')}</span>
                    </Button>
                    {/*
                    <Button style={{ width: "100%" }} shape="round" type="primary" className="create-account-button" size="large" onClick={() => props.history.push('/new-account/dgsn_000004')} >
                      <span style={{ fontSize: "17px", fontWeight: "bold" }}><UserAddOutlined />{t('login.nouveau-compte-dgsn')}</span>
                    </Button>
                    */}
                    {process.env.DGSN_AUTH_ENABLED == 'true' && 
                    <Button style={{ width: "100%" }} shape="round" type="primary" className="create-account-button" size="large" onClick={() => window.location.assign(Api.dgsn.base_url()+'/dgsn_login')} >
                      <span style={{ fontSize: "17px", fontWeight: "bold" }}><UserAddOutlined />{t('login.nouveau-compte-dgsn')}</span>
                    </Button>
                    }
                  </Space>
                </Card>
              </Col>
              : ''
            }
            <Col lg={{ order: 2, span: process.env.REACT_APP_PROFIL === "ARTISAN" ? 10 : 10, offset: process.env.REACT_APP_PROFIL === "ARTISAN" ? 0 : 7 }}
              md={{ order: 2, span: process.env.REACT_APP_PROFIL === "ARTISAN" ? 24 : 16, offset: process.env.REACT_APP_PROFIL === "ARTISAN" ? 0 : 4 }}
              sm={24}
              xs={24}
              align="middle" style={{ width: "100%" }}
            >
              <LoginForm />
            </Col>
          </Row>
        </Space>
      </Content>
      <span style={{ backgroundColor: 'transparent', textAlign: "center", fontWeight: '500', color: "white" }}>  {t('login.footer')}</span>
    </Layout >
  );
};

export default withRouter(Login);
