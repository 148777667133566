import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Typography, Alert, Divider, Card, Space } from 'antd';
import { addActiviteBranche, addActiviteFiliere, addActivite, updateActiviteBranche, updateActiviteFiliere, updateActivite } from '../../Action'
import { useTranslation } from 'react-i18next';
import { InputWithDoubleLabel, SeletWithDoubleLabel, TextAreaWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import Utils from "../../../../../../../utilities/Utils/Utils";
import SpinLoader from '../../../../../../../component/SpinLoader/SpinLaoder';
const { Title } = Typography;


const ReferentielDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const { handleClose, activeReferenciel, action, refType } = props;


    const loadedActiviteBranchee = useSelector(state => state.referentielParamsReducer?.referentielActivite?.activiteBranche);
    const loadedActiviteFiliere = useSelector(state => state.referentielParamsReducer?.referentielActivite?.activiteFiliere);

    const [activiteBranche, setActiviteBranchee] = useState(loadedActiviteBranchee);
    const [activiteFiliere, setActiviteFiliere] = useState(loadedActiviteFiliere);

    const getSelectData = (data) => {

        return data.map(line => {
            return {
                id: line.key,
                name: {
                    AR: line.label_ar,
                    FR: line.label_fr
                }
            }
        })
    }


    useEffect(() => {
        setActiviteBranchee(getSelectData(loadedActiviteBranchee))
        setActiviteFiliere(getSelectData(loadedActiviteFiliere))
    }, []);

    const getPayload = (entityType, values) => {
        const payload = {
            entity_type: entityType,
            payload: values
        }
        return payload;
    }

    const onFinish = (values) => {
        const { loadDataOrRedirect, setSuccesseMessage, setErrorMessage } = props;
        if (action === 'ADD') {
            if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE) {
                dispatch(addActiviteBranche(getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_BRANCHE, values))).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.activiteBranche.add'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE) {
                dispatch(addActiviteFiliere(getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_FILIERE, values))).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.activiteFiliere.add'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE) {
                dispatch(addActivite(getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE, values)))
                    .then(res => {
                        setSuccesseMessage(t('agent.referentiel.message.activite.add'))
                        loadDataOrRedirect();
                    })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            }
        } else {
            if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE) {
                dispatch(updateActiviteBranche(activeReferenciel.key, getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_BRANCHE, values))).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.activiteBranche.update'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE) {
                dispatch(updateActiviteFiliere(activeReferenciel.key, getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_FILIERE, values))).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.activiteFiliere.update'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE) {
                dispatch(updateActivite(activeReferenciel.key, getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE, values))).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.activite.update'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            }
        }
        handleClose();
    };
    /** if(e){
                setVilles(props.params.villes.filter(ville => ville.provinceId === e));
                constprovince = props.params.provinces.filter(province => province.id === e);
          
                if(province){
                  form.setFieldsValue({region: province[0].regionId})
                }
              }else{
                const region = form.getFieldValue('region')
                setVilles(props.params.villes.filter(ville => ville.regionId === region));
              } */
    const onChangeActiviteBranchee = (e) => {
        if (e) {
            const filtredActiviteBranche = activiteBranche.filter(p => p.id === e);
            form.setFieldsValue({ activite_branche_id: filtredActiviteBranche[0].id })
            // filtre  Activite Filiere by activite_branche_id
            const filtredActiviteFiliere = loadedActiviteFiliere.filter(activiteFiliere => activiteFiliere.activite_branche_id === e);
            setActiviteFiliere(getSelectData(filtredActiviteFiliere))
        } else {

            setActiviteFiliere(getSelectData(loadedActiviteFiliere))
            setActiviteBranchee(getSelectData(loadedActiviteBranchee))
        }
        const activiteFiliereId = form.getFieldValue('activite_filiere_id');
        if (activiteFiliereId) {
            form.setFieldsValue({ activite_filiere_id: undefined })
        }


    }

    const onChangeActiviteFiliere = e => {
        if (e) {
            //const activiteBrancheId = form.getFieldValue('activite_branche_id');
            const activiteBrancheId = loadedActiviteFiliere.filter(activiteFiliere => activiteFiliere.code === e)[0].activite_branche_id;
            //----
            const filtredActiviteFiliere = activiteFiliere.filter(activiteFiliere => activiteFiliere.id === e);
            form.setFieldsValue({ activite_filiere_id: filtredActiviteFiliere[0].id })
            form.setFieldsValue({ activite_branche_id: activiteBrancheId })
        } else {
            const activiteBrancheId = form.getFieldValue('activite_branche_id');
            if (activiteBrancheId) {
                form.setFieldsValue({ activite_branche_id: undefined })
            }
            setActiviteFiliere(getSelectData(loadedActiviteFiliere))
            setActiviteBranchee(getSelectData(loadedActiviteBranchee))
        }

    };



    const getInitData = () => {
        return action !== 'ADD' ? activeReferenciel : {};
    }

    const isConsultation = () => {
        return action === 'CONSULTATION';
    }

    const getTitle = () => {
        if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE) {
            switch (props.action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.activiteBranche');
                case 'UPDATE':
                    return t('agent.referentiel.update.activiteBranche');
                case 'ADD':
                    return t('agent.referentiel.add.activiteBranche');
                default:
                    break;
            }
        } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE) {
            switch (props.action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.activiteFiliere');
                case 'UPDATE':
                    return t('agent.referentiel.update.activiteFiliere');
                case 'ADD':
                    return t('agent.referentiel.add.activiteFiliere');
                default:
                    break;
            }
        } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE) {
            switch (props.action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.activite');
                case 'UPDATE':
                    return t('agent.referentiel.update.activite');
                case 'ADD':
                    return t('agent.referentiel.add.activite');
                default:
                    break;
            }
        }


    }

    return (
        <>
            {loading ? <SpinLoader /> :
                <Modal visible={true} width={1500} onCancel={handleClose}
                    footer=
                    {
                        !isConsultation() &&
                        <>
                            <Button shape="round" key="back" onClick={handleClose}>
                                {t('agent.referentiel.modal.cancel.label')}
                            </Button>
                            <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                                {t('agent.referentiel.modal.submit.label')}
                            </Button>
                        </>
                    }
                >

                    <Form form={form} onFinish={onFinish} initialValues={getInitData()}>
                        <Title level={5} style={{ paddingBottom: '20px' }}>
                            {getTitle()}
                        </Title >
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            {
                                (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE || refType === Utils.REFERENTIEL_TYPES.ACTIVITE) &&
                                < Col lg={11} md={24} sm={24} xs={24} >
                                    <SeletWithDoubleLabel type="text" name="activite_branche_id" label={t('agent.referentiel.modal.activiteBranche.label')} errorMessage={t('agent.referentiel.modal.activiteBranche.error-message')} options={activiteBranche} onChangeAction={onChangeActiviteBranchee} disabled={isConsultation()} />
                                </Col>
                            }
                            {
                                refType === Utils.REFERENTIEL_TYPES.ACTIVITE &&
                                <>
                                    <Divider type="vertical" />
                                    < Col lg={11} md={24} sm={24} xs={24} >
                                        <SeletWithDoubleLabel type="text" name="activite_filiere_id" label={t('agent.referentiel.modal.activiteFiliere.label')} errorMessage={t('agent.referentiel.modal.activiteFiliere.error-message')} options={activiteFiliere} onChangeAction={onChangeActiviteFiliere} disabled={isConsultation()} />
                                    </Col>
                                </>
                            }
                        </Row>
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Col span={24}>
                                <TextAreaWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALPHABETIC_WITH_SPACE} name="description" label={t('agent.referentiel.modal.description.label')} errorMessage={t('agent.referentiel.modal.description.error-message')} disabled={isConsultation()} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="label_fr" label={t('agent.referentiel.modal.label-fr.label')} errorMessage={t('agent.referentiel.modal.label-fr.error-message')} disabled={isConsultation()} />
                            </Col>
                            <Divider type="vertical" />
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="label_ar" label={t('agent.referentiel.modal.label-ar.label')} errorMessage={t('agent.referentiel.modal.label-ar.error-message')} disabled={isConsultation()} />
                            </Col>
                        </Row>
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="label_reduit_fr" label={t('agent.referentiel.modal.label-reduit-fr.label')} errorMessage={t('agent.referentiel.modal.label-reduit-fr.error-message')} disabled={isConsultation()} />
                            </Col>
                            <Divider type="vertical" />
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="label_reduit_ar" label={t('agent.referentiel.modal.label-reduit-ar.label')} errorMessage={t('agent.referentiel.modal.label-reduit-ar.error-message')} disabled={isConsultation()} />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ReferentielDetail;
