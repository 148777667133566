import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  report: [],
};


function reducer(state = initialeState, action) {
    switch (action.type) {
  
      case types.RESET_MESSAGES: {
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        };
      }
  
      case types.GET_ARTISAN_COUNT_REPORT_PENDING: {
        return {
          ...state,
          error: "",
          success: "",
          loading: true,
        };
      }
  
      case types.GET_ARTISAN_COUNT_REPORT_FULFILLED: {
        const data = action.payload.data.data;
        return {
          ...state,
          error: "",
          success: "",
          report: data.totals,
          loading: false,
        };
      }
  
      case types.GET_ARTISAN_COUNT_REPORT_REJECTED: {
        const { message } = action.payload.response.data;
        return {
          ...state,
          error: message,
          success: "",
          loading: false,
        };
      }

      default:
        return state;
    }
}

export default reducer;