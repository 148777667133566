import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  diplomeDuree: [],
  diplome: [],
  duree: [],
};

const mapDataToDiplomeDuree = data => {
  return data.map(line => {
    return {
      key: line.id,
      duree_id: line.duree_id,
      diplome_id: line.diplome_id,
    };
  })
};

const mapDataToDuree = data => {
  return data.map(line => {
    return {
      key: line.id,
      label_fr: line.labels?.FR,
      label_ar: line.labels?.AR,
    };
  })
};

const mapDataToDiplome = data => {
  return data.map(line => {
    return {
      key: line.id,
      label_fr: line.labels?.FR,
      label_ar: line.labels?.AR,
      region_id: line?.region_id,
      province_id: line?.province_id
    };
  })
};

function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case (types.GET_DIPLOME_DUREE_PENDING || types.ADD_DIPLOME_DUREE_PENDING || types.UPDATE_DIPLOME_DUREE_PENDING): {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case types.GET_DIPLOME_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        diplome: mapDataToDiplome(data),
        loading: false,
      };
    }
    case types.GET_DUREE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        duree: mapDataToDuree(data),
        loading: false,
      };
    }
    case types.GET_DIPLOME_DUREE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        diplomeDuree: mapDataToDiplomeDuree(data),
        loading: false,
      };
    }


    case types.ADD_DIPLOME_DUREE_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "Diplome duree créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_DIPLOME_DUREE_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "Diplome duree modifié avec succès",
        loading: false
      };
    }


    case (types.GET_DIPLOME_DUREE_REJECTED || types.ADD_DIPLOME_DUREE_REJECTED || types.UPDATE_DIPLOME_DUREE_REJECTED): {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
