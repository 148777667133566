import React, { useEffect, useState } from 'react';
import {
  Row, Col, Image, Form,
  Input,
  Radio,
  Select,
  Cascader,
  Space,
  DatePicker,
  Typography,
  InputNumber,
  TreeSelect,
  Switch,
  Button,
  Card,
  Divider
} from "antd";
import './PersonalData.less'
import { InputCNIEWithDoubleLabel, InputEmailWithDoubleLabel, InputWithDoubleLabel, SeletWithDoubleLabel, BirthDayDateWithDoubleLabel, RadioWithDoubleLabel, InputPhoneNumberWithDoubleLabel, TextAreaWithDoubleLabel, SimpleDateWithDoubleLabel, InputCNIEWithTypeIdentity } from '../../../../../../../utilities/FormFields/FormFields'
import moment from 'moment';
import { getAnnexe, addNewEstablshment, getEtablissement, getDuree, getDiplome } from './../../../../../../Referentiels/Action';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Utils from '../../../../../../../utilities/Utils/Utils';

const PersonalData = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  let actualTypeIdentity;

  const getInitData = () => {

    const { initData } = props;
    let data = initData;

    if (!isEmpty(data)) {
      if(data.date_naissance){
        data = { ...data, date_naissance: moment(data.date_naissance, "DD/MM/YYYY") }
      }
      if(data.date_expiration){
        data = { ...data, date_expiration: moment(data.date_expiration, "DD/MM/YYYY") }
      }
      if (!data.type_identite) {
        data.type_identite = Utils.TYPE_IDENTITIES.CNIE;
      }
    }

    actualTypeIdentity = data.type_identite;
    return data;
  }

  return (
    <Card >
      <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }} align="middle">
        <Space direction="vertical" size="middle" className="w-100">
          <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
            <Form form={form}
              name="registration"
              style={{ width: '100%' }}
              size='small'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              scrollToFirstError
              forceRender={true}
              initialValues={getInitData()}
            >
              <Row style={{ width: '100%', justifyContent: 'space-between', direction: 'ltr' }}>
                <Col lg={11} md={24} sm={24} xs={24} >
                  <InputWithDoubleLabel forceFr={true} form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="nom" label="Nom" disabled={true} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="nom_ar" label="الاسم العائلي" disabled={true} />
                </Col>
              </Row>
              <Row style={{ width: '100%', justifyContent: 'space-between', direction: 'ltr' }}>
                <Col lg={11} md={24} sm={24} xs={24} >
                  <InputWithDoubleLabel forceFr={true} form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="prenom" label="Prénom" disabled={true} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="prenom_ar" label="الاسم الشخصي" disabled={true} />
                </Col>
              </Row>

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24} >
                  <RadioWithDoubleLabel name="genre" label={t('component.personal-data.form.genre.label')} disabled={true} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel form={form} type="text" name="nationalite" label={t('component.personal-data.form.nationalite.label')} disabled={true} />
                </Col>
              </Row>
              {/* <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <SeletWithDoubleLabel disabled name="type_identite" label={t('component.personal-data.form.typeIdentite.label')} errorMessage={t('component.personal-data.form.typeIdentite.label')} options={Utils.identites} />
              </Row> */}
              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24} >
                  <InputCNIEWithTypeIdentity
                    disabled
                    cnieName="cnie"
                    typeIdentityName="type_identite"
                    label={t(`component.personal-data.form.${actualTypeIdentity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.label`)}
                    typeIdentityErrorMessage={t('component.personal-data.form.typeIdentite.error-message')}
                    errorMessage={t(`component.personal-data.form.${actualTypeIdentity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.error-message`)} options={Utils.identites}
                  />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24} >
                  <SimpleDateWithDoubleLabel type="text" name="date_expiration" label={t(`component.personal-data.form.date_expiration_${actualTypeIdentity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.label`)} disabled={true} />
                </Col>
              </Row>

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24} >
                  <BirthDayDateWithDoubleLabel type="text" name="date_naissance" label={t('component.personal-data.form.date-naissance.label')} disabled={true} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24} >
                  <InputWithDoubleLabel form={form} type="text" name="lieu_de_naissance" label={t('component.personal-data.form.lieu-de-naissance.label')} disabled={true} />
                </Col>
              </Row>


              <Row style={{ width: '100%', justifyContent: 'space-between', direction: 'ltr' }}>
                <Col lg={11} md={24} sm={24} xs={24} >
                  <TextAreaWithDoubleLabel form={form} type="text" name="adresse_personnelle" label={Utils.staticInternationalization.adressePersonnelleFR} disabled={true} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24} >
                  <TextAreaWithDoubleLabel type={Utils.TEXT_TYPE.ARABIC} form={form} name="adresse_personnelle_ar" label={Utils.staticInternationalization.adressePersonnelleAR} disabled={true} />
                </Col>
              </Row>

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={8} md={24} sm={24} xs={24} >
                  <SeletWithDoubleLabel type="text" name="region" label={t('component.personal-data.form.region.label')} options={props.params.regions} disabled={true} />
                </Col>
                <Col lg={8} md={24} sm={24} xs={24} >
                  <SeletWithDoubleLabel type="text" name="province" label={t('component.personal-data.form.province.label')} options={props.params.provinces} disabled={true} />
                </Col>
                <Col lg={7} md={24} sm={24} xs={24} >
                  <SeletWithDoubleLabel type="text" name="ville" label={t('component.personal-data.form.ville.label')} options={props.params.villes} disabled={true} />
                </Col>
              </Row>

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24} style={{ width: '100%', justifyContent: 'center' }}>
                  <InputPhoneNumberWithDoubleLabel type="text" name="numero_telephone" label={t('component.personal-data.form.numero-telephone.label')} disabled={true} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24} style={{ width: '100%', justifyContent: 'center' }}>
                  <InputEmailWithDoubleLabel type="text" name="email" label={t('component.personal-data.form.email.label')} required={false} disabled={true} />
                </Col>
              </Row>

            </Form>
          </Col>
        </Space>
      </Row >
    </Card>
  );


};

export default PersonalData;
