import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
  DOWNLOAD_CERTIFICATION: 'DOWNLOAD_CERTIFICATION',
  DOWNLOAD_CERTIFICATION_PENDING: `DOWNLOAD_CERTIFICATION_${ActionType.Pending}`,
  DOWNLOAD_CERTIFICATION_FULFILLED: `DOWNLOAD_CERTIFICATION_${ActionType.Fulfilled}`,
  DOWNLOAD_CERTIFICATION_REJECTED: `DOWNLOAD_CERTIFICATION_${ActionType.Rejected}`,

  CLEAR_DOWNLOAD_CERTIFICATION_MESSAGES: 'CLEAR_DOWNLOAD_CERTIFICATION_MESSAGES',
}

export default ActionTypes;
