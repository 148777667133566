import React from 'react';
import { Progress } from 'antd';


export class JvmThreads extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      threadStats: {
        threadDumpAll: 0,
        threadDumpRunnable: 0,
        threadDumpTimedWaiting: 0,
        threadDumpWaiting: 0,
        threadDumpBlocked: 0
      }
    };
  }

  countThreadByState() {
    if (this.props.jvmThreads.threads) {
      const threadStats = {
        threadDumpAll: 0,
        threadDumpRunnable: 0,
        threadDumpTimedWaiting: 0,
        threadDumpWaiting: 0,
        threadDumpBlocked: 0
      };

      this.props.jvmThreads.threads.forEach(thread => {
        if (thread.threadState === 'RUNNABLE') {
          threadStats.threadDumpRunnable += 1;
        } else if (thread.threadState === 'WAITING') {
          threadStats.threadDumpWaiting += 1;
        } else if (thread.threadState === 'TIMED_WAITING') {
          threadStats.threadDumpTimedWaiting += 1;
        } else if (thread.threadState === 'BLOCKED') {
          threadStats.threadDumpBlocked += 1;
        }
      });

      threadStats.threadDumpAll =
        threadStats.threadDumpRunnable + threadStats.threadDumpWaiting + threadStats.threadDumpTimedWaiting + threadStats.threadDumpBlocked;

      this.setState({ threadStats });
    }
  }

  componentDidMount() {
    if (this.props.jvmThreads.threads) {
      this.countThreadByState();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.jvmThreads.threads && this.props.jvmThreads.threads !== prevProps.jvmThreads.threads) {
      this.countThreadByState();
    }
  }

  openModal = () => {
    this.setState({
      showModal: true
    });
  };

  handleClose = (e) => {
    this.setState({
      showModal: false
    });
  };

  //renderModal = () => <ThreadsModal handleClose={this.handleClose} showModal={this.state.showModal} threadDump={this.props.jvmThreads} />;

  render() {
    const { wholeNumberFormat } = this.props;
    const { threadStats } = this.state;
    return (
      <div>
        (Total: {threadStats.threadDumpAll}){' '}
        <p>
          <span>Runnable</span> {threadStats.threadDumpRunnable}
          <Progress percent={threadStats.threadDumpRunnable * 100 / threadStats.threadDumpAll} size="large" status="active" strokeColor="#52c41a" />
        </p>

        <p>
          <span>Timed Waiting</span> ({threadStats.threadDumpTimedWaiting})
          <Progress percent={threadStats.threadDumpTimedWaiting * 100 / threadStats.threadDumpAll} size="large" status="active" strokeColor="#8c8c8c" />

        </p>
        <p>
          <span>Waiting</span> ({threadStats.threadDumpWaiting})
          <Progress percent={threadStats.threadDumpWaiting * 100 / threadStats.threadDumpAll} size="large" status="active" strokeColor="#fadb14" />

        </p>
        <p>
          <span>Blocked</span> ({threadStats.threadDumpBlocked})
          <Progress percent={threadStats.threadDumpBlocked * 100 / threadStats.threadDumpAll} size="large" status="active" strokeColor="#f5222d" />

        </p>
      </div>
    );
  }
}