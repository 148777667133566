import * as React from 'react';
import { Table } from 'antd';

export class EndpointsRequestsMetrics extends React.Component {

  render() {
    const { endpointsRequestsMetrics } = this.props;

    const columns = [
      {
        title: 'Method',
        dataIndex: 'methode',
        key: 'methode',
      },
      {
        title: 'Endpoint url',
        dataIndex: 'url',
        key: 'url',
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
      },
      {
        title: 'Mean',
        dataIndex: 'mean',
        key: 'mean',
      },
    ]


    const getData = () => {
      const data = [];
      Object.entries(endpointsRequestsMetrics).map(([key, entry]) =>
        Object.entries(entry).map(([method, methodValue]) => (
          data.push({
            "methode": method,
            "url": key,
            "count": methodValue.count,
            "mean": Math.round(methodValue.mean)
          })
        ))
      )
      return data;
    }

    return (
      <div>
        <h3>Endpoints requests (time in millisecond)</h3>
        <Table columns={columns} dataSource={getData()}  pagination={{ 
          defaultPageSize: 10,
           showSizeChanger: true, 
           pageSizeOptions: ['10', '20', '30']}}/>
      </div>
    );
  }
}