import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Typography, Button, Modal, Checkbox, Switch } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import find from "lodash.find";
import { InputWithDoubleLabel, TextAreaWithDoubleLabel } from "../../../../utilities/FormFields/FormFields";
const { Title } = Typography;

const RejectDeleteArtisanForm = props => {
    const { t } = useTranslation();
    const { handleSubmit, close, visible } = props;
    const [form] = Form.useForm();

    useEffect(() => form.resetFields(), []);

    return (<>
        <Modal title={t('agent.deleteArtisanAccount.modal.rejection.title')} visible={visible} onCancel={close} width={800}
            footer={[
                <>
                    <Button shape="round" key="back" onClick={close}>
                        {t('agent.deleteArtisanAccount.modal.rejection.dismiss')}
                    </Button>
                    <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                        {t('agent.deleteArtisanAccount.modal.rejection.save')}
                    </Button>
                </>
            ]}>
            <Form form={form} onFinish={handleSubmit} >
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col span={24}>
                        <TextAreaWithDoubleLabel form={form} type="text" name="motif_rejet" label={t('agent.deleteArtisanAccount.modal.rejection.motifRejet.label')} placeholder={t('agent.deleteArtisanAccount.modal.rejection.motifRejet.placeholder')} errorMessage={t('agent.deleteArtisanAccount.modal.rejection.motifRejet.error-message')} maxLength={3000} />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
    );
}
export default RejectDeleteArtisanForm;