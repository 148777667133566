import types from './ActionTypes';
import Api from '../../../utilities/services/Api';

export const getAllArtisanDeleteDemande = (payload) => {
  return ({
    type: types.SEARCH_DELETE_ACCOUNT,
    payload: Api.accounts.getAllArtisanDeleteDemandeBySearchCriteria(payload)
  })
};

export const getDeleteDemandeDocument = (artisanId, demandeSuppressionId) => {
  return ({
    type: types.GET_DEMANDE_SUPPRESSION_DOCUMENT,
    payload: Api.accounts.getDeleteDemandeDocument(artisanId, demandeSuppressionId)
  })
};

export const handelDeleteArtisanAccount = (artisanId, demandeSuppressionId, payload) => {
  return ({
    type: types.HANDLE_DELETE_ACCOUNT,
    payload: Api.accounts.handelDeleteArtisanAccount(artisanId, demandeSuppressionId, payload),
  })
};

 export const updateDemandeSuppList = (suppId) => {

   return ({
     type: types.UPDATE_ARTISANT_DEMANDE_SUPP_LIST,
     payload: suppId
   })
 };


// export const clearMessages = () => {
//   return ({
//     type: types.CLEAR_DELETE_ACCOUNT_MESSAGES,
//   })
// };
