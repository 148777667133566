import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Typography, Alert, Divider, Card, Space } from 'antd';
import { getGlobalParam } from '../../../../../../GlobalParams/Action';
import { addActiviteBrancheDecret, addActiviteFiliereDecret, addActiviteDecret, updateActiviteBrancheDecret, updateActiviteFiliereDecret, updateActiviteDecret } from './../../Action'
import { useTranslation } from 'react-i18next';
import { InputWithDoubleLabel, SeletWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import Utils from "../../../../../../../utilities/Utils/Utils";
import SpinLoader from '../../../../../../../component/SpinLoader/SpinLaoder';
const { Title } = Typography;


const ReferentielDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const { handleClose, activeReferenciel, action, refType } = props;


    const loadedActiviteBrancheeDecret = useSelector(state => state.referentielParamsReducer?.referentielActiviteDecret?.activiteBrancheDecret);
    const loadedActiviteFiliereDecret = useSelector(state => state.referentielParamsReducer?.referentielActiviteDecret?.activiteFiliereDecret);

    const [activiteBrancheDecret, setActiviteBrancheeDecret] = useState(loadedActiviteBrancheeDecret);
    const [activiteFiliereDecret, setActiviteFiliereDecret] = useState(loadedActiviteFiliereDecret);

    const getSelectData = (data) => {

        return data.map(line => {
            return {
                id: line.key,
                name: {
                    AR: line.label_ar,
                    FR: line.label_fr
                }
            }
        })
    }


    useEffect(() => {
        setActiviteBrancheeDecret(getSelectData(loadedActiviteBrancheeDecret))
        setActiviteFiliereDecret(getSelectData(loadedActiviteFiliereDecret))
    }, []);

    const getPayload = (entityType, values) => {
        const payload = {
            entity_type: entityType,
            payload: values
        }
        return payload;
    }

    const onFinish = (values) => {
        const { loadDataOrRedirect, setSuccesseMessage, setErrorMessage } = props;
        if (action === 'ADD') {
            if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE_DECRET) {
                dispatch(addActiviteBrancheDecret(getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_BRANCHE_DECRET, values))).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.activiteBrancheDecret.add'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE_DECRET) {
                dispatch(addActiviteFiliereDecret(getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_FILIERE_DECRET, values))).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.activiteFiliereDecret.add'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_DECRET) {
                dispatch(addActiviteDecret(getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_DECRET, values)))
                    .then(res => {
                        setSuccesseMessage(t('agent.referentiel.message.activiteDecret.add'))
                        loadDataOrRedirect();
                    })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            }
        } else {
            if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE_DECRET) {
                dispatch(updateActiviteBrancheDecret(activeReferenciel.key, getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_BRANCHE_DECRET, values))).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.activiteBrancheDecret.update'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE_DECRET) {
                dispatch(updateActiviteFiliereDecret(activeReferenciel.key, getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_FILIERE_DECRET, values))).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.activiteFiliereDecret.update'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_DECRET) {
                dispatch(updateActiviteDecret(activeReferenciel.key, getPayload(Utils.REFERENTIEL_ENTITY_TYPES.ACTIVITE_DECRET, values))).then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.activiteDecret.update'))
                    loadDataOrRedirect();
                })
                    .catch(err => {
                        setErrorMessage(err)
                    })
            }
        }
        handleClose();
    };

    const onChangeActiviteBrancheeDecret = (e) => {
        if (e) {
            const filtredActiviteBrancheDecret = activiteBrancheDecret.filter(p => p.id === e);
            form.setFieldsValue({ activite_branche_decret_id: filtredActiviteBrancheDecret[0].id })
            // filtre  Activite Filiere by activite_branche_id
            const filtredActiviteFiliere = loadedActiviteFiliereDecret.filter(activiteFiliere => activiteFiliere.activite_branche_decret_id === e);
            setActiviteFiliereDecret(getSelectData(filtredActiviteFiliere))
        } else {

            setActiviteBrancheeDecret(getSelectData(loadedActiviteBrancheeDecret))
            setActiviteFiliereDecret(getSelectData(loadedActiviteFiliereDecret))
        }
        const activiteFiliereId = form.getFieldValue('activite_filiere_decret_id');
        if (activiteFiliereId) {
            form.setFieldsValue({ activite_filiere_decret_id: undefined })
        }

    }

    const onChangeActiviteFiliereDecret = e => {
        if (e) {
            const activiteBrancheId = loadedActiviteFiliereDecret.filter(activiteFiliere => activiteFiliere.code === e)[0].activite_branche_decret_id;
            //----
            const filtredActiviteFiliere = activiteFiliereDecret.filter(activiteFiliere => activiteFiliere.id === e);
            form.setFieldsValue({ activite_filiere_decret_id: filtredActiviteFiliere[0].id })
            form.setFieldsValue({ activite_branche_decret_id: activiteBrancheId })
        } else {
            const activiteBrancheId = form.getFieldValue('activite_branche_decret_id');
            if (activiteBrancheId) {
                form.setFieldsValue({ activite_branche_decret_id: undefined })
            }
            setActiviteBrancheeDecret(getSelectData(loadedActiviteBrancheeDecret))
            setActiviteFiliereDecret(getSelectData(loadedActiviteFiliereDecret))
        }
    };

    const getInitData = () => {
        return action !== 'ADD' ? activeReferenciel : {};
    }

    const isConsultation = () => {
        return action === 'CONSULTATION';
    }

    const getTitle = () => {
        if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE_DECRET) {
            switch (props.action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.activiteBrancheDecret');
                case 'UPDATE':
                    return t('agent.referentiel.update.activiteBrancheDecret');
                case 'ADD':
                    return t('agent.referentiel.add.activiteBrancheDecret');
                default:
                    break;
            }
        } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE_DECRET) {
            switch (props.action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.activiteFiliereDecret');
                case 'UPDATE':
                    return t('agent.referentiel.update.activiteFiliereDecret');
                case 'ADD':
                    return t('agent.referentiel.add.activiteFiliereDecret');
                default:
                    break;
            }
        } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_DECRET) {
            switch (props.action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.activiteDecret');
                case 'UPDATE':
                    return t('agent.referentiel.update.activiteDecret');
                case 'ADD':
                    return t('agent.referentiel.add.activiteDecret');
                default:
                    break;
            }
        }


    }

    return (
        <>
            {loading ? <SpinLoader /> :
                <Modal visible={true} width={1500} onCancel={handleClose}
                    footer=
                    {
                        !isConsultation() &&
                        <>
                            <Button shape="round" key="back" onClick={handleClose}>
                                {t('agent.referentiel.modal.cancel.label')}
                            </Button>
                            <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                                {t('agent.referentiel.modal.submit.label')}
                            </Button>
                        </>
                    }
                >

                    <Form form={form} onFinish={onFinish} initialValues={getInitData()}>
                        <Title level={5} style={{ paddingBottom: '20px' }}>
                            {getTitle()}
                        </Title >
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            {
                                (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE_DECRET || refType === Utils.REFERENTIEL_TYPES.ACTIVITE_DECRET) &&
                                < Col lg={11} md={24} sm={24} xs={24} >
                                    <SeletWithDoubleLabel type="text" name="activite_branche_decret_id" label={t('agent.referentiel.modal.activiteBrancheDecret.label')} errorMessage={t('agent.referentiel.modal.activiteBrancheDecret.error-message')} options={activiteBrancheDecret} onChangeAction={onChangeActiviteBrancheeDecret} disabled={isConsultation()} />
                                </Col>
                            }
                            {
                                refType === Utils.REFERENTIEL_TYPES.ACTIVITE_DECRET &&
                                <>
                                    <Divider type="vertical" />
                                    < Col lg={11} md={24} sm={24} xs={24} >
                                        <SeletWithDoubleLabel type="text" name="activite_filiere_decret_id" label={t('agent.referentiel.modal.activiteFiliereDecret.label')} errorMessage={t('agent.referentiel.modal.activiteFiliereDecret.error-message')} options={activiteFiliereDecret} onChangeAction={onChangeActiviteFiliereDecret} disabled={isConsultation()} />
                                    </Col>
                                </>
                            }
                        </Row>
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="label_fr" label={t('agent.referentiel.modal.label-fr.label')} errorMessage={t('agent.referentiel.modal.label-fr.error-message')} disabled={isConsultation()} />
                            </Col>
                            <Divider type="vertical" />
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="label_ar" label={t('agent.referentiel.modal.label-ar.label')} errorMessage={t('agent.referentiel.modal.label-ar.error-message')} disabled={isConsultation()} />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ReferentielDetail;
