import history from '../history';
import jwt from 'jwt-decode';
import localforage from 'localforage';

const AlertMiddleware = (storeAPI) => (next) => (action) => {

    let token = localStorage.getItem('token');
    if (token) {
        const tokenData = jwt(token); // decode your token here
        const expiryTimestamp = tokenData.exp * 1000 - 60 * 1000;
        const nowTimestamp = new Date().getTime();

        if (nowTimestamp >= expiryTimestamp) {
            localforage.clear();
            localStorage.removeItem('token');
            window.location.reload();
        }
    }

    if (action.type && action.type.includes('_FULFILLED') && action.type.includes('AUTHENTICATION')) {

        const data = action.payload.data.data;
        localStorage.setItem('token', data.token_id);

        if (data.users_detail.type === "AGENT") {
            if (data.users_detail.password_status === "EXPIRED") {
                history.push('/app/agent/changer-pass');
                setTimeout(() => {
                    window.location.reload()
                }, 500);
            } else {
                history.push('/app/agent/home');
                window.location.reload();
            }

        } else {
            if (data.users_detail.password_status === "EXPIRED") {
                history.push('/app/artisan/changer-pass');
                setTimeout(() => {
                    window.location.reload()
                }, 500);
            } else {
                if (data.users_detail?.artisan_summary?.statut === "INITIE") {
                    history.push('/app/artisan/souscrire');
                    window.location.reload();
                } else if (data.users_detail?.artisan_summary?.statut === "EXPIRE") {
                    history.push('/app/artisan/modification/donnees');
                    window.location.reload();
                } else {
                    history.push('/app/artisan/home');
                    window.location.reload();
                }
            }
        }


    }

    return next(action);
};
export default AlertMiddleware;
