/* ************************************* */
/* ********       IMPORTS       ******** */
/* ************************************* */
import types from './ActionTypes';
import map from 'lodash.map';

/* ************************************* */
/* ********      VARIABLES      ******** */
/* ************************************* */
const initialState = {
  profiles: [],
  activeProfile: [],
  menuActions: [],
  loading: false,
  loadingGetActiveProfil: false,
  error: '',
  success: ''
};

/* ************************************* */
/* ********       EXPORTS       ******** */
/* ************************************* */
export default reducer;

/* ************************************* */
/* ********  PRIVATE FUNCTIONS  ******** */
/* ************************************* */

function mapAction(data) {
  const menutree = [];
  data.menus.map(menu => {
    menutree.push(
      {
        id: menu.id,
        label: menu.description,
        parent: menu.parent,
        actions: !!menu.actions && menu.actions.map(action => ({
          id: action.id,
          code: action.code,
          enabled: action.enabled === true,
        })),
        children: !!menu.children && menu.children.map(subMenu => ({
          id: subMenu.id,
          label: subMenu.description,
          parent: subMenu.parent,
          actions: !!subMenu.actions && subMenu.actions.map(action => ({
            id: action.id,
            code: action.code,
            enabled: action.enabled === true,
          }))
        }))
      })
  })
  const habilitation = {
    id: data.id,
    code: data.code,
    description: data.description,
    menus: menutree
  };
  return habilitation;
}
function menuActions(data) {
  let optional = {}
  let clone = {}
  data.menus.map(menu => {
    menu.actions.map(action => {
      optional[action.id] = action.enabled;
      clone = { ...optional };
    })
    menu.children && menu.children.map(child => {
      child.actions.map(action => {
        optional[action.id] = action.enabled;
        clone = { ...optional };
      })
    })
  })
  const menuAction = {
    id: data.id,
    profile: data.code,
    description: data.description,
    ...clone
  }
  return menuAction;
}
/* ************************************* */
/* ********   PUBLIC FUNCTIONS  ******** */
/* ************************************* */

/**
 * Reducer.
 * @param state
 * @param action
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_PROFILS_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.GET_PROFILS_FULFILLED: {
      return {
        ...state,
        loading: false,
        profiles: action.payload.data.data,
      };
    }
    case types.GET_PROFIL_MENUS_PENDING: {
      return {
        ...state,
        loadingGetActiveProfil: true,
      };
    }
    case types.GET_PROFIL_MENUS_FULFILLED: {
      return {
        ...state,
        loadingGetActiveProfil: false,
        activeProfile: mapAction(action.payload.data.data),
        menuActions: menuActions(action.payload.data.data),
      };
    }
    case types.ASSIGN_MENU_ACTIONS_PENDING: {
      return {
        ...state,
        loading: true,
        error: '',
        success: ''
      }
    }
    case types.ASSIGN_MENU_ACTIONS_FULFILLED: {
      return {
        ...state,
        loading: false,
        error: '',
        success: 'Habilitations mises à jour avec succès'
      };
    }
    case types.ASSIGN_MENU_ACTIONS_REJECTED: {
      return {
        ...state,
        loading: false,
        error: 'Problème survenue lors de la mise à jour des habilitations',
        success: ''
      };
    }
    case types.ADD_PROFIL_PENDING: {
      return {
        ...state,
        loading: false,
        error: '',
        success: ''
      };
    }
    case types.ADD_PROFIL_FULFILLED: {
      return {
        ...state,
        loading: false,
        error: '',
        success: 'Profile ajouter avec succès'
      };
    }
    case types.ADD_PROFIL_REJECTED: {
      return {
        ...state,
        loading: false,
        error: 'Problème survenue lors du profile',
        success: ''
      };
    }
    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        loading: false,
        error: '',
        success: ''
      };
    }
    default:
      return state;
  }
}
