import {
    Row, Col, Form,
    Divider,
    Space,
    Button,
    Alert,
    Modal
} from "antd";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { InputWithDoubleLabel, SeletWithDoubleLabel } from '../../../../utilities/FormFields/FormFields'
import Utils from "../../../../utilities/Utils/Utils";
import { changerOperateur } from "../../Action";

const OtpValidator = props => {
    const { t } = useTranslation();

    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const dispatch = useDispatch();
    const { prev, validateOtp, regenerateOpt, accountData: { phone_number } } = props;

    const [visible, setVisible] = React.useState(false);
    const [successeMessage, setSuccesseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const onFinishChangerOperateur = (values) => {
        dispatch(changerOperateur({...values, "phone_number": phone_number})).then(
                () => {
                    setSuccesseMessage(t('new-account.form.otp-validator.changeOperateur.modal.sucess-message'))
                }
            ).catch(() => {
                setErrorMessage(t('new-account.form.otp-validator.changeOperateur.modal.error-message'))

            })
        hideModal();
    } 
    
    return (<>
        <Row justify="center" style={{ padding: '20px' }}>
            <h2 >{t('new-account.form.otp-validator.title')}</h2>
        </Row>
        <Space style={{ flexDirection: "column" }} size={"large"} direction='vertical'>
                    {errorMessage &&
                        <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                            <Col>
                                <Alert
                                    style={{ textAlign: 'center' }}
                                    message={errorMessage}
                                    type="error"
                                    closable
                                    onClose={() => setErrorMessage("")}
                                />
                            </Col>
                        </Row>
                    }
                    {successeMessage &&
                        <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                            <Col>
                                <Alert
                                    style={{ textAlign: 'center' }}
                                    message={successeMessage}
                                    type="success"
                                    onClose={() => setSuccesseMessage("")}
                                    closable
                                />
                            </Col>
                        </Row>
                    }
            <Alert message={t('new-account.form.otp-validator.alert')} type="info" showIcon style={{ textAlign: 'initial' }} />

            <Row>
                <p>{t('new-account.form.otp-validator.description')}<strong> {phone_number} </strong></p>
            </Row>

            <Form form={form1} onFinish={validateOtp} style={{ paddingBottom: '20px' }}>
                <Col span={24}>
                    <InputWithDoubleLabel type={Utils.TEXT_TYPE.NUMERIC} name="otp" label={t('new-account.form.otp-validator.otp.label')} placeholder={t('new-account.form.otp-validator.otp.placeholder')} errorMessage={t('new-account.form.otp-validator.otp.error-message')} />
                </Col>
                <Col span={24}>
                    <Button shape="round" type="primary" htmlType="submit" style={{ width: '100%' }} >
                        {t('new-account.form.otp-validator.submit.label')}
                    </Button>

                </Col>
                <Divider />
                <Col span={24}>
                    <a style={{ fontSize: '15px' }} onClick={() => { regenerateOpt() }} >
                        {t('new-account.form.otp-validator.reset.label')}
                    </a>
                </Col>

                <Col span={24}>
                    <a style={{ fontSize: '15px' }} onClick={() => { showModal() }} >
                        {t('new-account.form.otp-validator.changeOperateur.label')}
                    </a>
                </Col>

            </Form>
        </Space>
        {visible &&
            <Modal visible={visible} width={800} onCancel={hideModal}
                footer={[
                    <>
                    <Button shape="round" key="back" onClick={hideModal}>
                        {t('new-account.form.otp-validator.changeOperateur.modal.cancel')}
                    </Button>
                    <Button shape="round" key="submit" type="primary" onClick={form2.submit}>
                        {t('new-account.form.otp-validator.changeOperateur.modal.confirm')}
                    </Button>
                    </>
                ]}>

                <Form form={form2} name="changeOperateur" onFinish={onFinishChangerOperateur}>
                    <Col span={24} md={20} sm={20}>
                        <SeletWithDoubleLabel name="operateur" label={t('new-account.form.otp-validator.changeOperateur.modal.operateur.label')} 
                            placeholder={t('new-account.form.otp-validator.changeOperateur.modal.operateur.placeholder')} 
                            errorMessage={t('new-account.form.otp-validator.changeOperateur.modal.operateur.error-message')} 
                            options={Utils.Operateurs} />
                    </Col>
                </Form>
            </Modal>
        }
    </>)
}

export default OtpValidator;