import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_REGIONS: 'GET_REGIONS',
    GET_REGIONS_PENDING: `GET_REGIONS_${ActionType.Pending}`,
    GET_REGIONS_FULFILLED: `GET_REGIONS_${ActionType.Fulfilled}`,
    GET_REGIONS_REJECTED: `GET_REGIONS_${ActionType.Rejected}`,

    GET_ENTITE_ADMINISTRATIVE: 'GET_ENTITE_ADMINISTRATIVE',
    GET_ENTITE_ADMINISTRATIVE_PENDING: `GET_ENTITE_ADMINISTRATIVE_${ActionType.Pending}`,
    GET_ENTITE_ADMINISTRATIVE_FULFILLED: `GET_ENTITE_ADMINISTRATIVE_${ActionType.Fulfilled}`,
    GET_ENTITE_ADMINISTRATIVE_REJECTED: `GET_ENTITE_ADMINISTRATIVE_${ActionType.Rejected}`,

    ADD_ENTITE_ADMINISTRATIVE: 'ADD_ENTITE_ADMINISTRATIVE',
    ADD_ENTITE_ADMINISTRATIVE_PENDING: `ADD_ENTITE_ADMINISTRATIVE_${ActionType.Pending}`,
    ADD_ENTITE_ADMINISTRATIVE_FULFILLED: `ADD_ENTITE_ADMINISTRATIVE_${ActionType.Fulfilled}`,
    ADD_ENTITE_ADMINISTRATIVE_REJECTED: `ADD_ENTITE_ADMINISTRATIVE_${ActionType.Rejected}`,

    UPDATE_ENTITE_ADMINISTRATIVE: 'UPDATE_ENTITE_ADMINISTRATIVE',
    UPDATE_ENTITE_ADMINISTRATIVE_PENDING: `UPDATE_ENTITE_ADMINISTRATIVE_${ActionType.Pending}`,
    UPDATE_ENTITE_ADMINISTRATIVE_FULFILLED: `UPDATE_ENTITE_ADMINISTRATIVE_${ActionType.Fulfilled}`,
    UPDATE_ENTITE_ADMINISTRATIVE_REJECTED: `UPDATE_ENTITE_ADMINISTRATIVE_${ActionType.Rejected}`,
};

export default ActionTypes;
