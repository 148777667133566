import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_CATEGORIE_DIPLOME: 'GET_CATEGORIE_DIPLOME',
    GET_CATEGORIE_DIPLOME_PENDING: `GET_CATEGORIE_DIPLOME_${ActionType.Pending}`,
    GET_CATEGORIE_DIPLOME_FULFILLED: `GET_CATEGORIE_DIPLOME_${ActionType.Fulfilled}`,
    GET_CATEGORIE_DIPLOME_REJECTED: `GET_CATEGORIE_DIPLOME_${ActionType.Rejected}`,

    ADD_CATEGORIE_DIPLOME: 'ADD_CATEGORIE_DIPLOME',
    ADD_CATEGORIE_DIPLOME_PENDING: `ADD_CATEGORIE_DIPLOME_${ActionType.Pending}`,
    ADD_CATEGORIE_DIPLOME_FULFILLED: `ADD_CATEGORIE_DIPLOME_${ActionType.Fulfilled}`,
    ADD_CATEGORIE_DIPLOME_REJECTED: `ADD_CATEGORIE_DIPLOME_${ActionType.Rejected}`,

    UPDATE_CATEGORIE_DIPLOME: 'UPDATE_CATEGORIE_DIPLOME',
    UPDATE_CATEGORIE_DIPLOME_PENDING: `UPDATE_CATEGORIE_DIPLOME_${ActionType.Pending}`,
    UPDATE_CATEGORIE_DIPLOME_FULFILLED: `UPDATE_CATEGORIE_DIPLOME_${ActionType.Fulfilled}`,
    UPDATE_CATEGORIE_DIPLOME_REJECTED: `UPDATE_CATEGORIE_DIPLOME_${ActionType.Rejected}`,
};

export default ActionTypes;
