import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
    RESET_MESSAGES: 'RESET_MESSAGES',

    GET_ARTISAN_COUNT_REPORT: 'GET_ARTISAN_COUNT_REPORT',
    GET_ARTISAN_COUNT_REPORT_PENDING: `GET_ARTISAN_COUNT_REPORT_${ActionType.Pending}`,
    GET_ARTISAN_COUNT_REPORT_FULFILLED: `GET_ARTISAN_COUNT_REPORT_${ActionType.Fulfilled}`,
    GET_ARTISAN_COUNT_REPORT_REJECTED: `GET_ARTISAN_COUNT_REPORT_${ActionType.Rejected}`,
};

export default ActionTypes;