import { ActionType } from 'redux-promise-middleware';


export default {
  AUTHENTICATION: 'AUTHENTICATION',
  AUTHENTICATION_PENDING: `AUTHENTICATION_${ActionType.Pending}`,
  AUTHENTICATION_FULFILLED: `AUTHENTICATION_${ActionType.Fulfilled}`,
  AUTHENTICATION_REJECTED: `AUTHENTICATION_${ActionType.Rejected}`,

  CLEAR_ERROR_MESSAGE: 'CLEAR_ERROR_MESSAGE',

  CHANGE_ARTISAN_STATUS: 'CHANGE_ARTISAN_STATUS',
  CHANGE_APP_LANGUAGE: 'CHANGE_APP_LANGUAGE',

  UPDATE_ARTISAN: 'UPDATE_ARTISAN',
  UPDATE_ARTISAN_PENDING: `UPDATE_ARTISAN_${ActionType.Pending}`,
  UPDATE_ARTISAN_FULFILLED: `UPDATE_ARTISAN_${ActionType.Fulfilled}`,
  UPDATE_ARTISAN_REJECTED: `UPDATE_ARTISAN_${ActionType.Rejected}`,

  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_PENDING: `FORGOT_PASSWORD_${ActionType.Pending}`,
  FORGOT_PASSWORD_FULFILLED: `FORGOT_PASSWORD_${ActionType.Fulfilled}`,
  FORGOT_PASSWORD_REJECTED: `FORGOT_PASSWORD_${ActionType.Rejected}`,

  CLEAR_CHANGE_PASSWORD: 'CLEAR_CHANGE_PASSWORD',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_PENDING: `CHANGE_PASSWORD_${ActionType.Pending}`,
  CHANGE_PASSWORD_FULFILLED: `CHANGE_PASSWORD_${ActionType.Fulfilled}`,
  CHANGE_PASSWORD_REJECTED: `CHANGE_PASSWORD_${ActionType.Rejected}`,
  
};
