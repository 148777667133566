import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_ALL_SMS: 'GET_ALL_SMS',
    GET_ALL_SMS_PENDING: `GET_ALL_SMS_${ActionType.Pending}`,
    GET_ALL_SMS_FULFILLED: `GET_ALL_SMS_${ActionType.Fulfilled}`,
    GET_ALL_SMS_REJECTED: `GET_ALL_SMS_${ActionType.Rejected}`,

    UPDATE_SMS: 'UPDATE_SMS',
    UPDATE_SMS_PENDING: `UPDATE_SMS_${ActionType.Pending}`,
    UPDATE_SMS_FULFILLED: `UPDATE_SMS_${ActionType.Fulfilled}`,
    UPDATE_SMS_REJECTED: `UPDATE_SMS_${ActionType.Rejected}`,

};

export default ActionTypes;
