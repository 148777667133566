import { ActionType } from 'redux-promise-middleware';
export default {
  GET_PROFILS: 'GET_PROFILS',
  GET_PROFILS_PENDING: `GET_PROFILS_${ActionType.Pending}`,
  GET_PROFILS_FULFILLED: `GET_PROFILS_${ActionType.Fulfilled}`,
  GET_PROFILS_REJECTED: `GET_PROFILS_${ActionType.Rejected}`,

  GET_PROFIL_MENUS: 'GET_PROFIL_MENUS',
  GET_PROFIL_MENUS_PENDING: `GET_PROFIL_MENUS_${ActionType.Pending}`,
  GET_PROFIL_MENUS_FULFILLED: `GET_PROFIL_MENUS_${ActionType.Fulfilled}`,
  GET_PROFIL_MENUS_REJECTED: `GET_PROFIL_MENUS_${ActionType.Rejected}`,

  ADD_PROFIL: 'ADD_PROFIL',
  ADD_PROFIL_PENDING: `ADD_PROFIL_${ActionType.Pending}`,
  ADD_PROFIL_FULFILLED: `ADD_PROFIL_${ActionType.Fulfilled}`,
  ADD_PROFIL_REJECTED: `ADD_PROFIL_${ActionType.Rejected}`,

  ASSIGN_MENU_ACTIONS: 'ASSIGN_MENU_ACTIONS',
  ASSIGN_MENU_ACTIONS_PENDING: `ASSIGN_MENU_ACTIONS_${ActionType.Pending}`,
  ASSIGN_MENU_ACTIONS_FULFILLED: `ASSIGN_MENU_ACTIONS_${ActionType.Fulfilled}`,
  ASSIGN_MENU_ACTIONS_REJECTED: `ASSIGN_MENU_ACTIONS_${ActionType.Rejected}`,

  CLEAR_MESSAGES: 'CLEAR_MESSAGES',
};
