import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  diplome: [],
  publicPrive: [],
  etablissement: [],
  departementFormation: [],
};

const mapDataToEtablissement = data => {
  return data.map(line => {
    return {
      key: line.id,
      label_fr: line.labels?.FR,
      label_ar: line.labels?.AR,
      code: line.code,
      statut: line.statut,
      departementFormation: line.departement_formation_id,
      publicPrive: line.publicprive_id,
      region: line.region_id,
      province: line.province_id,
      ville: line.ville_id
    };
  })
};

const mapDataToDepartementFormation = data => {
  return data.map(line => {
    return {
      id: line.id,
      codepublicPriveId: line.code_public_prive_id,
      name: {
        FR: line.labels?.FR,
        AR: line.labels?.AR,
      }
    };
  })
};
const mapData = data => {
  return data.map(line => {
    return {
      id: line.id,
      name: {
        FR: line.labels?.FR,
        AR: line.labels?.AR,
      }
    };
  })
};


function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case (types.GET_ETABLISSEMENT_PENDING || types.ADD_ETABLISSEMENT_PENDING || types.UPDATE_ETABLISSEMENT_PENDING): {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }
    case types.GET_DEPARTEMENT_FORMATION_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        departementFormation: mapDataToDepartementFormation(data),
        loading: false,
      };
    }
    case types.GET_PUBLIC_PRIVE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        publicPrive: mapData(data),
        loading: false,
      };
    }

    case types.GET_ETABLISSEMENT_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        etablissement: mapDataToEtablissement(data),
        loading: false,
      };
    }

    case types.ADD_ETABLISSEMENT_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "Etablissement créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_ETABLISSEMENT_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "Etablissement modifié avec succès",
        loading: false
      };
    }


    case (types.GET_ETABLISSEMENT_REJECTED || types.ADD_ETABLISSEMENT_REJECTED || types.UPDATE_ETABLISSEMENT_REJECTED): {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
