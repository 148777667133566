import types from './ActionTypes';
import Api from '../../../utilities/services/Api';

export const getArtisanEvents = (artisanId) => {
  return ({
    type: types.ARTISAN_EVENTS,
    payload: Api.artisan.getArtisanEvents(artisanId),
  })
};

