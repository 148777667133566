import axios from 'axios';

if (localStorage.hasOwnProperty('token')) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
}


const backend_base_url = process.env.BACKEND_BASE_URL;

const api_url = backend_base_url + '/rna/api/v1';
// const base_url = 'http://188.165.223.137:9096/rna/api/v1';
// const base_url = 'http://10.254.33.5:9096/rna/api/v1';
const clientAPI = axios.create({
  baseURL: api_url,
})

const client_DGSN_API = axios.create({
  baseURL: backend_base_url,
})

const Api = {
  base_url: backend_base_url,
  ministere_title: {
    getMinistereTitle() {
      return clientAPI.get(`/ministere_title`);
    }
  },
  user: {
    authentication(payload) {
      return clientAPI.post('/users/authenticate', payload);
    },
    authentication_by_cnie(payload) {
      return clientAPI.post('/users/authenticate', payload);
    },
    changerPassword(userId, payload) {
      return clientAPI.post(`/users/${userId}/change-password`, payload);
    },
    searchUsers(payload) {
      return clientAPI.post('/users/search', payload);
    },
    addUser(payload) {
      return clientAPI.post('/users/', payload);
    },
    getUserById(id) {
      return clientAPI.get(`/users/${id}`);
    },
    updateUser(id, payload) {
      return clientAPI.put(`/users/${id}`, payload);
    },
    updateUserById(id, payload) {
      return clientAPI.put(`/users/${id}`, payload);
    },
    deleteUserById(id) {
      return clientAPI.delete(`/users/${id}`);
    },
    deactivateUserById(id) {
      return clientAPI.delete(`/users/${id}/deactivate`);
    },
  },

  report: {
    artisanCountReport(payload) {
      return clientAPI.post(`/artisans/report-count`, payload);
    },
    artisanCNSSReport(params) {
      return clientAPI.get(`/artisans/cnss-report?${params}`);
    }
  },

  accounts: {
    createAccount(payload) {
      return clientAPI.post('/accounts', payload);
    },
    forgotPassword(payload) {
      return clientAPI.post('/accounts/forget-password', payload);
    },
    changerOperateur(payload) {
      return clientAPI.post('/accounts/change-operateur', payload);
    },
    updateArtisant(artisanId, payload) {
      return clientAPI.put(`/artisans/${artisanId}`, payload);
    },
    savePersonalData(artisanId, payload) {
      return clientAPI.post(`/artisans/${artisanId}/donnees/personnelles`, payload);
    },
    saveEducationData(artisanId, payload) {
      return clientAPI.post(`/artisans/${artisanId}/donnees/education`, payload);
    },
    saveRepresentantData(artisanId, payload) {
      return clientAPI.post(`/artisans/${artisanId}/donnees/representant`, payload);
    },
    saveProfessionalData(artisanId, payload) {
      return clientAPI.post(`/artisans/${artisanId}/donnees/professionelles`, payload);
    },
    updatePersonalData(artisanId, payload) {
      return clientAPI.put(`/artisans/${artisanId}/donnees/personnelles`, payload);
    },
    updateEducationData(artisanId, payload) {
      return clientAPI.put(`/artisans/${artisanId}/donnees/education`, payload);
    },
    updateRepresentantData(artisanId, payload) {
      return clientAPI.put(`/artisans/${artisanId}/donnees/representant`, payload);
    },
    updateProfessionalData(artisanId, payload) {
      return clientAPI.put(`/artisans/${artisanId}/donnees/professionelles`, payload);
    },
    getDocumentTypeByArtisanId(artisanId) {
      return clientAPI.get(`/artisans/${artisanId}/documenttypes`);
    },
    finalizeSubscription(artisanId) {
      return clientAPI.post(`/artisans/${artisanId}/finalize`);
    },
    validateSubscription(artisanId, payload) {
      return clientAPI.post(`/artisans/${artisanId}/valider`, payload);
    },
    rejectSubscription(artisanId, payload) {
      return clientAPI.post(`/artisans/${artisanId}/rejeter`, payload);
    },
    uploadArtisanFile(artisanId, formData) {
      return clientAPI.post(`/artisans/${artisanId}/documents`, formData);
    },
    getArtisanFile(artisanId, documentId) {
      return clientAPI.get(`/artisans/${artisanId}/documents/${documentId}`, { responseType: 'blob' });
    },
    removeArtisanFile(artisanId, documentId) {
      return clientAPI.delete(`/artisans/${artisanId}/documents/${documentId}`);
    },
    getArtisanFilesList(artisanId) {
      return clientAPI.get(`/artisans/${artisanId}/documents`);
    },
    getArtisanDataById(artisanId) {
      return clientAPI.get(`/artisans/${artisanId}`);
    },
    radiateRnaAccountById(artisanId) {
      return clientAPI.post(`/artisans/${artisanId}/radier`);
    },
    deletAccount(artisanId, payload) {
      return clientAPI.post(`/artisans/${artisanId}/demandes_suppression`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
    },
    getAllArtisanDeleteDemandeBySearchCriteria(payload) {
      return clientAPI.post(`/artisans/demandes_suppression/search`, payload);
    },
    getDeleteDemandeDocument(artisanId, demandeSuppressionId) {
      return clientAPI.get(`artisans/${artisanId}/demandes_suppression/${demandeSuppressionId}/get-document`, { responseType: 'blob' });
    },
    handelDeleteArtisanAccount(artisanId, demandeSuppressionId, payload) {
      return clientAPI.post(`artisans/${artisanId}/demandes_suppression/${demandeSuppressionId}/handle`, payload);
    },
    getActions(artisanId) {
      return clientAPI.get(`/artisans/${artisanId}/events`);
    }
  },

  document: {
    getPrivacyPolicy(lang) {
      return clientAPI.get(`/documents/conditions?language=${lang}`, { responseType: 'blob' });
    }
  },

  otp: {
    validateOtp(payload) {
      return clientAPI.post('/otp/validate', payload);
    },
    regenerateOtp(payload) {
      return axios({
        method: 'post',
        url: api_url + '/otp/regenerate',
        headers: { 'Content-Type': 'application/json' },
        data: { "phone_number": payload },
      })
    }
  },

  commite: {
    uploadFile(artisanId, formData) {
      return clientAPI.post(`/artisans/${artisanId}/comite-documents`, formData);
    },
    removeFile(artisanId, documentId) {
      return clientAPI.delete(`/artisans/${artisanId}/comite-documents/${documentId}`);
    },
    getFiles(artisanId) {
      return clientAPI.get(`/artisans/${artisanId}/comite-documents`);
    },
    getFile(artisanId, documentId) {
      return clientAPI.get(`/artisans/${artisanId}/comite-documents/${documentId}`, { responseType: 'blob' });
    }
  },

  admin: {
    systemHealth() {
      return clientAPI.get('management/health');
    },
    systemMetrics() {
      return clientAPI.get('management/jhimetrics');
    },
    systemThreadDump() {
      return clientAPI.get('management/threaddump');
    },
    getLoggers() {
      return clientAPI.get('management/loggers');
    },
    changeLogLevel(name, configuredLevel) {
      return axios({
        method: 'post',
        url: api_url + '/management/loggers/' + name,
        headers: { 'Content-Type': 'application/json' },
        data: { "configuredLevel": configuredLevel },
      })
    },
    getConfigurations() {
      return clientAPI.get('management/configprops');
    },
    getEnv() {
      return clientAPI.get('management/env');
    }
  },

  globalParam: {
    getGlobalParam() {
      return clientAPI.get('global_params');
    }
  },

  referentiels: {
    getReferentiels() {
      return clientAPI.get('referentiels');
    }
    , addNewEstablshment(establishment) {
      return clientAPI.post('etablissements', { label: establishment });
    },
    regions: {
      getAll() {
        return clientAPI.get('regions');
      },
      update(regionId, payload) {
        return clientAPI.put(`/regions/${regionId}`, payload);
      },
      add(payload) {
        return clientAPI.post('regions', payload);
      },
    },
    villes: {
      getAll() {
        return clientAPI.get('villes');
      },
      update(villeId, payload) {
        return clientAPI.put(`/villes/${villeId}`, payload);
      },
      add(payload) {
        return clientAPI.post('villes', payload);
      },
    },
    provinces: {
      getAll() {
        return clientAPI.get('provinces');
      },
      update(provinceId, payload) {
        return clientAPI.put(`/provinces/${provinceId}`, payload);
      },
      add(payload) {
        return clientAPI.post('provinces', payload);
      },
    },
    etablissements: {
      getAll() {
        return clientAPI.get('etablissements');
      },
      update(provinceId, payload) {
        return clientAPI.put(`/etablissements/${provinceId}`, payload);
      },
      add(payload) {
        return clientAPI.post('etablissements', payload);
      },
    },
    annexe: {
      getAll() {
        return clientAPI.get('/referentiels/type/ANNEXE_ADMINISTRATIVE');
      },
      update(annexeId, payload) {
        return clientAPI.put(`referentiels/${annexeId}`, payload);
      },
      add(payload) {
        return clientAPI.post('referentiels', payload);
      },
    },
    couvertureSociale: {
      getAll() {
        return clientAPI.get('/referentiels/type/COUVERTURE_SOCIALE');
      },
      update(id, payload) {
        return clientAPI.put(`referentiels/${id}`, payload);
      },
      add(payload) {
        return clientAPI.post('referentiels', payload);
      },
    },
    referentiel: {
      getAll(refType) {
        return clientAPI.get(`/referentiels/type/${refType}`);
      },
      update(id, payload) {
        return clientAPI.put(`referentiels/${id}`, payload);
      },
      add(payload) {
        return clientAPI.post('referentiels', payload);
      },
    }
  },

  profiles: {
    getProfiles() {
      return clientAPI.get('profiles');
    }
  },

  profil: {
    getAllProfils() {
      return clientAPI.get('/profiles');
    },
    addProfil(payload) {
      return clientAPI.post('/profiles', payload);
    },
    getProfileMenus(id) {
      return clientAPI.get(`/profiles/${id}/menus`);
    },
    assignMenuActions(profilesMmenusActionCommand) {
      return clientAPI.post(`/profiles-menus-actions`, profilesMmenusActionCommand);
    }
  },

  artisan: {
    getArtisanBySearchCriteia(searchCiteria) {
      return clientAPI.post('/artisans/search', searchCiteria);
    },
    getArtisanEvents(artisanId) {
      return clientAPI.get(`/artisans/${artisanId}/events`);
    },
    getCertification(artisanId) {
      return clientAPI.get(`/artisans/${artisanId}/certificat`, { responseType: 'blob' });
    },
    getAttestation(artisanId) {
      return clientAPI.get(`/artisans/${artisanId}/attestation-exercice`, { responseType: 'blob' });
    },
    getAttestationArtisan(artisanId, attestationId) {
      return clientAPI.get(`/artisans/attestationverification/${artisanId}/attestationid/${attestationId}`);
    },
    changeArtisanPhoneNumber(userId, payload) {
      return clientAPI.post(`/users/${userId}/artisan-change-phone-number`, payload);
    },
    reinitializeAccount(userId, payload) {
      return clientAPI.post(`/users/${userId}/reinitialize-account`, payload);
    }
  },

  reclamation: {
    createReclamation(payload) {
      return clientAPI.post('/reclamations', payload);
    },
    getAllForArtisan(artisanId, type) {
      return clientAPI.get(`/reclamations/artisans/${artisanId}/type/${type}`);
    },
    getOneForArtisan(artisanId, reclamationId) {
      return clientAPI.get(`/reclamations/${reclamationId}/artisans/${artisanId}`);
    },
    getAllForAgent(searchCriteria) {
      return clientAPI.post(`/reclamations/search`, searchCriteria);
    },
    getOneForAgent(reclamationId) {
      return clientAPI.get(`/reclamations/${reclamationId}`);
    },
    handle(reclamationId, payload) {
      return clientAPI.post(`/reclamations/${reclamationId}/handle`, payload);
    },
  },

  sms: {
    getAll() {
      return clientAPI.get('/sms_labels')
    },
    updateLabel(smsCode, payload) {
      return clientAPI.put(`/sms_labels/${smsCode}`, payload)
    },
  },
  settings: {
    getAll() {
      return clientAPI.get('/settings')
    },
    update(code, payload) {
      return clientAPI.put(`/settings/${code}`, payload)
    },
  },
  dgsn: {
    loadInfos(citizenId) {
      return client_DGSN_API.get('/load/preinscription?citizen_id='+citizenId)
    },
    base_url(){
      return backend_base_url;
    }
  },

}

export default Api;
