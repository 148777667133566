import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
    RESET_MESSAGES_ARTISAN_DETAIL: 'RESET_MESSAGES_ARTISAN_DETAIL',

    GET_ARTISAN_BY_SEARCH_CRITERIA: 'GET_ARTISAN_BY_SEARCH_CRITERIA',
    GET_ARTISAN_BY_SEARCH_CRITERIA_PENDING: `GET_ARTISAN_BY_SEARCH_CRITERIA_${ActionType.Pending}`,
    GET_ARTISAN_BY_SEARCH_CRITERIA_FULFILLED: `GET_ARTISAN_BY_SEARCH_CRITERIA_${ActionType.Fulfilled}`,
    GET_ARTISAN_BY_SEARCH_CRITERIA_REJECTED: `GET_ARTISAN_BY_SEARCH_CRITERIA_${ActionType.Rejected}`,

    REJECT_ARTISAN_SUBSCRIPTION: 'REJECT_ARTISAN_SUBSCRIPTION',
    REJECT_ARTISAN_SUBSCRIPTION_PENDING: `REJECT_ARTISAN_SUBSCRIPTION_${ActionType.Pending}`,
    REJECT_ARTISAN_SUBSCRIPTION_FULFILLED: `REJECT_ARTISAN_SUBSCRIPTION_${ActionType.Fulfilled}`,
    REJECT_ARTISAN_SUBSCRIPTION_REJECTED: `REJECT_ARTISAN_SUBSCRIPTION_${ActionType.Rejected}`,

    VALIDATE_ARTISAN_SUBSCRIPTION: 'VALIDATE_ARTISAN_SUBSCRIPTION',
    VALIDATE_ARTISAN_SUBSCRIPTION_PENDING: `VALIDATE_ARTISAN_SUBSCRIPTION_${ActionType.Pending}`,
    VALIDATE_ARTISAN_SUBSCRIPTION_FULFILLED: `VALIDATE_ARTISAN_SUBSCRIPTION_${ActionType.Fulfilled}`,
    VALIDATE_ARTISAN_SUBSCRIPTION_REJECTED: `VALIDATE_ARTISAN_SUBSCRIPTION_${ActionType.Rejected}`,

    DOWNLOAD_ARTISAN_REPORT_FORMAT_CNSS: 'DOWNLOAD_ARTISAN_REPORT_FORMAT_CNSS',
    DOWNLOAD_ARTISAN_REPORT_FORMAT_CNSS_PENDING: `DOWNLOAD_ARTISAN_REPORT_FORMAT_CNSS_${ActionType.Pending}`,
    DOWNLOAD_ARTISAN_REPORT_FORMAT_CNSS_FULFILLED: `DOWNLOAD_ARTISAN_REPORT_FORMAT_CNSS_${ActionType.Fulfilled}`,
    DOWNLOAD_ARTISAN_REPORT_FORMAT_CNSS_REJECTED: `DOWNLOAD_ARTISAN_REPORT_FORMAT_CNSS_${ActionType.Rejected}`,

    DOWNLOAD_CERTIFICATION: 'DOWNLOAD_CERTIFICATION',
    DOWNLOAD_CERTIFICATION_PENDING: `DOWNLOAD_CERTIFICATION_${ActionType.Pending}`,
    DOWNLOAD_CERTIFICATION_FULFILLED: `DOWNLOAD_CERTIFICATION_${ActionType.Fulfilled}`,
    DOWNLOAD_CERTIFICATION_REJECTED: `DOWNLOAD_CERTIFICATION_${ActionType.Rejected}`,

    CLEAR_DOWNLOAD_CERTIFICATION_MESSAGES: 'CLEAR_DOWNLOAD_CERTIFICATION_MESSAGES',
};

export default ActionTypes;
