import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Typography, Button, Modal, Alert} from "antd";
import filter from 'lodash.filter';
import { CheckOutlined, CloseOutlined, ArrowLeftOutlined, EditOutlined, RedoOutlined } from '@ant-design/icons';
import { SeletWithDoubleLabel, TextAreaWithDoubleLabel, InputPhoneNumberWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields'
import history from '../../../../../../../history';
import Utils from '../../../../../../../utilities/Utils/Utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import UploadFile from '../../../../../../../component/UploadFile/UploadFile';
import { isEqual } from 'lodash';
//import { changeArtisanPhoneNumber } from '../../Action'
import { getUserById, updateUser } from '../../../../../Users/Action.js';
import { uploadCommiteFile } from '../../../../../Users/Action.js';


const { Title, Paragraph } = Typography;

const ValidationInscriptionFooter = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { motifRejetList, rejectAction, validateAction,
          changeArtisanPhoneNumberAction, changeArtisanPhoneNumberClearAction,
          reinitializeAccountAction, reinitializeAccountClearAction,
          couldValidate, uploadFile, removeFile } = props;
  const [form] = Form.useForm();
  const [fileToUpload, setFileToUplod] = useState([]);
  const [validated, setValidation] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visibleModalChangePhoneNumber, setVisibleModalChangePhoneNumber] = useState(false);
  const [visibleModalChangePhoneNumberConfirmDialog, setVisibleModalChangePhoneNumberConfirmDialog] = useState(false);
  const [visibleModalReinitializeAccount, setVisibleModalReinitializeAccount] = useState(false);
  const [visibleModalReinitializeAccountConfirmDialog, setVisibleModalReinitializeAccountConfirmDialog] = useState(false);
  const [forcerValidation, setForcerValidation] = useState(false);
  const profileCode = useSelector(state => state.loginReducer?.profil?.code);
  const menus = useSelector(state => state.loginReducer?.menus);
  const [changePhoneActionAllowed, setChangePhoneActionAllowed] = useState(false);
  const [reinitializeAccountActionAllowed, setReinitializeAccountActionAllowed] = useState(false);
  const artisanStatus = useSelector(state => state.registration?.artisantData?.statut);
  const artisantId = useSelector(state => state.registration?.artisantData?.id);
  const userId = useSelector(state => state.registration?.artisantData?.user_id);
  const userRecord = useSelector(state => { console.log(state); return state.usersReducer?.user} );
  const ancien_numero_telephone = useSelector(state => { console.log(state); return state.usersReducer?.user?.login} );
  const operateur = useSelector(state => state.usersReducer?.user?.operateur);
  const uploadedCommiteDocumentId = useSelector(state => state.artisanDetailReducer?.uploadedCommiteDocument?.id);
  const changePhoneNumberError = useSelector(state => state.artisanDetailReducer?.changeArtisanPhoneNumber?.error);
  const changePhoneNumberSuccess = useSelector(state => state.artisanDetailReducer?.changeArtisanPhoneNumber?.success);
  const reinitializeAccountError = useSelector(state => state.artisanDetailReducer?.reinitializeArtisanAccount?.error);
  const reinitializeAccountSuccess = useSelector(state => state.artisanDetailReducer?.reinitializeArtisanAccount?.success);
  const [formChangePhoneNumberValidation, setFormChangePhoneNumberValidation] = useState({ valid: true, error: {message: null} });
  const [formReinitializeAccountValidation, setFormReinitializeAccountValidation] = useState({ valid: true, error: {message: null} });


  useEffect(() => {
      dispatch(getUserById(userId));
      changeArtisanPhoneNumberClearAction()
      reinitializeAccountClearAction()
      checkChangePhoneAction(menus)
      checkReinitializeAccountAction(menus)
  }, []);

  const checkChangePhoneAction = (menus) => {
    let allowed =  false;
    allowed = menus?.filter( e => e.id == '0020')?.[0].children.filter(e=> e.id == '0193')?.[0]?.actions.filter(e=> e.id == '01930')?.[0] ? true : false;    
    setChangePhoneActionAllowed(allowed)  
  }

  const checkReinitializeAccountAction = (menus) => {
    let allowed =  true;
    allowed = menus?.filter( e => e.id == '0020')?.[0].children.filter(e=> e.id == '0194')?.[0]?.actions.filter(e=> e.id == '01940')?.[0] ? true : false;
    setReinitializeAccountActionAllowed(allowed)  
  }
  

  const getInitDataFormChangeNumTelephone = () => {    
    return {
        ancien_numero_telephone: Utils.mapPhoneNumberToNumber(ancien_numero_telephone),
        nouveau_numero_telephone: null,
        operateur: operateur
    }          
  }

  const getInitDataFormReinitializeAccount = () => {    
    return {
    }          
  }

  const onSubmit = (values) => {
    (validated)
      ? validateAction(forcerValidation)
      : rejectAction(values);

    setVisible(false);
  }

  const onSubmitChangeNumTelephone = (values) => {
    var data = {}
    data.operateur = values.operateur
    data.phone_number = Utils.mapNumberToPhoneNumber(values.nouveau_numero_telephone)
    //dispatch(updateUser(userId, {...userRecord, operateur: values.operateur, login: Utils.mapNumberToPhoneNumber(values.nouveau_numero_telephone)} ))
    //dispatch(updateUser(userId, userRecord))    

    setVisibleModalChangePhoneNumberConfirmDialog(false);
    changeArtisanPhoneNumberAction(userId, data)
    /*dispatch(changeArtisanPhoneNumber(userId, data))
    .then((res) => {
      console.log('resres')
      console.log(res)
      const timerId = setTimeout(() => {
        this.setState({ ...this.state, artisanDetailReducer: {changeArtisanPhoneNumber: }});  
      }, 1000);
      
      //dispatch(getProfiles()
      //setVisibleModalChangePhoneNumber(false)
    }).catch(error => {
      console.log('error')
      console.log(error)
      //setVisibleModalChangePhoneNumber(false)
    })*/
    
  };

  const onSubmitReinitializeAccount = (values) => {
    var data = {}
    
    setVisibleModalReinitializeAccountConfirmDialog(false);
    reinitializeAccountAction(userId, data)
    
  };

  const handleCancel = () => {
    //  }, 1000);

    setVisible(false);
  };

  const handleCancelChangePhoneNumber = () => {
    /*var state = this.state;

    state.artisanDetailReducer.changeArtisanPhoneNumber.error = null;
    state.artisanDetailReducer.changeArtisanPhoneNumber.success = null;
    //const timerId = setTimeout(() => {
    this.setState({ ...this.state, state});  */

    setFormChangePhoneNumberValidation({valid : true, error : null})

    setVisibleModalChangePhoneNumber(false);
  };

 const handleCancelReinitializeAccount = () => {
    /*var state = this.state;

    state.artisanDetailReducer.changeArtisanPhoneNumber.error = null;
    state.artisanDetailReducer.changeArtisanPhoneNumber.success = null;
    //const timerId = setTimeout(() => {
    this.setState({ ...this.state, state});  */

    setFormReinitializeAccountValidation({valid : true, error : null})

    setVisibleModalReinitializeAccount(false);
  };


  const showModal = (validated, forcerValidation = false) => {
    setVisible(true);
    setValidation(validated);
    setForcerValidation(forcerValidation);
  };

  const showModalChangePhoneNumber = (validated = true, forcerValidation = false) => {
    setVisibleModalChangePhoneNumber(true);
    setFormChangePhoneNumberValidation({valid : true, error : null})
    //setValidation(validated);
    //setForcerValidation(forcerValidation);
  };

  const showModalChangePhoneNumberConfirmDialog = (validated = true, forcerValidation = false) => {
    const formValues = form.getFieldsValue();
    if(Utils.mapNumberToPhoneNumber(formValues.nouveau_numero_telephone) == ancien_numero_telephone){
      setFormChangePhoneNumberValidation({valid : false, error : {message: 'ARTISAN_NEW_PHONE_NUMBER_SHOULD_BE_DIFFERENT'}})
      return
    }
    setFormChangePhoneNumberValidation({valid : true, error : null})
    changeArtisanPhoneNumberClearAction()
    setVisibleModalChangePhoneNumberConfirmDialog(true);
    //setValidation(validated);
    //setForcerValidation(forcerValidation);
  };

  const handleCancelChangePhoneNumberConfirmDialog = () => {
    setVisibleModalChangePhoneNumberConfirmDialog(false);
  };

  const showModalReinitializeAccount = (validated = true, forcerValidation = false) => {
    setVisibleModalReinitializeAccount(true);
    setFormReinitializeAccountValidation({valid : true, error : null})
    //setValidation(validated);
    //setForcerValidation(forcerValidation);
  };

  const showModalReinitializeAccountConfirmDialog = (validated = true, forcerValidation = false) => {
    const formValues = form.getFieldsValue();
    /*if(Utils.mapNumberToPhoneNumber(formValues.nouveau_numero_telephone) == ancien_numero_telephone){
      setFormChangePhoneNumberValidation({valid : false, error : {message: 'ARTISAN_NEW_PHONE_NUMBER_SHOULD_BE_DIFFERENT'}})
      return
    }*/
    setFormReinitializeAccountValidation({valid : true, error : null})
    reinitializeAccountClearAction()
    setVisibleModalReinitializeAccountConfirmDialog(true);
    //setValidation(validated);
    //setForcerValidation(forcerValidation);
  };

  const handleCancelReinitializeAccountConfirmDialog = () => {
    setVisibleModalReinitializeAccountConfirmDialog(false);
  };

  const retourner = () => {
    history.push(history.location.state.from)
  }

  const showValidate = () => {    
    return Utils.getStatusToShow(profileCode).includes(artisanStatus) && couldValidate;
  }

  const changeUploadedFileStatus = fileId => {
    const newFileToUpload = fileToUpload;
    const file = filter(newFileToUpload, ['id', fileId])[0];
    const newFileStatusToUpload = { ...file, uploaded: true };
    const indexOfFile = newFileToUpload.indexOf(file);
    newFileToUpload[indexOfFile] = newFileStatusToUpload
    setFileToUplod(newFileToUpload);
  }

  const removedFileHandler = (artisantId, documentId) => {
    removeFile(artisantId, documentId)
  }


  const shouldForceValisation = () => {
    return profileCode === Utils.AGENT_PROFILES.VALIDATEUR_LOCAL_1;
  }


  const getModalTite = () => {
    return forcerValidation ?
      t('component.validation-inscription-footer.modal.form.final-validation.title') :
      t('component.validation-inscription-footer.modal.form.validation.title') + ' ' + t('niveau.' + profileCode);
  }
  return (
    <>  

      <Row style={{ width: '100%', justifyContent: 'space-between', flexDirection: 'row' }}>
        <Col span={12}>
          <Button shape="round" icon={<ArrowLeftOutlined />} size="large" onClick={() => retourner()}>
            {t('component.validation-inscription-footer.go-back')}
          </Button>
          &nbsp;&nbsp;
          {changePhoneActionAllowed && 
            <Button shape="round" icon={<EditOutlined />} size="large" type="warning" onClick={() => showModalChangePhoneNumber()}
                    style={{ background: "darkorange", color: 'white', marginLeft: '10px'}}>
              {t('component.validation-inscription-footer.change-phone-number')}
            </Button>
          }
          &nbsp;
          {reinitializeAccountActionAllowed && 
            <Button shape="round" icon={<RedoOutlined />} size="large" type="warning" onClick={() => showModalReinitializeAccount()}
                    style={{ background: "darkorange", color: 'white', marginLeft: '30px'}}>
              {t('component.validation-inscription-footer.reinitialize-account')}
            </Button>
          }
        </Col>
        {showValidate() &&
          <Row span={16} style={{ flexDirection: 'row-reverse', alignItems: "flex-end", justifyContent: "flex-end", textAlign: 'left' }}>
            <Button shape="round" type="primary" icon={<CheckOutlined />} size="large" onClick={() => showModal(true)} style={{ marginLeft: '30px' }}>
              {t('component.validation-inscription-footer.validate')}
            </Button>

            {shouldForceValisation() &&
              <Button shape="round" type="primary" icon={<CheckOutlined />} size="large" onClick={() => showModal(true, true)} style={{ marginLeft: '30px' }}>
                {t('component.validation-inscription-footer.final-validation')}
              </Button>
            }

            <Button shape="round" danger type="primary" icon={<CloseOutlined />} size="large" onClick={() => showModal(false)}>
              {t('component.validation-inscription-footer.reject')}
            </Button>
          </Row>

        }
      </Row>
      {showValidate() &&
        <Modal visible={visible} width={800} onCancel={handleCancel}
          footer={[
            <>
              <Button shape="round" key="back" onClick={handleCancel}>
                {t('component.validation-inscription-footer.modal.form.cancel')}
              </Button>
              <Button shape="round" key="submit" type="primary" onClick={form.submit} disabled={false}>
                {t('component.validation-inscription-footer.modal.form.confirm')}
              </Button>
            </>
          ]}>

          <Form form={form} onFinish={onSubmit} >
            {validated
              ? <>
                <Title level={5}>  {getModalTite()}</Title >
                <Paragraph>{t('component.validation-inscription-footer.modal.form.validation.confirmation-message')} </Paragraph>
                <UploadFile style={{ paddingBottom: '10px' }}
                  artisanId={artisantId}
                  uploadArtisanFile={uploadFile}
                  description={t('component.upload-document.autre')}
                  documentTypeId={uploadedCommiteDocumentId}
                  changeUploadedFileStatus={changeUploadedFileStatus}
                  handleRemove={removedFileHandler} />

              </>
              : <>
                <Title level={5} style={{ paddingBottom: '20px' }}>{t('component.validation-inscription-footer.modal.form.reject.title')}</Title >
                <SeletWithDoubleLabel type="text" name="motif_id" label={t('component.validation-inscription-footer.modal.form.reject.motif')} options={motifRejetList} errorMessage={t('component.validation-inscription-footer.modal.form.reject.motif-id.error-message')} />
                <TextAreaWithDoubleLabel maxLength="500" type="text" name="commentaire" label={t('component.validation-inscription-footer.modal.form.reject.description')} required={false} />
              </>
            }
          </Form>
        </Modal>
      }

      <Modal visible={visibleModalChangePhoneNumber} width={800} onCancel={handleCancelChangePhoneNumber}
        footer={[
          <>
            <Button shape="round" key="back" onClick={handleCancelChangePhoneNumber}>
              {t('component.validation-inscription-footer.modal.form.cancel')}
            </Button>
            <Button shape="round" key="submit" type="primary" onClick={showModalChangePhoneNumberConfirmDialog} disabled={false}>
              {t('component.validation-inscription-footer.modal.form.confirm')}
            </Button>
          </>
        ]}>

        <Form form={form} onFinish={onSubmitChangeNumTelephone} initialValues={getInitDataFormChangeNumTelephone()}>
              <Title level={5}>  {t('component.validation-inscription-footer.modal.form.change-phone-number.title')}</Title >
              
              { changePhoneNumberError &&
                  <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                      <Col span={24}>
                          <Alert
                              style={{ textAlign: 'center', width: '100%', fontSize: 'large' }}
                              message={t(changePhoneNumberError.code)}
                              type="error"
                              closable
                          />
                      </Col>
                  </Row>
              }
              {!formChangePhoneNumberValidation.valid &&
                  <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                      <Col span={24}>
                          <Alert
                              style={{ textAlign: 'center', width: '100%', fontSize: 'large' }}
                              message={t(formChangePhoneNumberValidation.error.message)}
                              type="error"
                              closable
                          />
                      </Col>
                  </Row>
              }

              {changePhoneNumberSuccess &&
                  <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                      <Col span={24}>
                          <Alert
                              style={{ textAlign: 'center', width: '100%', fontSize: 'large' }}
                              message={t(changePhoneNumberSuccess.code)}
                              type="success"
                              closable
                          />
                      </Col>
                  </Row>
              }

              <InputPhoneNumberWithDoubleLabel name="ancien_numero_telephone" label={t('component.validation-inscription-footer.modal.form.fields.label.ancien-numero-telephone')} placeholder={t('component.professional-data.form.numero-telephone.placeholder')} errorMessage={t('component.professional-data.form.numero-telephone.error-message')} disabled={true} />
              <SeletWithDoubleLabel required={true} name="operateur" label={t('agent.user-details-form.form.operateur.label')} placeholder={t('agent.user-details-form.form.operateur.placeholder')} options={Utils.Operateurs} errorMessage={t('agent.user-details-form.form.operateur.error-message')} />
              <InputPhoneNumberWithDoubleLabel name="nouveau_numero_telephone" label={t('component.validation-inscription-footer.modal.form.fields.label.nouveau-numero-telephone')} placeholder={t('component.professional-data.form.numero-telephone.placeholder')} errorMessage={t('component.professional-data.form.numero-telephone.error-message')} disabled={false} />

              <UploadFile style={{ paddingBottom: '10px' }}
                artisanId={artisantId}
                uploadArtisanFile={uploadFile}
                description={t('component.upload-document.autre')}
                documentTypeId={uploadedCommiteDocumentId}
                changeUploadedFileStatus={changeUploadedFileStatus}
                handleRemove={removedFileHandler} />
        </Form>

        <Modal visible={visibleModalChangePhoneNumberConfirmDialog} width={800} onCancel={handleCancelChangePhoneNumberConfirmDialog}
        footer={[
          <>
            <Button shape="round" key="back" onClick={handleCancelChangePhoneNumberConfirmDialog}>
              {t('component.validation-inscription-footer.modal.form.change-phone-number.no')}
            </Button>
            <Button shape="round" key="submit" type="primary" onClick={form.submit} disabled={false}>
              {t('component.validation-inscription-footer.modal.form.change-phone-number.yes')}
            </Button>
          </>
        ]}>
            <Title level={5}>  {t('component.validation-inscription-footer.modal.form.change-phone-number.title')}</Title >
            <Paragraph>{t('component.validation-inscription-footer.modal.form.change-phone-number.confirmation-message')} </Paragraph>
        </Modal>

      </Modal>

      <Modal visible={visibleModalReinitializeAccount} width={800} onCancel={handleCancelReinitializeAccount}
        footer={[
          <>
            <Button shape="round" key="back" onClick={handleCancelReinitializeAccount}>
              {t('component.validation-inscription-footer.modal.form.cancel')}
            </Button>
            <Button shape="round" key="submit" type="primary" onClick={showModalReinitializeAccountConfirmDialog} disabled={false}>
              {t('component.validation-inscription-footer.modal.form.confirm')}
            </Button>
          </>
        ]}>

        <Form form={form} onFinish={onSubmitReinitializeAccount} initialValues={getInitDataFormReinitializeAccount()}>
          <Title level={5}>{t('component.validation-inscription-footer.modal.form.reinitialize-account.title')}</Title>

          {reinitializeAccountError &&
            <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
              <Col span={24}>
                <Alert
                  style={{ textAlign: 'center', width: '100%', fontSize: 'large' }}
                  message={t(reinitializeAccountError.code)}
                  type="error"
                  closable
                />
              </Col>
            </Row>
          }
          {!formReinitializeAccountValidation.valid &&
            <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
              <Col span={24}>
                <Alert
                  style={{ textAlign: 'center', width: '100%', fontSize: 'large' }}
                  message={t(formReinitializeAccountValidation.error.message)}
                  type="error"
                  closable
                />
              </Col>
            </Row>
          }

          {reinitializeAccountSuccess &&
            <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
              <Col span={24}>
                <Alert
                  style={{ textAlign: 'center', width: '100%', fontSize: 'large' }}
                  message={t(reinitializeAccountSuccess.code)}
                  type="success"
                  closable
                />
              </Col>
            </Row>
          }
          <Paragraph>{t('component.validation-inscription-footer.modal.form.reinitialize-account.message')}</Paragraph>

          <UploadFile style={{ paddingBottom: '10px' }}
            artisanId={artisantId}
            uploadArtisanFile={uploadFile}
            description={t('component.upload-document.autre')}
            documentTypeId={uploadedCommiteDocumentId}
            changeUploadedFileStatus={changeUploadedFileStatus}
            handleRemove={removedFileHandler} />
        </Form>

        <Modal visible={visibleModalReinitializeAccountConfirmDialog} width={800} onCancel={handleCancelReinitializeAccountConfirmDialog}
          footer={[
            <>
              <Button shape="round" key="back" onClick={handleCancelReinitializeAccountConfirmDialog}>
                {t('component.validation-inscription-footer.modal.form.reinitialize-account.no')}
              </Button>
              <Button shape="round" key="submit" type="primary" onClick={form.submit} disabled={false}>
                {t('component.validation-inscription-footer.modal.form.reinitialize-account.yes')}
              </Button>
            </>
          ]}>
          <Title level={5}>{t('component.validation-inscription-footer.modal.form.reinitialize-account.confirm-modal.title')}</Title>
          <Paragraph>{t('component.validation-inscription-footer.modal.form.reinitialize-account.confirm-modal.confirmation-message')}</Paragraph>
        </Modal>

      </Modal>

    </>
  );

};

export default ValidationInscriptionFooter;
