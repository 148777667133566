import React from "react"
import { Card, Col, Divider, Typography, Row, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import './ReportCards.less';
import { useTranslation } from "react-i18next";
const { Title } = Typography;
const { Meta } = Card;

const ReportCards = props => {
    const { t } = useTranslation();

    const backgroundColor = type_artisan_id => {
        switch (type_artisan_id) {
            case '1000': {
                return '#f4ffb8'
              }
              case '2000': {
                return '#fff1b8'
              }
              case '3000': {
                return '#d9f7be'
              }
              case '4000': {
                return '#b5f5ec'
              }
              case '5000': {
                return '#efdbff'
              }  
            default:
                return '#d9d9d9';
        }  
    }

    return (
        <Content>
            
            <Row gutter={[16,16]}>
                <Col span={24}>
                    <Divider >{t('agent.report-cards.divider')}</Divider>
                </Col>
            { props.report.map( statutReport => {
                return (<Col lg={12} md={24} sm={24} xs={24} >
                    <Card className="glassmorphism-report"
                        title={  <p className="label-title" >
                            {t(statutReport.statut)}
                            </p>} 
                        extra={<p className="label-title-2" >{statutReport.nombre}</p>}>
                        <Space direction="vertical" size="x-large" style={{ width: "100%" }} >
                            <Row gutter={[48, 48]} style={{ padding: "30px" }}>
                            { statutReport.type_artisans.map( typeArtisanReport => {

                                return (<>
                                        <Col lg={12} md={12} sm={12} xs={24}  >
                                            <Card
                                                className="glassmorphism-report"
                                                hoverable
                                                style={{
                                                    padding: "20px !important",
                                                    backgroundColor: backgroundColor(typeArtisanReport.type_artisan_id)
                                                }}
                                                size="small"
                                            >
                                                <Meta title={typeArtisanReport.labels.FR} style={{ justifyContent: "center", textAlign: "center" }} />
                                                <Title level={2} align='middle' style={{ justifyContent: "center", textAlign: "center", fontSize: "x-large", margin: "0px", color: '#6D4921', padding: 10 }}  >
                                                    { typeArtisanReport.nombre }
                                                </Title>
                                            </Card>
                                        </Col>
                                </>)
                                } )
                            }
                            </Row>
                        </Space>
                    </Card>
                    </Col>)
            } )
            }
            </Row>
        </Content >)

}

export default ReportCards;


