import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  regions: [],
  provinces: [],
  villes: [],
};

const mapDataToRegions = data => {
  const list = [];
  data.map(region => {
    list.push(
      {
        key: region.id,
        label_fr: region.labels.FR,
        label_ar: region.labels.AR,
        code: region.code
      })
  })
  return list;
}

const mapDataToRegion = region => {
  return {
    key: region.id,
    label_fr: region.labels.FR,
    label_ar: region.labels.AR,
    code: region.code
  };
}

const mapDataToProvinces = data => {
  const list = [];
  data.map(province => {
    list.push(
      {
        key: province.id,
        label_fr: province.labels.FR,
        label_ar: province.labels.AR,
        code: province.code,
        region_id: province.region_id
      })
  })
  return list;
}

const mapDataToProvince = province => {
  return {
    key: province.id,
    label_fr: province.labels.FR,
    label_ar: province.labels.AR,
    code: province.code,
    region_id: province.region_id
  };
}

const mapDataToVilles = data => {
  const list = [];
  data.map(ville => {
    list.push(
      {
        key: ville.id,
        label_fr: ville.labels.FR,
        label_ar: ville.labels.AR,
        code: ville.code,
        region_id: ville.region_id,
        province_id: ville.province_id
      })
  })
  return list;
};

const mapDataToVille = ville => {
  return {
    key: ville.id,
    label_fr: ville.labels.FR,
    label_ar: ville.labels.AR,
    code: ville.code,
    region_id: ville.region_id,
    province_id: ville.province_id
  };
};


function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case (types.GET_REGIONS_PENDING || types.ADD_REGION_PENDING || types.UPDATE_REGION_PENDING
      || types.GET_PROVINCES_PENDING || types.ADD_PROVINCE_PENDING || types.UPDATE_PROVINCE_PENDING
      || types.GET_VILLES_PENDING || types.ADD_VILLE_PENDING || types.UPDATE_VILLE_PENDING): {

        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        };
      }

    case types.GET_REGIONS_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        regions: mapDataToRegions(data),
        loading: false,
      };
    }

    case types.ADD_REGION_FULFILLED: {
      const regions = state.regions;
      const region = mapDataToRegion(action.payload.data.data);
      regions.push(region);

      return {
        ...state,
        error: "",
        success: "Region créé avec succès",
        regions: regions,
        loading: false,
      };
    }

    case types.UPDATE_REGION_FULFILLED: {
      const regions = state.regions;
      const region = mapDataToRegion(action.payload.data.data);
      regions.push(region);

      return {
        ...state,
        error: "",
        success: "Region modifié avec succès",
        regions: regions,
        loading: false
      };
    }

    case types.GET_PROVINCES_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        provinces: mapDataToProvinces(data),
        loading: false,
      };
    }

    case types.ADD_PROVINCE_FULFILLED: {
      const provinces = state.provinces;
      const province = mapDataToProvince(action.payload.data.data);
      provinces.push(province);

      return {
        ...state,
        error: "",
        success: "Province créé avec succès",
        provinces: provinces,
        loading: false,
      };
    }

    case types.UPDATE_PROVINCE_FULFILLED: {
      const provinces = state.provinces;
      const province = mapDataToProvince(action.payload.data.data);
      provinces.push(province);

      return {
        ...state,
        error: "",
        success: "Province modifié avec succès",
        provinces: provinces,
        loading: false
      };
    }

    case types.GET_VILLES_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        villes: mapDataToVilles(data),
        loading: false,
      };
    }

    case types.GET_ANNEXE_ADMINISTRATIVE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        data: data,
        loading: false,
      };
    }

    case types.ADD_VILLE_FULFILLED: {
      const villes = state.villes;
      const ville = mapDataToProvince(action.payload.data.data);
      villes.push(ville);

      return {
        ...state,
        error: "",
        success: "Ville créé avec succès",
        villes: villes,
        loading: false,
      };
    }

    case types.UPDATE_VILLE_FULFILLED: {
      const villes = state.villes;
      const ville = mapDataToProvince(action.payload.data.data);
      villes.push(ville);

      return {
        ...state,
        error: "",
        success: "Ville modifié avec succès",
        villes: villes,
        loading: false
      };
    }

    case (types.GET_REGIONS_REJECTED || types.ADD_REGION_REJECTED || types.UPDATE_REGION_REJECTED
      || types.GET_PROVINCES_REJECTED || types.ADD_PROVINCE_REJECTED || types.UPDATE_PROVINCE_REJECTED
      || types.GET_VILLES_REJECTED || types.ADD_VILLE_REJECTED || types.UPDATE_VILLE_REJECTED): {

        const { message } = action.payload.response.data;
        return {
          ...state,
          error: message,
          success: "",
          loading: false,
        };
      }

    default:
      return state;
  }
}

export default reducer;
