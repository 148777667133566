import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: {},
  documents: [],
  commiteDocuments: [],
  uploadedCommiteDocument: {},
  changeArtisanPhoneNumber: { error: null, success: null},
  reinitializeArtisanAccount: { error: null, success: null},
  actions:[]
};

function reducer(state = initialeState, action) {

  console.log('actionaction')
  console.log(action)
  switch (action.type) {

    case types.GET_FILES_LIST_PENDING: {
      return {
        ...state,
        loading: true,
        error: {},
      };
    }
    case types.GET_FILES_LIST_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        documents: data,
        error: {},
      };
    }
    case types.GET_ALL_COMMITE_FILES_PENDING: {
      return {
        ...state,
        loading: true,
        error: {},
      };
    }
    case types.GET_ALL_COMMITE_FILES_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        commiteDocuments: data,
        error: {},
      };
    }
    case types.GET_ACTIONS_PENDING: {
      return {
        ...state,
        loading: true,
        actions: [],
        error: {},
      };
    }
    case types.GET_ACTIONS_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        actions: data,
        error: {},
      };
    }
    
    case types.UPLOAD_COMMITE_FILE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        uploadedCommiteDocument: data,
        error: {},
      };
    }

    case types.CHANGE_ARTISAN_PHONE_NUMBER_CLEAR: {
      return {
        ...state,
        changeArtisanPhoneNumber: { 
          loading: false,
          error: null,
          success: null
        }
      };
    }

    case types.CHANGE_ARTISAN_PHONE_NUMBER_PENDING: {
      return {
        ...state,
        changeArtisanPhoneNumber: { 
          loading: true,
          error: null,
          success: null
        }
      };
    }

    case types.CHANGE_ARTISAN_PHONE_NUMBER_FULFILLED: {
      return {
        ...state,
        changeArtisanPhoneNumber: {
          loading: false,
          error: null,
          success: { code: 'ARTISAN_PHONE_NUMBER_UPDATED'}
        }
      };
    }

    case types.CHANGE_ARTISAN_PHONE_NUMBER_REJECTED: {
      return {
        ...state,
        changeArtisanPhoneNumber: {
          loading: false,
          error: { code: action.payload.response.data.code },
          success: null,
        }
      };
    }

    //

    case types.REINITIALIZE_ARTISAN_ACCOUNT_CLEAR: {
      return {
        ...state,
        reinitializeArtisanAccount: { 
          loading: false,
          error: null,
          success: null
        }
      };
    }

    case types.REINITIALIZE_ARTISAN_ACCOUNT_PENDING: {
      return {
        ...state,
        reinitializeArtisanAccount: { 
          loading: true,
          error: null,
          success: null
        }
      };
    }

    case types.REINITIALIZE_ARTISAN_ACCOUNT_FULFILLED: {
      return {
        ...state,
        reinitializeArtisanAccount: {
          loading: false,
          error: null,
          success: { code: 'ARTISAN_ACCOUNT_REINITIALIZED'}
        }
      };
    }

    case types.REINITIALIZE_ARTISAN_ACCOUNT_REJECTED: {
      return {
        ...state,
        reinitializeArtisanAccount: {
          loading: false,
          error: { code: action.payload.response.data.code },
          success: null,
        }
      };
    }

    default:
      return state;
  }
}



export default reducer;
