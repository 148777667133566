import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
    GET_GLOBAL_PARAMS: 'GET_GLOBAL_PARAMS',
    GET_GLOBAL_PARAMS_PENDING: `GET_GLOBAL_PARAMS_${ActionType.Pending}`,
    GET_GLOBAL_PARAMS_FULFILLED: `GET_GLOBAL_PARAMS_${ActionType.Fulfilled}`,
    GET_GLOBAL_PARAMS_REJECTED: `GET_GLOBAL_PARAMS_${ActionType.Rejected}`,
};

export default ActionTypes;
