import { Button, Col, Row, Alert, Space, Typography } from "antd";
import React, { useEffect, useState } from "react"
import ReferentielList from "./components/ReferentielList/ReferentielList";
import { getDiplomeDuree, getDuree, getDiplome } from './Action';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ReferentielDetail from "./components/ReferentielDetail/ReferentielDetail";
import { useTranslation } from "react-i18next";
import './ReferentielDiplomeDuree.less'
import SpinLoader from "../../../../../component/SpinLoader/SpinLaoder";
import { getGlobalParam } from "../../../../GlobalParams/Action";
const { Title } = Typography;

const ReferentielDiplomeDuree = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { refType } = useParams();

    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [visible, setVisible] = useState(false);
    const [successeMessage, setSuccesseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [activeReferenciel, setActiveReferenciel] = useState({});
    const [action, setAction] = useState('');
    const regions = useSelector(state => state.globalParamReducer?.params?.regions);
    const provinces = useSelector(state => state.globalParamReducer?.params?.provinces);
    const data = useSelector(state => state.referentielParamsReducer?.referentielDiplomeDureeReducer?.diplomeDuree);
    const duree = useSelector(state => state.referentielParamsReducer?.referentielDiplomeDureeReducer?.duree);
    const diplome = useSelector(state => state.referentielParamsReducer?.referentielDiplomeDureeReducer?.diplome);

    useEffect(() => {
        loadDataOrRedirect();
    }, []);

    async function loadDataOrRedirect() {
        setLoading(true);
        await dispatch(getDiplome());
        await dispatch(getGlobalParam())
        dispatch(getDuree()).then(
            dispatch(getDiplomeDuree())
                .then((res) => {
                    setLoading(false)
                })
                .catch((err) => setLoading(false))
        )
    }

    const handleDetails = (record) => {
        setActiveReferenciel(record)
        setVisible(true)
        setAction('CONSULTATION')
    }

    const handleAdd = () => {
        setActiveReferenciel({})
        setVisible(true)
        setLoadingTable(true)
        setAction('ADD')
    }

    const handleUpdates = (record) => {
        setActiveReferenciel(record)
        setVisible(true)
        setLoadingTable(true)
        setAction('UPDATE')
    }

    const hideModal = () => {
        setVisible(false);
        setLoadingTable(false)
        setActiveReferenciel({})

    };

    const getTitle = () => t('agent.referentiel.title.diplomeDuree');

    const getAddButtonTitle = () => t('agent.referentiel.add.diplomeDuree');

    const getRequiredData = () => ({
        regions: regions,
        provinces: provinces,
        duree: getSelectData(duree),
        diplome: getDiplomeSelectData(diplome)
    });
    const getSelectData = (data) => {
        return data.map(line => {
            return {
                id: line.key,
                key: line.key,
                name: {
                    FR: line.label_fr,
                    AR: line.label_ar
                }
            }
        })
    };
    const getDiplomeSelectData = (data) => {
        return data.map(line => {
            return {
                id: line.key,
                key: line.key,
                name: {
                    FR: line.label_fr,
                    AR: line.label_ar
                },
                province_id: line.province_id,
                region_id: line?.region_id,
            }
        })
    }

    const getData = () => {
        const _data = data.map(line => {
            const _activeDiplome = diplome.filter(dp => dp.key === line.diplome_id);
            return { ...line, province: _activeDiplome[0]?.province_id, region: _activeDiplome[0]?.region_id }
        })
        return _data;
    }
    return (<>

        <Space size="large" direction="vertical" style={{ width: '100%' }}  >


            {loading ? <SpinLoader /> : <>

                <div>
                    <Title span={3} className="aligne-left">
                        {getTitle()}
                    </Title>
                    <Button shape='round' type="primary" htmlType="submit" onClick={() => handleAdd()} className="aligne-right">
                        {getAddButtonTitle()}
                    </Button>
                </div>
                {errorMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={errorMessage}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {successeMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={successeMessage}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }
                <div className="search-result-list">
                    <ReferentielList requiredData={getRequiredData()} loadingTable={loadingTable} data={getData()} refType={refType} handleUpdates={handleUpdates} handleDetails={handleDetails} />
                    {visible &&
                        <ReferentielDetail requiredData={getRequiredData()} handleClose={hideModal} activeReferenciel={activeReferenciel} action={action} refType={refType}
                            loadDataOrRedirect={loadDataOrRedirect} setErrorMessage={setErrorMessage} setSuccesseMessage={setSuccesseMessage} />
                    }

                </div>
            </>}
        </Space>
    </>);
}

export default ReferentielDiplomeDuree;