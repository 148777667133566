import React  from "react";
import { useTranslation } from 'react-i18next';
import { Card, Modal } from "antd";

const VisualiseDocument = props => {
    const { t } = useTranslation();
    const { close, visible,file } = props;


    return (<>
        <Modal title={t('agent.deleteArtisanAccount.modal.document.title')} visible={visible} onCancel={close} width={1000} footer={null}>
            <Card >
                {file && <img id="image" src={file} width="100%" />}
                
            </Card>
        </Modal>
    </>
    );
}
export default VisualiseDocument;