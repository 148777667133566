import types from './ActionTypes';

const initialeState = {
  loading: false,
  success: "",
  error: ""
};


function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_DELETE_ACCOUNT_MESSAGES: {
      return {
        ...state,
        error: '',
      };
    }

    case types.DELETE_ACCOUNT_FULFILLED: {
      return {
        ...state,
        loading: false,
        success: "",
        error: ""
      };
    }

    case types.DELETE_ACCOUNT_REJECTED: {
      return {
        ...state,
        loading: false,
        success: "",
        error: action.payload.response.data.code
      };
    }

    case types.DELETE_ACCOUNT_PENDING: {
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      };
    }

    default:
      return state;
  }
}

export default reducer;
