import React from "react";
import { useTranslation } from 'react-i18next';
import { Modal } from "antd";
import Text from "antd/lib/typography/Text";

const ValidateDeleteArtisanForm = props => {
    const { t } = useTranslation();
    const { handleSubmit, close, visible } = props;

    return (<>
        <Modal title={t('agent.deleteArtisanAccount.modal.validation.title')} visible={visible} onOk={handleSubmit} onCancel={close} width={800}
            okText={t('agent.deleteArtisanAccount.modal.validation.okText')}
            cancelText={t('agent.deleteArtisanAccount.modal.validation.cancelText')}>
            <Text>{t('agent.deleteArtisanAccount.modal.validation.description')}</Text>
        </Modal>
    </>
    );
}
export default ValidateDeleteArtisanForm;