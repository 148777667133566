import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Typography, Alert, Divider, Card, Space } from 'antd';
import { getGlobalParam } from '../../../../../../GlobalParams/Action';
import { getEtablissement, addEtablissement, updateEtablissement } from '../../Action'
import { useTranslation } from 'react-i18next';
import { SeletWithDoubleLabel, InputWithDoubleLabel, TextAreaWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import Utils from "../../../../../../../utilities/Utils/Utils";
import SpinLoader from '../../../../../../../component/SpinLoader/SpinLaoder';
const { Title } = Typography;


const ReferentielDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const { handleClose, activeReferenciel, action, refType, requiredData: {
        regions,
        provinces,
        villes,
        publicPrive,
        departementFormation
    } } = props;

    const [_villes, set_Villes] = React.useState(villes);
    const [_provinces, set_Provinces] = React.useState(provinces);
    const [_regions, set_Regions] = React.useState(regions);
    const [_publicPrive, set_PublicPrive] = React.useState(publicPrive);
    const [_departementFormation, set_DepartementFormation] = React.useState(departementFormation);



    const onFinish = (values) => {
        const { loadDataOrRedirect, setSuccesseMessage, setErrorMessage } = props;
        const payload = {
            description: values.description,
            label_fr: values.label_fr,
            label_ar: values.label_ar,
            departement_formation_id: values.departementFormation,
            publicprive_id: values.publicPrive,
            region_id: values.region,
            province_id: values.province,
            ville_id: values.ville,
        }
        if (action === 'ADD') {
            dispatch(addEtablissement(payload))
                .then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.etablissement.add'))
                    loadDataOrRedirect();
                })
                .catch(err => {
                    setErrorMessage(err)
                })
        } else {
            dispatch(updateEtablissement(activeReferenciel.key, payload))
                .then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.etablissement.update'))
                    loadDataOrRedirect();
                })
                .catch(err => {
                    setErrorMessage(err)
                })
        }
        handleClose();
    };



    const getInitData = () => {
        let initData = {};
        if (action !== 'ADD') {
            initData = activeReferenciel;
        }
        return initData;
    }

    const isConsultation = () => {
        return action === 'CONSULTATION';
    }


    const getTitle = () => {
        if (refType === Utils.REFERENTIEL_TYPES.ESTABLISHMENT) {
            switch (action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.etablissement');
                case 'UPDATE':
                    return t('agent.referentiel.update.etablissement');
                case 'ADD':
                    return t('agent.referentiel.add.etablissement');
                default:
                    break;
            }
        }
    }



    const onChangeDepartementFormation = e => {
        const filtredDepartementFormation = departementFormation.filter(departement => departement.id === e);
        if (filtredDepartementFormation && filtredDepartementFormation.length > 0) {
            form.setFieldsValue({ publicPrive: filtredDepartementFormation[0].codepublicPriveId })
            set_PublicPrive(publicPrive.filter(pp => pp.id === filtredDepartementFormation[0].codepublicPriveId));
        } else {
            set_PublicPrive(publicPrive)
        }
    };

    const onChangePublicPrive = e => {
        const filtredDepartementFormation = departementFormation.filter(departement => departement.codepublicPriveId === e);
        set_DepartementFormation(filtredDepartementFormation)
    };


    const onChangeRegion = e => {
        if (e) {
            const etab = form.getFieldValue('etablissement')
            set_Provinces(provinces.filter(province => province.regionId === e));
            set_Villes(villes.filter(ville => ville.regionId === e));

            if (etab) {
                form.setFieldsValue({ etablissement: etab })
            }
        } else {
            set_Provinces(provinces)
            set_Villes(villes)
        }

        form.setFieldsValue({ province: '' })
        form.setFieldsValue({ ville: '' })
    };

    const onChangeProvince = e => {
        if (e) {
            const etab = form.getFieldValue('etablissement')
            set_Villes(villes.filter(ville => ville.provinceId === e));
            var province = provinces.filter(province => province.id === e);

            if (province) {
                form.setFieldsValue({ region: province[0].regionId })
            }
            if (etab) {
                form.setFieldsValue({ etablissement: etab })
            }
        } else {
            var region = form.getFieldValue('region')
            set_Villes(villes.filter(ville => ville.regionId === region));
        }

        form.setFieldsValue({ ville: '' })
    };

    const onChangeVille = e => {
        var ville = villes.filter(ville => ville.id === e);
        if (ville && ville.length !== 0) {
            form.setFieldsValue({ region: ville[0].regionId })
            form.setFieldsValue({ province: ville[0].provinceId })
            set_Provinces(provinces.filter(p => p.regionId === ville[0].regionId));
            set_Villes(villes.filter(v => v.provinceId === ville[0].provinceId))
        }
    }

    return (
        <>
            {loading ? <SpinLoader /> :
                <Modal visible={true} width={1500} onCancel={handleClose}
                    footer=
                    {
                        !isConsultation() &&
                        <>
                            <Button shape="round" key="back" onClick={handleClose}>
                                {t('agent.referentiel.modal.cancel.label')}
                            </Button>
                            <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                                {t('agent.referentiel.modal.submit.label')}
                            </Button>
                        </>
                    }
                >
                    <Form form={form} onFinish={onFinish} initialValues={getInitData()}>
                        <Title level={5} style={{ paddingBottom: '20px' }}>
                            {getTitle()}
                        </Title >
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>

                            < Col lg={11} md={24} sm={24} xs={24} >
                                <SeletWithDoubleLabel type="text" name="departementFormation" label={t('agent.referentiel.modal.departementFormation.label')} errorMessage={t('agent.referentiel.modal.departementFormation.error-message')}
                                    onChangeAction={onChangeDepartementFormation} options={_departementFormation} disabled={isConsultation()} required={activeReferenciel.departementFormation} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <SeletWithDoubleLabel type="text" name="publicPrive" label={t('agent.referentiel.modal.publicPrive.label')} errorMessage={t('agent.referentiel.modal.publicPrive.error-message')}
                                    options={_publicPrive} onChangeAction={onChangePublicPrive} disabled={isConsultation()} required={activeReferenciel.publicPrive} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <SeletWithDoubleLabel type="text" name="region" label={t('agent.referentiel.modal.region.label')} errorMessage={t('agent.referentiel.modal.region.error-message')} options={_regions} onChangeAction={onChangeRegion} disabled={isConsultation()} required={activeReferenciel.region} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <SeletWithDoubleLabel type="text" name="province" label={t('agent.referentiel.modal.province.label')} errorMessage={t('agent.referentiel.modal.province.error-message')} options={_provinces} onChangeAction={onChangeProvince} disabled={isConsultation()} required={activeReferenciel.province} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <SeletWithDoubleLabel type="text" name="ville" label={t('agent.referentiel.modal.ville.label')} errorMessage={t('agent.referentiel.modal.ville.error-message')} options={_villes} onChangeAction={onChangeVille} disabled={isConsultation()} required={activeReferenciel.ville} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="label_fr" label={t('agent.referentiel.modal.label-fr.label')} errorMessage={t('agent.referentiel.modal.label-fr.error-message')} disabled={isConsultation()} required={activeReferenciel.label_fr} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="label_ar" label={t('agent.referentiel.modal.label-ar.label')} errorMessage={t('agent.referentiel.modal.label-ar.error-message')} disabled={isConsultation()} required={activeReferenciel.label_ar} />
                            </Col>

                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ReferentielDetail;
