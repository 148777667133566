import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row, Typography, Divider, } from 'antd';
import { updateSettings, getAllSettings } from '../../Action'
import { useTranslation } from 'react-i18next';
import { InputWithDoubleLabel, CheckBoxWithDoubleLabel } from '../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import Utils from "../../../../../utilities/Utils/Utils";
import SpinLoader from '../../../../../component/SpinLoader/SpinLaoder';
import { isEmptyObject } from 'jquery';
const { Title } = Typography;


const SettingsDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { handleClose, activeSettings } = props;

    const [loading, setLoading] = useState(isEmptyObject(activeSettings));


    const onFinish = (values) => {
        const { setSuccesseMessage, setErrorMessage } = props;

        dispatch(updateSettings(activeSettings.code,values)).then(res => {
            dispatch(getAllSettings());
            setSuccesseMessage(t('agent.referentiel.message.setting.update'));
        }).catch(err => {
                setErrorMessage(err)
            })
        handleClose();
    };

    const processValues = ()=> {
        console.log('processValues')
        console.log(activeSettings)
        activeSettings.value  = typeof activeSettings.value === 'string' && activeSettings.value.toLowerCase() == 'true'? 1 : 0;
    }

    const showFieldsSMS = (e) => {
        console.log('e.target.checked')
        console.log(e.target.checked)
        /*if (!e.target.checked) {
            form.setFieldsValue({ sms_label_fr: '' })
            form.setFieldsValue({ sms_label_ar: '' })
        }
        setShouldShouldSmsFiedls(!activeReferenciel ? !activeReferenciel.sendSms : !shouldShouldSmsFiedls)*/
    }

    return (
        <>
            {loading ? <SpinLoader /> :
                <Modal visible={true} width={1500} onCancel={handleClose}
                    footer=
                    {
                        <>
                            <Button shape="round" key="back" onClick={handleClose}>
                                {t('agent.settings.modal.cancel')}
                            </Button>
                            <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                                {t('agent.settings.modal.submit')}
                            </Button>
                        </>
                    }
                >
                    {processValues()}   


                    <Form form={form} onFinish={onFinish} initialValues={activeSettings}>
                        <Title level={5} style={{ paddingBottom: '20px' }}>
                            { t('agent.settings.modal.update-title') }
                        </Title >

                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Col span={11}>
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} disabled={true} name="code"  label={t('agent.settings.modal.label.code')} errorMessage={t('agent.settings.modal.label-fr.error-message')}/>
                            </Col>
                        </Row>

                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Col span={11}>
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} disabled={true} name="type" label={t('agent.settings.modal.label.type')} errorMessage={t('agent.settings.modal.label-fr.error-message')}/>
                            </Col>

                            <Divider type="vertical" />

                            {activeSettings.type == 'text' &&
                                <Col span={11}>
                                    <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="value" label={t('agent.settings.modal.label.valeur')} errorMessage={t('agent.settings.modal.label-ar.error-message')}/>
                                </Col>
                            }
                            {activeSettings.type == 'boolean' &&
                                <Col span={11}>
                                    <CheckBoxWithDoubleLabel onChange={showFieldsSMS} name="value" label={t('agent.settings.modal.label.active')} errorMessage={t('agent.settings.modal.label-ar.error-message')}/>
                                </Col>
                            }
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default SettingsDetail;
