import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Space, Typography, Button, Card, Result } from "antd";
import { UserAddOutlined, ArrowLeftOutlined, ClockCircleOutlined } from '@ant-design/icons';
import Layout, { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { getAttestationArtisan } from './Action';
import SpinLoader from '../../component/SpinLoader/SpinLaoder';
import { useHistory } from 'react-router';
import { InputWithDoubleLabel } from '../../utilities/FormFields/FormFields';
import LanguageSelector from '../../component/LanguageSelector/LanguageSelector';
import Logo from '../Logo/Logo';

const { Title } = Typography;
const { Text } = Typography;
const AttestationVerification = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { artisanId, attestationId } = useParams();
  const artisan = useSelector(state => state.attestationVerificationReducer?.artisan);
  const loading = useSelector(state => state.attestationVerificationReducer?.loading);
  const error = useSelector(state => state.attestationVerificationReducer?.error);

  console.log("artisan");console.log(artisan); 

  useEffect(() => {
    dispatch(getAttestationArtisan(artisanId, attestationId))
  }, []);


  return (
    <Layout style={{ minHeight: '100vh', padding: "20px", }} className="background">
      <Row type="flex" align="middle">
        <Col span="24" align="middle" style={{ alignItems: "center" }} >
          <Logo height="15%" />
        </Col>
      </Row>
        <Row >
          <Col span="4" align="middle" style={{ right: 30, position: "absolute", top: "10px" }}>
            <LanguageSelector />
          </Col>
          
          <Col span="4" align="middle" style={{ left: 30, position: "absolute", top: "10px" }}>
            <Button type="default" shape="round" size={"large"} onClick={() => window.location.assign(process.env.REACT_APP_PORTAIL_URL)} icon={<ArrowLeftOutlined />}
              style={{ backgroundColor: "white" }}>
              {t("go-back")}
            </Button>
          </Col>
          
        </Row>
        { loading
            ? <SpinLoader />
            : error ?
            <Content style={{ width: "100% !important"  }}>
                <Space direction="vertical" size="large" style={{ width: "100%" }} >
                    <Col lg={{ span: 14, offset:  5 }} md={24} sm={24} xs={24}>   
                        <Card className="glassmorphism" style={{ width: "100%" }} >
                        <Result
                              status="error"
                              title={<Row justify="center"><span>{t(`artisan.attestationVerification.error`)}</span></Row>}
                          />
                        </Card>
                    </Col>
                </Space>
              </Content>
            : <Content style={{ width: "100% !important"  }}>
                <Space direction="vertical" size="large" style={{ width: "100%" }} >
                    <Col lg={{ span: 14, offset:  5 }} md={24} sm={24} xs={24}>   
                        <Card className="glassmorphism" style={{ width: "100%" }}>
                            {Object.keys(artisan).map((key)=>(
                              artisan[key] &&
                                      (key == 'activite_multiple') ? (
                                         (JSON.parse(artisan[key])).map((value, idx)=>(
                                          <div>
                                            <Row style={{ width: '100%', justifyContent: 'space-between', padding: '0.5%' }}>
                                              <Col lg={11} md={12} sm={12} xs={12} >
                                                  <Title level={3} align='' style={{ color: " rgba(0, 0, 0, 0.65)" }}>{t(`artisan.attestationVerification.branche`)}</Title>
                                              </Col>
                                              <Col lg={11} md={12} sm={12} xs={12}>
                                                <Title level={3} style={{ color: " rgba(0, 0, 0, 0.65)" }}>
                                                  {value['branche']}
                                                </Title>
                                              </Col>
                                            </Row>
                                            <Row style={{ width: '100%', justifyContent: 'space-between', padding: '0.5%' }}>
                                              <Col lg={11} md={12} sm={12} xs={12} >
                                                  <Title level={3} align='' style={{ color: " rgba(0, 0, 0, 0.65)" }}>{t(`artisan.attestationVerification.filiere`)}</Title>
                                              </Col>
                                              <Col lg={11} md={12} sm={12} xs={12}>
                                                <Title level={3} style={{ color: " rgba(0, 0, 0, 0.65)" }}>
                                                  {value['filiere']}
                                                </Title>
                                              </Col>
                                            </Row>
                                            <Row style={{ width: '100%', justifyContent: 'space-between', padding: '0.5%' }}>
                                              <Col lg={11} md={12} sm={12} xs={12} >
                                                  <Title level={3} align='' style={{ color: " rgba(0, 0, 0, 0.65)" }}>{t(`artisan.attestationVerification.activite`)}</Title>
                                              </Col>
                                              <Col lg={11} md={12} sm={12} xs={12}>
                                                <Title level={3} style={{ color: " rgba(0, 0, 0, 0.65)" }}>
                                                  {value['activite']}
                                                </Title>
                                              </Col>
                                            </Row>
                                          </div>
                                         ))
                                       )
                                       :( 

                                        <Row style={{ width: '100%', justifyContent: 'space-between', padding: '0.5%' }}>
                                          <Col lg={11} md={12} sm={12} xs={12} >
                                              <Title level={3} align='' style={{ color: " rgba(0, 0, 0, 0.65)" }}>{t(`artisan.attestationVerification.${key}`)}</Title>
                                          </Col>
                                          <Col lg={11} md={12} sm={12} xs={12}>
                                              <Title level={3} style={{ color: " rgba(0, 0, 0, 0.65)" }}>
                                                {artisan[key]}
                                              </Title>
                                          </Col>
                                        </Row>
                                        )
                                      
                            ))}
                        </Card>
                    </Col>
                </Space>
              </Content>
      }
      <span style={{ backgroundColor: 'transparent', textAlign: "center", fontWeight: '500', color: "white" }}>  {t('login.footer')}</span>
    </Layout >
  );

};

export default AttestationVerification;
