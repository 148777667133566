import { ActionType } from 'redux-promise-middleware';

const actionTypes = {
  REGISTER_ARTISAN_PERSONNAL_DATA: 'REGISTER_ARTISAN_PERSONNAL_DATA',
  REGISTER_ARTISAN_PERSONNAL_DATA_PENDING: `REGISTER_ARTISAN_PERSONNAL_DATA_${ActionType.Pending}`,
  REGISTER_ARTISAN_PERSONNAL_DATA_FULFILLED: `REGISTER_ARTISAN_PERSONNAL_DATA_${ActionType.Fulfilled}`,
  REGISTER_ARTISAN_PERSONNAL_DATA_REJECTED: `REGISTER_ARTISAN_PERSONNAL_DATA_${ActionType.Rejected}`,

  REGISTER_ARTISAN_PROFESSIONAL_DATA: 'REGISTER_ARTISAN_PROFESSIONAL_DATA',
  REGISTER_ARTISAN_PROFESSIONAL_DATA_PENDING: `REGISTER_ARTISAN_PROFESSIONAL_DATA_${ActionType.Pending}`,
  REGISTER_ARTISAN_PROFESSIONAL_DATA_FULFILLED: `REGISTER_ARTISAN_PROFESSIONAL_DATA_${ActionType.Fulfilled}`,
  REGISTER_ARTISAN_PROFESSIONAL_DATA_REJECTED: `REGISTER_ARTISAN_PROFESSIONAL_DATA_${ActionType.Rejected}`,

  REGISTER_ARTISAN_EDUCATIONAL_DATA: 'REGISTER_ARTISAN_EDUCATIONAL_DATA',
  REGISTER_ARTISAN_EDUCATIONAL_DATA_PENDING: `REGISTER_ARTISAN_EDUCATIONAL_DATA_${ActionType.Pending}`,
  REGISTER_ARTISAN_EDUCATIONAL_DATA_FULFILLED: `REGISTER_ARTISAN_EDUCATIONAL_DATA_${ActionType.Fulfilled}`,
  REGISTER_ARTISAN_EDUCATIONAL_DATA_REJECTED: `REGISTER_ARTISAN_EDUCATIONAL_DATA_${ActionType.Rejected}`,

  REGISTER_ARTISAN_REPRESENTANT_DATA: 'REGISTER_ARTISAN_REPRESENTANT_DATA',
  REGISTER_ARTISAN_REPRESENTANT_DATA_PENDING: `REGISTER_ARTISAN_REPRESENTANT_DATA${ActionType.Pending}`,
  REGISTER_ARTISAN_REPRESENTANT_DATA_FULFILLED: `REGISTER_ARTISAN_REPRESENTANT_DATA${ActionType.Fulfilled}`,
  REGISTER_ARTISAN_REPRESENTANT_DATA_REJECTED: `REGISTER_ARTISAN_REPRESENTANT_DATA${ActionType.Rejected}`,


  UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA: 'UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA',
  UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA_PENDING: `UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA_${ActionType.Pending}`,
  UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA_FULFILLED: `UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA_${ActionType.Fulfilled}`,
  UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA_REJECTED: `UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA_${ActionType.Rejected}`,

  UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA: 'UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA',
  UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA_PENDING: `UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA_${ActionType.Pending}`,
  UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA_FULFILLED: `UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA_${ActionType.Fulfilled}`,
  UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA_REJECTED: `UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA_${ActionType.Rejected}`,

  UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA: 'UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA',
  UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA_PENDING: `UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA_${ActionType.Pending}`,
  UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA_FULFILLED: `UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA_${ActionType.Fulfilled}`,
  UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA_REJECTED: `UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA_${ActionType.Rejected}`,

  UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA: 'UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA',
  UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA_PENDING: `UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA${ActionType.Pending}`,
  UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA_FULFILLED: `UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA${ActionType.Fulfilled}`,
  UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA_REJECTED: `UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA${ActionType.Rejected}`,

  REMOVE_ARTISAN_FILE: 'REMOVE_ARTISAN_FILE',
  REMOVE_ARTISAN_FILE_PENDING: `REMOVE_ARTISAN_FILE_${ActionType.Pending}`,
  REMOVE_ARTISAN_FILE_FULFILLED: `REMOVE_ARTISAN_FILE_${ActionType.Fulfilled}`,
  REMOVE_ARTISAN_FILE_REJECTED: `REMOVE_ARTISAN_FILE_${ActionType.Rejected}`,

  UPLOAD_ARTISAN_FILE: 'UPLOAD_ARTISAN_FILE',
  UPLOAD_ARTISAN_FILE_PENDING: `UPLOAD_ARTISAN_FILE_${ActionType.Pending}`,
  UPLOAD_ARTISAN_FILE_FULFILLED: `UPLOAD_ARTISAN_FILE_${ActionType.Fulfilled}`,
  UPLOAD_ARTISAN_FILE_REJECTED: `UPLOAD_ARTISAN_FILE_${ActionType.Rejected}`,

  GET_GLOBAL_PARAMS: 'GET_GLOBAL_PARAMS',
  GET_GLOBAL_PARAMS_PENDING: `GET_GLOBAL_PARAMS_${ActionType.Pending}`,
  GET_GLOBAL_PARAMS_FULFILLED: `GET_GLOBAL_PARAMS_${ActionType.Fulfilled}`,
  GET_GLOBAL_PARAMS_REJECTED: `GET_GLOBAL_PARAMS_${ActionType.Rejected}`,

  GET_DOCUMENT_TYPES: 'GET_DOCUMENT_TYPES',
  GET_DOCUMENT_TYPES_PENDING: `GET_DOCUMENT_TYPES_${ActionType.Pending}`,
  GET_DOCUMENT_TYPES_FULFILLED: `GET_DOCUMENT_TYPES_${ActionType.Fulfilled}`,
  GET_DOCUMENT_TYPES_REJECTED: `GET_DOCUMENT_TYPES_${ActionType.Rejected}`,

  GET_ARTISANT_BY_ID: 'GET_ARTISANT_BY_ID',
  GET_ARTISANT_BY_ID_PENDING: `GET_ARTISANT_BY_ID_${ActionType.Pending}`,
  GET_ARTISANT_BY_ID_FULFILLED: `GET_ARTISANT_BY_ID_${ActionType.Fulfilled}`,
  GET_ARTISANT_BY_ID_REJECTED: `GET_ARTISANT_BY_ID_${ActionType.Rejected}`,

  FINALIZE_SUBSCRIPTION: 'FINALIZE_SUBSCRIPTION',
  FINALIZE_SUBSCRIPTION_PENDING: `FINALIZE_SUBSCRIPTION_TYPES_${ActionType.Pending}`,
  FINALIZE_SUBSCRIPTION_TYPES_FULFILLED: `FINALIZE_SUBSCRIPTION_TYPES_${ActionType.Fulfilled}`,
  FINALIZE_SUBSCRIPTION_TYPES_REJECTED: `FINALIZE_SUBSCRIPTION_TYPES_${ActionType.Rejected}`,

  GET_ARTISANT_FILE: 'GET_ARTISANT_FILE',
  GET_ARTISANT_FILE_PENDING: `GET_ARTISANT_FILE_${ActionType.Pending}`,
  GET_ARTISANT_FILE_FULFILLED: `GET_ARTISANT_FILE_${ActionType.Fulfilled}`,
  GET_ARTISANT_FILE_REJECTED: `GET_ARTISANT_FILE_${ActionType.Rejected}`,

  GET_ARTISANT_FILES_LIST: 'GET_ARTISANT_FILES_LIST',
  GET_ARTISANT_FILES_LIST_PENDING: `GET_ARTISANT_FILES_LIST_${ActionType.Pending}`,
  GET_ARTISANT_FILES_LIST_FULFILLED: `GET_ARTISANT_FILES_LIST_${ActionType.Fulfilled}`,
  GET_ARTISANT_FILES_LIST_REJECTED: `GET_ARTISANT_FILES_LIST_${ActionType.Rejected}`,
};

export default actionTypes;
