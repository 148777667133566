import types from './ActionTypes';

const initialeState = {
  loading: false,
  success: "",
  error: "",
  deleteArtisanAccounts: [],
};

const mapDataToDeleteArtisanAccounts = data => {
  const artisanList = [];
  return data.map(demandeSupp => {
    return {
      key: demandeSupp.id,
      artisanId: demandeSupp.artisan_id,
      nom: demandeSupp.nom,
      prenom: demandeSupp.prenom,
      telephone: demandeSupp.telephone,
      nom_cooperative: demandeSupp.nom_cooperative,
      nom_entreprise: demandeSupp.nom_entreprise,
      artisanTypeFR: demandeSupp.artisan_type.labels.FR,
      artisanTypeAR: demandeSupp.artisan_type.labels.AR,
      cnie: demandeSupp.cnie,
      statut: demandeSupp.statut,
      motif: demandeSupp.motif,
      motifRejet: demandeSupp.motif_rejet,
      createdAt: demandeSupp.created_at,
    }
    // )
  })
  // return artisanList;
}

function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_DELETE_ACCOUNT_MESSAGES: {
      return {
        ...state,
        error: '',
      };
    }

    case types.HANDLE_DELETE_ACCOUNT_FULFILLED: {
      return {
        ...state,
        loading: false,
        success: "Artisan suppression avec success",
        error: ""
      };
    }

    case types.HANDLE_DELETE_ACCOUNT_REJECTED: {
      return {
        ...state,
        loading: false,
        success: "",
        error: action.payload.response.data.code
      };
    }

    case types.HANDLE_DELETE_ACCOUNT_PENDING: {
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      };
    }

    case types.SEARCH_DELETE_ACCOUNT_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        success: "Artisan suppression avec success",
        deleteArtisanAccounts: mapDataToDeleteArtisanAccounts(data),
        error: ""
      };
    }


    case types.UPDATE_ARTISANT_DEMANDE_SUPP_LIST: {
      const suppId = action.payload;

      return {
        ...state,
        deleteArtisanAccounts: state.deleteArtisanAccounts.filter( supp => supp.key !== suppId)
      };
    }

    case types.SEARCH_DELETE_ACCOUNT_REJECTED: {
      return {
        ...state,
        loading: false,
        success: "",
        error: "erreur"
      };
    }

    case types.SEARCH_DELETE_ACCOUNT_PENDING: {
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      };
    }





    default:
      return state;
  }
}

export default reducer;
