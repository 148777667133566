import React from "react"
import FR from './../../assets/fr.png';
import AR from './../../assets/ar.png';
import { changeAppLanguage } from './../../modules/login/Action';
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LanguageSelector = props => {

    const { i18n } = useTranslation();
    const curerntLAnguage = localStorage.getItem('i18nextLng');
    const dispatch = useDispatch();
    const language = useSelector(state => state.loginReducer?.language);

    const changeAppLanguageTo = language => {
        dispatch(changeAppLanguage(language));
        i18n.changeLanguage(language);
        window.location.reload();
    }

    return (
        <Select defaultValue={curerntLAnguage} shape="round" size={"large"} style={{ width: 120, borderRadius: "32px" }} onChange={val => changeAppLanguageTo(val)}>
            <Select.Option value='fr' ><img src={FR} style={{ marginLeft: "7px", marginRight: "7px", }} />{'FR'}</Select.Option>
            <Select.Option value='ar' ><img src={AR} style={{ marginLeft: "7px", marginRight: "7px", }} />{'AR'}</Select.Option>
        </Select>
    );
}

export default LanguageSelector;