import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Alert } from "antd";
import { InputPasswordWithDoubleLabelAndConfirmation, InputComplexePasswordWithDoubleLabelAndConfirmation, InputPasswordWithDoubleLabel } from '../../utilities/FormFields/FormFields'
import { getGlobalParam } from '../GlobalParams/Action';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { changerPassword, clearChangerPassword } from '../login/Action';
import Title from 'antd/lib/typography/Title';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Content } from 'antd/lib/layout/layout';
import SpinLoader from '../../component/SpinLoader/SpinLaoder';


const ChangerPasse = props => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [globalParams, setGlobalParams] = useState({});
    const [forceComplexePassword, setForceComplexePassword] = useState(false);

    const params = useSelector(state => state.globalParamReducer?.params);

    useEffect(() => {
        props.clearChangerPassword();
        dispatch(getGlobalParam())
            .then((res) => {
                console.log("res global params");
                console.log(res); 
                //setGlobalParams(res.value.data?res.value.data.data.settings:[]);
                var settings = res.value.data?res.value.data.data.settings:[];
                console.log('settings')
                console.log(settings)
                settings = settings.filter(e => e.code == 'FORCE_COMPLEXE_PASSWORD_ON_RNA_AGENT');
                if(settings.length > 0) settings = settings[0];
                var forceComplexePassword = (settings && typeof settings.value === 'string' && settings.value.toLowerCase()=='true')?true:false;
                setForceComplexePassword(forceComplexePassword)
                setLoading(false);
                
            })
            .catch((err) => setLoading(false));
    }, []);


    const [form] = Form.useForm();
    const onFinish = (values) => {
        const { userId } = props;
        props.changerPassword(userId, values);
    }

    const { changePassword } = props;

    return (<>
        { loading ? <SpinLoader /> : <Content>
            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }} align="bottom" >
                <Title level={3}>{t("change-password.titre")}</Title>
            </Row>

            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '20px' }} align="bottom" >

                {!isEmpty(changePassword.error) &&
                    < Row justify="center" style={{ padding: '10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={t(changePassword.error)}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {!isEmpty(changePassword.success) &&
                    < Row justify="center" style={{ padding: '10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                message={t(changePassword.success)}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }
                <div>
                </div>
                <hr/>

                <Col span={20} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }}>
                    <Form
                        form={form}
                        name="change-password-form"
                        onFinish={onFinish}
                        style={{ width: '100%' }}
                        size='middle'
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        scrollToFirstError
                    >
                        <Col span={24}>
                            <InputPasswordWithDoubleLabel
                                name="old_password"
                                label={t("change-password.form.ancien-password.label")}
                                placeholder={t("change-password.form.ancien-password.placeholder")}
                                errorMessage={t("change-password.form.ancien-password.error-message")} />
                            {forceComplexePassword == true &&
                            <InputComplexePasswordWithDoubleLabelAndConfirmation
                                getFieldValue={form.getFieldValue}
                                type="password"
                                name="new_password"
                                confirmationName="new_password_confirmation"
                                passwordLabel={t("change-password.form.nouveau-password.label")}
                                repeatPasswordLabel={t("change-password.form.nouveau-password-repeter.label")}
                                passwordPlaceholder={t("change-password.form.nouveau-password.placeholder")}
                                repeatPasswordPlaceholder={t("change-password.form.nouveau-password-repeter.placeholder")}
                                errorMessage={t("change-password.form.nouveau-password-repeter.error-message")} />
                            }
                            {forceComplexePassword == false && 
                            <InputPasswordWithDoubleLabelAndConfirmation
                                getFieldValue={form.getFieldValue}
                                type="password"
                                name="new_password"
                                confirmationName="new_password_confirmation"
                                passwordLabel={t("change-password.form.nouveau-password.label")}
                                repeatPasswordLabel={t("change-password.form.nouveau-password-repeter.label")}
                                passwordPlaceholder={t("change-password.form.nouveau-password.placeholder")}
                                repeatPasswordPlaceholder={t("change-password.form.nouveau-password-repeter.placeholder")}
                                errorMessage={t("change-password.form.nouveau-password-repeter.error-message")} />
                            }
                        </Col>

                        <Col type='flex' span={12} style={{ textAlign: 'right', padding: '21px', justifyContent: 'space-between' }} >
                            <Button shape="round" type="primary" htmlType="submit" style={{ width: '100%' }}>
                                {t("change-password.form.submit.label")}
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row >
        </Content>
        }
    </>)

}

const mapStateToProps = (state) => {
    return {
        userId: state.loginReducer.user?.id,
        changePassword: state.loginReducer.changePassword,
    };
};

const mapDispatchToProps = (dispatch) => ({
    changerPassword: (userId, values) => dispatch(changerPassword(userId, values)),
    clearChangerPassword: () => dispatch(clearChangerPassword())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangerPasse);