import types from './ActionTypes';

const initialeState = {
    titleLabelsFR: [],
    titleLabelsAR: [],
    loading: true,
    errorMessage: "",
};


function reducer(state = initialeState, action) {
    switch (action.type) {
        case types.MINISTRE_TITLE_PENDING: {
            return {
              ...state,
              loading: true,
              errorMessage: ""
            };
          }
          
          case types.MINISTRE_TITLE_FULFILLED: {
            const data = action.payload.data.data;
            return {
              ...state,
              loading: false,
              titleLabelsFR: data.FR,
              titleLabelsAR: data.AR,
              errorMessage: ""
            };
          }

          case types.MINISTRE_TITLE_REJECTED: {
            const { message } = action.payload.response ? action.payload.response.data: 'undefined';
            return {
              ...state,
              loading: false,
              errorMessage: message
            };
          }

          default:
            return state;
    }
}
      
export default reducer;