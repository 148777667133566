import { ActionType } from 'redux-promise-middleware';


export default {
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  CREATE_ACCOUNT_PENDING: `CREATE_ACCOUNT_${ActionType.Pending}`,
  CREATE_ACCOUNT_FULFILLED: `CREATE_ACCOUNT_${ActionType.Fulfilled}`,
  CREATE_ACCOUNT_REJECTED: `CREATE_ACCOUNT_${ActionType.Rejected}`,

  VALIDATE_OTP: 'VALIDATE_OTP',
  VALIDATE_OTP_PENDING: `VALIDATE_OTP_${ActionType.Pending}`,
  VALIDATE_OTP_FULFILLED: `VALIDATE_OTP_${ActionType.Fulfilled}`,
  VALIDATE_OTP_REJECTED: `VALIDATE_OTP_${ActionType.Rejected}`,

  REGENERATE_OTP: 'REGENERATE_OTP',
  REGENERATE_OTP_PENDING: `REGENERATE_OTP_${ActionType.Pending}`,
  REGENERATE_OTP_FULFILLED: `REGENERATE_OTP_${ActionType.Fulfilled}`,
  REGENERATE_OTP_REJECTED: `REGENERATE_OTP_${ActionType.Rejected}`,

  DOWNLOAD_PRIVACY_POLICY: 'DOWNLOAD_PRIVACY_POLICY',
  DOWNLOAD_PRIVACY_POLICY_PENDING: `DOWNLOAD_PRIVACY_POLICY_${ActionType.Pending}`,
  DOWNLOAD_PRIVACY_POLICY_FULFILLED: `DOWNLOAD_PRIVACY_POLICY_${ActionType.Fulfilled}`,
  DOWNLOAD_PRIVACY_POLICY_REJECTED: `DOWNLOAD_PRIVACY_POLICY_${ActionType.Rejected}`,

  CHANGE_OPERATEUR: 'CHANGE_OPERATEUR',
  CHANGE_OPERATEUR_PENDING: `CHANGE_OPERATEUR_${ActionType.Pending}`,
  CHANGE_OPERATEUR_FULFILLED: `CHANGE_OPERATEUR_${ActionType.Fulfilled}`,
  CHANGE_OPERATEUR_REJECTED: `CHANGE_OPERATEUR_${ActionType.Rejected}`,

  LOAD_DGSN_AUTH_INFOS: 'LOAD_DGSN_AUTH_INFOS',

  CLEAR_ERROR_MESSAGE: 'CLEAR_ERROR_MESSAGE',
};
