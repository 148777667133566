import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_ACTIVITE: 'GET_ACTIVITE',
    GET_ACTIVITE_PENDING: `GET_ACTIVITE_${ActionType.Pending}`,
    GET_ACTIVITE_FULFILLED: `GET_ACTIVITE_${ActionType.Fulfilled}`,
    GET_ACTIVITE_REJECTED: `GET_ACTIVITE_${ActionType.Rejected}`,

    ADD_ACTIVITE_: 'ADD_ACTIVITE',
    ADD_ACTIVITE_PENDING: `ADD_ACTIVITE_${ActionType.Pending}`,
    ADD_ACTIVITE_FULFILLED: `ADD_ACTIVITE_${ActionType.Fulfilled}`,
    ADD_ACTIVITE_REJECTED: `ADD_ACTIVITE_${ActionType.Rejected}`,

    UPDATE_ACTIVITE: 'UPDATE_ACTIVITE',
    UPDATE_ACTIVITE_PENDING: `UPDATE_ACTIVITE_${ActionType.Pending}`,
    UPDATE_ACTIVITE_FULFILLED: `UPDATE_ACTIVITE_${ActionType.Fulfilled}`,
    UPDATE_ACTIVITE_REJECTED: `UPDATE_ACTIVITE_${ActionType.Rejected}`,

    GET_ACTIVITE_BRANCHE: 'GET_ACTIVITE_BRANCHE',
    GET_ACTIVITE_BRANCHE_PENDING: `GET_ACTIVITE_BRANCHE_${ActionType.Pending}`,
    GET_ACTIVITE_BRANCHE_FULFILLED: `GET_ACTIVITE_BRANCHE_${ActionType.Fulfilled}`,
    GET_ACTIVITE_BRANCHE_REJECTED: `GET_ACTIVITE_BRANCHE_${ActionType.Rejected}`,

    ADD_ACTIVITE_BRANCHE: 'ADD_ACTIVITE_BRANCHE',
    ADD_ACTIVITE_BRANCHE_PENDING: `ADD_ACTIVITE_BRANCHE_${ActionType.Pending}`,
    ADD_ACTIVITE_BRANCHE_FULFILLED: `ADD_ACTIVITE_BRANCHE_${ActionType.Fulfilled}`,
    ADD_ACTIVITE_BRANCHE_REJECTED: `ADD_ACTIVITE_BRANCHE_${ActionType.Rejected}`,

    UPDATE_ACTIVITE_BRANCHE_: 'UPDATE_ACTIVITE_BRANCHE',
    UPDATE_ACTIVITE_BRANCHE_PENDING: `UPDATE_ACTIVITE_BRANCHE_${ActionType.Pending}`,
    UPDATE_ACTIVITE_BRANCHE_FULFILLED: `UPDATE_ACTIVITE_BRANCHE_${ActionType.Fulfilled}`,
    UPDATE_ACTIVITE_BRANCHE_REJECTED: `UPDATE_ACTIVITE_BRANCHE_${ActionType.Rejected}`,

    GET_ACTIVITE_FILIERE: 'GET_ACTIVITE_FILIERE',
    GET_ACTIVITE_FILIERE_PENDING: `GET_ACTIVITE_FILIERE_${ActionType.Pending}`,
    GET_ACTIVITE_FILIERE_FULFILLED: `GET_ACTIVITE_FILIERE_${ActionType.Fulfilled}`,
    GET_ACTIVITE_FILIERE_REJECTED: `GET_ACTIVITE_FILIERE_${ActionType.Rejected}`,

    ADD_ACTIVITE_FILIERE: 'ADD_ACTIVITE_FILIERE',
    ADD_ACTIVITE_FILIERE_PENDING: `ADD_ACTIVITE_FILIERE_${ActionType.Pending}`,
    ADD_ACTIVITE_FILIERE_FULFILLED: `ADD_ACTIVITE_FILIERE_${ActionType.Fulfilled}`,
    ADD_ACTIVITE_FILIERE_REJECTED: `ADD_ACTIVITE_FILIERE_${ActionType.Rejected}`,

    UPDATE_ACTIVITE_FILIERE: 'UPDATE_ACTIVITE_FILIERE',
    UPDATE_ACTIVITE_FILIERE_PENDING: `UPDATE_ACTIVITE_FILIERE_${ActionType.Pending}`,
    UPDATE_ACTIVITE_FILIERE_FULFILLED: `UPDATE_ACTIVITE_FILIERE_${ActionType.Fulfilled}`,
    UPDATE_ACTIVITE_FILIERE_REJECTED: `UPDATE_ACTIVITE_FILIERE_${ActionType.Rejected}`,

};

export default ActionTypes;
