import types from './ActionTypes';
import Api from '../../utilities/services/Api';

export const authentication = (payload) => {
  return ({
    type: types.AUTHENTICATION,
    payload: Api.user.authentication(payload),
  })
};

export const forgotPassword = (payload) => {
  return ({
    type: types.FORGOT_PASSWORD,
    payload: Api.accounts.forgotPassword(payload),
  })
};

export const clearErrorMessage = () => {
  return ({
    type: types.CLEAR_ERROR_MESSAGE,
  })
};
export const changeAppLanguage = (newLanguage) => {
  return ({
    type: types.CHANGE_APP_LANGUAGE,
    payload: newLanguage
  })
};

export const finalizeArtisanStatus = () => {
  return ({
    type: types.CHANGE_ARTISAN_STATUS,
  })
};

export const updateArtisant = (artisanId, payload) => {
  return ({
    type: types.UPDATE_ARTISAN,
    payload: Api.accounts.updateArtisant(artisanId, payload),
  })
};

export const changerPassword = (userId, payload) => {
  return ({
    type: types.CHANGE_PASSWORD,
    payload: Api.user.changerPassword(userId, payload),
  })
};

export const clearChangerPassword = () => {
  return ({
    type: types.CLEAR_CHANGE_PASSWORD,
  })
};