import React from "react";
import { Result, Button, Row } from 'antd';
import { useTranslation } from "react-i18next";

const NewAccountaResult = ({ registration }) => {
    const { t } = useTranslation();
    return (
        <Result
            status="success"
            title={<Row justify="center"><span>{t('new-account.form.new-account-result.title')}</span></Row>}
            subTitle={<Row justify="center"><span>{t('new-account.form.new-account-result.subTitle')}</span></Row>}
            extra={[
                <Button shape="round" type="primary" key="console" onClick={() => registration()} style={{ width: '50%' }}>
                    {<Row span={10} justify="center"><span> {t('new-account.form.new-account-result.action')}</span></Row>}
                </Button>
            ]}
        />
    )
}

export default NewAccountaResult;