import types from './ActionTypes';

const initialeState = {
  loading: false,
  artisan: {},
  error: false
}

function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.GET_ATTESTATION_ARTISAN_PENDING: {
      return {
        loading: true,
        artisan: {},
        error: false
      };
    }

    case types.GET_ATTESTATION_ARTISAN_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        artisan: data,
        error: false
      };
    }

    case types.GET_ATTESTATION_ARTISAN_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        artisan: {},
        loading: false,
        error: true,
      };
    }

    default:
      return state;
  }
}

export default reducer;
