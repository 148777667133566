import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
    RESET_MESSAGES_ARTISAN_DETAIL: 'RESET_MESSAGES_ARTISAN_DETAIL',

    GET_ARTISAN_BY_SEARCH_CRITERIA: 'GET_ARTISAN_BY_SEARCH_CRITERIA',
    GET_ARTISAN_BY_SEARCH_CRITERIA_PENDING: `GET_ARTISAN_BY_SEARCH_CRITERIA_${ActionType.Pending}`,
    GET_ARTISAN_BY_SEARCH_CRITERIA_FULFILLED: `GET_ARTISAN_BY_SEARCH_CRITERIA_${ActionType.Fulfilled}`,
    GET_ARTISAN_BY_SEARCH_CRITERIA_REJECTED: `GET_ARTISAN_BY_SEARCH_CRITERIA_${ActionType.Rejected}`,

    RADIAT_ARTISAN: 'RADIAT_ARTISAN',
    RADIAT_ARTISAN_PENDING: `RADIAT_ARTISAN_${ActionType.Pending}`,
    RADIAT_ARTISAN_FULFILLED: `RADIAT_ARTISAN_${ActionType.Fulfilled}`,
    RADIAT_ARTISAN_REJECTED: `RADIAT_ARTISAN_${ActionType.Rejected}`,
};

export default ActionTypes;
