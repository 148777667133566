import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_REGIONS: 'GET_REGIONS',
    GET_REGIONS_PENDING: `GET_REGIONS_${ActionType.Pending}`,
    GET_REGIONS_FULFILLED: `GET_REGIONS_${ActionType.Fulfilled}`,
    GET_REGIONS_REJECTED: `GET_REGIONS_${ActionType.Rejected}`,

    ADD_REGION: 'ADD_REGION',
    ADD_REGION_PENDING: `ADD_REGION_${ActionType.Pending}`,
    ADD_REGION_FULFILLED: `ADD_REGION_${ActionType.Fulfilled}`,
    ADD_REGION_REJECTED: `ADD_REGION_${ActionType.Rejected}`,

    UPDATE_REGION: 'UPDATE_REGION',
    UPDATE_REGION_PENDING: `UPDATE_REGION_${ActionType.Pending}`,
    UPDATE_REGION_FULFILLED: `UPDATE_REGION_${ActionType.Fulfilled}`,
    UPDATE_REGION_REJECTED: `UPDATE_REGION_${ActionType.Rejected}`,

    GET_VILLES: 'GET_VILLES',
    GET_VILLES_PENDING: `GET_VILLES_${ActionType.Pending}`,
    GET_VILLES_FULFILLED: `GET_VILLES_${ActionType.Fulfilled}`,
    GET_VILLES_REJECTED: `GET_VILLES_${ActionType.Rejected}`,

    ADD_VILLE: 'ADD_VILLE',
    ADD_VILLE_PENDING: `ADD_VILLE_${ActionType.Pending}`,
    ADD_VILLE_FULFILLED: `ADD_VILLE_${ActionType.Fulfilled}`,
    ADD_VILLE_REJECTED: `ADD_VILLE_${ActionType.Rejected}`,

    UPDATE_VILLE: 'UPDATE_VILLE',
    UPDATE_VILLE_PENDING: `UPDATE_VILLE_${ActionType.Pending}`,
    UPDATE_VILLE_FULFILLED: `UPDATE_VILLE_${ActionType.Fulfilled}`,
    UPDATE_VILLE_REJECTED: `UPDATE_VILLE_${ActionType.Rejected}`,

    GET_PROVINCES: 'GET_PROVINCES',
    GET_PROVINCES_PENDING: `GET_PROVINCES_${ActionType.Pending}`,
    GET_PROVINCES_FULFILLED: `GET_PROVINCES_${ActionType.Fulfilled}`,
    GET_PROVINCES_REJECTED: `GET_PROVINCES_${ActionType.Rejected}`,

    ADD_PROVINCE: 'ADD_PROVINCE',
    ADD_PROVINCE_PENDING: `ADD_PROVINCE_${ActionType.Pending}`,
    ADD_PROVINCE_FULFILLED: `ADD_PROVINCE_${ActionType.Fulfilled}`,
    ADD_PROVINCE_REJECTED: `ADD_PROVINCE_${ActionType.Rejected}`,

    UPDATE_PROVINCE: 'UPDATE_PROVINCE',
    UPDATE_PROVINCE_PENDING: `UPDATE_PROVINCE_${ActionType.Pending}`,
    UPDATE_PROVINCE_FULFILLED: `UPDATE_PROVINCE_${ActionType.Fulfilled}`,
    UPDATE_PROVINCE_REJECTED: `UPDATE_PROVINCE_${ActionType.Rejected}`,

};

export default ActionTypes;
