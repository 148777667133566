import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  users: [],
  user: {}
};

const mapDataToUsers = data => {
  const userList = [];
  data.map(user => {
    userList.push(
      {
        key: user.id,
        nom: user.user_info.firstName,
        prenom: user.user_info.lastName,
        login: user.login,
        email: user.user_info?.email,
        region_label: user.user_info?.region?.labels?.FR,
        region: user.user_info?.region?.id,
        province: user.user_provinces?.map(p => p.labels.FR + " "),
        profile: user.profiles[0].description,
        telephone: user.telephone,
        operateur: user.operateur
      })
  })
  return userList;
}
const mapDataToUser = user => {
  return {
    key: user.id,
    nom: user.user_info.firstName,
    prenom: user.user_info.lastName,
    login: user.login,
    email: user.user_info?.email,
    region_label: user.user_info?.region?.labels?.FR,
    region: user.user_info?.region,
    province: user.user_provinces,
    profile: user.profiles[0].id,
    niveau: user.profiles[0].niveau,
    telephone: user.telephone,
    operateur: user.operateur
  };
}


function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.RESET_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case types.GET_USERS_BY_SEARCH_CRITERIA_PENDING: {
      return {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
    }

    case types.GET_USERS_BY_SEARCH_CRITERIA_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        users: mapDataToUsers(data),
        loading: false,
      };
    }

    case types.GET_USERS_BY_SEARCH_CRITERIA_REJECTED: {
      return {
        ...state,
        error: action.payload.data.data,
        success: "",
        loading: false,
      };
    }

    case types.ADD_USER_PENDING: {
      return {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
    }

    case types.ADD_USER_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "ADD_USER_FULFILLED",
        loading: false,
      };
    }

    case types.ADD_USER_REJECTED: {
      return {
        ...state,
        error: "ADD_USER_REJECTED",
        success: "",
        loading: false,
      };
    }

    case types.GET_USER_BY_ID_PENDING: {
      return {
        ...state,
        error: "",
        success: "",
        loading: true,
        user: {}
      };
    }

    case types.GET_USER_BY_ID_FULFILLED: {
      const user = action.payload.data.data;

      return {
        ...state,
        error: "",
        success: "",
        loading: false,
        user: mapDataToUser(user)
      };
    }

    case types.GET_USER_BY_ID_REJECTED: {
      return {
        ...state,
        error: action.payload.response.data.code,
        success: "",
        loading: false,
        user: {}
      };
    }

    case types.UPDATE_USER_PENDING: {
      return {
        ...state,
        error: "",
        success: "",
        loading: true,
        user: {}
      };
    }

    case types.UPDATE_USER_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "UPDATE_USER_FULFILLED",
        loading: false,
        user: {}
      };
    }

    case types.UPDATE_USER_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
        user: {}
      };
    }
    case types.DELETE_USER_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "DELETE_USER_FULFILLED",
        loading: false,
        user: {}
      };
    }

    case types.DELETE_USER_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
        user: {}
      };
    }
    case types.DEACTIVATE_USER_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "DEACTIVATE_USER_FULFILLED",
        loading: false,
        user: {}
      };
    }

    case types.DEACTIVATE_USER_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "DEACTIVATE_USER_REJECTED",
        loading: false,
        user: {}
      };
    }



    default:
      return state;
  }
}

export default reducer;
