import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_PUBLIC_PRIVE: 'GET_PUBLIC_PRIVE',
    GET_PUBLIC_PRIVE_PENDING: `GET_PUBLIC_PRIVE_${ActionType.Pending}`,
    GET_PUBLIC_PRIVE_FULFILLED: `GET_PUBLIC_PRIVE_${ActionType.Fulfilled}`,
    GET_PUBLIC_PRIVE_REJECTED: `GET_PUBLIC_PRIVE_${ActionType.Rejected}`,

    GET_DEPARTEMENT_FORMATION: 'GET_DEPARTEMENT_FORMATION',
    GET_DEPARTEMENT_FORMATION_PENDING: `GET_DEPARTEMENT_FORMATION_${ActionType.Pending}`,
    GET_DEPARTEMENT_FORMATION_FULFILLED: `GET_DEPARTEMENT_FORMATION_${ActionType.Fulfilled}`,
    GET_DEPARTEMENT_FORMATION_REJECTED: `GET_DEPARTEMENT_FORMATION_${ActionType.Rejected}`,

    GET_ETABLISSEMENT: 'GET_ETABLISSEMENT',
    GET_ETABLISSEMENT_PENDING: `GET_ETABLISSEMENT_${ActionType.Pending}`,
    GET_ETABLISSEMENT_FULFILLED: `GET_ETABLISSEMENT_${ActionType.Fulfilled}`,
    GET_ETABLISSEMENT_REJECTED: `GET_ETABLISSEMENT_${ActionType.Rejected}`,

    ADD_ETABLISSEMENT: 'ADD_ETABLISSEMENT',
    ADD_ETABLISSEMENT_PENDING: `ADD_ETABLISSEMENT_${ActionType.Pending}`,
    ADD_ETABLISSEMENT_FULFILLED: `ADD_ETABLISSEMENT_${ActionType.Fulfilled}`,
    ADD_ETABLISSEMENT_REJECTED: `ADD_ETABLISSEMENT_${ActionType.Rejected}`,

    UPDATE_ETABLISSEMENT: 'UPDATE_ETABLISSEMENT',
    UPDATE_ETABLISSEMENT_PENDING: `UPDATE_ETABLISSEMENT_${ActionType.Pending}`,
    UPDATE_ETABLISSEMENT_FULFILLED: `UPDATE_ETABLISSEMENT_${ActionType.Fulfilled}`,
    UPDATE_ETABLISSEMENT_REJECTED: `UPDATE_ETABLISSEMENT_${ActionType.Rejected}`,
};

export default ActionTypes;
