import React, { useEffect } from 'react';
import { Row, Col, Button, Alert, Typography } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch, connect, useSelector } from 'react-redux';
import { downloadAttestation, clearMessages } from './Action'
import Title from 'antd/lib/typography/Title';
import SpinLoader from '../../../component/SpinLoader/SpinLaoder';
import { useTranslation } from 'react-i18next';
import Utils from '../../../utilities/Utils/Utils';
const { Text } = Typography;


const Attestation = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const statut = useSelector(state => state.loginReducer?.artisan?.statut);
    const artisanId = useSelector(state => state.loginReducer?.artisan?.artisan_id);
    const loading = useSelector(state => state.certificationReducer?.loading);
    const success = useSelector(state => state.certificationReducer?.success);
    const error = useSelector(state => state.certificationReducer?.error);
    const { ARTISANT_STATUS } = Utils;

    useEffect(() => {
        dispatch(clearMessages())
    }, []);

    const download = () => {
        dispatch(downloadAttestation(artisanId))
    }


    return (<>
        {loading ? <SpinLoader /> : <>
            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }} align="bottom" >
                <Title level={3}>{t("attestationExercice.titre")}</Title>
            </Row>

            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '20px' }} align="bottom" >
                {error &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={error}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {success &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={success}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }
                <Col span={18} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', paddingBottom: '30px' }}>
                    <Text>{t("attestationExercice.description")}</Text>
                </Col>
                {
                    statut && statut === ARTISANT_STATUS.VALIDE &&

                    <Col span={20} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }}>
                        <Button shape="round" type="primary" icon={<DownloadOutlined />} size="large" onClick={() => download()}>
                            {t("attestationExercice.submit.label")}
                        </Button>
                    </Col>
                }
            </Row >
        </>}

    </>)

}


export default Attestation;
