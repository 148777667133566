import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
    RESET_MESSAGES: 'RESET_MESSAGES',

    CREATE_RECLAMATION: 'CREATE_RECLAMATION',
    CREATE_RECLAMATION_PENDING: `CREATE_RECLAMATION_${ActionType.Pending}`,
    CREATE_RECLAMATION_FULFILLED: `CREATE_RECLAMATION_${ActionType.Fulfilled}`,
    CREATE_RECLAMATION_REJECTED: `CREATE_RECLAMATION_${ActionType.Rejected}`,

    GET_ALL_RECLAMATIONS_FOR_ARTISAN: 'GET_ALL_RECLAMATIONS_FOR_ARTISAN',
    GET_ALL_RECLAMATIONS_FOR_ARTISAN_PENDING: `GET_ALL_RECLAMATIONS_FOR_ARTISAN_${ActionType.Pending}`,
    GET_ALL_RECLAMATIONS_FOR_ARTISAN_FULFILLED: `GET_ALL_RECLAMATIONS_FOR_ARTISAN_${ActionType.Fulfilled}`,
    GET_ALL_RECLAMATIONS_FOR_ARTISAN_REJECTED: `GET_ALL_RECLAMATIONS_FOR_ARTISAN_${ActionType.Rejected}`,

    GET_ONE_RECLAMATION_FOR_ARTISAN: 'GET_ONE_RECLAMATION_FOR_ARTISAN',
    GET_ONE_RECLAMATION_FOR_ARTISAN_PENDING: `GET_ONE_RECLAMATION_FOR_ARTISAN_${ActionType.Pending}`,
    GET_ONE_RECLAMATION_FOR_ARTISAN_FULFILLED: `GET_ONE_RECLAMATION_FOR_ARTISAN_${ActionType.Fulfilled}`,
    GET_ONE_RECLAMATION_FOR_ARTISAN_REJECTED: `GET_ONE_RECLAMATION_FOR_ARTISAN_${ActionType.Rejected}`,


    GET_ALL_RECLAMATIONS_FOR_AGENT: 'GET_ALL_RECLAMATIONS_FOR_AGENT',
    GET_ALL_RECLAMATIONS_FOR_AGENT_PENDING: `GET_ALL_RECLAMATIONS_FOR_AGENT_${ActionType.Pending}`,
    GET_ALL_RECLAMATIONS_FOR_AGENT_FULFILLED: `GET_ALL_RECLAMATIONS_FOR_AGENT_${ActionType.Fulfilled}`,
    GET_ALL_RECLAMATIONS_FOR_AGENT_REJECTED: `GET_ALL_RECLAMATIONS_FOR_AGENT_${ActionType.Rejected}`,

    GET_ONE_RECLAMATION_FOR_AGENT: 'GET_ONE_RECLAMATION_FOR_AGENT',
    GET_ONE_RECLAMATION_FOR_AGENT_PENDING: `GET_ONE_RECLAMATION_FOR_AGENT_${ActionType.Pending}`,
    GET_ONE_RECLAMATION_FOR_AGENT_FULFILLED: `GET_ONE_RECLAMATION_FOR_AGENT_${ActionType.Fulfilled}`,
    GET_ONE_RECLAMATION_FOR_AGENT_REJECTED: `GET_ONE_RECLAMATION_FOR_AGENT_${ActionType.Rejected}`,

    HANDLE_RECLAMATION: 'HANDLE_RECLAMATION',
    HANDLE_RECLAMATION_PENDING: `HANDLE_RECLAMATION_${ActionType.Pending}`,
    HANDLE_RECLAMATION_FULFILLED: `HANDLE_RECLAMATION_${ActionType.Fulfilled}`,
    HANDLE_RECLAMATION_REJECTED: `HANDLE_RECLAMATION_${ActionType.Rejected}`,
};

export default ActionTypes;