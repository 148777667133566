import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise-middleware';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import hardSet from 'redux-persist/es/stateReconciler/hardSet';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import AlertMiddleware from './middlewares/AlertMiddleware';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducer from './reducer'
import localforage from 'localforage';


const composeEnhancers = composeWithDevTools({});

const middlewares = [thunk, promise, AlertMiddleware, logger];
if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const persistConfig = {
  key: "root",
  storage: localforage,
  // storage,
  stateReconciler: hardSet,
  transforms: [encryptTransform({
    secretKey: 'RNA-@-secret',
  })],
};

export const store = createStore(
  persistReducer(persistConfig, reducer),
  {},
  composeEnhancers(applyMiddleware(...middlewares)),
);

export const persistor = persistStore(store);


export default () => {
  return { store, persistor }
}