import React, { useEffect, useState } from "react"
import { Row, Col, Card, Steps, Spin, Space, Alert, Button } from "antd";
import { createAccount, validateOtp, regenerateOtp, loadDgsnAuthInfos, clearErrorMessage } from './Action';
import { changeAppLanguage } from './../login/Action';
import { SolutionOutlined, FileProtectOutlined, FileAddOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import CreateAccountForm from "./components/CreateAccountForm/CreateAccountForm";
import { useDispatch, useSelector } from "react-redux";
import OptValidator from "./components/OtpValidator/OtpValidator";
import NewAccountaResult from "./components/NewAccountResult/NewAccountaResult";
import { useHistory, useParams } from "react-router";
import Meta from "antd/lib/card/Meta";
import { getGlobalParam } from '../GlobalParams/Action';
import { withRouter } from 'react-router';
import { useTranslation } from "react-i18next";
import "./NewAccount.less"
import Layout from "antd/lib/layout/layout";
import LanguageSelector from "../../component/LanguageSelector/LanguageSelector";
import Logo from "../Logo/Logo";

const { Header, Content, Footer } = Layout;
const { Step } = Steps;

const NewAccount = (props) => {

    const { dgsn_id } = useParams();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [current, setCurrent] = useState(0);
    const [dgsnData, setDgsnData] = useState({});
    const [accountData, setAccountData] = useState({});
    const [userAccount, setUserAccount] = useState({});
    const language = useSelector(state => state.loginReducer?.language);
    const curerntLAnguage = localStorage.getItem('i18nextLng')

    useEffect(() => {
        if (history.location && history.location.state) {
            const { currentFromLogin, phoneNumberFromLogin } = history.location.state;
            if (currentFromLogin && phoneNumberFromLogin) {
                setCurrent(currentFromLogin);
                setAccountData({ ...accountData, phone_number: phoneNumberFromLogin });
                dispatch(regenerateOtp(phoneNumberFromLogin))
            }
        }
        dispatch(clearErrorMessage());
        dispatch(getGlobalParam());

        if(dgsn_id && dgsn_id != '__NOTFOUND'){
            dispatch(loadDgsnAuthInfos(dgsn_id)).then((result) => {
                if('value' in result && 'data' in result.value)
                    setDgsnData(result.value.data)
            });
        }

    }, []);

    const loading = useSelector(state => state.newAccountReducer?.loading)
    const error = useSelector(state => state.newAccountReducer?.error)
    const params = useSelector(state => state.globalParamReducer?.params)

    const changeAppLanguageTo = language => {
        return new Promise(
            (resolve, reject) => dispatch(changeAppLanguage(language)))
            .then(i18n.changeLanguage(language));
    }

    const handleValidateOtp = (formValue) => {
        dispatch(validateOtp({
            phone_number: accountData.phone_number,
            otp: formValue.otp
        })).then((result) => {
            setUserAccount(result.value);
            next();
        });
    }

    const handleRegenerateOtp = () => {
        dispatch(regenerateOtp(accountData.phone_number))
    }

    const handleCreateAccount = (formValue) => {
        if(dgsn_id && dgsn_id != '__NOTFOUND'){
            formValue.dgsn_id = dgsn_id;
        }
        console.log('formValue')
        console.log(formValue)
        dispatch(createAccount(formValue)).then((result) => {
            setAccountData(formValue);
            next()
        });
    }
    const registration = () => {
        dispatch({
            type: 'AUTHENTICATION_FULFILLED',
            payload: userAccount
        })
    }

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };
    const steps = [
        {
            title: t('new-account.steps.step-1.title'),
            description: t('new-account.steps.step-1.description'),
            content: <><CreateAccountForm createAccount={handleCreateAccount} next={next} globalParam={params} dgsnData={dgsnData} /></>,
            icon: <><FileAddOutlined /> </>
        },
        {
            title: t('new-account.steps.step-2.title'),
            description: t('new-account.steps.step-2.description'),
            content: <><OptValidator next={next} prev={prev} accountData={accountData} validateOtp={handleValidateOtp} regenerateOpt={handleRegenerateOtp} /></>,
            icon: <>< SolutionOutlined /></>
        },
        {
            title: t('new-account.steps.step-3.title'),
            description: t('new-account.steps.step-3.description'),
            content: <NewAccountaResult registration={registration} />,
            icon: <><FileProtectOutlined /></>
        }
    ];
    return (

        <Layout style={{ minHeight: '100vh', padding: "20px", }} className="background">
            <Header style={{ background: 'transparent', height: "100%" }}>
                {process.env.REACT_APP_PROFIL === "ARTISAN" &&
                    <Row >
                        <Col span="4" align="middle" style={{ right: 30, position: "absolute", top: "10px" }}>
                            <LanguageSelector />
                        </Col>

                        <Col span="4" align="middle" style={{ left: 30, position: "absolute", top: "10px" }}>
                            <Button type="default" shape="round" size={"large"} onClick={() => window.location.assign(process.env.REACT_APP_PORTAIL_URL)} icon={<ArrowLeftOutlined />}
                                style={{ backgroundColor: "white" }}>
                                {t("go-back")}
                            </Button>
                        </Col>
                    </Row>
                }
                <Row align="middle" justify="center" style={{ minHeight: '8vh', textAlign: "center" }}>
                    <Col span={24} style={{ maxWidth: '85%' }}><Logo height="23px" /></Col>
                    <Col span={24}><h1>{t('new-account.title')}</h1></Col>
                </Row>
                <Row style={{ padding: '40px', minHeight: '10%' }}>
                    <Steps size="default" current={current} direction="horizontal" labelPlacement="horizontal" >
                        {steps.map((item, index) => (
                            <Step style={{ minHeight: '20%' }} key={item.title} title={item.title} disabled={index === 1} icon={item.icon} description={item.description} />
                        ))}
                    </Steps>
                </Row>
            </Header>

            <Spin tip={t('new-account.spin')} spinning={loading} size="large" style={{ height: "100%", paddingBlock: "50px", width: "100%" }} >
                <Content style={{ padding: 24, minHeight: "50%", height: "100%", paddingBlock: "50px", width: "100%" }}>
                    <Row type="flex" justify="center" align="middle" style={{ height: 'auto', flexDirection: 'column', width: "100%" }} >
                        <Space size={"small"} direction='vertical' style={{ width: "100%" }}>
                            <Col span={24} md={24} sm={20} xs={16} type="flex" justify="center" align="center" style={{ width: "100%" }}>
                                <div className="steps-content" style={{ padding: 0, marginTop: "20px", width: "100%" }} >
                                    <Card type="flex" align="middle" className="glassmorphism" >
                                        <Meta title={t('new-account.form.title')} description={t('new-account.form.description')} />
                                        {error &&
                                            <Row justify="center" style={{ padding: '10px 0' }}>
                                                <Col span={16}>
                                                    <Alert
                                                        message={t(error)}
                                                        type="error"
                                                        closable
                                                    />
                                                </Col>
                                            </Row>}
                                        {steps[current].content}
                                    </Card>
                                </div>
                            </Col>

                        </Space>
                    </Row>
                </Content>
            </Spin>
            <Footer style={{ background: 'transparent' }}>
                <Col span={24} type="flex" justify="center" align="center" style={{ fontWeight: '500', color: "white" }} >
                    <span>{t('new-account.footer')}</span>
                </Col>
            </Footer>
        </Layout >
    )

}

export default withRouter(NewAccount);