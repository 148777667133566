import React, { useState } from 'react';
import { useDispatch, } from 'react-redux';
import { Button, Col, Row, Typography, Divider } from 'antd';
import { getGlobalParam } from '../../../../../../GlobalParams/Action';
import { addEntiteAdministrative, updateEntiteAdministrative } from '../../Action'
import { useTranslation } from 'react-i18next';
import { InputWithDoubleLabel, SeletWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import Utils from "../../../../../../../utilities/Utils/Utils";
import SpinLoader from '../../../../../../../component/SpinLoader/SpinLaoder';
const { Title } = Typography;


const ReferentielDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const { handleClose, activeReferenciel, activiteEntiyAdministrative, activiteRegions, action } = props;

    const onFinish = (values) => {
        const { loadDataOrRedirect, setSuccesseMessage, setErrorMessage } = props;
        const payload = {
            entity_type: Utils.REFERENTIEL_ENTITY_TYPES.ENTITE_ADMINISTRATIVE,
            payload: values
        }
        if (action === 'ADD') {
            dispatch(addEntiteAdministrative(payload))
                .then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.entiteAdministrative.add'))
                    loadDataOrRedirect();
                })
                .catch(err => {
                    setErrorMessage(err)
                })
        } else {
            dispatch(updateEntiteAdministrative(activeReferenciel.key, payload))
                .then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.entiteAdministrative.update'))
                    loadDataOrRedirect();
                })
                .catch(err => {
                    setErrorMessage(err)
                })
        }
        handleClose();
    };

    const getInitData = () => {
        return action !== 'ADD' ? activeReferenciel : {};
    }

    const isConsultation = () => {
        return action === 'CONSULTATION';
    }

    const getTitle = () => {
        switch (props.action) {
            case 'CONSULTATION':
                return t('agent.referentiel.consultation.entiteAdministrative');
            case 'UPDATE':
                return t('agent.referentiel.update.entiteAdministrative');
            case 'ADD':
                return t('agent.referentiel.add.entiteAdministrative');
            default:
                break;
        }
    }

    return (
        <>
            {loading ? <SpinLoader /> :
                <Modal visible={true} width={1500} onCancel={handleClose}
                    footer=
                    {!isConsultation() && <>
                        <Button shape="round" key="back" onClick={handleClose}>
                            {t('agent.referentiel.modal.cancel.label')}
                        </Button>
                        <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                            {t('agent.referentiel.modal.submit.label')}
                        </Button>
                    </>}
                >

                    <Form form={form} onFinish={onFinish} initialValues={getInitData()}>
                        <Title level={5} style={{ paddingBottom: '20px' }}>
                            {getTitle()}
                        </Title >
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="label_fr" label={t('agent.referentiel.modal.label-fr.label')} errorMessage={t('agent.referentiel.modal.label-fr.error-message')} disabled={isConsultation()} />
                            </Col>
                            <Divider type="vertical" />
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="label_ar" label={t('agent.referentiel.modal.label-ar.label')} errorMessage={t('agent.referentiel.modal.label-ar.error-message')} disabled={isConsultation()} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <SeletWithDoubleLabel type="text" name="parent" label={t('agent.referentiel.modal.parent.label')} errorMessage={t('agent.referentiel.modal.diplomeDistinct.error-message')} options={activiteEntiyAdministrative} disabled={isConsultation()} required={activeReferenciel.parent} />
                            </Col>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <SeletWithDoubleLabel type="text" name="regionId" label={t('agent.referentiel.modal.diplomeCategorie.label')} errorMessage={t('agent.referentiel.modal.diplomeCategorie.error-message')} options={activiteRegions} disabled={isConsultation()} required={activeReferenciel.regionId} />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ReferentielDetail;
