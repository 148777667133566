import { Col, Row, Alert, Space, Typography } from "antd";
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getAllSettings } from './Action';
import { useTranslation } from "react-i18next";
import './Settings.less'
import SettingsList from "./components/SettingsList/SettingsList";
import SettingsDetail from "./components/SettingsDetail/SettingsDetail";
import SpinLoader from "../../../component/SpinLoader/SpinLaoder";
const { Title } = Typography;

const Settings = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const SettingsData = useSelector(state => state.SettingsReducer?.settingsList);
    const loading = useSelector(state => state.SettingsReducer?.loading);

    const [successeMessage, setSuccesseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [activeSettings, setActiveSettings] = useState({});
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        dispatch(getAllSettings())
    }, []);

    const handleUpdates = (record) => {
        setActiveSettings(record)
        setVisible(true)
    }

    const hideModal = () => {
        setVisible(false);
        setActiveSettings({})
    };

    return (<>
        <Space size="large" direction="vertical" style={{ width: '100%' }}  >
            {loading ? <SpinLoader /> : <>

                <div>
                    <Title span={3} className="aligne-left">
                        {t('agent.settings.title')}
                    </Title>
                </div>
                {errorMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={errorMessage}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {successeMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={successeMessage}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }

                <div className="search-result-list">
                    <SettingsList loadingTable={loading} SettingsData={SettingsData} handleUpdates={handleUpdates} />
                    {visible && <SettingsDetail handleClose={hideModal} activeSettings={activeSettings} setErrorMessage={setErrorMessage} setSuccesseMessage={setSuccesseMessage} />}
                </div>
            </>}
        </Space>
    </>);
}

export default Settings;