import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_PUBLIC_PRIVE: 'GET_PUBLIC_PRIVE',
    GET_PUBLIC_PRIVE_PENDING: `GET_PUBLIC_PRIVE_${ActionType.Pending}`,
    GET_PUBLIC_PRIVE_FULFILLED: `GET_PUBLIC_PRIVE_${ActionType.Fulfilled}`,
    GET_PUBLIC_PRIVE_REJECTED: `GET_PUBLIC_PRIVE_${ActionType.Rejected}`,

    GET_MODE_FORMATION: 'GET_MODE_FORMATION',
    GET_MODE_FORMATION_PENDING: `GET_MODE_FORMATION_${ActionType.Pending}`,
    GET_MODE_FORMATION_FULFILLED: `GET_MODE_FORMATION_${ActionType.Fulfilled}`,
    GET_MODE_FORMATION_REJECTED: `GET_MODE_FORMATION_${ActionType.Rejected}`,

    GET_DIPLOME_DISTINCT: 'GET_DIPLOME_DISTINCT',
    GET_DIPLOME_DISTINCT_PENDING: `GET_DIPLOME_DISTINCT_${ActionType.Pending}`,
    GET_DIPLOME_DISTINCT_FULFILLED: `GET_DIPLOME_DISTINCT_${ActionType.Fulfilled}`,
    GET_DIPLOME_DISTINCT_REJECTED: `GET_DIPLOME_DISTINCT_${ActionType.Rejected}`,

    GET_CATEGORIE_DIPLOME: 'GET_CATEGORIE_DIPLOME',
    GET_CATEGORIE_DIPLOME_PENDING: `GET_CATEGORIE_DIPLOME_${ActionType.Pending}`,
    GET_CATEGORIE_DIPLOME_FULFILLED: `GET_CATEGORIE_DIPLOME_${ActionType.Fulfilled}`,
    GET_CATEGORIE_DIPLOME_REJECTED: `GET_CATEGORIE_DIPLOME_${ActionType.Rejected}`,

    GET_DEPARTEMENT_FORMATION: 'GET_DEPARTEMENT_FORMATION',
    GET_DEPARTEMENT_FORMATION_PENDING: `GET_DEPARTEMENT_FORMATION_${ActionType.Pending}`,
    GET_DEPARTEMENT_FORMATION_FULFILLED: `GET_DEPARTEMENT_FORMATION_${ActionType.Fulfilled}`,
    GET_DEPARTEMENT_FORMATION_REJECTED: `GET_DEPARTEMENT_FORMATION_${ActionType.Rejected}`,

    GET_ETABLISSMENT: 'GET_ETABLISSMENT',
    GET_ETABLISSMENT_PENDING: `GET_ETABLISSMENT_${ActionType.Pending}`,
    GET_ETABLISSMENT_FULFILLED: `GET_ETABLISSMENT_${ActionType.Fulfilled}`,
    GET_ETABLISSMENT_REJECTED: `GET_ETABLISSMENT_${ActionType.Rejected}`,

    GET_DIPLOME: 'GET_DIPLOME',
    GET_DIPLOME_PENDING: `GET_DIPLOME_${ActionType.Pending}`,
    GET_DIPLOME_FULFILLED: `GET_DIPLOME_${ActionType.Fulfilled}`,
    GET_DIPLOME_REJECTED: `GET_DIPLOME_${ActionType.Rejected}`,

    ADD_DIPLOME: 'ADD_DIPLOME',
    ADD_DIPLOME_PENDING: `ADD_DIPLOME_${ActionType.Pending}`,
    ADD_DIPLOME_FULFILLED: `ADD_DIPLOME_${ActionType.Fulfilled}`,
    ADD_DIPLOME_REJECTED: `ADD_DIPLOME_${ActionType.Rejected}`,

    UPDATE_DIPLOME: 'UPDATE_DIPLOME',
    UPDATE_DIPLOME_PENDING: `UPDATE_DIPLOME_${ActionType.Pending}`,
    UPDATE_DIPLOME_FULFILLED: `UPDATE_DIPLOME_${ActionType.Fulfilled}`,
    UPDATE_DIPLOME_REJECTED: `UPDATE_DIPLOME_${ActionType.Rejected}`,
};

export default ActionTypes;
