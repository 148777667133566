import types from './ActionTypes';
import Api from '../../../utilities/services/Api';

export const deleteArtisanAccount = (artisanId, payload) => {
  return ({
    type: types.DELETE_ACCOUNT,
    payload: Api.accounts.deletAccount(artisanId, payload)
  })
};

// export const clearMessages = () => {
//   return ({
//     type: types.CLEAR_DELETE_ACCOUNT_MESSAGES,
//   })
// };
