import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_ACTIVITE_DECRET: 'GET_ACTIVITE_DECRET',
    GET_ACTIVITE_DECRET_PENDING: `GET_ACTIVITE_DECRET_${ActionType.Pending}`,
    GET_ACTIVITE_DECRET_FULFILLED: `GET_ACTIVITE_DECRET_${ActionType.Fulfilled}`,
    GET_ACTIVITE_DECRET_REJECTED: `GET_ACTIVITE_DECRET_${ActionType.Rejected}`,

    ADD_ACTIVITE_DECRET: 'ADD_ACTIVITE_DECRET',
    ADD_ACTIVITE_DECRET_PENDING: `ADD_ACTIVITE_DECRET_${ActionType.Pending}`,
    ADD_ACTIVITE_DECRET_FULFILLED: `ADD_ACTIVITE_DECRET_${ActionType.Fulfilled}`,
    ADD_ACTIVITE_DECRET_REJECTED: `ADD_ACTIVITE_DECRET_${ActionType.Rejected}`,

    UPDATE_ACTIVITE_DECRET: 'UPDATE_ACTIVITE_DECRET',
    UPDATE_ACTIVITE_DECRET_PENDING: `UPDATE_ACTIVITE_DECRET_${ActionType.Pending}`,
    UPDATE_ACTIVITE_DECRET_FULFILLED: `UPDATE_ACTIVITE_DECRET_${ActionType.Fulfilled}`,
    UPDATE_ACTIVITE_DECRET_REJECTED: `UPDATE_ACTIVITE_DECRET_${ActionType.Rejected}`,

    GET_ACTIVITE_BRANCHE_DECRET: 'GET_ACTIVITE_BRANCHE_DECRET',
    GET_ACTIVITE_BRANCHE_DECRET_PENDING: `GET_ACTIVITE_BRANCHE_DECRET_${ActionType.Pending}`,
    GET_ACTIVITE_BRANCHE_DECRET_FULFILLED: `GET_ACTIVITE_BRANCHE_DECRET_${ActionType.Fulfilled}`,
    GET_ACTIVITE_BRANCHE_DECRET_REJECTED: `GET_ACTIVITE_BRANCHE_DECRET_${ActionType.Rejected}`,

    ADD_ACTIVITE_BRANCHE_DECRET: 'ADD_ACTIVITE_BRANCHE_DECRET',
    ADD_ACTIVITE_BRANCHE_DECRET_PENDING: `ADD_ACTIVITE_BRANCHE_DECRET_${ActionType.Pending}`,
    ADD_ACTIVITE_BRANCHE_DECRET_FULFILLED: `ADD_ACTIVITE_BRANCHE_DECRET_${ActionType.Fulfilled}`,
    ADD_ACTIVITE_BRANCHE_DECRET_REJECTED: `ADD_ACTIVITE_BRANCHE_DECRET_${ActionType.Rejected}`,

    UPDATE_ACTIVITE_BRANCHE_DECRET: 'UPDATE_ACTIVITE_BRANCHE_DECRET',
    UPDATE_ACTIVITE_BRANCHE_DECRET_PENDING: `UPDATE_ACTIVITE_BRANCHE_DECRET_${ActionType.Pending}`,
    UPDATE_ACTIVITE_BRANCHE_DECRET_FULFILLED: `UPDATE_ACTIVITE_BRANCHE_DECRET_${ActionType.Fulfilled}`,
    UPDATE_ACTIVITE_BRANCHE_DECRET_REJECTED: `UPDATE_ACTIVITE_BRANCHE_DECRET_${ActionType.Rejected}`,

    GET_ACTIVITE_FILIERE_DECRET: 'GET_ACTIVITE_FILIERE_DECRET',
    GET_ACTIVITE_FILIERE_DECRET_PENDING: `GET_ACTIVITE_FILIERE_DECRET_${ActionType.Pending}`,
    GET_ACTIVITE_FILIERE_DECRET_FULFILLED: `GET_ACTIVITE_FILIERE_DECRET_${ActionType.Fulfilled}`,
    GET_ACTIVITE_FILIERE_DECRET_REJECTED: `GET_ACTIVITE_FILIERE_DECRET_${ActionType.Rejected}`,

    ADD_ACTIVITE_FILIERE_DECRET: 'ADD_ACTIVITE_FILIERE_DECRET',
    ADD_ACTIVITE_FILIERE_DECRET_PENDING: `ADD_ACTIVITE_FILIERE_DECRET_${ActionType.Pending}`,
    ADD_ACTIVITE_FILIERE_DECRET_FULFILLED: `ADD_ACTIVITE_FILIERE_DECRET_${ActionType.Fulfilled}`,
    ADD_ACTIVITE_FILIERE_DECRET_REJECTED: `ADD_ACTIVITE_FILIERE_DECRET_${ActionType.Rejected}`,

    UPDATE_ACTIVITE_FILIERE_DECRET: 'UPDATE_ACTIVITE_FILIERE_DECRET',
    UPDATE_ACTIVITE_FILIERE_DECRET_PENDING: `UPDATE_ACTIVITE_FILIERE_DECRET_${ActionType.Pending}`,
    UPDATE_ACTIVITE_FILIERE_DECRET_FULFILLED: `UPDATE_ACTIVITE_FILIERE_DECRET_${ActionType.Fulfilled}`,
    UPDATE_ACTIVITE_FILIERE_DECRET_REJECTED: `UPDATE_ACTIVITE_FILIERE_DECRET_${ActionType.Rejected}`,

};

export default ActionTypes;
