import types from './ActionTypes';

const initialeState = {
  artisanEvents: [],
  error: {}
}

function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.ARTISAN_EVENTS_PENDING: {
      return {
        loading: false,
      };
    }

    case types.ARTISAN_EVENTS_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        artisanEvents: data,
      };
    }

    case types.ARTISAN_EVENTS_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        loading: false,
        error: { message: message },
      };
    }

    default:
      return state;
  }
}

export default reducer;
