import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getArtisanDataById } from '../../../../ArtisanApp/Registration/Action';
import { getGlobalParam } from '../../../../GlobalParams/Action';
import * as ref from '../../../../Referentiels/Action';
import { rejectSubscription, validateSubscription, resetMessages } from '../../Action'
import { Button, Col, Empty, Row, Space, Tabs, Alert } from 'antd';
import EducationData from './components/EducationData/EducationData';
import PersonalData from './components/PersonalData/PersonalData';
import ProfessionalData from './components/ProfessionalData/ProfessionalData';
import RepresentativePersonalData from './components/RepresentativePersonalData/RepresentativePersonalData';
import ValidationInscriptionFooter from './components/ValidationInscriptionFooter/ValidationInscriptionFooter';
import { getMotifDeRejet } from '../../../Referentiels/components/ReferentielMotifDeRejet/Action'
import Actions from './components/Actions/Actions';
import { getArtisanFile, getArtisanFilesList, uploadCommiteFile, removeCommiteFile, getCommiteFiles, getCommiteFile, getArtisanEvents, 
        changeArtisanPhoneNumber, changeArtisanPhoneNumberClear, reinitializeAccount, reinitializeAccountClear } from './Action'
import SpinLoader from '../../../../../component/SpinLoader/SpinLaoder';
import history from '../../../../../history';

import Documents from './components/Documents/Documents';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const ArtisanDetails = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [couldValidate, setCouldValidate] = useState(history.location.state.from === "/app/agent/artisans" || history.location.state.from === "/app/agent/home");
    const loading = useSelector(state => state.registration?.loading);
    const artisanType = useSelector(state => state.registration?.artisantData?.artisan_type?.code);
    const artisantData = useSelector(state => state.registration?.artisantData);
    const params = useSelector(state => state.globalParamReducer?.params);
    const referentiel = useSelector(state => state.referentielReducer?.params);
    const documents = useSelector(state => state.artisanDetailReducer?.documents);
    const commiteDocuments = useSelector(state => state.artisanDetailReducer?.commiteDocuments);
    const actions = useSelector(state => state.artisanDetailReducer?.actions);
    const error = useSelector(state => state.artisanReducer?.error);
    const successAcceptation = useSelector(state => state.artisanReducer?.successAcceptation);
    const successRejet = useSelector(state => state.artisanReducer?.successRejet);
    const motifRejetList = useSelector(state => state.referentielParamsReducer?.referentielMotifDeRejetReducer?.motifDeRejet);

    useEffect(() => {
        dispatch(resetMessages());
        dispatch(getGlobalParam());
        dispatch(ref.getReferentiels());
        dispatch(ref.getDiplome());
        dispatch(ref.getEtablissement());
        dispatch(ref.getDuree());
        dispatch(ref.getAnnexe());
        dispatch(ref.getActiviteBranche());
        dispatch(ref.getCouvertureSociale());
        dispatch(ref.getActivite());
        dispatch(getArtisanDataById(id));
        dispatch(getArtisanFilesList(id));
        dispatch(getCommiteFiles(id));
        dispatch(getArtisanEvents(id));
        dispatch(getMotifDeRejet())
    }, []);

    const downloadFile = (docId) => {
        return dispatch(getArtisanFile(id, docId));
    }

    const downloadCommiteFile = (docId) => {
        return dispatch(getCommiteFile(id, docId));
    }

    const rejectAction = (payload) => {
        dispatch(rejectSubscription(id, payload)).then((res) => setCouldValidate(false));
    }

    const validateAction = (forcerValidation = false) => {
        dispatch(validateSubscription(id, forcerValidation)).then((res) => setCouldValidate(false));
    }

    const changeArtisanPhoneNumberAction = (userId, payload) => {
        dispatch(changeArtisanPhoneNumber(userId, payload)).then((res) => setCouldValidate(false));
    }

    const changeArtisanPhoneNumberClearAction = () => {
        //dispatch(changeArtisanPhoneNumberClear())
        changeArtisanPhoneNumberClear()
    }

    const reinitializeAccountAction = (userId, payload) => {
        dispatch(reinitializeAccount(userId, payload)).then((res) => setCouldValidate(false));
    }

    const reinitializeAccountClearAction = () => {
        //dispatch(changeArtisanPhoneNumberClear())
        reinitializeAccountClear()
    }

    const uploadFile = (artisantId, document) => {
        dispatch(uploadCommiteFile(artisantId, document));
    }

    const removeFile = (artisantId, documentId) => {
        dispatch(removeCommiteFile(artisantId, documentId));
    }

    return (
        <>
            {loading ? <SpinLoader /> :
                artisantData.statut === "INITIE" ?
                    <>
                        <Row style={{
                            height: '60vh',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Col span="24" >
                                <Empty
                                    description={
                                        <span>{t('agent.artisan-details.without-data.description')}</span>
                                    }
                                >
                                    <Button shape="round" type="primary" icon={<ArrowLeftOutlined />} size="large" onClick={() => history.push(history.location.state.from)}>
                                        {t('agent.artisan-details.without-data.go-back')}
                                    </Button>
                                </Empty>
                            </Col>
                        </Row>
                    </>
                    :

                    <Space direction="vertical" size="middle" className="w-100">
                        {error &&
                            <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                                <Col span={24}>
                                    <Alert
                                        style={{ textAlign: 'center', width: '100%', fontSize: 'large' }}
                                        message={error}
                                        type="error"
                                        closable
                                    />
                                </Col>
                            </Row>
                        }
                        {successRejet &&
                            <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                                <Col span={24}>
                                    <Alert
                                        style={{ textAlign: 'center', width: '100%', fontSize: 'large' }}
                                        message={successRejet}
                                        type="error"
                                        closable
                                    />
                                </Col>
                            </Row>
                        }
                        {successAcceptation &&
                            <Row justify="center" style={{ padding: '20px 0 10px 0' }}>
                                <Col span={24}>
                                    <Alert
                                        style={{ textAlign: 'center', width: '100%', fontSize: 'large' }}
                                        message={successAcceptation}
                                        type="success"
                                        closable
                                    />
                                </Col>
                            </Row>
                        }

                        <Tabs defaultActiveKey="1" type="card" size="large">
                            {artisantData.donnees_personnelles && (artisanType != 'ENTREPRISE_ARTISANAT' && artisanType != 'COOPERATIVE_ARTISANAT') &&
                                <TabPane tab={t('agent.artisan-details.with-data.tabs.personal-data')} key="donnees_personnelles">
                                    <PersonalData params={params} referentiel={referentiel} initData={artisantData ? artisantData.donnees_personnelles : {}} />
                                </TabPane>
                            }
                            {artisantData.donnees_education && (artisanType == 'ARTISAN_AVEC_DIPLOME') &&
                                <TabPane tab={t('agent.artisan-details.with-data.tabs.education-data')} key="donnees_education">
                                    <EducationData params={params} referentiel={referentiel} initData={artisantData ? artisantData.donnees_education : {}} />
                                </TabPane>
                            }
                            {artisantData.donnees_professionnelle &&
                                <TabPane tab={t('agent.artisan-details.with-data.tabs.professional-data')} key="donnees_professionnelle">
                                    <ProfessionalData params={params} referentiel={referentiel} initData={artisantData ? artisantData.donnees_professionnelle : {}} artisanType={artisanType} />
                                </TabPane>
                            }
                            {artisantData.donnees_representant && (artisanType == 'ENTREPRISE_ARTISANAT' || artisanType == 'COOPERATIVE_ARTISANAT') &&
                                <TabPane tab={t('agent.artisan-details.with-data.tabs.representative-personal-data')} key="donnees_representant">
                                    <RepresentativePersonalData params={params} referentiel={referentiel} initData={artisantData ? artisantData.donnees_representant : {}} />
                                </TabPane>
                            }

                            {documents &&
                                <TabPane tab={t('agent.artisan-details.with-data.tabs.documents')} key="documents">
                                    <Documents artisanType={artisanType} documents={documents} downloadFile={downloadFile} isCommiteFile={false} />
                                </TabPane>
                            }

                            <TabPane tab={t('agent.artisan-details.with-data.tabs.commite-documents')} key="commite_documents">
                                <Documents artisanType={artisanType} documents={commiteDocuments} downloadFile={downloadCommiteFile} isCommiteFile={true} />
                            </TabPane>

                            <TabPane tab={t('agent.artisan-details.with-data.tabs.actions')} key="actions">
                                <Actions actions={actions} />
                            </TabPane>
                        </Tabs>

                        <ValidationInscriptionFooter couldValidate={couldValidate} motifRejetList={motifRejetList} rejectAction={rejectAction} validateAction={validateAction} changeArtisanPhoneNumberAction = {changeArtisanPhoneNumberAction} changeArtisanPhoneNumberClearAction = {changeArtisanPhoneNumberClearAction}  reinitializeAccountAction = {reinitializeAccountAction}  reinitializeAccountClearAction = {reinitializeAccountClearAction}uploadFile={uploadFile} removeFile={removeFile} />
                    </Space>
            }
        </>
    )
}

export default ArtisanDetails;
