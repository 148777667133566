import { Button, Col, Row, Alert, Space, Typography } from "antd";
import React, { useEffect, useState } from "react"
import ReferentielList from "./components/ReferentielList/ReferentielList";
import { getEtablissement, getPublicPrive, getDepartementFormation } from './Action';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getGlobalParam } from '../../../../GlobalParams/Action';
import ReferentielDetail from "./components/ReferentielDetail/ReferentielDetail";
import { useTranslation } from "react-i18next";
import './ReferentielEtablissement.less'
import SpinLoader from "../../../../../component/SpinLoader/SpinLaoder";
const { Title } = Typography;

const ReferentielEtablissement = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { refType } = useParams();

    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [visible, setVisible] = useState(false);
    const [successeMessage, setSuccesseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [activeReferenciel, setActiveReferenciel] = useState({});
    const [action, setAction] = useState('');

    const data = useSelector(state => state.referentielParamsReducer?.referentielEtablissementReducer?.etablissement);
    const publicPrive = useSelector(state => state.referentielParamsReducer?.referentielEtablissementReducer?.publicPrive);
    const departementFormation = useSelector(state => state.referentielParamsReducer?.referentielEtablissementReducer?.departementFormation);
    const regions = useSelector(state => state.globalParamReducer?.params?.regions);
    const provinces = useSelector(state => state.globalParamReducer?.params?.provinces);
    const villes = useSelector(state => state.globalParamReducer?.params?.villes);

    useEffect(() => {
        loadDataOrRedirect();
    }, []);

    const loadDataOrRedirect = () => {
        setLoading(true);
        dispatch(getGlobalParam())
            .then(dispatch(getPublicPrive()))
            .then(dispatch(getDepartementFormation()))
            .then((res) => {
                dispatch(getEtablissement())
                    .then((res) => {
                        setLoading(false)
                    })
                    .catch((err) => setLoading(false));

            })
            .catch((err) => setLoading(false))

    }

    const handleDetails = (record) => {
        setActiveReferenciel(record)
        setVisible(true)
        setAction('CONSULTATION')
    }

    const handleAdd = () => {
        setActiveReferenciel({})
        setVisible(true)
        setLoadingTable(true)
        setAction('ADD')
    }

    const handleUpdates = (record) => {
        setActiveReferenciel(record)
        setVisible(true)
        setLoadingTable(true)
        setAction('UPDATE')
    }

    const hideModal = () => {
        setVisible(false);
        setLoadingTable(false)
        setActiveReferenciel({})

    };

    const getTitle = () => t('agent.referentiel.title.etablissement');

    const getAddButtonTitle = () => t('agent.referentiel.add.etablissement');

    const getRequiredData = () => ({
        regions: regions,
        provinces: provinces,
        villes: villes,
        publicPrive: publicPrive,
        departementFormation: departementFormation
    });


    return (<>

        <Space size="large" direction="vertical" style={{ width: '100%' }}  >


            {loading ? <SpinLoader /> : <>

                <div>
                    <Title span={3} className="aligne-left">
                        {getTitle()}
                    </Title>
                    <Button shape='round' type="primary" htmlType="submit" onClick={() => handleAdd()} className="aligne-right">
                        {getAddButtonTitle()}
                    </Button>
                </div>
                {errorMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={errorMessage}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {successeMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={successeMessage}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }
                <div className="search-result-list">
                    <ReferentielList requiredData={getRequiredData()} loadingTable={loadingTable} data={data} refType={refType} handleUpdates={handleUpdates} handleDetails={handleDetails} />
                    {visible &&
                        <ReferentielDetail requiredData={getRequiredData()} handleClose={hideModal} activeReferenciel={activeReferenciel} action={action} refType={refType}
                            loadDataOrRedirect={loadDataOrRedirect} setErrorMessage={setErrorMessage} setSuccesseMessage={setSuccesseMessage} />
                    }

                </div>
            </>}
        </Space>
    </>);
}

export default ReferentielEtablissement;