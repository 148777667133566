import types from './ActionTypes';
import Api from '../../../utilities/services/Api';

export const getProfils = () => ({
  type: types.GET_PROFILS,
  payload: Api.profil.getAllProfils(),
});

export const addProfil = (payload) => ({
  type: types.ADD_PROFIL,
  payload: Api.profil.addProfil(payload),
});

export const getProfileMenus = (id) => {
  return ({
    type: types.GET_PROFIL_MENUS,
    payload: Api.profil.getProfileMenus(id),
  })
};

export const assignMenuActions = (profilesMmenusActionCommand) => {
  return ({
    type: types.ASSIGN_MENU_ACTIONS,
    payload: Api.profil.assignMenuActions(profilesMmenusActionCommand),
  })
};

export const clearMessages = () => {
  return ({
    type: types.CLEAR_MESSAGES
  })
};
