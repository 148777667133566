import types from './ActionTypes';
import Api from '../../utilities/services/Api';
import fileDownload from 'js-file-download';

export const createAccount = (payload) => {
  return ({
    type: types.CREATE_ACCOUNT,
    payload: Api.accounts.createAccount(payload),
  })
};
export const validateOtp = (payload) => {
  return ({
    type: types.VALIDATE_OTP,
    payload: Api.otp.validateOtp(payload),
  })
};
export const regenerateOtp = (payload) => {
  return ({
    type: types.REGENERATE_OTP,
    payload: Api.otp.regenerateOtp(payload),
  })
};

export const changerOperateur = (payload) => {
  return ({
    type: types.CHANGE_OPERATEUR,
    payload: Api.accounts.changerOperateur(payload),
  })
};

export const loadDgsnAuthInfos = (citizenId) => ({
   type: types.LOAD_DGSN_AUTH_INFOS,
   payload: Api.dgsn.loadInfos(citizenId)
});

export const clearErrorMessage = () => {
  return ({
    type: types.CLEAR_ERROR_MESSAGE,
  })
};
export const downloadPrivacyPolicy = (lang) => {
  return ({
    type: types.DOWNLOAD_PRIVACY_POLICY,
    payload: Api.document.getPrivacyPolicy(lang).then( res => {
      const fileName = res.headers.x_file_name &&  res.headers.x_file_name !== "" 
          ? res.headers.x_file_name
          : lang === 'fr' ? "Conditions générales d'utilisation.pdf" : "شروط عامة.pdf";

      fileDownload(res.data, fileName);
      return res;
    }),
  })
};

