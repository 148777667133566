import types from './ActionTypes';
import Api from '../../../utilities/services/Api';
import fileDownload from 'js-file-download';

export const downloadAttestation = (artisanId) => {
  return ({
    type: types.DOWNLOAD_ATTESTATION,
    payload: Api.artisan.getAttestation(artisanId).then( res => {
      const fileName = res.headers.x_file_name &&  res.headers.x_file_name !== "" 
          ? res.headers.x_file_name
          : "attestation_artisan.pdf" ;

      fileDownload(res.data, fileName);
      return res;
    }),
  })
};

export const clearMessages = () => {
  return ({
    type: types.CLEAR_DOWNLOAD_ATTESTATION_MESSAGES,
  })
};
