import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_DUREE: 'GET_DUREE',
    GET_DUREE_PENDING: `GET_DUREE_${ActionType.Pending}`,
    GET_DUREE_FULFILLED: `GET_DUREE_${ActionType.Fulfilled}`,
    GET_DUREE_REJECTED: `GET_DUREE_${ActionType.Rejected}`,

    ADD_DUREE: 'ADD_DUREE',
    ADD_DUREE_PENDING: `ADD_DUREE_${ActionType.Pending}`,
    ADD_DUREE_FULFILLED: `ADD_DUREE_${ActionType.Fulfilled}`,
    ADD_DUREE_REJECTED: `ADD_DUREE_${ActionType.Rejected}`,

    UPDATE_DUREE: 'UPDATE_DUREE',
    UPDATE_DUREE_PENDING: `UPDATE_DUREE_${ActionType.Pending}`,
    UPDATE_DUREE_FULFILLED: `UPDATE_DUREE_${ActionType.Fulfilled}`,
    UPDATE_DUREE_REJECTED: `UPDATE_DUREE_${ActionType.Rejected}`,
};

export default ActionTypes;
