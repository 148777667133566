import React from 'react';
import {
  Row, Col, Form,
  Input,
  Radio,
  Space,
  Typography,
  Card,
  Divider
} from "antd";
import './ProfessionalData.less';
import { InputCNIEWithDoubleLabel, InputEmailWithDoubleLabel, InputWithDoubleLabel, SeletWithDoubleLabel, DateWithDoubleLabel, FieldWithDoubleLabel, TextAreaWithDoubleLabel, InputPhoneNumberWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields'
import Utils from '../../../../../../../utilities/Utils/Utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const { Text } = Typography;

const ProfessionalData = props => {
  const { t } = useTranslation();
  const { artisanType } = props;
  const { villes, provinces, regions } = props.params
  const { activiteBranches, activites, activiteFilieres } = props.referentiel

  const [form] = Form.useForm();


  const getInitData = () => {
    const { initData } = props;
    let data = initData;

    if (isEmpty(data)) {
      data = { activites: [""] };
    } else {
      if (!isEmpty(data.date_debut_exercice)) {
        data = { ...data, date_debut_exercice: moment(data.date_debut_exercice, "DD/MM/YYYY") }
      }
      if (!isEmpty(data.date_inscription_registre_local)) {
        data = { ...data, date_inscription_registre_local: moment(data.date_inscription_registre_local, "DD/MM/YYYY") };
      }
      if (!isEmpty(data.date_creation_entreprise)) {
        data = { ...data, date_creation_entreprise: moment(data.date_creation_entreprise, "DD/MM/YYYY") };
      }
      if (!isEmpty(data.date_affiliation_cnss)) {
        data = { ...data, date_affiliation_cnss: moment(data.date_affiliation_cnss, "DD/MM/YYYY") };
      }
    }

    return data;

  }

  const isAutreAffiliationCouverture = () => {
    return props.initData && props.initData.affiliation_couverture 
      ? props.initData?.affiliation_couverture === "6000"
      : false ;
  }

  const isCnssAffiliationCouverture = () => {
    if(artisanType === Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT) {
      return true
    } else {
      return props.initData && props.initData.affiliation_couverture 
        ? !['5000', '6000', '7000', '4500'].includes(props.initData?.affiliation_couverture)
        : false;
    }
  }

  const isRamedAffiliationCouverture = () => {
    return props.initData && props.initData.affiliation_couverture 
      ? props.initData?.affiliation_couverture === "4500"
      : false ;
  }

  const getAdditionalInfo = () => {
    const { initData } = props;

    switch (initData.type_travail) {
      case 'CHEZ_UNE_ENTREPRISE':
        return <>
          <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="type_travail_nom_entreprise" label={t('component.professional-data.form.type-travail-nom-entreprise.label')} disabled />
          <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL_WITHOUT_SPACE} name="type_travail_num_rc" label={t('component.professional-data.form.num-immat-registre-commerce.label')} disabled />
        </>;
      case 'CHEZ_UNE_COOPERATIVE':
        return <>
          <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="type_travail_nom_cooperative" label={t('component.professional-data.form.nom-cooperative.label')} disabled />
          <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL_WITHOUT_SPACE} name="type_travail_num_rna" label={t('component.professional-data.form.immatriculation-registre-local.label')} disabled />
        </>;
      case 'CHEZ_UNE_UNITE_ARTISANALE':
        return <>
          <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="type_travail_nom_unite" label={t('component.professional-data.form.type-travail-nom-unite.label')} disabled />
          <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.NUMERIC} name="type_travail_num_patente" label={t('component.professional-data.form.type-travail-num-patente.label')} disabled />
        </>;
      case 'AUTO_ENTREPRENEUR':
        return <>
          <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="type_travail_num_autoentr" label={t('component.professional-data.form.type-travail-num-autoentr.label')} disabled />
        </>;

      case 'AUTRE':
        return <>
          <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="type_travail_autre" label={t('component.professional-data.form.type-travail-autre.label')} disabled />
        </>;

      default: break;
    }


  }

  return (
    <Card  >
      <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }} align="middle">
        <Space direction="vertical" size="middle" className="w-100">
          <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
            <Form form={form}
              name="registration"
              style={{ width: '100%' }}
              size='small'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              scrollToFirstError
              initialValues={getInitData()}
            >
              {[Utils.ARTISANT_TYPE.ARTISAN_AVEC_DIPLOME, Utils.ARTISANT_TYPE.ARTISAN_AVEC_EXPERIENCE, Utils.ARTISANT_TYPE.ARTISAN_MAALAM].includes(artisanType) ?

                <div>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                      <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Col lg={11} md={24} sm={24} xs={24} >
                          <FieldWithDoubleLabel name="actif" label={t('component.professional-data.form.actif.label')}>
                            <Radio.Group style={{ width: '100%' }} disabled={true}>
                              <Radio value="O" >{t('component.professional-data.form.actif.options.o')}</Radio>
                              <Radio value="N" >{t('component.professional-data.form.actif.options.n')}</Radio>
                            </Radio.Group>
                          </FieldWithDoubleLabel>
                        </Col>
                        <Divider type="vertical" />
                        <Col lg={11} md={24} sm={24} xs={24} >
                          <DateWithDoubleLabel type="text" name="date_debut_exercice" label={t('component.professional-data.form.date-debut-exercice.label')} disabled={true} />
                        </Col>
                      </Row>
                    </Row>
                  </Row>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={11} md={24} sm={24} xs={24} >
                      <FieldWithDoubleLabel name="type_travail" label={t('component.professional-data.form.type-travail.label')} >
                        <Radio.Group style={{ width: '100%' }} disabled={true}>
                          <Space direction="vertical" style={{ textAlign: 'initial', justifyContent: 'flex-start' }}>
                            <Radio value="CHEZ_UNE_ENTREPRISE">{t('component.professional-data.form.type-travail.options.CHEZ-UNE-ENTREPRIS')}</Radio>
                            <Radio value="CHEZ_UNE_COOPERATIVE">{t('component.professional-data.form.type-travail.options.CHEZ-UNE-COOPERATIVE')}</Radio>
                            <Radio value="CHEZ_UNE_UNITE_ARTISANALE" >{t('component.professional-data.form.type-travail.options.CHEZ-UNE-UNITE-ARTISANALE')}</Radio>
                            <Radio value="INDEPENDANT_DANS_UNE_UNITE_ARTISANALE">{t('component.professional-data.form.type-travail.options.INDEPENDANT-DANS-UNE-UNITE-ARTISANALE')}</Radio>
                            <Radio value="INDEPENDANT_A_DOMICILE">{t('component.professional-data.form.type-travail.options.INDEPENDANT-A-DOMICILE')}</Radio>
                            <Radio value="AUTO_ENTREPRENEUR">{t('component.professional-data.form.type-travail.options.AUTO-ENTREPRENEUR')}</Radio>
                            <Radio value='AUTRE'>  {t('component.professional-data.form.type-travail.options.AUTRE')}</Radio>
                          </Space>
                        </Radio.Group>
                      </FieldWithDoubleLabel>
                      {getAdditionalInfo()}
                    </Col>
                  </Row>
                  <Row style={{ width: '100%', justifyContent: 'space-between', direction: 'ltr' }}>
                    <Col lg={11} md={24} sm={24} xs={24}>
                      <TextAreaWithDoubleLabel form={form} type="text" name="adresse" label={Utils.staticInternationalization.adresseProfessionelleFR} disabled />
                    </Col>
                    <Divider type="vertical" />
                    <Col lg={11} md={24} sm={24} xs={24}>
                      <TextAreaWithDoubleLabel type={Utils.TEXT_TYPE.ARABIC} form={form} name="adresse_ar" label={Utils.staticInternationalization.adresseProfessionelleAR} disabled />
                    </Col>
                  </Row>
                </div>
                :

                <div>
                  {(artisanType === Utils.ARTISANT_TYPE.COOPERATIVE_ARTISANAT) &&
                    <Col span={24}>
                      <InputWithDoubleLabel form={form} type="text" name="nom_cooperative" label={t('component.professional-data.form.nom-cooperative.label')} disabled={true} />
                    </Col>
                  }
                  {(artisanType === Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT) &&
                    <Col span={24}>
                      <InputWithDoubleLabel form={form} type="text" name="nom_entreprise" label={t('component.professional-data.form.nom-entreprise.label')} disabled={true} />
                    </Col>
                  }

                  {(artisanType === Utils.ARTISANT_TYPE.COOPERATIVE_ARTISANAT) &&
                    <>
                      <Col span={24}>
                        <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL_WITHOUT_SPACE} name="immatriculation_registre_local" label={t('component.professional-data.form.immatriculation-registre-local.label')} disabled={true} />
                      </Col>
                      <Col span={24}>
                        <DateWithDoubleLabel type="text" name="date_inscription_registre_local" label={t('component.professional-data.form.date-inscription-registre-local.label')} disabled={true} />
                      </Col>
                    </>
                  }
                  {(artisanType === Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT) &&
                    <>
                      <Col span={24}>
                        <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL_WITHOUT_SPACE} name="num_immat_registre_commerce" label={t('component.professional-data.form.num-immat-registre-commerce.label')} disabled={true} />
                      </Col>
                      <Col span={24}>
                        <DateWithDoubleLabel type="text" name="date_creation_entreprise" label={t('component.professional-data.form.date-creation-entreprise.label')} disabled={true} />
                      </Col>
                    </>
                  }

                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={11} md={24} sm={24} xs={24} >
                      <TextAreaWithDoubleLabel form={form} type="text" name="adresse_siege" label={t('component.professional-data.form.adresse-siege.label')} disabled={true} />
                    </Col>
                  </Row>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={11} md={24} sm={24} xs={24} >
                      <InputEmailWithDoubleLabel type="text" name="email" label={t('component.professional-data.form.email.label')} required={false} disabled={true} />
                    </Col>
                    <Divider type="vertical" />
                    <Col lg={11} md={24} sm={24} xs={24} >
                      <InputPhoneNumberWithDoubleLabel type="text" name="numero_telephone" label={t('component.professional-data.form.numero-telephone.label')} disabled={true} />
                    </Col>
                  </Row>
                </div>
              }

              {
                artisanType !== Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT &&
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col lg={11} md={24} sm={24} xs={24}>
                    <SeletWithDoubleLabel type="text" name="affiliation_couverture" label={t('component.professional-data.form.affiliationCouverture.label')} options={props.params.typeAffiliationCouverture} disabled={true} />
                  </Col>
                </Row>
              }

              {
                isAutreAffiliationCouverture() &&
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col lg={11} md={24} sm={24} xs={24}>
                    <InputWithDoubleLabel form={form} type="text" name="autre_affiliation_couverture" label={t('component.professional-data.form.affiliationCouvertureAutre.label')} disabled={true} />
                  </Col>
                </Row>
              }
              {
                isCnssAffiliationCouverture() &&
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col lg={11} md={24} sm={24} xs={24}>
                    <InputWithDoubleLabel form={form} type="alphanumeric" name="numero_affiliation_cnss" label={t('component.professional-data.form.numeroAffiliationCnss.label')} disabled={true} />
                  </Col>
                  <Divider type="vertical" />
                  <Col lg={11} md={24} sm={24} xs={24}>
                    <DateWithDoubleLabel name="date_affiliation_cnss" label={t('component.professional-data.form.dateAffiliationCnss.label')} disabled={true} />
                  </Col>
                </Row>
              }
              {
                isRamedAffiliationCouverture() &&
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col lg={11} md={24} sm={24} xs={24}>
                    <InputWithDoubleLabel form={form} type="alphanumeric" name="numero_affiliation_cnss" label={t('component.professional-data.form.numeroAffiliationRamed.label')} disabled={true} />
                  </Col>
                </Row>
              }

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={8} md={24} sm={24} xs={24} >
                  <SeletWithDoubleLabel type="text" name="ville" label={t('component.professional-data.form.ville.label')} options={villes} disabled={true} />
                </Col>
                <Col lg={8} md={24} sm={24} xs={24} >
                  <SeletWithDoubleLabel type="text" name="province" label={t('component.professional-data.form.province.label')} options={provinces} disabled={true} />
                </Col>
                <Col lg={7} md={24} sm={24} xs={24} >
                  <SeletWithDoubleLabel type="text" name="region" label={t('component.professional-data.form.region.label')} options={regions} disabled={true} />
                </Col>
              </Row>

              <Form.List name="activites">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (<>

                      {index === 0 &&
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                          <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }} span={16}>
                            <h1 style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }}>
                              {t('component.professional-data.form.activite-principale.text')}
                            </h1>
                          </Col>
                        </Row>
                      }

                      {index === 0 &&
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                          <Col lg={11} md={24} sm={24} xs={24} >
                            <SeletWithDoubleLabel type="text" index={index} name={[field.name, 'branche']} label={t('component.professional-data.form.branche.label')} options={activiteBranches} disabled={true} />
                          </Col>
                          <Col lg={{ order: 3, span: 11 }} md={{ order: 2, span: 24 }} sm={24} xs={24} >
                            <SeletWithDoubleLabel type="text" index={index} name={[field.name, 'filiere']} label={t('component.professional-data.form.filiere.label')} options={activiteFilieres} disabled={true} />
                          </Col>
                          <Col lg={11} md={24} sm={24} xs={24} >
                            <SeletWithDoubleLabel type="text" index={index} name={[field.name, 'activite']} label={t('component.professional-data.form.activite.label')} options={activites} disabled={true} />
                          </Col>
                        </Row>
                      }

                      {index === 1 &&
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                          <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }} span={16}>
                            <h1 style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }}>
                              {t('component.professional-data.form.activite-secondaire.text')}
                            </h1>
                          </Col>
                        </Row>
                      }

                      {index > 0 &&
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                          <Col lg={11} md={24} sm={24} xs={24} >
                            <SeletWithDoubleLabel type="text" index={index} name={[field.name, 'branche']} label={t('component.professional-data.form.branche.label')} options={activiteBranches} disabled={true} />
                          </Col>
                          <Col lg={11} md={24} sm={24} xs={24} >
                            <SeletWithDoubleLabel type="text" index={index} name={[field.name, 'activite']} label={t('component.professional-data.form.activite.label')} options={activites} disabled={true} />
                          </Col>
                        </Row>
                      }
                    </>
                    ))}
                  </>
                )}
              </Form.List>

              {(artisanType === Utils.ARTISANT_TYPE.COOPERATIVE_ARTISANAT) &&
                <>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col span={24}>
                      <InputWithDoubleLabel form={form} type="number" name="nombre_cooperant" label={t('component.professional-data.form.nombre-cooperant.label')} disabled={true} />
                    </Col>
                  </Row>

                  <Form.List name="cooperants">
                    {(fields) => (
                      <>
                        {fields.map((field, index) => (
                          <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Col lg={7} md={24} sm={24} xs={24} >
                              <InputWithDoubleLabel form={form} type="text" required={false} name={[field.name, 'nom']} label={t('component.professional-data.form.nom.label')} disabled={true} />
                            </Col>
                            <Col lg={7} md={24} sm={24} xs={24} >
                              <InputWithDoubleLabel form={form} type="text" required={false} name={[field.name, 'prenom']} label={t('component.professional-data.form.prenom.label')} disabled={true} />
                            </Col>
                            <Col lg={7} md={24} sm={24} xs={24} >
                              <InputCNIEWithDoubleLabel type="text" required={false} name={[field.name, 'cnie']} label={t('component.professional-data.form.cnie.label')} disabled={true} />
                            </Col>
                          </Row>

                        ))}
                      </>
                    )}
                  </Form.List>
                </>
              }

              {(artisanType === Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT) &&
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col span={24}>
                    <InputWithDoubleLabel form={form} type="text" name="rna_responsable_prod" label={t('component.professional-data.form.rna-responsable-prod.label')} disabled={true} />
                  </Col>
                </Row>
              }

              {[Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT, Utils.ARTISANT_TYPE.COOPERATIVE_ARTISANAT].includes(artisanType) &&
                <>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }} span={16}>
                      <h1 style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }}>
                        {t(`component.professional-data.form.chiffre.${artisanType === 'ENTREPRISE_ARTISANAT' ? 'entreprise' : 'cooperative'}.text`)}
                      </h1>
                    </Col>
                  </Row>

                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={7} md={24} sm={24} xs={24} >
                      <InputWithDoubleLabel form={form} type="number" required={false} name={['chiffre_resultat', 'ca_annee_n']} label={t('component.professional-data.form.ca-annee-n.label')} disabled={true} />
                    </Col>
                    <Col lg={7} md={24} sm={24} xs={24} >
                      <InputWithDoubleLabel form={form} type="number" required={false} name={['chiffre_resultat', 'ca_annee_n_1']} label={t('component.professional-data.form.ca-annee-n.label')} disabled={true} />
                    </Col>
                    <Col lg={7} md={24} sm={24} xs={24} >
                      <InputWithDoubleLabel form={form} type="number" required={false} name={['chiffre_resultat', 'ca_annee_n_2']} label={t('component.professional-data.form.ca-annee-n.label')} disabled={true} />
                    </Col>
                  </Row>

                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }} span={16}>
                      <h1 style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }}>
                        {t(`component.professional-data.form.resultat.${artisanType === 'ENTREPRISE_ARTISANAT' ? 'entreprise' : 'cooperative'}.text`)}
                      </h1>
                    </Col>
                  </Row>

                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={7} md={24} sm={24} xs={24} >
                      <InputWithDoubleLabel form={form} type="number" required={false} name={['chiffre_resultat', 'rn_annee_n']} label={t('component.professional-data.form.rn-annee-n.label')} disabled={true} />
                    </Col>
                    <Col lg={7} md={24} sm={24} xs={24} >
                      <InputWithDoubleLabel form={form} type="number" required={false} name={['chiffre_resultat', 'rn_annee_n_1']} label={t('component.professional-data.form.rn-annee-n-1.label')} disabled={true} />
                    </Col>
                    <Col lg={7} md={24} sm={24} xs={24} >
                      <InputWithDoubleLabel form={form} type="number" required={false} name={['chiffre_resultat', 'rn_annee_n_2']} label={t('component.professional-data.form.rn-annee-n-2.label')} disabled={true} />
                    </Col>
                  </Row>
                </>
              }
            </Form>
          </Col>
        </Space>
      </Row >
    </Card >
  );
};

export default ProfessionalData;

