
import filter from "lodash.filter";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router";

const isAuthorized = (menus, path) => {
    let isAuth = false;
    const staticMenu = ['home', 'changer-pass'];
    staticMenu.forEach(singleMenu => {
        if (path.includes(singleMenu)) {
            isAuth = true;
        }
    })

    !isAuth && menus && menus.forEach(menu => {
        if (menu.children.length > 0) {
            menu.children.forEach(child => {
                if (path.includes(child.link)) {
                    const tmp_actions = filter(child.actions, { 'code': "VIEW", 'status': "ENABLED" });
                    isAuth = tmp_actions.length > 0;
                }
            });
        } else {
            if (path.includes(menu.link)) {
                const tmp_actions = filter(menu.actions, { 'code': "VIEW", 'status': "ENABLED" });
                isAuth = tmp_actions.length > 0;
            }
        }
    });
    return isAuth;
}

const AuthRoute = ({ component: Component, ...rest }) => {

    const menus = useSelector(state => {
        return state.loginReducer.menus
    });
    const userType = useSelector(state => {
        return state.loginReducer.type
    });

    const location = useLocation();
    let isUserAuthorized = isAuthorized(menus, location.pathname);
    return (
        <Route
            {...rest}
            render={props =>
                isUserAuthorized ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: userType === "ARTISAN" ? "/app/artisan/home" : "/app/agent/home"
                        }}
                    />
                )
            }
        />
    );
}


export default AuthRoute;