import { combineReducers } from 'redux';
import referentielVilleProvainceRegionsReducer from './components/ReferentielVilleRegionsProvaince/reducer';
import referentielAnnexeReducer from './components/ReferentielAnnexe/reducer';
import referentielCouvertureSocialeReducer from './components/ReferentielCouvertureSocial/reducer';
import referentielPublicPriveReducer from './components/ReferentielPublicPrive/reducer';
import referentielDepartementFormation from './components/ReferentielDepartementFormation/reducer';
import referentielActiviteDecret from './components/ReferentielActiviteDecret/reducer';
import referentielActivite from './components/ReferentielActivite/reducer';
import referentielMotifDeRejetReducer from './components/ReferentielMotifDeRejet/reducer';
import referentielEntiteAdministrativeReducer from './components/ReferentielEntiteAdministrative/reducer';
import referentielDiplomeDistinctReducer from './components/ReferentielDiplomeDistinct/reducer';
import referentielCategoryDiplomeReducer from './components/ReferentielCategoryDiplome/reducer';
import referentielModeFormationReducer from './components/ReferentielModeFormation/reducer';
import referentielDureeReducer from './components/ReferentielDuree/reducer';
import referentielDiplomeReducer from './components/ReferentielDiplome/reducer';
import referentielDiplomeDureeReducer from './components/ReferentielDiplomeDuree/reducer';
import referentielEtablissementReducer from './components/ReferentielEtablissement/reducer';


const reducer = combineReducers({
    referentielVilleProvainceRegionsReducer,
    referentielActiviteDecret,
    referentielActivite,
    referentielMotifDeRejetReducer,
    referentielEntiteAdministrativeReducer,
    referentielAnnexeReducer,
    referentielModeFormationReducer,
    referentielDureeReducer,
    referentielDiplomeReducer,
    referentielDiplomeDureeReducer,
    referentielDiplomeDistinctReducer,
    referentielCategoryDiplomeReducer,
    referentielCouvertureSocialeReducer,
    referentielPublicPriveReducer,
    referentielDepartementFormation,
    referentielEtablissementReducer
})

export default reducer;