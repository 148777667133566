import types from './ActionTypes';

const initialeState = {
  loading: false,
  reclamations: [],
  activeReclamation: {},
  error: "",
  success: ""
};


const mapData = (data) => {
    let list = [];
    data.forEach(reclamation => {
      list.push({
        id: reclamation.id,
        statut: reclamation.statut,
        type: reclamation.type,
        reference: reclamation.reference,
        createdAt: reclamation.created_at,
        updatedAt: reclamation.updated_at,
        artisanType: reclamation.artisan?.artisan_type?.labels.FR,
        nom: reclamation.artisan?.nom,
        prenom: reclamation.artisan?.prenom,
        nomCooperative: reclamation.artisan?.nom_cooperative,
        nomEntreprise: reclamation.artisan?.nom_entreprise,
        telephone: reclamation.artisan?.telephone,
      })
    });

    return list;
}

function reducer(state = initialeState, action) {

  switch (action.type) {
    case types.RESET_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case types.GET_ALL_RECLAMATIONS_FOR_AGENT_PENDING: {

        return {
        ...state,
        error: "",
        success: "",
        loading: true,
        reclamations: [],
        activeReclamation: {}
      };
    }

    case types.GET_ALL_RECLAMATIONS_FOR_ARTISAN_PENDING: {

        return {
        ...state,
        error: "",
        success: "",
        loading: true,
        reclamations: [],
        activeReclamation: {}
      };
    }

    case types.GET_ONE_RECLAMATION_FOR_AGENT_PENDING: {

        return {
        ...state,
        error: "",
        success: "",
        loading: true,
        reclamations: [],
        activeReclamation: {}
      };
    }

    case types.GET_ONE_RECLAMATION_FOR_ARTISAN_PENDING: {

        return {
        ...state,
        error: "",
        success: "",
        loading: true,
        reclamations: [],
        activeReclamation: {}
      };
    }

    case types.CREATE_RECLAMATION_PENDING: {

        return {
        ...state,
        error: "",
        success: "",
        loading: true,
        reclamations: [],
        activeReclamation: {}
      };
    }

    case types.HANDLE_RECLAMATION_PENDING: {

        return {
        ...state,
        error: "",
        success: "",
        loading: true,
      };
    }

    case types.HANDLE_RECLAMATION_FULFILLED: {

      return {
      ...state,
      error: "",
      success: "",
      loading: false,
    };
  }

    case types.GET_ALL_RECLAMATIONS_FOR_ARTISAN_FULFILLED: {
      const data = action.payload.data.data;

      return {
        ...state,
        error: "",
        success: "",
        loading: true,
        reclamations: mapData(data)
      };
    }

    case types.GET_ALL_RECLAMATIONS_FOR_AGENT_FULFILLED : {
      const data = action.payload.data.data;
      
      return {
        ...state,
        error: "",
        success: "",
        loading: true,
        reclamations: mapData(data)
      };
    }

    case types.GET_ONE_RECLAMATION_FOR_AGENT_FULFILLED: {
      const data = action.payload.data.data;

      return {
        ...state,
        error: "",
        success: "",
        loading: true,
        activeReclamation: data
      };
    }

    case types.GET_ONE_RECLAMATION_FOR_ARTISAN_FULFILLED: {
      const data = action.payload.data.data;

      return {
        ...state,
        error: "",
        success: "",
        loading: true,
        activeReclamation: data
      };
    }

    case types.CREATE_RECLAMATION_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "Réclamation a été créé avec succès, vous serez notifié dès qu'un agent la traiter",
        loading: false,
      };
    }

    case types.GET_ALL_RECLAMATIONS_FOR_AGENT_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    case types.GET_ALL_RECLAMATIONS_FOR_ARTISAN_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    case types.GET_ONE_RECLAMATION_FOR_AGENT_REJECTED : {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    case types.GET_ONE_RECLAMATION_FOR_ARTISAN_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    case types.CREATE_RECLAMATION_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }
    case types.HANDLE_RECLAMATION_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        success: "",
        loading: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;