import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
    RESET_MESSAGES: 'RESET_MESSAGES',

    GET_PROFILES: 'GET_PROFILES',
    GET_PROFILES_PENDING: `GET_PROFILES_${ActionType.Pending}`,
    GET_PROFILES_FULFILLED: `GET_PROFILES_${ActionType.Fulfilled}`,
    GET_PROFILES_REJECTED: `GET_PROFILES_${ActionType.Rejected}`,
};

export default ActionTypes;