import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  settingsList: []
};


const mapDataToSettingsLIst = data => {

  const list = [];
  data.map(settings => {
    list.push(
      {
        key: settings.id,
        code: settings.code,
        description: settings.description,
        type: settings.type,
        value: settings.value,
      })
  })
  return list;
}


function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case types.GET_ALL_SETTING_PENDING: {
      return {
        ...state,
        error: "",
        success: "",
        loading: true,
        settingsList: []
      };
    }

    case types.GET_ALL_SETTING_FULFILLED: {
      console.log('INSIDEEEEEE')
      const settingsList = action.payload.data.data;

      return {
        ...state,
        error: "",
        success: "",
        loading: false,
        settingsList: mapDataToSettingsLIst(settingsList)
      };
    }

    case types.GET_ALL_SETTING_REJECTED: {

      return {
        ...state,
        error: "error get all settings",
        success: "",
        loading: false,
        settingsList: []
      };
    }

    default:
      return state;
  }
}

export default reducer;