import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
    GET_REFERENTIELS: 'GET_REFERENTIELS',
    GET_REFERENTIELS_PENDING: `GET_REFERENTIELS_${ActionType.Pending}`,
    GET_REFERENTIELS_FULFILLED: `GET_REFERENTIELS_${ActionType.Fulfilled}`,
    GET_REFERENTIELS_REJECTED: `GET_REFERENTIELS_${ActionType.Rejected}`,

    ADD_NEW_ESTABLISHMENT: 'ADD_NEW_ESTABLISHMENT',
    ADD_NEW_ESTABLISHMENT_PENDING: `ADD_NEW_ESTABLISHMENT_${ActionType.Pending}`,
    ADD_NEW_ESTABLISHMENT_FULFILLED: `ADD_NEW_ESTABLISHMENT_${ActionType.Fulfilled}`,
    ADD_NEW_ESTABLISHMENT_REJECTED: `ADD_NEW_ESTABLISHMENT_${ActionType.Rejected}`,

    GET_ANNEXE_ADMINISTRATIVE: 'GET_ANNEXE_ADMINISTRATIVE',
    GET_ANNEXE_ADMINISTRATIVE_PENDING: `GET_ANNEXE_ADMINISTRATIVE_${ActionType.Pending}`,
    GET_ANNEXE_ADMINISTRATIVE_FULFILLED: `GET_ANNEXE_ADMINISTRATIVE_${ActionType.Fulfilled}`,
    GET_ANNEXE_ADMINISTRATIVE_REJECTED: `GET_ANNEXE_ADMINISTRATIVE_${ActionType.Rejected}`,

    GET_ACTIVITE: 'GET_ACTIVITE',
    GET_ACTIVITE_PENDING: `GET_ACTIVITE_${ActionType.Pending}`,
    GET_ACTIVITE_FULFILLED: `GET_ACTIVITE_${ActionType.Fulfilled}`,
    GET_ACTIVITE_REJECTED: `GET_ACTIVITE_${ActionType.Rejected}`,

    GET_ACTIVITE_BRANCHE: 'GET_ACTIVITE_BRANCHE',
    GET_ACTIVITE_BRANCHE_PENDING: `GET_ACTIVITE_BRANCHE_${ActionType.Pending}`,
    GET_ACTIVITE_BRANCHE_FULFILLED: `GET_ACTIVITE_BRANCHE_${ActionType.Fulfilled}`,
    GET_ACTIVITE_BRANCHE_REJECTED: `GET_ACTIVITE_BRANCHE_${ActionType.Rejected}`,

    GET_ACTIVITE_FILIERE: 'GET_ACTIVITE_FILIERE',
    GET_ACTIVITE_FILIERE_PENDING: `GET_ACTIVITE_FILIERE_${ActionType.Pending}`,
    GET_ACTIVITE_FILIERE_FULFILLED: `GET_ACTIVITE_FILIERE_${ActionType.Fulfilled}`,
    GET_ACTIVITE_FILIERE_REJECTED: `GET_ACTIVITE_FILIERE_${ActionType.Rejected}`,

    GET_ETABLISSEMENT: 'GET_ETABLISSEMENT',
    GET_ETABLISSEMENT_PENDING: `GET_ETABLISSEMENT_${ActionType.Pending}`,
    GET_ETABLISSEMENT_FULFILLED: `GET_ETABLISSEMENT_${ActionType.Fulfilled}`,
    GET_ETABLISSEMENT_REJECTED: `GET_ETABLISSEMENT_${ActionType.Rejected}`,

    GET_DUREE: 'GET_DUREE',
    GET_DUREE_PENDING: `GET_DUREE_${ActionType.Pending}`,
    GET_DUREE_FULFILLED: `GET_DUREE_${ActionType.Fulfilled}`,
    GET_DUREE_REJECTED: `GET_DUREE_${ActionType.Rejected}`,

    GET_COUVERTURE_SOCIALE: 'GET_COUVERTURE_SOCIALE',
    GET_COUVERTURE_SOCIALE_PENDING: `GET_COUVERTURE_SOCIALE_${ActionType.Pending}`,
    GET_COUVERTURE_SOCIALE_FULFILLED: `GET_COUVERTURE_SOCIALE_${ActionType.Fulfilled}`,
    GET_COUVERTURE_SOCIALE_REJECTED: `GET_COUVERTURE_SOCIALE_${ActionType.Rejected}`,

    GET_DIPLOME: 'GET_DIPLOME',
    GET_DIPLOME_PENDING: `GET_DIPLOME_${ActionType.Pending}`,
    GET_DIPLOME_FULFILLED: `GET_DIPLOME_${ActionType.Fulfilled}`,
    GET_DIPLOME_REJECTED: `GET_DIPLOME_${ActionType.Rejected}`,

    GET_DIPLOME_DUREE: 'GET_DIPLOME_DUREE',
    GET_DIPLOME_DUREE_PENDING: `GET_DIPLOME_DUREE_${ActionType.Pending}`,
    GET_DIPLOME_DUREE_FULFILLED: `GET_DIPLOME_DUREE_${ActionType.Fulfilled}`,
    GET_DIPLOME_DUREE_REJECTED: `GET_DIPLOME_DUREE_${ActionType.Rejected}`,

};

export default ActionTypes;
