import React, { useEffect, useState } from 'react';
import {
    Row, Col,
    Space,
    Button,
    Card,
    Form,
    Alert,
} from "antd";
import filter from 'lodash.filter';
import { useSelector } from 'react-redux';
import UploadFile from '../../../../../component/UploadFile/UploadFile';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useHistory } from 'react-router';
import { AddBoutonWithDoubleLabel } from '../../../../../utilities/FormFields/FormFields';


const UploadDocument = props => {
    const { t } = useTranslation();
    const { uploadArtisanFile, removeArtisanFile, validateSubscription, artisanId, artisanType, documents } = props;
    const history = useHistory();
    const [fileToUpload, setFileToUplod] = useState([]);
    const [numberOfFile, setNumberOfFile] = useState(0);
    const [disable, setDisable] = useState(true);
    const documentTypes = useSelector(state => state.registration?.documentTypes);
    var documentIds = useSelector(state => state.registration?.documentIds);
    const otherDocumentIds = useSelector(state => state.registration?.otherDocumentIds);
    const message = useSelector(state => state.registration?.error?.message);
    const language = i18n.language;

    useEffect(() => {
        const pathname = history.location?.pathname;
        setNumberOfFile(documentTypes.length);
        let fileList = [];
        documentTypes.map(documentType => {
            fileList = [...fileList, { id: documentType.id, uploaded: false }];
        })
        setFileToUplod(fileList);

        if (!pathname.includes('souscrire')) {
            setDisable(false);
        }
    }, []);

    const changeUploadedFileStatus = fileId => {
        const newFileToUpload = fileToUpload;
        const file = filter(newFileToUpload, ['id', fileId])[0];
        const newFileStatusToUpload = { ...file, uploaded: true };
        const indexOfFile = newFileToUpload.indexOf(file);
        newFileToUpload[indexOfFile] = newFileStatusToUpload
        setDisable(filter(newFileToUpload, ['uploaded', true]).length < 1)
        setFileToUplod(newFileToUpload);
    }
    const handelRemoveFile = (id) => {
        const newFileToUpload = fileToUpload;
        const file = filter(newFileToUpload, ['id', id])[0];
        const newFileStatusToUpload = { ...file, uploaded: false };
        const indexOfFile = newFileToUpload.indexOf(file);
        newFileToUpload[indexOfFile] = newFileStatusToUpload

        removeDocumentId(id)
        setDisable(filter(newFileToUpload, ['uploaded', true]).length < 1)
        setFileToUplod(newFileToUpload);
    }

    const removeArtisanFileCustom = (artisanId, file) => {
        console.log('removeArtisanFileCustom')
        return removeArtisanFile(artisanId, file)/*.then(() => {
            //handelRemoveFile
            //this.props.getArtisanFilesList(artisanId);
            //setFileToUplod([...fileToUpload]);
        })*/
    }

    const getDocumentId = (typeId) => {
        console.log('getDocumentId triggered')
        console.log(documentIds)
        if (typeId !== '9999') {
            var dd = documentIds.filter(docId => docId.typeId === typeId);
            return dd[0];
        }
    }

    const removeDocumentId = (typeId) => {
        documentIds = documentIds.filter(docId => docId.typeId !== typeId);
    }

    const disabledAddOthers = (fieldsLength) => {
        return fieldsLength > otherDocumentIds.length;
    }



    const getInitData = () => {
        return { "autre_document": otherDocumentIds }
    }


    return (
        <Card  >
            {message &&
                <Row>
                    <Col>
                        <Alert
                            message={t('component.upload-document.alert')}
                            description={message}
                            type="error"
                            closable
                        />
                    </Col>
                </Row>}
            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }} align="middle">
                <Space direction="vertical" size="middle" className="w-100">
                    <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
                        <Space direction="vertical" size="middle" className="w-100">
                            {documentTypes.map(documentType => {
                                return (<UploadFile key={documentType.id} onRemoveAction={handelRemoveFile} artisanId={artisanId} uploadArtisanFile={uploadArtisanFile} description={language === "ar" ? documentType.labels.AR : documentType.labels.FR} documentTypeId={documentType.id} documentId={getDocumentId(documentType.id)} changeUploadedFileStatus={changeUploadedFileStatus} handleRemove={removeArtisanFile} />)
                            })}

                            <Form initialValues={getInitData()}>
                                <Form.List name="autre_document">
                                    {(fields, { add, remove }) => (
                                        <>
                                            <Space direction="vertical" size="middle" className="w-100">
                                                {fields.map((field, index) => (<>
                                                    <UploadFile onRemoveAction={handelRemoveFile} style={{ paddingBottom: '10px' }} artisanId={artisanId} uploadArtisanFile={uploadArtisanFile} description={t('component.upload-document.autre')} documentTypeId={"9999"} documentId={otherDocumentIds[index]} changeUploadedFileStatus={changeUploadedFileStatus} handleRemove={removeArtisanFile} />
                                                </>
                                                ))}
                                            </Space >
                                            <Row style={{ width: '100%', paddingTop: '10px' }} justify='center'>
                                                <Col span={12}>
                                                    <AddBoutonWithDoubleLabel label={t('component.upload-document.ajout-autre')} action={add} disabled={disabledAddOthers(fields.length)} />
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Form.List >
                            </Form >
                        </Space >
                        <Row style={{ padding: '21px', justifyContent: 'space-between' }}>
                            <Button
                                shape="round"
                                size="middle"
                                style={{ margin: '0 8px' }}
                                onClick={() => { props.prev() }}
                            >
                                {t('component.education-data.form.reset.label')}
                            </Button>
                            <Button shape="round" type="primary" htmlType="submit" size="middle" onClick={validateSubscription}
                                disabled={disable} >
                                {t('component.upload-document.submit.label')}
                            </Button>
                        </Row>
                    </Col >
                </Space >
            </Row >
        </Card >
    );
};

export default UploadDocument;
