import React, { Component } from "react"
import { connect, reset } from "react-redux";
import { Table, Input, Button, Space, Divider, Modal, Col, Row, Alert } from 'antd';
import { EditOutlined , EyeOutlined } from '@ant-design/icons';
import { withTranslation } from "react-i18next";
import { Fragment } from "react";
import { getProfils, getProfileMenus, assignMenuActions, clearMessages } from "./Action";
import SpinLoader from '../../../component/SpinLoader/SpinLaoder';
import HabilitationForm from "./components/HabilitationForm";
import keysIn from 'lodash.keysin';
import Title from 'antd/lib/typography/Title';
import history from '../../../history';

class Habilitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      visible: false,
    }
    this.updateHabilitation = this.updateHabilitation.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }


  componentDidMount() {
    const { getProfils, clearMessages } = this.props;
    getProfils();
    clearMessages();

  }

  showModal(id, disabled) {
    const { getProfileMenus } = this.props;
    this.setState({ ...this.state, disabled: disabled, visible: true });
    getProfileMenus(id);
  }

  renderHabilitation() {
    const { t, profiles } = this.props;
    const columns = [
      {
          title: t('agent.habilitation.table.profil'),
          dataIndex: 'description',
          key: 'description',
      },
      {
          title: t('agent.habilitation.table.operation'),
          dataIndex: 'action',
          key: Math.random,
          render: (text, record, index) => (
          <Row align='middle'>
            <Col span={4}>
                        <a onClick={ () => this.showModal(record.id,true) }> 
                            <EyeOutlined /> 
                        </a>
            </Col>

            <Col span={4} size="middle">
                        <a onClick={ () => this.showModal(record.id,false) }> 
                          <EditOutlined />
                        </a>
            </Col>
          </Row>
          ),
      },
  ];
    
    return (
      <div>
          <Title span={3}> {t('agent.habilitation.title')} </Title>
          <div className="row card-body pt-0">
              <Table columns={columns} dataSource={profiles.filter(profile => profile.code !== 'ARTISAN')}  pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}/>
          </div>
      </div>
    );
  }

  render() {
    const { loading , loadingGetActiveProfil ,error, success, t} = this.props

    return (
      <div className="container-fluid ">
        {loading && loadingGetActiveProfil ? (
          <SpinLoader />
        ) : (
          <Fragment>
            {error &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={error}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {success &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={success}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }
                <Row justify="end" align="middle" style={{ paddingBottom: '15px'}}>
                <Button shape='round' type="primary" htmlType="submit" onClick={() => history.push('/app/agent/habilitation/add')}>
                    {t('agent.habilitation.add-profile')}
                </Button>
        </Row>

            {this.renderHabilitation()}
          </Fragment>
        )}
        {this.renderHabilitationModal()}
      </div>)
  }

  updateHabilitation(value) {
    const { activeProfile: { id } } = this.props;

    const { assignMenuActionsToUserMenu } = this.props;
    const keys = keysIn(value).filter(key => key === "id" || key === "profile" || key === "description" ? false : true);
    const actions = keys.filter(key => value[key] === true )
    const menuAction = { actions: actions, profileId: id };

    assignMenuActionsToUserMenu(menuAction).then(res => {
      this.closeModal();
    }).catch( err => {
      this.closeModal();
    });
  }

  closeModal() {
    this.setState({ ...this.state, visible: false });
  }

  renderHabilitationModal() {
    const { activeProfile: { menus }, menuActions} = this.props;

    return(
          <HabilitationForm
              visible={this.state.visible}
              close={this.closeModal}
              initialValues={{ ...menuActions }}
              menus={menus}
              disabled={this.state.disabled}
              changeMode={() => { this.setState({ ...this.state, disabled: !this.state.disabled }) }}
              handleSubmit={this.updateHabilitation}
            />)
  }
}

const mapStateToProps = (state) => {
  return {
    profiles: state.habilitationReducer.profiles,
    menuActions: state.habilitationReducer.menuActions,
    activeProfile: state.habilitationReducer.activeProfile,
    loading: state.habilitationReducer.loading,
    loadingGetActiveProfil: state.habilitationReducer.loadingGetActiveProfil,
    error: state.habilitationReducer.error,
    success: state.habilitationReducer.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProfils: () => dispatch(getProfils()),
  getProfileMenus: (id) => dispatch(getProfileMenus(id)),
  assignMenuActionsToUserMenu: (profileValueActions) => dispatch(assignMenuActions(profileValueActions)),
  clearMessages: () => dispatch(clearMessages())
  //clearForm: formName => dispatch(reset(formName))
});

Habilitation = connect(mapStateToProps, mapDispatchToProps)(Habilitation);
export default withTranslation()(Habilitation);
