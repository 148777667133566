import i18n from 'i18next';

const ARTISANT_TYPE = {
    ARTISAN_AVEC_DIPLOME: 'ARTISAN_AVEC_DIPLOME',
    ARTISAN_AVEC_EXPERIENCE: `ARTISAN_AVEC_EXPERIENCE`,
    ARTISAN_MAALAM: `ARTISAN_MAALAM`,
    COOPERATIVE_ARTISANAT: `COOPERATIVE_ARTISANAT`,
    ENTREPRISE_ARTISANAT: `ENTREPRISE_ARTISANAT`,
}

const ARTISANT_STATUS = {
    EN_COURS_DE_VALIDATION: 'EN_COURS_DE_VALIDATION',
    VALIDE_NIVEAU_2: 'VALIDE_NIVEAU_2',
    VALIDE_NIVEAU_1: 'VALIDE_NIVEAU_1',
    FINALISE: 'FINALISE',
    INITIE: 'INITIE',
    REJETE: 'REJETE',
    VALIDE: 'VALIDE',
    RADIE: "RADIE",
    SUPPRIME: "SUPPRIME",
    EXPIRE: "EXPIRE"
}

const AGENT_PROFILES = {
    VALIDATEUR_LOCAL_1: 'VALIDATEUR_LOCAL_1',
    VALIDATEUR_LOCAL_2: 'VALIDATEUR_LOCAL_2',
    VALIDATEUR_LOCAL_3: 'VALIDATEUR_LOCAL_3',
    VALIDATEUR_CENTRAL_1: 'VALIDATEUR_CENTRAL_1',
    VALIDATEUR_CENTRAL_2: 'VALIDATEUR_CENTRAL_2',
    ADMIN: 'ADMIN'
}

const USER_LEVEL = {
    LOCAL: 'LOCAL',
    CENTRAL: `CENTRAL`,
}
const LANGUAGUES = {
    FR: 'fr',
    AR: 'ar',
}

const USER_TYPES = {
    AGENT: 'AGENT',
    ARTISAN: 'ARTISAN'
}

const REFERENTIEL_TYPES = {
    REGIONS: 'regions',
    REGION: 'region',
    PROVINCES: 'provinces',
    PROVINCE: 'province',
    VILLES: 'villes',
    VILLE: 'ville',
    ANNEXE: 'annexe',
    ANNEXE_ADMINISTRATIVE: 'annexe',
    COUVERTURE_SOCIALE: 'couverture-sociale',
    PUBLIC_PRIVE: 'public-prive',
    MOTIF_REJET: 'motif-de-rejet',
    ENTITE_ADMINISTRATIVE: 'entite-administrative',
    DEPARTEMENT_FORMATION: 'departement-formation',
    ACTIVITE_BRANCHE_DECRET: 'activite-branche-decret',
    ACTIVITE_FILIERE_DECRET: 'activite-filiere-decret',
    ACTIVITE_DECRET: 'activite-decret',
    ACTIVITE_BRANCHE: 'activite-branche',
    ACTIVITE_FILIERE: 'activite-filiere',
    ACTIVITE: 'activite',
    CATEGORIE_DIPLOME: 'categorie-diplome',
    DIPLOME_DISTINCT: 'diplome-distinct',
    DIPLOME: 'diplome',
    MODE_FORMATION: 'mode-formation',
    ESTABLISHMENT: 'etablisement',
    DUREE: 'duree',
    DIPLOME_DUREE: 'diplome-duree',
}

const REFERENTIEL_ENTITY_TYPES = {
    ANNEXE_ADMINISTRATIVE: 'ANNEXE_ADMINISTRATIVE',
    COUVERTURE_SOCIALE: 'COUVERTURE_SOCIALE',
    PUBLIC_PRIVE: 'PUBLIC_PRIVE',
    MOTIF_REJET: 'MOTIF_REJET',
    DUREE: 'DUREE',
    ENTITE_ADMINISTRATIVE: 'ENTITE_ADMINISTRATIVE',
    DEPARTEMENT_FORMATION: 'DEPARTEMENT_FORMATION',
    ACTIVITE_BRANCHE_DECRET: 'ACTIVITE_BRANCHE_DECRET',
    ACTIVITE_FILIERE_DECRET: 'ACTIVITE_FILIERE_DECRET',
    ACTIVITE_DECRET: 'ACTIVITE_DECRET',
    ACTIVITE_BRANCHE: 'ACTIVITE_BRANCHE',
    ACTIVITE_FILIERE: 'ACTIVITE_FILIERE',
    ACTIVITE: 'ACTIVITE',
    CATEGORIE_DIPLOME: 'CATEGORIE_DIPLOME',
    DIPLOME_DISTINCT: 'DIPLOME_DISTINCT',
    DIPLOME: 'DIPLOME',
    DIPLOME_DUREE: 'DIPLOME_DUREE',
    MODE_FORMATION: 'MODE_FORMATION',
    ESTABLISHMENT: 'ESTABLISHMENT',

}

const mapNumberToPhoneNumber = phoneNumber => phoneNumber && "+212" + phoneNumber.slice(1);

const mapPhoneNumberToNumber = phoneNumber => phoneNumber && phoneNumber.replace("+212", 0);

const statusToShow = [
    {
        'agent_profile': AGENT_PROFILES.VALIDATEUR_LOCAL_1,
        'artisan_status': [ARTISANT_STATUS.FINALISE, ARTISANT_STATUS.EN_COURS_DE_VALIDATION],
    },
    {
        'agent_profile': AGENT_PROFILES.VALIDATEUR_LOCAL_2,
        'artisan_status': [ARTISANT_STATUS.VALIDE_NIVEAU_1],
    },
    {
        'agent_profile': AGENT_PROFILES.VALIDATEUR_LOCAL_3,
        'artisan_status': [ARTISANT_STATUS.VALIDE_NIVEAU_2],
    },
    {
        'agent_profile': AGENT_PROFILES.VALIDATEUR_CENTRAL_1,
        'artisan_status': [ARTISANT_STATUS.FINALISE, ARTISANT_STATUS.VALIDE_NIVEAU_1, ARTISANT_STATUS.EN_COURS_DE_VALIDATION],
    },
    {
        'agent_profile': AGENT_PROFILES.VALIDATEUR_CENTRAL_2,
        'artisan_status': [ARTISANT_STATUS.VALIDE_NIVEAU_2],
    },
]

const getStatusToShow = (activeProfile) => {
    let status = [];
    statusToShow.map(line => {
        if (line.agent_profile === activeProfile) { status = line.artisan_status }
    })

    return status;
}


const statusReclamation = [
    { 'id': 'RECLAMATION_SAISIE', 'name': { 'FR': "Réclamation saisie", 'AR': "Réclamation saisie" } },
    { 'id': 'RECLAMATION_ENCOURS_DE_TRAITEMENT', 'name': { 'FR': "Réclamation en cours de traitement", 'AR': "Réclamation en cours de traitement" } },
    { 'id': 'RECLAMATION_TRAITE', 'name': { 'FR': "Réclamation traitée", 'AR': "Réclamation traitée" } },
]

const statusDemandeInformation = [
    { 'id': 'DEMANDE_INFORMATION_SAISIE', 'name': { 'FR': "Demande d'information saisie", 'AR': "Demande d'information saisie" } },
    { 'id': 'DEMANDE_INFORMATION_ENCOURS_DE_TRAITEMENT', 'name': { 'FR': "Demande d'information En cours de traitement", 'AR': "Demande d'information En cours de traitement" } },
    { 'id': 'DEMANDE_INFORMATION_TRAITE', 'name': { 'FR': "Demande d'information traitée", 'AR': "Demande d'information traitée" } },
]

const typeDemande = [
    { 'id': 'RECLAMATION', 'name': { 'FR': "Réclamation", 'AR': "شكاية" } },
    { 'id': 'DEMANDE_INFORMATION', 'name': { 'FR': "Demande d'information", 'AR': "طلب للحصول على معلومات" } },
]


const Operateurs = [
    { 'id': 'IAM', 'name': { 'FR': "Maroc Telecom", 'AR': "اتصالات المغرب" } },
    { 'id': 'ORANGE', 'name': { 'FR': "Orange", 'AR': "Orange" } },
    { 'id': 'INWI', 'name': { 'FR': "Inwi", 'AR': "Inwi" } },
]

const TEXT_TYPE = {
    ARABIC: "arabic",
    ALL_SPECIAL_WITHOUT_SPACE: "all_special_char_no_space",
    ALL_SPECIAL: "all_special_char_and_space",
    SPECIAL: "special",
    ALPHANUMERIC: "alphanumeric",
    NUMERIC: "numeric",
    ALPHABETIC: "alphabetic",
    ALPHABETIC_WITH_SPACE: "alphabeticWithSpace",
    NAME_WITH_SPACE: "nameWithSpace",
    ANY_LANGUAGE: "anyLanguage",
    ANY_LANGUAGE_WITH_SPACE: "anyLanguageWithSpace"
}
const commonKeyboardOptions = {
    theme: "simple-keyboard hg-theme-default hg-layout-default",
    physicalKeyboardHighlight: true,
    syncInstanceInputs: true,
    mergeDisplay: true,
    debug: true
};

const staticInternationalization = {
    nomFR: 'Nom',
    nomAR: 'الاسم العائلي',
    prenomFR: 'Prénom',
    prenomAR: 'الاسم الشخصي',
    adressePersonnelleFR: 'Adresse personnelle',
    adressePersonnelleAR: 'العنوان الشخصي',
    adresseProfessionelleFR: 'Adresse professionnelle',
    adresseProfessionelleAR: 'عنوان مقر العمل',
    champObligatoireFR: "Ce champs est obligatoire",
    champObligatoireAR: "هذا الحقل ضروري  ",
}
const identites = [
    {
        id: "CNIE",
        name: {
            AR: "بطاقة التعريف الوطنية",
            FR: "CNIE"
        }
    },
    {
        id: "Carte de séjour",
        name: {
            AR: "بطاقة الإقامة للأجانب",
            FR: "Carte de séjour"
        }
    }
];
const TYPE_IDENTITIES = {
    CNIE: "CNIE",
    CARTE_SEJOUR: "CARTE_SEJOUR"
}

const KEYBOARDO_PTIONS = {
    ...commonKeyboardOptions,

    layout: {
        default: [
            "ذ 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
            "{tab} ض ص ث ق ف غ ع ه خ ح ج د \\",
            "{lock} ش س ي ب ل ا ت ن م ك ط {enter}",
            "{shift} ئ ء ؤ ر لا ى ة و ز ظ {shift}",
            ".com @ {space}",
        ],
        shift: [
            " ! @ # $ % ^ & * ) ( _ +  {backspace}",
            "{tab} َ ً ُ ٌ لإ إ ‘ ÷ × ؛ < > |",
            "{lock} ِ ٍ ] [ لأ أ ـ ، / : \" {enter}",
            "{shift} ~ ْ } { لآ آ ’ , . ؟ {shift}",
            ".com @ {space}"
        ]
    }
    ,
    display: {
        "{escape}": "esc ⎋",
        "{tab}": "tab ⇥",
        "{backspace}": "backspace ⌫",
        "{enter}": "enter ↵",
        "{capslock}": "caps lock ⇪",
        "{shiftleft}": "shift ⇧",
        "{shiftright}": "shift ⇧",
        "{controlleft}": "ctrl ⌃",
        "{controlright}": "ctrl ⌃",
        "{altleft}": "alt ⌥",
        "{altright}": "alt ⌥",
        "{metaleft}": "cmd ⌘",
        "{metaright}": "cmd ⌘"
    }
};

const REGEXS = {
    cnie: new RegExp(/^([A-Za-z]{1,2}[0-9]{2,6})$/),
    ALL_SPECIAL: new RegExp(/^[a-zA-ZÀ-ú -_0-9/]+$/),
    ALPHANUMERIC: new RegExp(/^[a-zA-Z0-9]+$/),
    NUMERIC: new RegExp(/^[0-9]+$/),
    SPECIAL: new RegExp(/^[À-úa-zA-Z0-9/]+$/),
    ALPHABETIC_WITH_SPACE: new RegExp(/^[A-zÀ-ú ]*$/)
};


const PAGE_SEARCH_DEFAULT = {
    page: 1,
    pageSize: 10,
    withCount: true,
    sortField: undefined,
    sortDirection: undefined,
};

const DEFAULT_PAGINATION = {
    current: PAGE_SEARCH_DEFAULT.page,
    defaultPageSize: PAGE_SEARCH_DEFAULT.pageSize,
    pageSize: PAGE_SEARCH_DEFAULT.pageSize,
    pageSizeOptions: ['10', '20',  '50', '100', '1000', '5000', '10000'],
    showSizeChanger: true,
};
export default {
    mapNumberToPhoneNumber,
    mapPhoneNumberToNumber,
    LANGUAGUES,
    ARTISANT_TYPE,
    ARTISANT_STATUS,
    USER_LEVEL,
    getStatusToShow,
    AGENT_PROFILES,
    REFERENTIEL_ENTITY_TYPES,
    REFERENTIEL_TYPES,
    USER_TYPES,
    statusReclamation,
    statusDemandeInformation,
    TEXT_TYPE,
    KEYBOARDO_PTIONS,
    typeDemande,
    Operateurs,
    staticInternationalization,
    identites,
    TYPE_IDENTITIES,
    REGEXS,
    PAGE_SEARCH_DEFAULT,
    DEFAULT_PAGINATION
}
