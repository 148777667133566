import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_ANNEXE_ADMINISTRATIVE: 'GET_ANNEXE_ADMINISTRATIVE',
    GET_ANNEXE_ADMINISTRATIVE_PENDING: `GET_ANNEXE_ADMINISTRATIVE_${ActionType.Pending}`,
    GET_ANNEXE_ADMINISTRATIVE_FULFILLED: `GET_ANNEXE_ADMINISTRATIVE_${ActionType.Fulfilled}`,
    GET_ANNEXE_ADMINISTRATIVE_REJECTED: `GET_ANNEXE_ADMINISTRATIVE_${ActionType.Rejected}`,

    ADD_ANNEXE_ADMINISTRATIVE: 'ADD_ANNEXE_ADMINISTRATIVE',
    ADD_ANNEXE_ADMINISTRATIVE_PENDING: `ADD_ANNEXE_ADMINISTRATIVE_${ActionType.Pending}`,
    ADD_ANNEXE_ADMINISTRATIVE_FULFILLED: `ADD_ANNEXE_ADMINISTRATIVE_${ActionType.Fulfilled}`,
    ADD_ANNEXE_ADMINISTRATIVE_REJECTED: `ADD_ANNEXE_ADMINISTRATIVE_${ActionType.Rejected}`,

    UPDATE_ANNEXE_ADMINISTRATIVE: 'UPDATE_ANNEXE_ADMINISTRATIVE',
    UPDATE_ANNEXE_ADMINISTRATIVE_PENDING: `UPDATE_ANNEXE_ADMINISTRATIVE_${ActionType.Pending}`,
    UPDATE_ANNEXE_ADMINISTRATIVE_FULFILLED: `UPDATE_ANNEXE_ADMINISTRATIVE_${ActionType.Fulfilled}`,
    UPDATE_ANNEXE_ADMINISTRATIVE_REJECTED: `UPDATE_ANNEXE_ADMINISTRATIVE_${ActionType.Rejected}`,
};

export default ActionTypes;
