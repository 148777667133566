import React, { useEffect, useState } from 'react';
import {
  Row, Col, Form,
  Space,
  Button,
  Card,
  Input,
  Select,
} from "antd";
import './RepresentativePersonalData.less'
import { saveRepresentantData, updateRepresentantData } from '../../Action';
import { InputCNIEWithDoubleLabel, InputWithDoubleLabel, InputPhoneNumberWithDoubleLabel, SeletWithDoubleLabel, InputCNIEWithTypeIdentity } from '../../../../../utilities/FormFields/FormFields'
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Utils from '../../../../../utilities/Utils/Utils';
import Text from 'antd/lib/typography/Text';
import i18next from 'i18next';
import { persistor } from '../../../../../configureStore';
const { Option } = Select;

const RepresentativePersonalData = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [identity, setIdentity] = useState(Utils.TYPE_IDENTITIES.CNIE);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const artisanType = useSelector(state => state.loginReducer?.artisan?.artisan_type?.code);
  const pathname = history.location?.pathname;

  const onFinish = formValue => {
    const { next, saveRepresentantData, artisanId, initData } = props;
    if (!pathname.includes('souscrire') || initData) {
      dispatch(updateRepresentantData(artisanId, formValue)).then(result => {
        persistor.flush();
        next();
      });
      // next();
    } else {
      saveRepresentantData(artisanId, formValue).then(result => {
        persistor.flush();
        next();
      });
    }
  }

  useEffect(() => {
    if (props?.initData) {
      setIdentity(props.initData?.type_identite)
    }
  }, [])

  const onChangeIdentity = e => {
    setIdentity(e)
  }

  const getInitData = () => {
    const artisanName = {
      nom: i18next.language === "fr" ? props.user?.lastName : props.user?.last_name_ar,
      prenom: i18next.language === "fr" ? props.user?.firstName : props.user?.first_name_ar
    }
    return { ...props.initData, ...artisanName, type_identite: identity };
  }

  return (
    <Card  >
      <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }} align="middle">
        <Space direction="vertical" size="middle" className="w-100">
          <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
            <Form form={form}
              name="registration"
              style={{ width: '100%' }}
              onFinish={onFinish}
              size='small'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              scrollToFirstError
              initialValues={getInitData()}
            >
              <Col span={24}>
                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="nom" label={t(`component.representative-personal-data.form.nom.label.${artisanType === 'ENTREPRISE_ARTISANAT' ? 'entreprise' : 'cooperative'}`)} placeholder={t('component.representative-personal-data.form.nom.placeholder')} errorMessage={t('component.representative-personal-data.form.nom.error-message')} />
              </Col>

              <Col span={24}>
                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="prenom" label={t(`component.representative-personal-data.form.prenom.label.${artisanType === 'ENTREPRISE_ARTISANAT' ? 'entreprise' : 'cooperative'}`)} placeholder={t('component.representative-personal-data.form.prenom.placeholder')} errorMessage={t('component.representative-personal-data.form.prenom.error-message')} />
              </Col>

              <Col span={24}>
                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="poste" label={t(`component.representative-personal-data.form.poste.label.${artisanType === 'ENTREPRISE_ARTISANAT' ? 'entreprise' : 'cooperative'}`)} placeholder={t('component.representative-personal-data.form.poste.placeholder')} errorMessage={t('component.representative-personal-data.form.poste.error-message')} />
              </Col>
              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <InputCNIEWithTypeIdentity
                  cnieName="cnie"
                  typeIdentityName="type_identite"
                  typeIdentitValue={identity}
                  label={t(`component.personal-data.form.${identity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.label`)}
                  typeIdentityErrorMessage={t('component.personal-data.form.typeIdentite.error-message')}
                  errorMessage={t(`component.representative-personal-data.form.${identity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.error-message`)}
                  options={Utils.identites}
                  onChangeAction={onChangeIdentity} />
              </Row>

              <Col span={24}>
                <InputWithDoubleLabel form={form} type="anyLanguageWithSpace" name="adresse" label={t('component.representative-personal-data.form.adresse.label')} placeholder={t('component.representative-personal-data.form.adresse.placeholder')} errorMessage={t('component.representative-personal-data.form.adresse.error-message')} />
              </Col>

              <Col span={24}>
                <InputPhoneNumberWithDoubleLabel type="text" name="numero_telephone" label={t('component.representative-personal-data.form.numero_telephone.label')} placeholder={t('component.representative-personal-data.form.numero_telephone.placeholder')} errorMessage={t('component.representative-personal-data.form.numero_telephone.error-message')} />
              </Col>


              <Row style={{ padding: '21px', justifyContent: 'space-between' }}>
                <Button
                  shape="round"
                  size={"middle"}
                  style={{ margin: '0 8px' }}
                  onClick={() => { props.prev() }}
                >
                  {t('component.representative-personal-data.form.reset.label')}
                </Button>
                <Button shape="round" type="primary" htmlType="submit" size={"middle"} >
                  {t('component.representative-personal-data.form.submit.label')}
                </Button>
              </Row>
            </Form>
          </Col>
        </Space>
      </Row >
    </Card >
  );

};


const mapStateToProps = (state) => ({
  artisanId: state.loginReducer.artisan.artisan_id,
  user: state.loginReducer.user,
});

const mapDispatchToProps = { saveRepresentantData };

export default connect(mapStateToProps, mapDispatchToProps)(RepresentativePersonalData);
