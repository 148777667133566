import React, { useEffect } from 'react';
import { Card, Timeline } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getArtisanEvents } from './Action'
import './ArtisanNotification.less'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const ArtisanNotification = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const artisanId = useSelector(state => state?.loginReducer?.artisan?.artisan_id);
    const artisanEvents = useSelector(state => state?.artisanEventReducer?.artisanEvents);
    const langague = i18n.language;

    useEffect(() => {
        dispatch(getArtisanEvents(artisanId))
    }, [])

    return (
        <Card title={t('artisan.artisan-notification.title')} bordered={false} style={{ textAlign: 'center', padding: "0" }}>
            <div style={{
                display: 'flex',
                overflow: 'hidden scroll',
                height: '70vh',
            }}>
                <Timeline mode={'left'} style={{ width: '100%' }} >
                    {artisanEvents && artisanEvents.map((event, index) => {
                        return <Timeline.Item key={index} label={<span style={{ fontSize: '12px', width: '100%' }}>{event.date_evenement}</span>}>
                            <span style={{
                                fontSize: "12px",
                                fontWeight: "649"
                            }}>
                                <span>{t(`artisan.artisan-notification.notification.${event.type_evement}`)} </span>
                                <span > {event.type_evement === 'DOSSIER_SOUMI_A_VALIDATION' && event.commentaire}</span>
                                <span style={{ color: '#f5222d' }}> {['DOSSIER_REJETE', 'DEMANDE_SUPPRESSION_REJETE'].includes(event.type_evement) && event.commentaire}</span>
                            </span>
                        </Timeline.Item>
                    })}
                </Timeline>
            </div>
        </Card>
    )
}

export default ArtisanNotification;