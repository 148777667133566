import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Tabs, Typography } from "antd";
// import { Carousel } from 'antd';
import "./home.less"
// import home_1 from '../../../assets/home_1.jpeg';
// import home_2 from '../../../assets/home_2.jpeg';
// import home_3 from '../../../assets/home_3.jpeg';
// import home_4 from '../../../assets/home_4.jpeg';
import { useTranslation } from "react-i18next";
import * as ref from '../../Referentiels/Action';
import { getArtisanDataById } from '../../ArtisanApp/Registration/Action';
import { getGlobalParam } from '../../GlobalParams/Action';
import SpinLoader from '../../../component/SpinLoader/SpinLaoder';

import PersonalData from '../../AgentApp/Artisan/components/ArtisanDetails/components/PersonalData/PersonalData';
import ProfessionalData from '../../AgentApp/Artisan/components/ArtisanDetails/components/ProfessionalData/ProfessionalData';
import EducationData from '../../AgentApp/Artisan/components/ArtisanDetails/components/EducationData/EducationData';
import RepresentativePersonalData from '../../AgentApp/Artisan/components/ArtisanDetails/components/RepresentativePersonalData/RepresentativePersonalData';

const { TabPane } = Tabs;
const { Title } = Typography;

const Home = props => {

  const { t } = useTranslation();
  const dispatch = useDispatch();


  const artisanId = useSelector(state => state.loginReducer?.artisan?.artisan_id);
  const loading = useSelector(state => state.registration?.loading);
  const artisanType = useSelector(state => state.registration?.artisantData?.artisan_type?.code);
  const artisantData = useSelector(state => state.registration?.artisantData);

  const params = useSelector(state => state.globalParamReducer?.params);
  const referentiel = useSelector(state => state.referentielReducer?.params);


  useEffect(() => {
    //dispatch(resetMessages());
    dispatch(getGlobalParam());
    dispatch(ref.getReferentiels());
    dispatch(ref.getDiplome());
    dispatch(ref.getEtablissement());
    dispatch(ref.getDuree());
    dispatch(ref.getAnnexe());
    dispatch(ref.getActiviteBranche())
    dispatch(ref.getCouvertureSociale())
    dispatch(ref.getActivite())
    dispatch(getArtisanDataById(artisanId));
    //dispatch(getArtisanFilesList(id));
    //dispatch(getCommiteFiles(id));
  }, []);

  return (
    <div>
      <Title level={1}> {t('artisan.home.title')}</Title>
      {/* <Carousel autoplay>
        <img src={home_1} alt="home" />
        <img src={home_2} alt="home" />
        <img src={home_3} alt="home" />
        <img src={home_4} alt="home" />
      </Carousel> */}
      <>
        {loading ? <SpinLoader /> :
          <Space direction="vertical" size="middle" className="w-100">
            <Tabs defaultActiveKey="1" type="card" size="large">
              {artisantData.donnees_personnelles && (artisanType != 'ENTREPRISE_ARTISANAT' && artisanType != 'COOPERATIVE_ARTISANAT') &&
                <TabPane tab={t('agent.artisan-details.with-data.tabs.personal-data')} key="donnees_personnelles">
                  <PersonalData params={params} referentiel={referentiel} initData={artisantData ? artisantData.donnees_personnelles : {}} />
                </TabPane>
              }
              {artisantData.donnees_education && (artisanType == 'ARTISAN_AVEC_DIPLOME') &&
                <TabPane tab={t('agent.artisan-details.with-data.tabs.education-data')} key="donnees_education">
                  <EducationData params={params} referentiel={referentiel} initData={artisantData ? artisantData.donnees_education : {}} />
                </TabPane>
              }
              {artisantData.donnees_professionnelle && 
                <TabPane tab={t('agent.artisan-details.with-data.tabs.professional-data')} key="donnees_professionnelle">
                  <ProfessionalData params={params} referentiel={referentiel} initData={artisantData ? artisantData.donnees_professionnelle : {}} artisanType={artisanType} />
                </TabPane>
              }
              {artisantData.donnees_representant && (artisanType == 'COOPERATIVE_ARTISANAT' || artisanType == 'ENTREPRISE_ARTISANAT') &&
                <TabPane tab={t('agent.artisan-details.with-data.tabs.representative-personal-data')} key="donnees_representant">
                  <RepresentativePersonalData params={params} referentiel={referentiel} initData={artisantData ? artisantData.donnees_representant : {}} />
                </TabPane>
              }
            </Tabs>
          </Space>
        }
      </>
    </div>

  );
}

export default Home;