import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const Languages = process.env.REACT_APP_PROFIL === 'AGENT' ? ['fr'] : ['fr', 'ar'];
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'fr', //default language 
    ns: ['common'], //, 'errors'],
    defaultNS: 'common',
    debug: true,
    whitelist: Languages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;