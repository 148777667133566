import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

  SEARCH_DELETE_ACCOUNT: 'SEARCH_DELETE_ACCOUNT',
  SEARCH_DELETE_ACCOUNT_PENDING: `SEARCH_DELETE_ACCOUNT_${ActionType.Pending}`,
  SEARCH_DELETE_ACCOUNT_FULFILLED: `SEARCH_DELETE_ACCOUNT_${ActionType.Fulfilled}`,
  SEARCH_DELETE_ACCOUNT_REJECTED: `SEARCH_DELETE_ACCOUNT_${ActionType.Rejected}`,

  GET_DEMANDE_SUPPRESSION_DOCUMENT: 'GET_DEMANDE_SUPPRESSION_DOCUMENT',
  GET_DEMANDE_SUPPRESSION_DOCUMENT_PENDING: `GET_DEMANDE_SUPPRESSION_DOCUMENT_${ActionType.Pending}`,
  GET_DEMANDE_SUPPRESSION_DOCUMENT_FULFILLED: `GET_DEMANDE_SUPPRESSION_DOCUMENT_${ActionType.Fulfilled}`,
  GET_DEMANDE_SUPPRESSION_DOCUMENT_REJECTED: `GET_DEMANDE_SUPPRESSION_DOCUMENT_${ActionType.Rejected}`,

  HANDLE_DELETE_ACCOUNT: 'HANDLE_DELETE_ACCOUNT',
  HANDLE_DELETE_ACCOUNT_PENDING: `HANDLE_DELETE_ACCOUNT_${ActionType.Pending}`,
  HANDLE_DELETE_ACCOUNT_FULFILLED: `HANDLE_DELETE_ACCOUNT_${ActionType.Fulfilled}`,
  HANDLE_DELETE_ACCOUNT_REJECTED: `HANDLE_DELETE_ACCOUNT_${ActionType.Rejected}`,

  UPDATE_ARTISANT_DEMANDE_SUPP_LIST: 'UPDATE_ARTISANT_DEMANDE_SUPP_LIST',

  CLEAR_DELETE_ACCOUNT_MESSAGES: 'CLEAR_DELETE_ACCOUNT_MESSAGES',
}

export default ActionTypes;
