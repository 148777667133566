import React, { useEffect, useRef, useState } from 'react';
import {
    Row, Col, Form,
    Space,
    Button,
    Card,
    Divider,
} from "antd";
import { saveEducationData, updateEducationData } from '../../Action';
import { InputWithDoubleLabel, DateWithDoubleLabel, SeletWithDoubleLabel } from '../../../../../utilities/FormFields/FormFields'
import { useDispatch, useSelector } from 'react-redux';
import { getReferentiels, addNewEstablshment } from './../../../../Referentiels/Action';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Utils from '../../../../../utilities/Utils/Utils';
import * as referentiel from '../../../../Referentiels/Action';
import Text from 'antd/lib/typography/Text';

const EducationData = props => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [showOtherEtablishement, setShowOtherEtablishement] = useState(props.initData?.autre_etablissement || false);
    const [showOtherDiplome, setShowOtherDiplome] = useState(props.initData?.autre_formation || false);
    const [selectedEtablissement, setSelectedEtablissement] = useState({});
    const params = useSelector(state => state.referentielReducer?.params);
    const etablissement = useSelector(state => state.referentielReducer?.etablissement);
    const diplome = useSelector(state => state.referentielReducer?.diplome);
    const diplomeDuree = useSelector(state => state.referentielReducer?.diplomeDuree);
    const duree = useSelector(state => state.referentielReducer?.duree);
    const artisanId = useSelector(state => state.loginReducer?.artisan?.artisan_id);
    const [provinces, setProvinces] = React.useState(isEmpty(props.initData?.province) ? props.params.provinces : props.params.provinces.filter(province => province.regionId === props.initData.region));
    const [regions, setRegions] = React.useState(props.params.regions);
    const [activeEtablishement, setActiveEtablishement] = React.useState(isEmpty(props.initData?.province) ? etablissement : etablissement.filter(et => et.province === props.initData.province));
    const [activeDiplome, setActiveDiplome] = React.useState(isEmpty(props.initData?.etablissement) ? diplome : diplome.filter(et => et.etablissement === props.initData.etablissement));

    useEffect(() => {
        dispatch(referentiel.getEtablissement());
        dispatch(referentiel.getDiplomeDuree());
        dispatch(referentiel.getDuree());
    }, []);


    const onFinish = formValue => {
        const { next, initData } = props;
        const pathname = history.location?.pathname;

        const payload = {
            ...formValue,
            date_diplome: formValue.date_diplome.format("YYYY"),
        };

        !pathname.includes('souscrire') || initData
            ? dispatch(updateEducationData(artisanId, payload)).then((result) => next())
            : dispatch(saveEducationData(artisanId, payload)).then((result) => next());
    }


    const getInitData = () => {
        if (!isEmpty(props.initData) && props.initData.date_diplome) {
            return { ...props.initData, date_diplome: moment(props.initData.date_diplome, "YYYY") }
        }
        return props.initData;
    }

    const getAutreEtablissement = (etablissementId) => {
        return activeEtablishement.filter(e => e.code === 'AUTRE_' + etablissementId);
    }
    const handleEstablishementChange = (value) => {
        dispatch(referentiel.getDiplome()).then(
            () => {
                if (value) {
                    const autreEtablissement = getAutreEtablissement(form.getFieldValue('etablissement'));
                    if (autreEtablissement && autreEtablissement.length === 1) {
                        setShowOtherEtablishement(true);
                        setShowOtherDiplome(true)
                        setActiveDiplome(diplome.filter(et => et.etablissement === autreEtablissement[0].id));
                    } else {
                        setShowOtherEtablishement(false);
                        setShowOtherDiplome(false);
                        diplome.filter(et => et.etablissement === value);
                        setActiveDiplome(diplome.filter(et => et.etablissement === value));
                    }

                }
            }
        );
        form.setFieldsValue({ formation: '', duree_de_formation: '', etablissement_autre: '', autre_formation: '', specialite: '' })
    }

    const getDiplomeById = id => {
        return activeDiplome.filter(e => e.id === id)[0];
    }

    const handleDiplomeChange = (value) => {
        if (value) {
            const _diplome = getDiplomeById(value);
            if (_diplome && _diplome.diplomeDistinct === 'AUTRE') {
                setShowOtherDiplome(true);
            } else {
                setShowOtherDiplome(false);
                var _etablissement = form.getFieldValue('etablissement')
                if (!_etablissement) {
                    setActiveEtablishement(etablissement)
                    const selectedDiplome = diplome.filter(dp => dp.id === value)[0];
                    form.setFieldsValue({ etablissement: selectedDiplome.etablissement })
                }
                const selectedDiplomeDuree = diplomeDuree.filter(dp => dp.diplome_id === value)[0];
                if (selectedDiplomeDuree) {
                    form.setFieldsValue({ duree_de_formation: selectedDiplomeDuree.duree_id })
                }


            }
        }
        form.setFieldsValue({ autre_formation: '', specialite: '' })
    }

    const onChangeRegion = e => {
        if (e) {
            setProvinces(props.params.provinces.filter(province => province.regionId === e));
        } else {
            setProvinces(props.params.provinces)
        }
        form.setFieldsValue({ province: '', etablissement: '', formation: '', duree_de_formation: '', specialite: '' })
    };

    const onChangeProvince = e => {
        if (e) {
            var province = props.params.provinces.filter(province => province.id === e);
            setActiveEtablishement(etablissement.filter(et => et.province === e))
            if (province) {
                form.setFieldsValue({ region: province[0].regionId })
            }


        }
        form.setFieldsValue({ etablissement: '', formation: '', duree_de_formation: '', specialite: '' })
    };

    const handleDiplomeClearAction = () => {
        setShowOtherDiplome(false);
        form.setFieldsValue({ duree_de_formation: '', specialite: '', autre_formation: '' })

    }

    const handleEtablissementClearAction = () => {
        setShowOtherEtablishement(false);
        setShowOtherDiplome(false);
        form.setFieldsValue({ formation: '', duree_de_formation: '', specialite: '', etablissement_autre: '', autre_formation: '' })
    }

    return (

        < Card >
            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }}>
                <Space direction="vertical" size="middle" className="w-100">
                    <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
                        <Form form={form}
                            name="registration"
                            style={{ width: '100%' }}
                            onFinish={onFinish}
                            size='small'
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            layout="horizontal"
                            scrollToFirstError
                            initialValues={getInitData()}
                        >
                            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Col lg={11} md={24} sm={24} xs={24}>
                                    <SeletWithDoubleLabel type="text" name="region" label={t('component.personal-data.form.region.label')} placeholder={t('component.personal-data.form.region.placeholder')}
                                        options={regions} onChangeAction={onChangeRegion} errorMessage={t('component.personal-data.form.region.error-message')} />
                                </Col>
                                <Divider type="vertical" />
                                <Col lg={11} md={24} sm={24} xs={24}>
                                    <SeletWithDoubleLabel type="text" name="province" label={t('component.personal-data.form.province.label')} placeholder={t('component.personal-data.form.province.placeholder')} options={provinces} onChangeAction={onChangeProvince} errorMessage={t('component.personal-data.form.province.error-message')} />
                                </Col>
                            </Row>
                            <Col span={24}>
                                <SeletWithDoubleLabel type="text" name="etablissement" label={t('component.education-data.form.etablissement.label')} placeholder={t('component.education-data.etablissement.form.formation.placeholder')}
                                    options={activeEtablishement}
                                    onChangeAction={handleEstablishementChange}
                                    onClearAction={handleEtablissementClearAction}
                                    errorMessage={t('component.education-data.form.etablissement.error-message')} />
                            </Col>
                            {showOtherEtablishement &&
                                <Col span={24}>
                                    <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="autre_etablissement" label={t('component.education-data.form.autre-etablissement.label')} placeholder={t('component.education-data.form.autre-etablissement.placeholder')} errorMessage={t('component.education-data.form.autre-etablissement.error-message')} />
                                </Col>
                            }
                            <Col span={24}>
                                <SeletWithDoubleLabel name="formation" label={t('component.education-data.form.formation.label')} placeholder={t('component.education-data.form.formation.placeholder')} errorMessage={t('component.education-data.form.formation.error-message')}
                                    options={activeDiplome}
                                    onClearAction={handleDiplomeClearAction}
                                    onChangeAction={handleDiplomeChange} />
                            </Col>
                            {showOtherDiplome &&
                                <Col span={24}>
                                    <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="autre_formation" label={t('component.education-data.form.autre-formation.label')} placeholder={t('component.education-data.form.autre-formation.placeholder')} errorMessage={t('component.education-data.form.autre-formation.error-message')} />
                                </Col>
                            }
                            <Col span={24}>
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALPHABETIC_WITH_SPACE} name="specialite" label={t('component.education-data.form.specialite.label')} placeholder={t('component.education-data.form.specialite.placeholder')} errorMessage={t('component.education-data.form.specialite.error-message')} />
                            </Col>
                            <Col span={24}>
                                <SeletWithDoubleLabel name="duree_de_formation" label={t('component.education-data.form.duree-de-formation.label')} errorMessage={t('component.education-data.form.duree-de-formation.error-message')} options={duree} />
                            </Col>
                            <Col span={24}>
                                <DateWithDoubleLabel picker="year" type="text" name="date_diplome" label={t('component.education-data.form.date-diplome.label')} placeholder={t('component.education-data.form.date-diplome.placeholder')} errorMessage={t('component.education-data.form.date-diplome.error-message')} />
                            </Col>
                            <Col span={24}>
                                <Text>{t('component.education-data.form.note')}</Text>
                            </Col>


                            <Row style={{ padding: '21px', justifyContent: 'space-between' }}>
                                <Button
                                    shape="round"
                                    size="middle"
                                    style={{ margin: '0 8px' }}
                                    onClick={() => { props.prev() }}
                                >
                                    {t('component.education-data.form.reset.label')}
                                </Button>
                                <Button shape="round" type="primary" htmlType="submit" size="middle" >
                                    {t('component.education-data.form.submit.label')}
                                </Button>
                            </Row>
                        </Form>
                    </Col>
                </Space>
            </Row >
        </Card >
    );

};


export default EducationData;