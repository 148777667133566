import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  activiteBrancheDecret: [],
  activiteFiliereDecret: [],
  activiteDecret: [],
};

const mapDataToActiviteBrancheeDecrets = data => {
  const list = [];
  data.map(line => {
    list.push(
      {
        key: line.id,
        label_fr: line.labels.FR,
        label_ar: line.labels.AR,
        code: line.code
      })
  })
  return list;
}



const mapDataToActiviteFiliereDecrets = data => {
  const list = [];
  data.map(line => {
    list.push(
      {
        key: line.id,
        label_fr: line.labels.FR,
        label_ar: line.labels.AR,
        code: line.code,
        activite_branche_decret_id: line.activite_branche_decret_id
      })
  })
  return list;
}



const mapDataToActiviteDecrets = data => {
  const list = [];
  data.map(line => {
    list.push(
      {
        key: line.id,
        label_fr: line.labels.FR,
        label_ar: line.labels.AR,
        code: line.code,
        activite_branche_decret_id: line.activite_branche_decret_id,
        activite_filiere_decret_id: line.activite_filiere_decret_id
      })
  })
  return list;
};



function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case (types.GET_ACTIVITE_BRANCHE_DECRET_PENDING || types.ADD_ACTIVITE_BRANCHE_DECRET_PENDING || types.UPDATE_ACTIVITE_BRANCHE_DECRET_PENDING
      || types.GET_ACTIVITE_FILIERE_DECRET_PENDING || types.ADD_ACTIVITE_FILIERE_DECRET_PENDING || types.UPDATE_ACTIVITE_FILIERE_DECRET_PENDING
      || types.GET_ACTIVITE_DECRET_PENDING || types.ADD_ACTIVITE_DECRET_PENDING || types.UPDATE_ACTIVITE_DECRET_PENDING): {

        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        };
      }

    case types.GET_ACTIVITE_BRANCHE_DECRET_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        activiteBrancheDecret: mapDataToActiviteBrancheeDecrets(data),
        loading: false,
      };
    }

    case types.ADD_ACTIVITE_BRANCHE_DECRET_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "ActiviteBrancheeDecret créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_ACTIVITE_BRANCHE_DECRET_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "ActiviteBrancheeDecret modifié avec succès",
        loading: false
      };
    }

    case types.GET_ACTIVITE_FILIERE_DECRET_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        activiteFiliereDecret: mapDataToActiviteFiliereDecrets(data),
        loading: false,
      };
    }

    case types.ADD_ACTIVITE_FILIERE_DECRET_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "ActiviteFiliereDecret créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_ACTIVITE_FILIERE_DECRET_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "ActiviteFiliereDecret modifié avec succès",
        loading: false
      };
    }

    case types.GET_ACTIVITE_DECRET_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        activiteDecret: mapDataToActiviteDecrets(data),
        loading: false,
      };
    }


    case types.ADD_ACTIVITE_DECRET_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "ActiviteDecret créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_ACTIVITE_DECRET_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "ActiviteDecret modifié avec succès",
        loading: false
      };
    }

    case (types.GET_ACTIVITE_BRANCHE_DECRET_REJECTED || types.ADD_ACTIVITE_BRANCHE_DECRET_REJECTED || types.UPDATE_ACTIVITE_BRANCHE_DECRET_REJECTED
      || types.GET_ACTIVITE_FILIERE_DECRET_REJECTED || types.ADD_ACTIVITE_FILIERE_DECRET_REJECTED || types.UPDATE_ACTIVITE_FILIERE_DECRET_REJECTED
      || types.GET_ACTIVITE_DECRET_REJECTED || types.ADD_ACTIVITE_DECRET_REJECTED || types.UPDATE_ACTIVITE_DECRET_REJECTED): {

        const { message } = action.payload.response.data;
        return {
          ...state,
          error: message,
          success: "",
          loading: false,
        };
      }

    default:
      return state;
  }
}

export default reducer;
