import React from 'react';
import { Row, Col, Progress } from 'antd';


export class SystemMetrics extends React.Component {

  static convertMillisecondsToDuration(ms) {
    const times = {
      year: 31557600000,
      month: 2629746000,
      day: 86400000,
      hour: 3600000,
      minute: 60000,
      second: 1000
    };
    let timeString = '';
    let plural = '';
    for (const key in times) {
      if (Math.floor(ms / times[key]) > 0) {
        plural = Math.floor(ms / times[key]) > 1 ? 's' : '';
        timeString += Math.floor(ms / times[key]).toString() + ' ' + key.toString() + plural + ' ';
        ms = ms - times[key] * Math.floor(ms / times[key]);
      }
    }
    return timeString;
  }

  nanToZero = (input) => isNaN(input) ? 0 : input;

  render() {
    const { systemMetrics, wholeNumberFormat, timestampFormat } = this.props;
    return (
      <div>
          <Row>
              Uptime {SystemMetrics.convertMillisecondsToDuration(systemMetrics['process.uptime'])}
          </Row>
          <br/>
          <Row>
              <Col span={6}>
                    <span style={{paddingRight: '25px', fontWeight: '600'}}>Start time :</span>
                    {systemMetrics['process.start.time']}
              </Col>
              <Col span={6}>
                  <span style={{paddingRight: '25px', fontWeight: '600'}}>System 1m Load average :</span>
                  {this.nanToZero(systemMetrics['system.load.average.1m'])}
              </Col>
              <Col span={6}>
                  <span style={{paddingRight: '25px', fontWeight: '600'}}>Process files max :</span>
                  {this.nanToZero(systemMetrics['process.files.max'])}
              </Col>
              <Col span={6}>
                  <span style={{paddingRight: '25px', fontWeight: '600'}}>Process files open :</span>
                  {this.nanToZero(systemMetrics['process.files.open'])}
              </Col>
          </Row>
          <br/>
          <Row>
              Process CPU usage 
              <Progress percent={100 * systemMetrics['process.cpu.usage']} size="large" status="active" strokeColor="#52c41a" />
          </Row>

          <Row>
              System CPU usage
              <Progress percent={100 * systemMetrics['system.cpu.usage']} size="large" status="active" strokeColor="#52c41a" />
          </Row>

      </div>
    );
  }
}