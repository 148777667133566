import React from 'react';
import { Progress, Table } from 'antd';


export class JvmMemory extends React.Component {
  render() {
    const { jvmMetrics, wholeNumberFormat } = this.props;

    const roundNumber = (number) =>{
      return Math.round(number * 100) / 100;
    }

    const columns = [
      {
        title: 'Used',
        dataIndex: 'used',
        key: 'used',
      },
      {
        title: 'Committed',
        dataIndex: 'committed',
        key: 'committed',
      },
      {
        title: 'PercentageUsed',
        dataIndex: 'percentageUsed',
        key: 'percentageUsed',
        render : (percentageUsed) => {
          return (<Progress percent={percentageUsed} size="small" status="active" strokeColor="#52c41a" />);
        }
      }
    ]

    const getData = () => {
      const data = [];
      Object.keys(jvmMetrics).map((key) => (
          data.push({
            "used":  jvmMetrics[key].max !== -1 
                              ? key +' '+ roundNumber(jvmMetrics[key].used / 1048576)+"M / "+roundNumber(jvmMetrics[key].max / 1048576)+"M" 
                              : key +' '+ roundNumber(jvmMetrics[key].used / 1048576)+"M",
            "committed": roundNumber(jvmMetrics[key].committed / 1048576)+"M",
            "percentageUsed": Math.round(jvmMetrics[key].used * 100 / jvmMetrics[key].max),
            })
        ));
      return data;
    }

    return (
        <Table columns={columns} dataSource={getData()}  pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}/>
    );
  }
}