import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Typography, Alert, Divider, Card, Space } from 'antd';
import { getGlobalParam } from '../../../../../../GlobalParams/Action';
import { getDiplomeDuree, addDiplomeDuree, updateDiplomeDuree } from '../../Action'
import { useTranslation } from 'react-i18next';
import { CheckBoxWithDoubleLabel, InputWithDoubleLabel, SeletWithDoubleLabel } from '../../../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import Utils from "../../../../../../../utilities/Utils/Utils";
import SpinLoader from '../../../../../../../component/SpinLoader/SpinLaoder';
const { Title } = Typography;


const ReferentielDetail = props => {
    const { handleClose, activeReferenciel, action, refType, requiredData: { diplome, duree } } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [shouldShouldSmsFiedls, setShouldShouldSmsFiedls] = useState(false)
    const allProvinces = useSelector(state => state.globalParamReducer?.params.provinces);
    const allRegions = useSelector(state => state.globalParamReducer?.params?.regions);
    const [provinces, setProvinces] = React.useState(allProvinces);
    const [regions, setRegions] = React.useState(allRegions);
    const [activeDiplome, setActiveDiplome] = React.useState(diplome);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setShouldShouldSmsFiedls(activeReferenciel ? activeReferenciel.sendSms : shouldShouldSmsFiedls)
        dispatch(getGlobalParam())
            .then((res) => setLoading(false))
            .catch((err) => setLoading(false))
    }, []);

    const onChangeRegion = e => {
        if (e) {
            setProvinces(allProvinces.filter(province => province.regionId === e));
        } else {
            setProvinces(allProvinces)
        }
        form.setFieldsValue({ province: '' })
    };

    const onChangeProvince = e => {
        if (e) {
            var province = allProvinces.filter(province => province.id === e);
            const selectedDiplomes = diplome.filter(dp => dp.province_id === e);
            form.setFieldsValue({ diplome_id: null })
            setActiveDiplome(selectedDiplomes);
            if (province) {
                form.setFieldsValue({ region: province[0].regionId })
            }

        }
    };

    const onFinish = (values) => {
        const { loadDataOrRedirect, setSuccesseMessage, setErrorMessage } = props;
        const payload = {
            entity_type: Utils.REFERENTIEL_ENTITY_TYPES.DIPLOME_DUREE,
            payload: values
        }
        if (action === 'ADD') {
            dispatch(addDiplomeDuree(payload))
                .then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.diplomeDuree.add'))
                    loadDataOrRedirect();
                })
                .catch(err => {
                    setErrorMessage(err)
                })
        } else {
            dispatch(updateDiplomeDuree(activeReferenciel.key, payload))
                .then(res => {
                    setSuccesseMessage(t('agent.referentiel.message.diplomeDuree.update'))
                    loadDataOrRedirect();
                })
                .catch(err => {
                    setErrorMessage(err)
                })
        }
        handleClose();
    };

    const getInitData = () => {
        let initData = {};
        const _activeDiplome = diplome.filter(dp => dp.id === activeReferenciel.diplome_id);
        if (action !== 'ADD') {
            initData = { ...activeReferenciel, province: _activeDiplome[0]?.province_id, region: _activeDiplome[0]?.region_id };
        }
        return initData;
    }

    const isConsultation = () => {
        return action === 'CONSULTATION';
    }


    const getTitle = () => {
        if (refType === Utils.REFERENTIEL_TYPES.DIPLOME_DUREE) {
            switch (action) {
                case 'CONSULTATION':
                    return t('agent.referentiel.consultation.diplomeDuree');
                case 'UPDATE':
                    return t('agent.referentiel.update.diplomeDuree');
                case 'ADD':
                    return t('agent.referentiel.add.diplomeDuree');
                default:
                    break;
            }
        }
    }
    return (
        <>
            {loading ? <SpinLoader /> :
                <Modal visible={true} width={1500} onCancel={handleClose}
                    footer=
                    {
                        !isConsultation() &&
                        <>
                            <Button shape="round" key="back" onClick={handleClose}>
                                {t('agent.referentiel.modal.cancel.label')}
                            </Button>
                            <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                                {t('agent.referentiel.modal.submit.label')}
                            </Button>
                        </>
                    }
                >
                    <Form form={form} onFinish={onFinish} initialValues={getInitData()}>
                        <Title level={5} style={{ paddingBottom: '20px' }}>
                            {getTitle()}
                        </Title >
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Col lg={11} md={24} sm={24} xs={24}>
                                    <SeletWithDoubleLabel type="text" name="region" label={t('component.personal-data.form.region.label')} placeholder={t('component.personal-data.form.region.placeholder')} options={regions} onChangeAction={onChangeRegion} errorMessage={t('component.personal-data.form.region.error-message')} disabled={isConsultation()} />
                                </Col>
                                <Divider type="vertical" />
                                <Col lg={11} md={24} sm={24} xs={24}>
                                    <SeletWithDoubleLabel type="text" name="province" label={t('component.personal-data.form.province.label')} placeholder={t('component.personal-data.form.province.placeholder')} options={provinces} onChangeAction={onChangeProvince} errorMessage={t('component.personal-data.form.province.error-message')} disabled={isConsultation()} />
                                </Col>
                            </Row>
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <SeletWithDoubleLabel type="text" name="diplome_id" label={t('agent.referentiel.modal.diplome.label')} errorMessage={t('agent.referentiel.modal.diplome.error-message')} options={activeDiplome} disabled={isConsultation()} />
                            </Col>
                            <Divider type="vertical" />
                            < Col lg={11} md={24} sm={24} xs={24} >
                                <SeletWithDoubleLabel type="text" name="duree_id" label={t('agent.referentiel.modal.duree.label')} errorMessage={t('agent.referentiel.modal.duree.error-message')} options={duree} disabled={isConsultation()} />
                            </Col>

                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ReferentielDetail;
