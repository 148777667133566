import React from 'react';
import { Card, Tag } from 'antd';
import {
    CheckCircleOutlined,
    SyncOutlined,
    CloseCircleOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import './ArtisanStatus.less'
import Utils from "./../../../utilities/Utils/Utils"
import { useTranslation } from 'react-i18next';

const ArtisanStatus = props => {
    const { t } = useTranslation();
    const statut = useSelector(state => state?.loginReducer?.artisan?.statut);
    const date_radiation = useSelector(state => state.loginReducer?.artisan?.date_radiation);

    const arisanStatusFlag = () => {
        const { ARTISANT_STATUS } = Utils;
        switch (statut) {
            case ARTISANT_STATUS.INITIE:
                return (<Tag icon={<ClockCircleOutlined />} color="default" style={{ width: '100%' }}>
                    {t('artisan.artisan-status.initie')}
                </Tag>)
            case ARTISANT_STATUS.EN_COURS_DE_VALIDATION:
                return (<Tag icon={<SyncOutlined spin />} color="processing" style={{ width: '100%' }}>
                    {t('artisan.artisan-status.en-cours-validation')}
                </Tag>)
            case ARTISANT_STATUS.VALIDE_NIVEAU_1:
                return (<Tag icon={<SyncOutlined spin />} color="processing" style={{ width: '100%' }}>
                    {t('artisan.artisan-status.valide-niveau-1')}
                </Tag>)
            case ARTISANT_STATUS.VALIDE_NIVEAU_2:
                return (<Tag icon={<CheckCircleOutlined />} color="processing" style={{ width: '100%' }}>
                    {t('artisan.artisan-status.valide-niveau-2')}
                </Tag>)
            case ARTISANT_STATUS.FINALISE:
                return (<Tag icon={<CheckCircleOutlined />} color="processing" style={{ width: '100%' }}>
                    {t('artisan.artisan-status.finalise')}
                </Tag>)
            case ARTISANT_STATUS.VALIDE:
                return (<Tag icon={<CheckCircleOutlined />} color="success" style={{ width: '100%' }}>
                    {t('artisan.artisan-status.valide')}
                </Tag>)
            case ARTISANT_STATUS.REJETE:
                return (<Tag icon={<CloseCircleOutlined />} color="error" style={{ width: '100%' }}>
                    {t('artisan.artisan-status.error')}
                </Tag>)
            case ARTISANT_STATUS.RADIE:
                return (<Tag icon={<CloseCircleOutlined />} color="error" style={{ width: '100%' }}>
                    {t('artisan.artisan-status.radie')}<span>{date_radiation}</span>
                </Tag>)
            case ARTISANT_STATUS.EXPIRE:
                return (<Tag icon={<CloseCircleOutlined />} color="error" style={{ width: '100%' }}>
                    {t('artisan.artisan-status.expire')}
                </Tag>)
            default:
                return (<Tag icon={<CloseCircleOutlined />} color="error" style={{ width: '100%' }}>
                    {t('artisan.artisan-status.error')}
                </Tag>)
        }
    }

    return (
        <Card title={t('artisan.artisan-status.title')} bordered={false} style={{ textAlign: 'center', padding: "0" }}>
            <div style={{
                width: '100%',
                textAlign: 'center',
            }}>{arisanStatusFlag()} </div>
        </Card>
    )
}

export default ArtisanStatus;