import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Typography, Button, Modal, Checkbox, Switch } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import find from "lodash.find";
import { InputWithDoubleLabel } from "../../../../utilities/FormFields/FormFields";
import Utils from "../../../../utilities/Utils/Utils";
const { Title } = Typography;

const HabilitationForm = props => {
    const { t } = useTranslation();
    const { handleSubmit, disabled, menus, initialValues, close, changeMode, visible } = props;
    const [form] = Form.useForm();

    useEffect(() => form.resetFields(), [initialValues]);

    return (<>
    <Modal visible={visible} onCancel={close} width={800}
        footer={[
            disabled ?
                <>
                    <Button shape="round" key="back" onClick={changeMode}>
                        {t('agent.habilitation.modal.details.toggleUpdate')}
                    </Button>
                </>
                : 
                <>
                    <Button shape="round" key="back" onClick={changeMode}>
                        {t('agent.habilitation.modal.update.dismiss')}
                    </Button>
                    <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                        {t('agent.habilitation.modal.update.save')}
                    </Button>

                </>
        ]}>
            <Form form={form} onFinish={handleSubmit} initialValues={initialValues}>
                {  !disabled 
                    ?   <Title level={4} >{t('agent.habilitation.modal.update.title')}</Title>
                    :   <Title level={4} >{t('agent.habilitation.modal.details.title')}</Title>
                }

                <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} name="profile" label={t('agent.habilitation.modal.code')} disabled={true}  required={false} />
                <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} name="description" label={t('agent.habilitation.modal.description')} disabled={true} required={false} />
                <table style={{width: '100%'}}>
                        <thead>
                                <td>
                                    <Title level={5} >{t('agent.habilitation.details.focntionality.header.title')}</Title>
                                </td>
                        </thead>
                        <tbody>
                        {!!menus && menus.map(menu => {
                            if(menu && ![ '0010','0030' ].includes(menu.id)){

                            const viewAction = find(menu.actions, ['code', 'VIEW'])
                            //const viewAction = find(menu.actions,  item => item.code === 'VIEW' || item.code === 'ACTION')
                            return (<>
                                <tr>
                                    <div className="form-check col-4 border-right">
                                        {!!menu.children & menu.children.length !== 0  ?

                                            <a className="btn p-0" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                            </a> : ''}
                                        <span style={{paddingLeft: '10px'}}>{menu.label}</span>
                                    </div>
                                    {!!menu.children & menu.children.length === 0 ? (<>
                                        <div>
                                            {viewAction ? (
                                                <Form.Item name={viewAction.id} valuePropName="checked" style={{paddingLeft: '10px'}}>
                                                    <Switch
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                        disabled={disabled}
                                                        id={`chekbox-${viewAction.id}`}/>
                                                </Form.Item>
                                            ) : ''}
                                        </div>
                                    </>)

                                        : ''}
                                </tr>
                                
                                {!!menu.children & menu.children.length !== 0 ?  menu.children.map(child => {
                                        //const viewAction = find(child.actions, ['code', 'VIEW']);
                                        const viewAction = find(child.actions,  item => item.code === 'VIEW' || item.code === 'ACTION')
                                        return (<>
                                        <tr>
                                            <td>
                                                <span style={{paddingLeft: '60px'}}>{child.label}</span>
                                            </td>
                                            <td>
                                                {viewAction ? (
                                                    <Form.Item name={viewAction.id} valuePropName="checked" style={{paddingLeft: '10px'}}>
                                                        <Switch
                                                            checkedChildren={<CheckOutlined />}
                                                            unCheckedChildren={<CloseOutlined />}
                                                            disabled={disabled}
                                                            id={`chekbox-${viewAction.id}`}/>
                                                    </Form.Item>
                                                ) : ''}
                                            </td>
                                        </tr>
                                        </>)
                                    }) : ''}
                                
                            </>)
                        }})}
                    </tbody>    
                </table> 
            </Form>
        </Modal>
        </>
        );
}
export default HabilitationForm;