import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
  DOWNLOAD_ATTESTATION: 'DOWNLOAD_ATTESTATION',
  DOWNLOAD_ATTESTATION_PENDING: `DOWNLOAD_ATTESTATION_${ActionType.Pending}`,
  DOWNLOAD_ATTESTATION_FULFILLED: `DOWNLOAD_ATTESTATION_${ActionType.Fulfilled}`,
  DOWNLOAD_ATTESTATION_REJECTED: `DOWNLOAD_ATTESTATION_${ActionType.Rejected}`,

  CLEAR_DOWNLOAD_ATTESTATION_MESSAGES: 'CLEAR_DOWNLOAD_ATTESTATION_MESSAGES',
}

export default ActionTypes;
