import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_PUBLIC_PRIVE: 'GET_PUBLIC_PRIVE',
    GET_PUBLIC_PRIVE_PENDING: `GET_PUBLIC_PRIVE_${ActionType.Pending}`,
    GET_PUBLIC_PRIVE_FULFILLED: `GET_PUBLIC_PRIVE_${ActionType.Fulfilled}`,
    GET_PUBLIC_PRIVE_REJECTED: `GET_PUBLIC_PRIVE_${ActionType.Rejected}`,

    ADD_PUBLIC_PRIVE: 'ADD_PUBLIC_PRIVE',
    ADD_PUBLIC_PRIVE_PENDING: `ADD_PUBLIC_PRIVE_${ActionType.Pending}`,
    ADD_PUBLIC_PRIVE_FULFILLED: `ADD_PUBLIC_PRIVE_${ActionType.Fulfilled}`,
    ADD_PUBLIC_PRIVE_REJECTED: `ADD_PUBLIC_PRIVE_${ActionType.Rejected}`,

    UPDATE_PUBLIC_PRIVE: 'UPDATE_PUBLIC_PRIVE',
    UPDATE_PUBLIC_PRIVE_PENDING: `UPDATE_PUBLIC_PRIVE_${ActionType.Pending}`,
    UPDATE_PUBLIC_PRIVE_FULFILLED: `UPDATE_PUBLIC_PRIVE_${ActionType.Fulfilled}`,
    UPDATE_PUBLIC_PRIVE_REJECTED: `UPDATE_PUBLIC_PRIVE_${ActionType.Rejected}`,
};

export default ActionTypes;
