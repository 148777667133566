import { ActionType } from 'redux-promise-middleware';


export default  {
    ADMINISTRATION_FETCH_LOGS: 'ADMINISTRATION_FETCH_LOGS',
    ADMINISTRATION_FETCH_LOGS_PENDING: `ADMINISTRATION_FETCH_LOGS_${ActionType.Pending}`,
    ADMINISTRATION_FETCH_LOGS_FULFILLED: `ADMINISTRATION_FETCH_LOGS_${ActionType.Fulfilled}`,
    ADMINISTRATION_FETCH_LOGS_REJECTED: `ADMINISTRATION_FETCH_LOGS_${ActionType.Rejected}`,

    ADMINISTRATION_FETCH_LOGS_CHANGE_LEVEL: 'ADMINISTRATION_FETCH_LOGS_CHANGE_LEVEL',
    ADMINISTRATION_FETCH_LOGS_CHANGE_LEVEL_PENDING: `ADMINISTRATION_FETCH_LOGS_CHANGE_LEVEL_${ActionType.Pending}`,
    ADMINISTRATION_FETCH_LOGS_CHANGE_LEVEL_FULFILLED: `ADMINISTRATION_FETCH_LOGS_CHANGE_LEVEL_${ActionType.Fulfilled}`,
    ADMINISTRATION_FETCH_LOGS_CHANGE_LEVEL_REJECTED: `ADMINISTRATION_FETCH_LOGS_CHANGE_LEVEL_${ActionType.Rejected}`,

    ADMINISTRATION_FETCH_HEALTH: 'ADMINISTRATION_FETCH_HEALTH',
    ADMINISTRATION_FETCH_HEALTH_PENDING: `ADMINISTRATION_FETCH_HEALTH_${ActionType.Pending}`,
    ADMINISTRATION_FETCH_HEALTH_FULFILLED: `ADMINISTRATION_FETCH_HEALTH_${ActionType.Fulfilled}`,
    ADMINISTRATION_FETCH_HEALTH_REJECTED: `ADMINISTRATION_FETCH_HEALTH_${ActionType.Rejected}`,

    ADMINISTRATION_FETCH_METRICS: 'ADMINISTRATION_FETCH_METRICS',
    ADMINISTRATION_FETCH_METRICS_PENDING: `ADMINISTRATION_FETCH_METRICS_${ActionType.Pending}`,
    ADMINISTRATION_FETCH_METRICS_FULFILLED: `ADMINISTRATION_FETCH_METRICS_${ActionType.Fulfilled}`,
    ADMINISTRATION_FETCH_METRICS_REJECTED: `ADMINISTRATION_FETCH_METRICS_${ActionType.Rejected}`,

    ADMINISTRATION_FETCH_THREAD_DUMP: 'ADMINISTRATION_FETCH_THREAD_DUMP',
    ADMINISTRATION_FETCH_THREAD_DUMP_PENDING: `ADMINISTRATION_FETCH_THREAD_DUMP_${ActionType.Pending}`,
    ADMINISTRATION_FETCH_THREAD_DUMP_FULFILLED: `ADMINISTRATION_FETCH_THREAD_DUMP_${ActionType.Fulfilled}`,
    ADMINISTRATION_FETCH_THREAD_DUMP_REJECTED: `ADMINISTRATION_FETCH_THREAD_DUMP_${ActionType.Rejected}`,

    ADMINISTRATION_FETCH_CONFIGURATIONS: 'ADMINISTRATION_FETCH_CONFIGURATIONS',
    ADMINISTRATION_FETCH_CONFIGURATIONS_PENDING: `ADMINISTRATION_FETCH_CONFIGURATIONS_${ActionType.Pending}`,
    ADMINISTRATION_FETCH_CONFIGURATIONS_FULFILLED: `ADMINISTRATION_FETCH_CONFIGURATIONS_${ActionType.Fulfilled}`,
    ADMINISTRATION_FETCH_CONFIGURATIONS_REJECTED: `ADMINISTRATION_FETCH_CONFIGURATIONS_${ActionType.Rejected}`,

    ADMINISTRATION_FETCH_ENV: 'ADMINISTRATION_FETCH_ENV',
    ADMINISTRATION_FETCH_ENV_PENDING: `ADMINISTRATION_FETCH_ENV_${ActionType.Pending}`,
    ADMINISTRATION_FETCH_ENV_FULFILLED: `ADMINISTRATION_FETCH_ENV_${ActionType.Fulfilled}`,
    ADMINISTRATION_FETCH_ENV_REJECTED: `ADMINISTRATION_FETCH_ENV_${ActionType.Rejected}`,

    ADMINISTRATION_WEBSOCKET_ACTIVITY_MESSAGE: 'ADMINISTRATION_WEBSOCKET_ACTIVITY_MESSAGE',
    ADMINISTRATION_WEBSOCKET_ACTIVITY_MESSAGE_PENDING: `ADMINISTRATION_WEBSOCKET_ACTIVITY_MESSAGE_${ActionType.Pending}`,
    ADMINISTRATION_WEBSOCKET_ACTIVITY_MESSAGE_FULFILLED: `ADMINISTRATION_WEBSOCKET_ACTIVITY_MESSAGE_${ActionType.Fulfilled}`,
    ADMINISTRATION_WEBSOCKET_ACTIVITY_MESSAGE_REJECTED: `ADMINISTRATION_WEBSOCKET_ACTIVITY_MESSAGE_${ActionType.Rejected}`,
  };