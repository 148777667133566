import types from './ActionTypes';
import Api from '../../utilities/services/Api';


export const create = (payload) => ({
      type: types.CREATE_RECLAMATION,
      payload: Api.reclamation.createReclamation(payload),
    })

export const handle = (reclamationId,payload) => ({
      type: types.HANDLE_RECLAMATION,
      payload: Api.reclamation.handle(reclamationId,payload),
    }) 

export const getAllForAgent = (searchCriteria) => ({
      type: types.GET_ALL_RECLAMATIONS_FOR_AGENT,
      payload: Api.reclamation.getAllForAgent(searchCriteria),
    })


export const getAllForArtisan = (artisanId,type) => ({
      type: types.GET_ALL_RECLAMATIONS_FOR_ARTISAN,
      payload: Api.reclamation.getAllForArtisan(artisanId, type),
    })
  
export const getOneForAgent = (reclamationId) => ({
      type: types.GET_ONE_RECLAMATION_FOR_AGENT,
      payload: Api.reclamation.getOneForAgent(reclamationId),
    })


export const getOneForArtisan = (artisanId,reclamationId) => ({
      type: types.GET_ONE_RECLAMATION_FOR_ARTISAN,
      payload: Api.reclamation.getOneForArtisan(artisanId,reclamationId),
    })

export const resetMessages = () => {
    return ({
      type: types.RESET_MESSAGES,
    })
  }; 