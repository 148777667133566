import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row, Typography, Divider, } from 'antd';
import { updateSms, getAllSms } from '../../Action'
import { useTranslation } from 'react-i18next';
import { InputWithDoubleLabel } from '../../../../../utilities/FormFields/FormFields';
import Form from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import Utils from "../../../../../utilities/Utils/Utils";
import SpinLoader from '../../../../../component/SpinLoader/SpinLaoder';
import { isEmptyObject } from 'jquery';
const { Title } = Typography;


const SmsDetail = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { handleClose, activeSms } = props;

    const [loading, setLoading] = useState(isEmptyObject(activeSms));


    const onFinish = (values) => {
        const { setSuccesseMessage, setErrorMessage } = props;

        dispatch(updateSms(activeSms.code,values)).then(res => {
            dispatch(getAllSms());
            setSuccesseMessage(t('agent.referentiel.message.ville.update'));
        }).catch(err => {
                setErrorMessage(err)
            })
        handleClose();
    };


    return (
        <>
            {loading ? <SpinLoader /> :
                <Modal visible={true} width={1500} onCancel={handleClose}
                    footer=
                    {
                        <>
                            <Button shape="round" key="back" onClick={handleClose}>
                                {t('agent.sms.modal.cancel')}
                            </Button>
                            <Button shape="round" key="submit" type="primary" onClick={form.submit}>
                                {t('agent.sms.modal.submit')}
                            </Button>
                        </>
                    }
                >

                    <Form form={form} onFinish={onFinish} initialValues={activeSms}>
                        <Title level={5} style={{ paddingBottom: '20px' }}>
                            { t('agent.sms.modal.update-title') }
                        </Title >

                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Col span={11}>
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} disabled={true} name="code"  label={t('agent.sms.modal.code.label')} errorMessage={t('agent.sms.modal.label-fr.error-message')}/>
                            </Col>
                        </Row>

                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Col span={11}>
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="label_fr" label={t('agent.sms.modal.labelFr.label')} errorMessage={t('agent.sms.modal.label-fr.error-message')}/>
                            </Col>
                            <Divider type="vertical" />
                            <Col span={11}>
                                <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="label_ar" label={t('agent.sms.modal.labelAr.label')} errorMessage={t('agent.sms.modal.label-ar.error-message')}/>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default SmsDetail;
