import { Col, Row, Alert, Space, Typography } from "antd";
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getAllSms } from './Action';
import { useTranslation } from "react-i18next";
import './Sms.less'
import SmsList from "./components/SmsList/SmsList";
import SmsDetail from "./components/SmsDetail/SmsDetail";
import SpinLoader from "../../../component/SpinLoader/SpinLaoder";
const { Title } = Typography;

const Sms = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const SmsData = useSelector(state => state.SmsReducer?.smsList);
    const loading = useSelector(state => state.SmsReducer?.loading);

    const [successeMessage, setSuccesseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [activeSms, setActiveSms] = useState({});
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        dispatch(getAllSms())
    }, []);

    const handleUpdates = (record) => {
        setActiveSms(record)
        setVisible(true)
    }

    const hideModal = () => {
        setVisible(false);
        setActiveSms({})
    };

    return (<>
        <Space size="large" direction="vertical" style={{ width: '100%' }}  >
            {loading ? <SpinLoader /> : <>

                <div>
                    <Title span={3} className="aligne-left">
                        {t('agent.sms.title')}
                    </Title>
                </div>
                {errorMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={errorMessage}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {successeMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={successeMessage}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }

                <div className="search-result-list">
                    <SmsList loadingTable={loading} SmsData={SmsData} handleUpdates={handleUpdates} />
                    {visible && <SmsDetail handleClose={hideModal} activeSms={activeSms} setErrorMessage={setErrorMessage} setSuccesseMessage={setSuccesseMessage} />}
                </div>
            </>}
        </Space>
    </>);
}

export default Sms;