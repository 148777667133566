import React, { useEffect, useState } from "react";
import "./LoginForm.less";
import { Form, Alert, Button, Checkbox, Typography, Row, Col, Space, Card } from "antd";
import { CloseCircleOutlined, IdcardOutlined} from "@ant-design/icons";
import { connect } from 'react-redux';
import { authentication, clearErrorMessage } from './Action';
import { useHistory, withRouter } from "react-router";
import Utils from "../../utilities/Utils/Utils";
import Api from "../../utilities/services/Api";
import { LoginPasswordInputWithDoubleLabel, LoginInputPhoneNumberWithDoubleLabel } from '../../utilities/FormFields/FormFields'
import { useTranslation } from "react-i18next";
import "react-simple-keyboard/build/css/index.css";


const Login = (props) => {

  const { errorMessage } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');

  const history = useHistory();

  const onFinish = (values) => {
    let phoneNumber = process.env.REACT_APP_PROFIL === "ARTISAN"
      ? Utils.mapNumberToPhoneNumber(values.username)
      : values.username;

    setPhoneNumber(phoneNumber);
    props.login({
      ...values,
      username: phoneNumber
    })
  };

  useEffect(() => {
    const { clearErrorMessage } = props;
    clearErrorMessage();
  }, []);


  const alertMessage = () => {
    if (errorMessage === "USER_NOT_VALIDATED") {
      history.push('/new-account', { currentFromLogin: 1, phoneNumberFromLogin: phoneNumber })
    } else if (errorMessage === "USER_DELETED") {
      return <Alert message={t(`login.user-deleted`)} icon={<CloseCircleOutlined />} type="error" showIcon />
    } else if (errorMessage === "ACCESS_DENIED") {
      return <Alert message={t(`login.access-denied`)} icon={<CloseCircleOutlined />} type="error" showIcon />
    } else if (errorMessage === "ERROR_GLOBAL") {
      return <Alert message={t(`login.access-denied`)} icon={<CloseCircleOutlined />} type="error" showIcon />
    }
  }

  const layouts = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { offset: 2, span: 20 },
  };

  const { Title } = Typography;

  return (
    <Card className="glassmorphism" style={{ width: "100%" }}>

      <Space direction="vertical" size="large" style={{}}>
        <Title level={2} align='middle' style={{ width: "100%" }}>{process.env.REACT_APP_PROFIL === "ARTISAN" ? t('login.form.title.artisan') : t('login.form.title.agent')}</Title>
        <Row align='middle' justify="center" >
          <Col>
            {errorMessage && alertMessage()}
          </Col>
        </Row>
        <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }} align="bottom" >
          <Col span={24} type="flex" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }}>
            <Form form={form} name="login-form" className=" col  login-form" style={{ width: '100%' }} onFinish={onFinish} {...layouts} id="login-form">
              <Col span={24} >
                <LoginInputPhoneNumberWithDoubleLabel name="username" label={t(`login.form.username.label.${process.env.REACT_APP_PROFIL === "ARTISAN" ? 'artisan' : 'agent'}`)} placeholder={t(`login.form.username.placeholder.${process.env.REACT_APP_PROFIL === "ARTISAN" ? 'artisan' : 'agent'}`)} errorMessage={t(`login.form.username.error-message.${process.env.REACT_APP_PROFIL === "ARTISAN" ? 'artisan' : 'agent'}`)} />
              </Col>
              <Col span={24} >
                <LoginPasswordInputWithDoubleLabel form={form} name="password" label={t('login.form.password.label')} placeholder={t('login.form.password.placeholder')} errorMessage={t('login.form.password.error-message')} />
              </Col>
              <Col span={24} align="middle" style={{ justifyContent: "space-between", flexDirection: 'row', display: 'inline-flex', padding: '0 0 10px 0' }} >
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>{t('login.form.remember.label')}</Checkbox>
                </Form.Item>
                <a className="login-form-forgot" style={{ textAlign: 'center', fontWeight: '500' }} href="/forgot-password">{t('login.form.forgot-password.label')}</a>
              </Col>

              <Form.Item {...tailLayout}>
                <Button shape="round" type="primary" htmlType="submit" className="login-form-button" style={{minWidth: '140px', width: '47%', display: 'inline-block'}}>
                  {t('login.form.se-connecter.label')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Space>

    </Card>
  );

};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.loginReducer.errorMessage
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(authentication(user)),
  clearErrorMessage: () => dispatch(clearErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
