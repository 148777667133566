import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_MODE_FORMATION: 'GET_MODE_FORMATION',
    GET_MODE_FORMATION_PENDING: `GET_MODE_FORMATION_${ActionType.Pending}`,
    GET_MODE_FORMATION_FULFILLED: `GET_MODE_FORMATION_${ActionType.Fulfilled}`,
    GET_MODE_FORMATION_REJECTED: `GET_MODE_FORMATION_${ActionType.Rejected}`,

    ADD_MODE_FORMATION: 'ADD_MODE_FORMATION',
    ADD_MODE_FORMATION_PENDING: `ADD_MODE_FORMATION_${ActionType.Pending}`,
    ADD_MODE_FORMATION_FULFILLED: `ADD_MODE_FORMATION_${ActionType.Fulfilled}`,
    ADD_MODE_FORMATION_REJECTED: `ADD_MODE_FORMATION_${ActionType.Rejected}`,

    UPDATE_MODE_FORMATION: 'UPDATE_MODE_FORMATION',
    UPDATE_MODE_FORMATION_PENDING: `UPDATE_MODE_FORMATION_${ActionType.Pending}`,
    UPDATE_MODE_FORMATION_FULFILLED: `UPDATE_MODE_FORMATION_${ActionType.Fulfilled}`,
    UPDATE_MODE_FORMATION_REJECTED: `UPDATE_MODE_FORMATION_${ActionType.Rejected}`,
};

export default ActionTypes;
