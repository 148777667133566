import React from "react";
import { Table, Input, Button, Space, Tooltip } from 'antd';
import { withRouter } from "react-router";
import Highlighter from 'react-highlight-words';
import history from '../../../../../history';
import { SearchOutlined, DeleteOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { withTranslation } from "react-i18next";
import TableWithExport from "../../../../../component/Table/TableWithExport";
import moment from "moment";
import ValidateRadiationArtisanForm from "../ValidateRadiationArtisanForm";


class ArtisanList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            activeArtisan: {},
            showRadiationModal: false,

        };
    }



    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        shape="round"
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {this.props.t('agent.artisan-list.filterDropdown.search')}
                    </Button>
                    <Button shape="round" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {this.props.t('agent.artisan-list.filterDropdown.reset')}
                    </Button>
                    <Button
                        shape="round"
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        {this.props.t('agent.artisan-list.filterDropdown.filter')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleRadiation = (record) => {
        this.setState({ ...this.state, showRadiationModal: true, activeArtisan: record });
    }

    getDelaiStatus = record => {
        const given = moment(record.dateDemandeInscription, "DD-MM-YYYYY");
        const current = moment().startOf('day');//add .add(20, 'd') for tests
        const numberOfDay = moment.duration(current.diff(given)).asDays();

        if (numberOfDay < 15) {
            return (<span> <Tooltip title="Délai < 15jours" >
                <ClockCircleOutlined style={{ color: 'green' }} />
            </Tooltip>
            </span>)
        } else if (numberOfDay > 15 && numberOfDay < 30) {
            return (<span> <Tooltip title="Délai entre 15 et 30 jours" >
                <ClockCircleOutlined style={{ color: 'orange' }} />
            </Tooltip></span>)
        } else {
            return (<span> <Tooltip title="Délai > 30 jours" >
                <ClockCircleOutlined style={{ color: 'red' }} />
            </Tooltip></span>)
        }


    }
    handleOkRadiationRequest = (record) => {
        const { radiateRnaAccount } = this.props;
        radiateRnaAccount(this.state.activeArtisan);
        this.handleCancel();
    }

    handleCancel = () => {
        this.setState({ ...this.state, showRadiationModal: false });
    }


    render() {
        const { artisans: data, location, t, pagination: _pagination, handleTableChange, artisanListMetadata } = this.props;
        const isDeepEmpty = (obj) => {
            if (Object.keys(obj).length === 0) {
                return true;
            };
            let numberOfFilterColumn = 0;
            Object.values(columns).filter(al => {
                const filter = Object.keys(al).filter(o => {
                    return o === "onFilter"
                }).length;
                numberOfFilterColumn = numberOfFilterColumn + filter;
                return true;
            })
            const numberOfNull = Object.values(obj).filter(ele => ele === null).length;
            return numberOfNull === numberOfFilterColumn ? true : false;
        }

        const handleChanged = (pagination, filters, sorter) => {
            if (isDeepEmpty(filters)) {
                handleTableChange(pagination, filters, sorter);
            };
        };
        const columns = [
            {
                title: t('agent.artisan-list.columns.statut'),
                dataIndex: 'statut',
                key: 'statut',
                render: (text, record, index) => (
                    <span>{t(record.statut)}</span>
                )
            },
            {
                title: t('agent.artisan-list.columns.artisan-type'),
                dataIndex: 'artisanTypeFR',
                key: 'artisanTypeFR',
                ...this.getColumnSearchProps('artisanTypeFR'),
            },
            {
                title: t('agent.artisan-list.columns.nom'),
                dataIndex: 'nom',
                key: 'nom',
                ...this.getColumnSearchProps('nom'),
            },
            {
                title: t('agent.artisan-list.columns.prenom'),
                dataIndex: 'prenom',
                key: 'prenom',
                ...this.getColumnSearchProps('prenom'),
            },
            {
                title: t('agent.artisan-list.columns.cnie'),
                dataIndex: 'cnie',
                key: 'cnie',
                ...this.getColumnSearchProps('cnie'),
            },
            {
                title: t('agent.artisan-list.columns.telephone'),
                dataIndex: 'telephone',
                key: 'telephone',
                ...this.getColumnSearchProps('telephone'),
            },
            {
                title: t('agent.artisan-list.columns.nom-cooperative'),
                dataIndex: 'nom_cooperative',
                key: 'nom_cooperative',
                ...this.getColumnSearchProps('nom_cooperative'),
            },
            {
                title: t('agent.artisan-list.columns.nom-entreprise'),
                dataIndex: 'nom_entreprise',
                key: 'nom_entreprise',
                ...this.getColumnSearchProps('nom_entreprise'),
            },
        ];

        let locale = {
            emptyText: 'Pas de données',
        };

        return <>
            <ValidateRadiationArtisanForm visible={this.state.showRadiationModal} handleSubmit={this.handleOkRadiationRequest} close={this.handleCancel} />
            <TableWithExport
                data={data}
                columns={columns}
                total={artisanListMetadata?.total}
                pagination={_pagination}
                onChange={handleChanged}
                title={location.pathname.includes("artisans") ? t('agent.artisan-list.divider.search') : t('agent.artisan-list.divider.home')} />
        </>;
    }
}

export default withTranslation()(withRouter(ArtisanList));