import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Input, Radio, Space, Typography, Button, Card, Divider } from "antd";
import './ProfessionalData.less';
import { InputCNIEWithDoubleLabel, AddBoutonWithDoubleLabel, InputEmailWithDoubleLabel, SimpleInputWithDoubleLabel, CustomSeletWithDoubleLabel, InputNumberWithDoubleLabel, InputWithDoubleLabel, SeletWithDoubleLabel, DateWithDoubleLabel, FieldWithDoubleLabel, TextAreaWithDoubleLabel, InputPhoneNumberWithDoubleLabel, ExperianceDateWithDoubleLabel, InputCNIEWithTypeIdentity } from '../../../../../utilities/FormFields/FormFields'
import Utils from '../../../../../utilities/Utils/Utils';
import { saveProfessionalData, updateProfessionalData } from '../../Action';
import { getActivite, getActiviteBranche, getAnnexe, getCouvertureSociale } from '../../../../Referentiels/Action';
import { MinusCircleTwoTone } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { persistor } from '../../../../../configureStore';
const { Text } = Typography;

var cacheOfFormValues = {} // temporary variable used to cache data

const ProfessionalData = props => {
  const { t } = useTranslation();
  const [identity, setIdentity] = useState([]);
  
  const history = useHistory();
  const dispatch = useDispatch();
  const { artisanType } = props;
  const [form] = Form.useForm();
  const [value, setValue] = useState(undefined);

  const [villes, setVilles] = useState(props.params.villes);
  const [provinces, setProvinces] = useState(props.params.provinces);
  const [regions, setRegions] = useState(props.params.regions);
  const [branches, setBranches] = useState([props.referentiel.activiteBranches]);
  const [activites, setActivites] = useState([props.referentiel.activites]);
  const [filieres, setfilieres] = useState([props.referentiel.activiteFilieres]);

  const couvertureSociale = useSelector(state => state.referentielReducer?.couvertureSociale);

  const artisantData = useSelector(state => state.registration?.artisantData);

  const [autreAffiliationCouverture, setAutreAffiliationCouverture] = React.useState(props.initData && props.initData.affiliation_couverture
    ? props.initData?.affiliation_couverture === "6000"
    : false);
  const [cnssAffiliationCouverture, setCnssAffiliationCouverture] = React.useState(props.initData && props.initData.affiliation_couverture
    ? !['5000', '6000', '7000', '4500'].includes(props.initData?.affiliation_couverture)
    : false);
  const [ramedAffiliationCouverture, setRamedAffiliationCouverture] = React.useState(props.initData && props.initData.affiliation_couverture
    ? props.initData?.affiliation_couverture === "4500"
    : false);

  const artisanId = useSelector(state => state.loginReducer?.artisan?.artisan_id);
  const user = useSelector(state => state.loginReducer?.user);

  useEffect(() => {
    dispatch(getActiviteBranche())
    dispatch(getCouvertureSociale())
    dispatch(getActivite())

    if (props.initData && props.initData.region) {
      setProvinces(props.params.provinces.filter(province => province.regionId === props.initData.region));
    }
    
    if (props.initData && props.initData.province) {
      setVilles(props.params.villes.filter(ville => ville.provinceId === props.initData.province));
    }

    if(artisantData && artisantData.donnees_personnelles){
      //console.log('artisantData.donnees_personnelles')
      //console.log(artisantData.donnees_personnelles)
      if(!artisantData.donnees_professionnelle || !artisantData.donnees_professionnelle.adresse)
        form.setFieldsValue({ 
          adresse: artisantData.donnees_personnelles.adresse_personnelle,
          adresse_ar: artisantData.donnees_personnelles.adresse_personnelle_ar
       })
    }
  }, []);

  const onChangeIdentity = (e) => {
    const tmpIdentity = [...identity];
    setIdentity(tmpIdentity.push(e))
  }

  const onFinish = formValue => {

    cacheOfFormValues = {...formValue} // caching input data before send request

    const { next, initData } = props;
    let cooperantList = [];
    const pathname = history.location?.pathname;
    

    if (formValue.cooperants) {
      formValue.cooperants.forEach(element => {
        if (!isEmpty(element.nom) && !isEmpty(element.prenom) && !isEmpty(element.cnie)) {
          cooperantList.push(element)
        }
      });
    }
    if ([Utils.ARTISANT_TYPE.ARTISAN_AVEC_DIPLOME, Utils.ARTISANT_TYPE.ARTISAN_AVEC_EXPERIENCE, Utils.ARTISANT_TYPE.ARTISAN_MAALAM].includes(artisanType)) {
      formValue = { ...formValue, date_debut_exercice: formValue?.date_debut_exercice?.format("DD/MM/YYYY") }
    } else if (artisanType === Utils.ARTISANT_TYPE.COOPERATIVE_ARTISANAT) {
      formValue = { ...formValue, date_inscription_registre_local: formValue.date_inscription_registre_local.format("DD/MM/YYYY") };
    } else if (artisanType === Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT) {
      formValue = { ...formValue, date_creation_entreprise: formValue.date_creation_entreprise.format("DD/MM/YYYY") };
    }

    if (!isEmpty(formValue.date_affiliation_cnss)) {
      formValue = { ...formValue, date_affiliation_cnss: formValue?.date_affiliation_cnss?.format("DD/MM/YYYY") }
    }

    if (!pathname.includes('souscrire') || initData) {
      dispatch(updateProfessionalData(artisanId, { ...formValue, cooperants: cooperantList }))
        .then(result => {
          persistor.flush();
          next();
        });
      //next();
    } else {
      dispatch(saveProfessionalData(artisanId, { ...formValue, cooperants: cooperantList })).then(result => {
        persistor.flush();
        next();
      });
    }
  }

  const onChangeRegion = e => {
    if (e) {
      setProvinces(props.params.provinces.filter(province => province.regionId === e));
      setVilles(props.params.villes.filter(ville => ville.regionId === e));
    } else {
      setProvinces(props.params.provinces)
      setVilles(props.params.villes)
    }

    form.setFieldsValue({ province: '' })
    form.setFieldsValue({ ville: '' })
  };

  const onChangeProvince = e => {
    if (e) {
      setVilles(props.params.villes.filter(ville => ville.provinceId === e));
      var province = props.params.provinces.filter(province => province.id === e);

      if (province) {
        form.setFieldsValue({ region: province[0].regionId })
      }
    } else {
      var region = form.getFieldValue('region')
      setVilles(props.params.villes.filter(ville => ville.regionId === region));
    }

    form.setFieldsValue({ ville: '' })
  };

  const onChangeVille = e => {
    var ville = props.params.villes.filter(ville => ville.id === e);

    if (ville && ville.length !== 0) {
      form.setFieldsValue({ region: ville[0].regionId })
      form.setFieldsValue({ province: ville[0].provinceId })

      setProvinces(props.params.provinces.filter(p => p.regionId === ville[0].regionId));
      setVilles(props.params.villes.filter(v => v.provinceId === ville[0].provinceId))
    }
  }

  const onChangeBranche = (e, index) => {
    if (e) {
      setfilieres(updateItemInArray(filieres, index, props.referentiel.activiteFilieres.filter(filiere => filiere.activiteBrancheId === e)));
      setActivites(updateItemInArray(activites, index, props.referentiel.activites.filter(activitie => activitie.activiteBrancheId === e)));
    } else {
      setfilieres(updateItemInArray(filieres, index, props.referentiel.activiteFilieres));
      setActivites(updateItemInArray(activites, index, props.referentiel.activites));
    }

    var field = form.getFieldValue("activites")
    field[index].filiere = '';
    field[index].activite = '';

    form.setFieldsValue({ activites: field })
  };

  const onChangeFiliere = (e, index) => {
    var field = form.getFieldValue("activites")
    field[index].activite = '';

    if (e) {
      setActivites(updateItemInArray(activites, index, props.referentiel.activites.filter(activitie => activitie.activiteFiliereId === e)));
      var filiere = props.referentiel.activiteFilieres.filter(filiere => filiere.id === e)

      if (filiere) {
        field[index].branche = filiere[0].activiteBrancheId;
      }
    } else {
      setActivites(updateItemInArray(activites, index, props.referentiel.activites.filter(activitie => activitie.activiteBrancheId === field[index].branche)));
    }

    form.setFieldsValue({ activites: field })
  };

  const onChangeActivite = (e, index) => {
    var activite = props.referentiel.activites.filter(activitie => activitie.id === e);
    if (activite && activite.length !== 0) {
      var field = form.getFieldValue("activites")
      field[index].branche = activite[0].activiteBrancheId;
      field[index].filiere = activite[0].activiteFiliereId;

      form.setFieldsValue({ activites: field })

      //form.setFieldsValue({ activites: []})

      setfilieres(updateItemInArray(filieres, index, props.referentiel.activiteFilieres.filter(f => f.activiteBrancheId === activite[0].activiteBrancheId)));
      setActivites(updateItemInArray(activites, index, props.referentiel.activites.filter(a => a.activiteFiliereId === activite[0].activiteFiliereId)));
    }
  }

  const updateItemInArray = (array, index, newItem) => {
    array[index] = newItem;
    return array;
  }

  const addActivity = () => {
    var b = branches;
    b.push(props.referentiel.activiteBranches);
    var f = filieres;
    f.push(props.referentiel.activiteFilieres);
    var a = activites;
    a.push(props.referentiel.activites);

    setBranches(b)
    setfilieres(f)
    setActivites(a)
  }

  const removeActivity = index => {
    var b = branches;
    b.push(b.splice(index, 1));
    var f = filieres;
    f.push(f.splice(index, 1));
    var a = activites;
    a.push(a.splice(index, 1));

    setBranches(b)
    setfilieres(f)
    setActivites(a)
  }

  const onChange = e => {
    setValue(e.target.value)
  };

  const onChangeAffiliationCouverture = e => {
    const activeAffiliationCouverture = couvertureSociale.filter(act => act.id === e);
    if (activeAffiliationCouverture && activeAffiliationCouverture.length > 0) {
      if ("6000" === activeAffiliationCouverture[0].code) {
        // AUTRE
        setAutreAffiliationCouverture(true);
        setCnssAffiliationCouverture(false);
        setRamedAffiliationCouverture(false);
      } else if ("4500" === activeAffiliationCouverture[0].code) {
        // RAMED
        setAutreAffiliationCouverture(false);
        setCnssAffiliationCouverture(false);
        setRamedAffiliationCouverture(true);
      } else if (['5000', '7000'].includes(activeAffiliationCouverture[0].code)) {
        // PRIVE & AUCUN
        setAutreAffiliationCouverture(false);
        setCnssAffiliationCouverture(false);
        setRamedAffiliationCouverture(false);
      } else {
        // CNSS
        setAutreAffiliationCouverture(false);
        setCnssAffiliationCouverture(true);
        setRamedAffiliationCouverture(false);
      }
    }
  }

  const getInitData = () => {

    var initData = { ...props.initData, numero_telephone: Utils.mapPhoneNumberToNumber(user.login) };
    if (!isEmpty(initData.date_debut_exercice)) {
      initData = { ...initData, date_debut_exercice: moment(initData.date_debut_exercice, "DD/MM/YYYY") }
    }
    if (!isEmpty(initData.date_affiliation_cnss)) {
      initData = { ...initData, date_affiliation_cnss: moment(initData.date_affiliation_cnss, "DD/MM/YYYY") }
    }
    if (!isEmpty(initData.date_inscription_registre_local)) {
      initData = { ...initData, date_inscription_registre_local: moment(initData.date_inscription_registre_local, "DD/MM/YYYY") };
    }
    if (!isEmpty(initData.date_creation_entreprise)) {
      initData = { ...initData, date_creation_entreprise: moment(initData.date_creation_entreprise, "DD/MM/YYYY") };
    }
    if (isEmpty(initData.activites)) {
      initData = { ...initData, activites: [''] }
    }

    // adding used input values to existing initData
    initData = {...initData, ...cacheOfFormValues}

    return initData;
  }
  const getAdditionalInfo = type => {
    switch (type) {
      case 'CHEZ_UNE_ENTREPRISE':
        return <>
          <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} name="type_travail_nom_entreprise" label={t('component.professional-data.form.type-travail-nom-entreprise.label')} placeholder={t('component.professional-data.form.type-travail-nom-entreprise.placeholder')} errorMessage={t('component.professional-data.form.type-travail-nom-entreprise.error-message')} />
          <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL_WITHOUT_SPACE} name="type_travail_num_rc" label={t('component.professional-data.form.num-immat-registre-commerce.label')} placeholder={t('component.professional-data.form.num-immat-registre-commerce.placeholder')} errorMessage={t('component.professional-data.form.num-immat-registre-commerce.error-message')} />
        </>;
      case 'CHEZ_UNE_COOPERATIVE':
        return <>
          <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} name="type_travail_nom_cooperative" label={t('component.professional-data.form.nom-cooperative.label')} placeholder={t('component.professional-data.form.nom-cooperative.placeholder')} errorMessage={t('component.professional-data.form.nom-cooperative.error-message')} />
          <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL_WITHOUT_SPACE} name="type_travail_num_rna" label={t('component.professional-data.form.immatriculation-registre-local.label')} placeholder={t('component.professional-data.form.immatriculation-registre-local.placeholder')} errorMessage={t('component.professional-data.form.immatriculation-registre-local.error-message')} />
        </>;
      case 'CHEZ_UNE_UNITE_ARTISANALE':
        return <>
          <InputWithDoubleLabel type={Utils.TEXT_TYPE.ALL_SPECIAL} name="type_travail_nom_unite" label={t('component.professional-data.form.type-travail-nom-unite.label')} placeholder={t('component.professional-data.form.type-travail-nom-unite.placeholder')} errorMessage={t('component.professional-data.form.type-travail-nom-unite.error-message')} />
          <InputWithDoubleLabel type={Utils.TEXT_TYPE.NUMERIC} name="type_travail_num_patente" label={t('component.professional-data.form.type-travail-num-patente.label')} placeholder={t('component.professional-data.form.type-travail-num-patente.placeholder')} errorMessage={t('component.professional-data.form.type-travail-num-patente.error-message')} />
        </>;
      case 'AUTO_ENTREPRENEUR':
        return <>
          <InputWithDoubleLabel forceFr={true} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="type_travail_num_autoentr" label={t('component.professional-data.form.type-travail-num-autoentr.label')} placeholder={t('component.professional-data.form.type-travail-num-autoentr.placeholder')} errorMessage={t('component.professional-data.form.type-travail-num-autoentr.error-message')} />
        </>;

      case 'AUTRE':
        return <>
          <InputWithDoubleLabel forceFr={true} type={Utils.TEXT_TYPE.ALL_SPECIAL} name="type_travail_autre" label={t('component.professional-data.form.type-travail-autre.label')} placeholder={t('component.professional-data.form.type-travail-autre.placeholder')} errorMessage={t('component.professional-data.form.type-travail-autre.error-message')} />
        </>;

      default: break;
    }


  }

  const minExperianceYears = () => {
    if (artisanType === Utils.ARTISANT_TYPE.ARTISAN_AVEC_EXPERIENCE)
      return 3;
    else if (artisanType === Utils.ARTISANT_TYPE.ARTISAN_MAALAM)
      return 10;
    else return 0;
  }

  return (
    <Card  >
      <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }} align="middle">
        <Space direction="vertical" size="middle" className="w-100">
          <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
            <Form
              form={form}
              name="registration-professional-data"
              style={{ width: '100%' }}
              onFinish={onFinish}
              size='small'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              scrollToFirstError
              initialValues={getInitData()}
            >
              {[Utils.ARTISANT_TYPE.ARTISAN_AVEC_DIPLOME, Utils.ARTISANT_TYPE.ARTISAN_AVEC_EXPERIENCE, Utils.ARTISANT_TYPE.ARTISAN_MAALAM].includes(artisanType) ?

                <div>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={11} md={24} sm={24} xs={24}>
                      <FieldWithDoubleLabel name="actif" label={t('component.professional-data.form.actif.label')} errorMessage={t('component.professional-data.form.actif.error-message')}>
                        <Radio.Group style={{ width: '100%' }}  >
                          <Radio value="O" >{t('component.professional-data.form.actif.options.o')}</Radio>
                          <Radio value="N" >{t('component.professional-data.form.actif.options.n')}</Radio>
                        </Radio.Group>
                      </FieldWithDoubleLabel>
                    </Col>
                    <Divider type="vertical" />
                    <Col lg={11} md={24} sm={24} xs={24}>
                      <ExperianceDateWithDoubleLabel minExp={minExperianceYears()} type="text" name="date_debut_exercice" required={true} label={t('component.professional-data.form.date-debut-exercice.label')} placeholder={t('component.professional-data.form.date-debut-exercice.placeholder')} errorMessage={t('component.professional-data.form.date-debut-exercice.error-message')} />
                    </Col>
                  </Row>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={11} md={24} sm={24} xs={24}>
                      <FieldWithDoubleLabel name="type_travail" label={t('component.professional-data.form.type-travail.label')} errorMessage={t('component.professional-data.form.type-travail.error-message')} >
                        <Radio.Group style={{ width: '100%' }} onChange={onChange} >
                          <Space direction="vertical" style={{ textAlign: 'initial', justifyContent: 'flex-start' }}>
                            <Radio value="CHEZ_UNE_ENTREPRISE">{t('component.professional-data.form.type-travail.options.CHEZ-UNE-ENTREPRIS')}</Radio>
                            <Radio value="CHEZ_UNE_COOPERATIVE">{t('component.professional-data.form.type-travail.options.CHEZ-UNE-COOPERATIVE')}</Radio>
                            <Radio value="CHEZ_UNE_UNITE_ARTISANALE" >{t('component.professional-data.form.type-travail.options.CHEZ-UNE-UNITE-ARTISANALE')}</Radio>
                            <Radio value="INDEPENDANT_DANS_UNE_UNITE_ARTISANALE">{t('component.professional-data.form.type-travail.options.INDEPENDANT-DANS-UNE-UNITE-ARTISANALE')}</Radio>
                            <Radio value="INDEPENDANT_A_DOMICILE">{t('component.professional-data.form.type-travail.options.INDEPENDANT-A-DOMICILE')}</Radio>
                            <Radio value="AUTO_ENTREPRENEUR">{t('component.professional-data.form.type-travail.options.AUTO-ENTREPRENEUR')}</Radio>
                            <Radio value='AUTRE'>  {t('component.professional-data.form.type-travail.options.AUTRE')}</Radio>
                          </Space>
                        </Radio.Group>
                      </FieldWithDoubleLabel>
                      {getAdditionalInfo(form.getFieldValue('type_travail') || value)}
                    </Col>
                  </Row>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={11} md={24} sm={24} xs={24}>
                      <TextAreaWithDoubleLabel forceFr={true} form={form} type="text" name="adresse" label={Utils.staticInternationalization.adresseProfessionelleFR} errorMessage={t('component.professional-data.form.adresse.error-message')} />
                    </Col>
                    <Divider type="vertical" />
                    <Col lg={11} md={24} sm={24} xs={24}>
                      <TextAreaWithDoubleLabel type={Utils.TEXT_TYPE.ARABIC} form={form} name="adresse_ar" label={Utils.staticInternationalization.adresseProfessionelleAR} errorMessage={t('component.professional-data.form.adresse.error-message')} />
                    </Col>
                  </Row>
                </div>
                :

                <div>
                  {(artisanType === Utils.ARTISANT_TYPE.COOPERATIVE_ARTISANAT) &&
                    <Col span={24}>
                      <InputWithDoubleLabel forceFr form={form} type={Utils.TEXT_TYPE.ALPHABETIC_WITH_SPACE} name="nom_cooperative" label={t('component.professional-data.form.nom-cooperative.label')} placeholder={t('component.professional-data.form.nom-cooperative.placeholder')} errorMessage={t('component.professional-data.form.nom-cooperative.error-message')} />
                    </Col>
                  }
                  {(artisanType === Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT) &&
                    <Col span={24}>
                      <SimpleInputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ALPHABETIC_WITH_SPACE} name="nom_entreprise" label={t('component.professional-data.form.nom-entreprise.label')} placeholder={t('component.professional-data.form.nom-entreprise.placeholder')} errorMessage={t('component.professional-data.form.nom-entreprise.error-message')} />
                    </Col>
                  }

                  {(artisanType === Utils.ARTISANT_TYPE.COOPERATIVE_ARTISANAT) &&
                    <>
                      <Col span={24}>
                        <InputWithDoubleLabel forceFr type={Utils.TEXT_TYPE.ALL_SPECIAL_WITHOUT_SPACE} name="immatriculation_registre_local" label={t('component.professional-data.form.immatriculation-registre-local.label')} placeholder={t('component.professional-data.form.immatriculation-registre-local.placeholder')} errorMessage={t('component.professional-data.form.immatriculation-registre-local.error-message')} />
                      </Col>
                      <Col span={24}>
                        <DateWithDoubleLabel name="date_inscription_registre_local" label={t('component.professional-data.form.date-inscription-registre-local.label')} placeholder={t('component.professional-data.form.date-inscription-registre-local.placeholder')} errorMessage={t('component.professional-data.form.date-inscription-registre-local.error-message')} />
                      </Col>
                    </>
                  }
                  {(artisanType === Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT) &&
                    <>
                      <Col span={24}>
                        <InputWithDoubleLabel forceFr type={Utils.TEXT_TYPE.ALL_SPECIAL_WITHOUT_SPACE} name="num_immat_registre_commerce" label={t('component.professional-data.form.num-immat-registre-commerce.label')} placeholder={t('component.professional-data.form.num-immat-registre-commerce.placeholder')} errorMessage={t('component.professional-data.form.num-immat-registre-commerce.error-message')} />
                      </Col>
                      <Col span={24}>
                        <DateWithDoubleLabel name="date_creation_entreprise" label={t('component.professional-data.form.date-creation-entreprise.label')} placeholder={t('component.professional-data.form.date-creation-entreprise.placeholder')} errorMessage={t('component.professional-data.date-creation-entreprise.error-message')} />
                      </Col>
                    </>
                  }

                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={11} md={24} sm={24} xs={24}>
                      <TextAreaWithDoubleLabel forceFr form={form} type="text" name="adresse_siege" label={t('component.professional-data.form.adresse-siege.label')} placeholder={t('component.professional-data.form.adresse-siege.placeholder')} errorMessage={t('component.professional-data.form.adresse-siege.error-message')} />
                    </Col>
                  </Row>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={11} md={24} sm={24} xs={24}>
                      <InputEmailWithDoubleLabel type="text" name="email" required={false} label={t('component.professional-data.form.email.label')} placeholder={t('component.professional-data.form.email.placeholder')} errorMessage={t('component.professional-data.form.email.error-message')} />
                    </Col>
                    <Divider type="vertical" />
                    <Col lg={11} md={24} sm={24} xs={24}>
                      <InputPhoneNumberWithDoubleLabel name="numero_telephone" label={t('component.professional-data.form.numero-telephone.label')} placeholder={t('component.professional-data.form.numero-telephone.placeholder')} errorMessage={t('component.professional-data.form.numero-telephone.error-message')} disabled={true} />
                    </Col>
                  </Row>
                </div>
              }

              {
                artisanType !== Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT &&
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col lg={11} md={24} sm={24} xs={24}>
                    <SeletWithDoubleLabel type="text" name="affiliation_couverture" label={t('component.professional-data.form.affiliationCouverture.label')} placeholder={t('component.professional-data.form.affiliationCouverture.placeholder')} options={couvertureSociale} errorMessage={t('component.professional-data.form.affiliationCouverture.error-message')}
                      onChangeAction={onChangeAffiliationCouverture} />
                  </Col>
                </Row>
              }
              {
                autreAffiliationCouverture &&
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col lg={11} md={24} sm={24} xs={24}>
                    <InputWithDoubleLabel form={form} type="text" name="autre_affiliation_couverture" label={t('component.professional-data.form.affiliationCouvertureAutre.label')} placeholder={t('component.professional-data.form.affiliationCouvertureAutre.placeholder')} errorMessage={t('component.professional-data.form.affiliationCouvertureAutre.error-message')} />
                  </Col>
                </Row>
              }
              {
                (cnssAffiliationCouverture || artisanType === Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT) &&
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col lg={11} md={24} sm={24} xs={24}>
                    <InputWithDoubleLabel form={form} type="alphanumeric" name="numero_affiliation_cnss" label={t('component.professional-data.form.numeroAffiliationCnss.label')} placeholder={t('component.professional-data.form.numeroAffiliationCnss.placeholder')} errorMessage={t('component.professional-data.form.numeroAffiliationCnss.error-message')} />
                  </Col>
                  <Divider type="vertical" />
                  <Col lg={11} md={24} sm={24} xs={24}>
                    <DateWithDoubleLabel name="date_affiliation_cnss" label={t('component.professional-data.form.dateAffiliationCnss.label')} placeholder={t('component.professional-data.form.dateAffiliationCnss.placeholder')} errorMessage={t('component.professional-data.form.dateAffiliationCnss.error-message')} />
                  </Col>
                </Row>
              }
              {
                ramedAffiliationCouverture &&
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col lg={11} md={24} sm={24} xs={24}>
                    <InputWithDoubleLabel form={form} type="alphanumeric" name="numero_affiliation_cnss" label={t('component.professional-data.form.numeroAffiliationRamed.label')} placeholder={t('component.professional-data.form.numeroAffiliationRamed.placeholder')} errorMessage={t('component.professional-data.form.numeroAffiliationRamed.error-message')} />
                  </Col>
                </Row>
              }
              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <SeletWithDoubleLabel type="text" name="ville" label={t('component.professional-data.form.ville.label')} placeholder={t('component.professional-data.form.ville.placeholder')} options={villes ?? []} errorMessage={t('component.professional-data.form.ville.error-message')} onChangeAction={onChangeVille} />
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <SeletWithDoubleLabel type="text" name="province" label={t('component.professional-data.form.province.label')} placeholder={t('component.professional-data.form.province.placeholder')} options={provinces ?? []} errorMessage={t('component.professional-data.form.province.error-message')} onChangeAction={onChangeProvince} />
                </Col>
                <Col lg={7} md={24} sm={24} xs={24}>
                  <SeletWithDoubleLabel type="text" name="region" label={t('component.professional-data.form.region.label')} placeholder={t('component.professional-data.form.region.placeholder')} options={regions ?? []} errorMessage={t('component.professional-data.form.region.error-message')} onChangeAction={onChangeRegion} />
                </Col>
              </Row>

              <Form.List name="activites">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (<>
                      {index === 0 &&
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                          <h1 style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }}>
                            {t('component.professional-data.form.activite-principale.text')}
                          </h1>
                        </Row>
                      }

                      <Row style={{ width: '100%', justifyContent: 'space-between' }} >
                        <Col lg={{ order: 2, span: 2 }} md={{ order: 3, span: 24 }} sm={24} xs={24} style={{ marginBottom: "15px" }}>
                          {index !== 0 && fields.length > 1 ? (
                            <MinusCircleTwoTone
                              twoToneColor="#eb2f96"
                              onClick={() => {
                                removeActivity(index);
                                remove(field.name);
                              }}
                            />
                          ) : null}
                        </Col>
                        <Col lg={{ order: 1, span: 11 }} md={{ order: 1, span: 24 }} sm={24} xs={24}>
                          <SeletWithDoubleLabel type="text" index={index} name={[field.name, 'branche']} label={t('component.professional-data.form.branche.label')} onChangeAction={(e) => onChangeBranche(e, index)} options={branches[0]} errorMessage={t('component.professional-data.form.branche.error-message')} />
                        </Col>
                        <Col lg={{ order: 3, span: 11 }} md={{ order: 2, span: 24 }} sm={24} xs={24} >
                          <SeletWithDoubleLabel type="text" index={index} name={[field.name, 'filiere']} label={t('component.professional-data.form.filiere.label')} onChangeAction={(e) => onChangeFiliere(e, index)} options={filieres[0]} errorMessage={t('component.professional-data.form.filiere.error-message')} />
                        </Col>
                        <Col lg={{ order: 3, span: 11 }} md={{ order: 2, span: 24 }} sm={24} xs={24} >
                          <SeletWithDoubleLabel type="text" index={index} name={[field.name, 'activite']} label={t('component.professional-data.form.activite.label')} onChangeAction={(e) => onChangeActivite(e, index)} options={activites[0]} errorMessage={t('component.professional-data.form.activite.error-message')} />
                        </Col>
                      </Row>

                      {index === 0 &&
                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                          <h1 style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }}>
                            {t('component.professional-data.form.activite-secondaire.text')}
                          </h1>
                        </Row>
                      }
                    </>
                    ))}
                    <Row style={{ width: '100%' }} justify='center'>
                      <Col span={12}>
                        <AddBoutonWithDoubleLabel label={t('component.professional-data.form.autreActivite.label')} action={(e) => { addActivity(); add(); }} />
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>

              {(artisanType === Utils.ARTISANT_TYPE.COOPERATIVE_ARTISANAT) &&
                <>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col span={24}>
                      <InputNumberWithDoubleLabel form={form} type="number" name="nombre_cooperant" label={t('component.professional-data.form.nombre-cooperant.label')} placeholder={t('component.professional-data.form.nombre-cooperant.placeholder')} errorMessage={t('component.professional-data.form.nombre-cooperant.error-message')} />
                    </Col>
                  </Row>

                  <Form.List name="cooperants">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => {
                          return (
                            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                              <Col span={2}>
                                {fields.length > 1 ? (
                                  <MinusCircleTwoTone
                                    twoToneColor="#eb2f96"
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  />
                                ) : null}
                              </Col>
                              <Col lg={7} md={24} sm={24} xs={24}>
                                <InputWithDoubleLabel form={form} type="text" required={false} formListName={"cooperants"} name={[field.name, 'nom']} label={t('component.professional-data.form.nom.label')} placeholder={t('component.professional-data.form.nom.placeholder')} errorMessage={t('component.professional-data.form.nom.error-message')} />
                              </Col>
                              <Col lg={7} md={24} sm={24} xs={24}>
                                <InputWithDoubleLabel form={form} type="text" required={false} formListName={"cooperants"} name={[field.name, 'prenom']} label={t('component.professional-data.form.prenom.label')} placeholder={t('component.professional-data.form.prenom.placeholder')} errorMessage={t('component.professional-data.form.prenom.error-message')} />
                              </Col>
                              <Col lg={7} md={24} sm={24} xs={24}>
                                <InputCNIEWithDoubleLabel acceptBothTypes type="text" required={false} formListName={"cooperants"} name={[field.name, 'cnie']} label={t('component.professional-data.form.cnie.label')} placeholder={t('component.professional-data.form.cnie.placeholder')} errorMessage={t('component.professional-data.form.cnie.label')} />
                              </Col>
                            </Row>

                          )
                        })}
                        {
                          <Row style={{ width: '100%' }} justify='center'>
                            <Col span={12}>
                              <AddBoutonWithDoubleLabel label={t('component.professional-data.form.autreCoperatif.label')} action={add} />
                            </Col>
                          </Row>
                        }

                      </>
                    )}
                  </Form.List>
                </>
              }

              {(artisanType === Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT) &&
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Col span={24}>
                    <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.NUMERIC} name="rna_responsable_prod" label={t('component.professional-data.form.rna-responsable-prod.label')} placeholder={t('component.professional-data.form.rna-responsable-prod.placeholder')} errorMessage={t('component.professional-data.form.rna-responsable-prod.error-message')} />
                  </Col>
                </Row>
              }

              {[Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT, Utils.ARTISANT_TYPE.COOPERATIVE_ARTISANAT].includes(artisanType) &&
                <>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }} span={16}>
                      <h1 style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }}>
                        {t(`component.professional-data.form.chiffre.${artisanType === 'ENTREPRISE_ARTISANAT' ? 'entreprise' : 'cooperative'}.text`)}
                      </h1>
                    </Col>
                  </Row>

                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={7} md={24} sm={24} xs={24}>
                      <InputNumberWithDoubleLabel form={form} required={false} type="number" name={['chiffre_resultat', 'ca_annee_n']} label={t('component.professional-data.form.ca-annee-n.label')} errorMessage={t('component.professional-data.form.ca-annee-n.error-message')} />
                    </Col>
                    <Col lg={7} md={24} sm={24} xs={24}>
                      <InputNumberWithDoubleLabel form={form} required={false} type="number" name={['chiffre_resultat', 'ca_annee_n_1']} label={t('component.professional-data.form.ca-annee-n-1.label')} errorMessage={t('component.professional-data.form.ca-annee-n-1.error-message')} />
                    </Col>
                    <Col lg={7} md={24} sm={24} xs={24}>
                      <InputNumberWithDoubleLabel form={form} required={false} type="number" name={['chiffre_resultat', 'ca_annee_n_2']} label={t('component.professional-data.form.ca-annee-n-2.label')} errorMessage={t('component.professional-data.form.ca-annee-n-2.error-message')} />
                    </Col>
                  </Row>

                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col className="gutter-row" style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }} span={16}>
                      <h1 style={{ alignSelf: "flex-start", textAlign: 'initial', paddingBottom: '15px' }}>
                        {t(`component.professional-data.form.resultat.${artisanType === 'ENTREPRISE_ARTISANAT' ? 'entreprise' : 'cooperative'}.text`)}
                      </h1>
                    </Col>
                  </Row>

                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col lg={7} md={24} sm={24} xs={24}>
                      <InputNumberWithDoubleLabel form={form} required={false} type="number" name={['chiffre_resultat', 'rn_annee_n']} label={t('component.professional-data.form.rn-annee-n.label')} errorMessage={t('component.professional-data.form.rn-annee-n.error-message')} />
                    </Col>
                    <Col lg={7} md={24} sm={24} xs={24}>
                      <InputNumberWithDoubleLabel form={form} required={false} type="number" name={['chiffre_resultat', 'rn_annee_n_1']} label={t('component.professional-data.form.rn-annee-n-1.label')} errorMessage={t('component.professional-data.form.rn-annee-n-1.error-message')} />
                    </Col>
                    <Col lg={7} md={24} sm={24} xs={24}>
                      <InputNumberWithDoubleLabel form={form} required={false} type="number" name={['chiffre_resultat', 'rn_annee_n_2']} label={t('component.professional-data.form.rn-annee-n-2.label')} errorMessage={t('component.professional-data.form.rn-annee-n-2.error-message')} />
                    </Col>
                  </Row>
                </>
              }

              <Row style={{ padding: '21px', justifyContent: 'space-between' }}>
                {[Utils.ARTISANT_TYPE.ARTISAN_AVEC_DIPLOME, Utils.ARTISANT_TYPE.ARTISAN_AVEC_EXPERIENCE, Utils.ARTISANT_TYPE.ARTISAN_MAALAM].includes(artisanType) &&
                  <Button
                    shape="round"
                    size={"middle"}
                    style={{ margin: '0 8px' }}
                    onClick={() => { props.prev() }}
                  >
                    {t('component.professional-data.form.reset.label')}
                  </Button>
                }
                <Button shape="round" type="primary" htmlType="submit" size={"middle"} >
                  {t('component.professional-data.form.submit.label')}
                </Button>
              </Row>
            </Form>
          </Col>
        </Space>
      </Row >
    </Card >
  );
};

export default ProfessionalData;

