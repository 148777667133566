import types from './ActionTypes';

const initialeState = {
    loading: false,
    success: "",
    error: ""
};


function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: '',
      };
    }

    case types.DOWNLOAD_CERTIFICATION_FULFILLED: {
      return {
        ...state,
        loading: false,
        success: "Votre certificat a été téléchargé avec succès",
        error: ""
      };
    }

    case types.DOWNLOAD_CERTIFICATION_REJECTED: {
        return {
          ...state,
          loading: false,
          success: "",
          error: "Il somble d'avoire une erreur lors du téléchargement de votre certification"
        };
      }

    case types.DOWNLOAD_CERTIFICATION_PENDING: {
        return {
          ...state,
          loading: true,
          success: "",
          error: ""
        };
      }

    case types.CLEAR_DOWNLOAD_CERTIFICATION_MESSAGES: {
        return {
          ...state,
          loading: false,
          success: "",
          error: ""
        };
      } 

    default:
      return state;
  }
}

export default reducer;
