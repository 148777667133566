import { Button, Col, Row, Alert, Space, Typography } from "antd";
import React, { useEffect, useState } from "react"
import ReferentielList from "./components/ReferentielList/ReferentielList";
import { getActiviteBrancheDecrets, getActiviteFiliereDecrets, getActiviteDecret } from './Action';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Utils from "../../../../../utilities/Utils/Utils";
import history from "../../../../../history";
import ReferentielDetail from "./components/ReferentielDetail/ReferentielDetail";
import { useTranslation } from "react-i18next";
import './ReferentielActiviteDecret.less'
import SpinLoader from "../../../../../component/SpinLoader/SpinLaoder";
const { Title } = Typography;

const ReferentielActiviteDecret = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { refType } = useParams();

    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [visible, setVisible] = useState(false);
    const [successeMessage, setSuccesseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [activeReferenciel, setActiveReferenciel] = useState({});
    const [action, setAction] = useState('');

    const activiteBrancheDecret = useSelector(state => state.referentielParamsReducer?.referentielActiviteDecret?.activiteBrancheDecret);
    const activiteFiliereDecret = useSelector(state => state.referentielParamsReducer?.referentielActiviteDecret?.activiteFiliereDecret);
    const activiteDecret = useSelector(state => state.referentielParamsReducer?.referentielActiviteDecret?.activiteDecret);

    useEffect(() => {
        setSuccesseMessage("")
        setErrorMessage("")
        loadDataOrRedirect();
    }, [refType]);

    const loadDataOrRedirect = () => {
        setLoading(true);
        if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE_DECRET) {
            dispatch(getActiviteBrancheDecrets())
                .then((res) => setLoading(false))
                .catch((err) => setLoading(false));
        } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE_DECRET) {
            dispatch(getActiviteFiliereDecrets())
                .then((res) => dispatch(getActiviteBrancheDecrets()))
                .then((res) => setLoading(false))
                .catch((err) => setLoading(false));
        } else if (refType === Utils.REFERENTIEL_TYPES.ACTIVITE_DECRET) {
            dispatch(getActiviteDecret())
                .then((res) => dispatch(getActiviteFiliereDecrets())
                    .then((res) => dispatch(getActiviteBrancheDecrets())))
                .then((res) => setLoading(false))
                .catch((err) => setLoading(false));
        } else {
            history.push("/app/agent/home");
        }
    }

    const handleDetails = (record) => {
        setActiveReferenciel(record)
        setVisible(true)
        setAction('CONSULTATION')
    }

    const handleAdd = () => {
        setActiveReferenciel({})
        setVisible(true)
        setLoadingTable(true)
        setAction('ADD')
    }

    const handleUpdates = (record) => {
        setActiveReferenciel(record)
        setVisible(true)
        setLoadingTable(true)
        setAction('UPDATE')
    }

    const hideModal = () => {
        setVisible(false);
        setLoadingTable(false)
        setActiveReferenciel({})
    };

    const getTitle = () => {

        switch (refType) {
            case Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE_DECRET:
                return t('agent.referentiel.title.activiteBrancheDecret');
            case Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE_DECRET:
                return t('agent.referentiel.title.activiteFiliereDecret');
            case Utils.REFERENTIEL_TYPES.ACTIVITE_DECRET:
                return t('agent.referentiel.title.activiteDecret');
            default:
                return "";
        }

    }

    const getAddButtonTitle = () => {
        switch (refType) {
            case Utils.REFERENTIEL_TYPES.ACTIVITE_BRANCHE_DECRET:
                return t('agent.referentiel.add.activiteBrancheDecret');
            case Utils.REFERENTIEL_TYPES.ACTIVITE_FILIERE_DECRET:
                return t('agent.referentiel.add.activiteFiliereDecret');
            case Utils.REFERENTIEL_TYPES.ACTIVITE_DECRET:
                return t('agent.referentiel.add.activiteDecret');
            default:
                return "";
        }

    }

    return (<>
        <Space size="large" direction="vertical" style={{ width: '100%' }}  >
            {loading ? <SpinLoader /> : <>

                <div>
                    <Title span={3} className="aligne-left">
                        {getTitle()}
                    </Title>
                    <Button shape='round' type="primary" htmlType="submit" onClick={() => handleAdd()} className="aligne-right">
                        {getAddButtonTitle()}
                    </Button>
                </div>
                {errorMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={errorMessage}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {successeMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={successeMessage}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }

                <div className="search-result-list">
                    <ReferentielList loadingTable={loadingTable} activiteBrancheDecret={activiteBrancheDecret} activiteFiliereDecret={activiteFiliereDecret} activiteDecret={activiteDecret} refType={refType} handleUpdates={handleUpdates} handleDetails={handleDetails} />
                    {visible && <ReferentielDetail handleClose={hideModal} activeReferenciel={activeReferenciel} action={action} refType={refType}
                        loadDataOrRedirect={loadDataOrRedirect} setErrorMessage={setErrorMessage} setSuccesseMessage={setSuccesseMessage} />}
                </div>
            </>}
        </Space>
    </>);
}

export default ReferentielActiviteDecret;