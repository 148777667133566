import React, { useState } from "react";
import { Layout, Menu, Button, Dropdown, Row, Col, Space, Select } from "antd";
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { PoweroffOutlined, DownOutlined, UserOutlined, ArrowLeftOutlined, LockFilled } from '@ant-design/icons';
import Avatar from "antd/lib/avatar/avatar";
import Utils from '../utilities/Utils/Utils';
import history from "../history"
import storage from 'redux-persist/lib/storage';
import { useTranslation } from "react-i18next";
import './UpperMenu.less';
import LanguageSelector from "./LanguageSelector/LanguageSelector";
import Logo from "../modules/Logo/Logo";
import localforage from "localforage";

const UpperMenu = props => {
  const [current, setCurrent] = useState('');
  const { t, i18n } = useTranslation();
  const menus = useSelector(state => state.loginReducer?.menus);
  const user = useSelector(state => state.loginReducer?.user);
  const userType = useSelector(state => state.loginReducer?.type);
  const artisanStatus = useSelector(state => state.loginReducer?.artisan?.statut);
  const { Header } = Layout;
  const { SubMenu } = Menu;


  const logOut = () => {
    localforage.removeItem('persist:root');
    localStorage.removeItem('token');
    window.location.reload();
  };

  const changePassword = () => {
    userType === "AGENT"
      ? history.push("/app/agent/changer-pass")
      : history.push("/app/artisan/changer-pass");
  }

  const menu = (
    <Menu>
      <Menu.Item key="change-Password" className="subMenu" icon={<LockFilled />} onClick={() => changePassword()}>
        {t("changer-password")}
      </Menu.Item>
      <Menu.Item key="logout" className="subMenu" icon={<PoweroffOutlined />} onClick={() => logOut()}>
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

  

  return (
    <Header className="header" style={{ height: "100%", paddingLeft: '10px', paddingRight: '10px', background: 'transparent' }}>
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <Row type="flex" align="middle">
          <Col span="24" align="middle" style={{ alignItems: "center" }}><Logo height="23px" /></Col>
        </Row>
        {process.env.REACT_APP_PROFIL === "ARTISAN" &&
          <Row >
            <Col span="4" align="middle" style={{ right: 30, position: "absolute", top: "10px" }}>
              <LanguageSelector />
            </Col>

            <Col span="4" align="middle" style={{ left: 30, position: "absolute", top: "10px" }}>
              <Button type="default" shape="round" size={"large"} onClick={() => window.location.assign(process.env.REACT_APP_PORTAIL_URL)} icon={<ArrowLeftOutlined />}
                style={{ backgroundColor: "white" }}>
                {t("go-back")}
              </Button>
            </Col>

          </Row>
        }

        <Row width="100" justify="space-between">
          <Col lg={{ order: 1, span: 18 }} md={{ order: 1, span: 12 }} sm={{ order: 2, span: 24 }} xs={{ order: 2, span: 24 }}>
            <Menu className="menuStyle" theme="light" mode="horizontal" defaultSelectedKeys={current}>
              {menus?.sort((a, b) => a.globalOrder - b.globalOrder)
                .map((menu) => {
                  const { id, link, code, children } = menu;
                  if (children.length !== 0) {
                    const subMenu = children.map((menuChild) => {
                      const { id, link, code } = menuChild;
                      const shouldHideMenuItem = (artisanStatus !== Utils.ARTISANT_STATUS.INITIE && code == 'inscrit_rna')
                        || (![Utils.ARTISANT_STATUS.VALIDE, Utils.ARTISANT_STATUS.REJETE, Utils.ARTISANT_STATUS.EN_COURS_DE_VALIDATION].includes(artisanStatus) && code === 'je_gere_mon_profil_rna')
                        || (artisanStatus !== Utils.ARTISANT_STATUS.VALIDE && code == 'je_demande_att_exercice')
                        || (artisanStatus !== Utils.ARTISANT_STATUS.VALIDE && code == 'je_demande_certification')
                      if (code == 'phonenumber_change_artisans') return;
                      if (code == 'reinitialize_artisan_account') return;

                      return <Menu.Item key={id} disabled={shouldHideMenuItem} ><NavLink to={link}> {t(`menu.${code}`)}</NavLink></Menu.Item>
                    });
                    return (
                      <SubMenu key={id} title={<div style={{ justifyContent: "space-between", textTransform: "none" }}><span style={{ marginInline: "7px" }}>{t(`menu.${code}`)}</span><DownOutlined /></div>}>{subMenu}</SubMenu>
                    );
                  } else {
                    return (
                      <Menu.Item key={id}><NavLink to={link}>{t(`menu.${code}`)}</NavLink></Menu.Item>
                    );
                  }
                })}

            </Menu>
          </Col>
          <Col lg={{ order: 2, span: 6 }} md={{ order: 2, span: 12 }} sm={{ order: 1, span: 24 }} xs={{ order: 1, span: 24 }} >
            <Dropdown overlay={menu} >
              <Button shape="round" type="link" block >
                <Space size="small" direction="horizontal" justify="end" >

                  <Avatar icon={<UserOutlined />} />
                  <span style={{
                    fontSize: 'medium',
                    fontWeight: '500',
                    // fontFamily: "Roboto",
                    fontStyle: "normal",
                    textTransform: "capitalize",
                  }}>   {user.firstName + ' ' + user.lastName} </span> <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Space >
    </Header >
  );

}


export default UpperMenu;