import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Space, Typography, Button, Card, Divider } from "antd";
import { savePersonalData, updatePersonalData, } from '../../Action';
import { InputCNIEWithDoubleLabel, InputCNIEWithTypeIdentity, InputEmailWithDoubleLabel, InputWithDoubleLabel, SeletWithDoubleLabel, BirthDayDateWithDoubleLabel, RadioWithDoubleLabel, InputPhoneNumberWithDoubleLabel, TextAreaWithDoubleLabel, DateWithDoubleLabel, SimpleDateWithDoubleLabel } from '../../../../../utilities/FormFields/FormFields'
import moment from 'moment';
import { isEmpty } from 'lodash';
import Utils from '../../../../../utilities/Utils/Utils'
import { useTranslation } from 'react-i18next';
import { getAnnexe } from '../../../../Referentiels/Action';
import './PersonalData.less'
import { useHistory } from 'react-router';
import { getGlobalParam } from './../../../../GlobalParams/Action';
import { store, persistor } from '../../../../../configureStore';
const { Text } = Typography;

const PersonalData = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [identity, setIdentity] = useState(Utils.TYPE_IDENTITIES.CNIE);
  const artisanId = useSelector(state => state.loginReducer?.artisan?.artisan_id);
  const artisanType = useSelector(state => state.loginReducer?.artisan?.artisan_type?.code);
  const dgsn_data = useSelector(state => state.loginReducer?.dgsn_id);
  const user = useSelector(state => state.loginReducer?.user)
  const { t } = useTranslation();
  const [villes, setVilles] = React.useState(props.params.villes);
  const [provinces, setProvinces] = React.useState(props.params.provinces);
  const [regions, setRegions] = React.useState(props.params.regions);
  const [dgsnData, setDgsnData] = useState({});

  useEffect(() => {
    if (props.initData && props.initData.region) {
      setProvinces(props.params.provinces.filter(province => province.regionId === props.initData.region));
    }
    if (props.initData && props.initData.province) {
      setVilles(props.params.villes.filter(ville => ville.provinceId === props.initData.province));
    }

    if (props.initData?.type_identite) {
      props.initData?.type_identite === Utils.TYPE_IDENTITIES.CNIE ? setIdentity(Utils.TYPE_IDENTITIES.CNIE) : setIdentity(Utils.TYPE_IDENTITIES.CARTE_SEJOUR);
    }

    if(!props.initData){
      if(dgsn_data){
        var dgsnData = JSON.parse(dgsn_data);
        //console.log('dgsnData')
        //console.log(dgsnData)
        var date_expiration_format = (dgsnData.Date_expiration && dgsnData.Date_expiration.length > 6)?'DDMMYYYY':'DDMMYY';
        /*form.setFieldsValue({ genre: dgsnData.genre, type_identite: Utils.TYPE_IDENTITIES.CNIE, cnie: dgsnData.citizenid,
                              lieu_de_naissance: dgsnData.birthplace_Fr, adresse_personnelle: dgsnData.address_Fr, adresse_personnelle_ar: dgsnData.address_Ar,
                              date_naissance: moment(dgsnData.birthdate,'YYYYMMDD'), date_expiration: moment(dgsnData.Date_expiration, date_expiration_format) })*/
        
        setIdentity(Utils.TYPE_IDENTITIES.CNIE)

        setDgsnData(dgsn_data)
      }
    }
  }, []);

  const [form] = Form.useForm();

  const onFinish = formValue => {
    const { next, setArtisanRegion, initData } = props;
    const pathname = history.location?.pathname;
    if (setArtisanRegion)
      setArtisanRegion(formValue.region);
    if (!pathname.includes('souscrire') || initData) {
      dispatch(updatePersonalData(artisanId,
        {
          ...formValue,
          date_expiration: formValue.date_expiration.format("DD/MM/YYYY"),
          date_naissance: formValue.date_naissance.format("DD/MM/YYYY")
        })).then((result) => {
          persistor.flush();
          next()
        });

    } else {
      dispatch(savePersonalData(artisanId,
        {
          ...formValue,
          date_expiration: formValue.date_expiration.format("DD/MM/YYYY"),
          date_naissance: formValue.date_naissance.format("DD/MM/YYYY")
        })).then(result => {
          persistor.flush();
          next();
        });
    }

  }

  const getInitData = () => {
    let data = props.initData;

    let fullUserName = {
      nom_ar: user?.last_name_ar,
      prenom_ar: user?.first_name_ar,
      nom: user?.lastName,
      prenom: user?.firstName,
      type_identite: identity
    };
    let forcedNationalite = isEmpty(props.initData) || isEmpty(props.initData.nationalite)
      ? t('component.personal-data.form.nationalite.default')
      : props.initData.nationalite;

    if (!isEmpty(data)) {
      if (data.date_naissance) {
        data = { ...data, date_naissance: moment(data.date_naissance, "DD/MM/YYYY") }
      }
      if (data.date_expiration) {
        data = { ...data, date_expiration: moment(data.date_expiration, "DD/MM/YYYY") }
      }
    }
    return { ...data, ...fullUserName, numero_telephone: Utils.mapPhoneNumberToNumber(user.login), nationalite: forcedNationalite };
  }


  const onChangeRegion = e => {
    if (e) {
      setProvinces(props.params.provinces.filter(province => province.regionId === e));
      setVilles(props.params.villes.filter(ville => ville.regionId === e));
    } else {
      setProvinces(props.params.provinces)
      setVilles(props.params.villes)
    }

    form.setFieldsValue({ province: '' })
    form.setFieldsValue({ ville: '' })
  };

  const onChangeProvince = e => {
    if (e) {
      setVilles(props.params.villes.filter(ville => ville.provinceId === e));
      var province = props.params.provinces.filter(province => province.id === e);

      if (province) {
        form.setFieldsValue({ region: province[0].regionId })
      }
    } else {
      var region = form.getFieldValue('region')
      setVilles(props.params.villes.filter(ville => ville.regionId === region));
    }

    form.setFieldsValue({ ville: '' })
  };

  const onChangeVille = e => {
    var ville = props.params.villes.filter(ville => ville.id === e);

    if (ville && ville.length !== 0) {
      form.setFieldsValue({ region: ville[0].regionId })
      form.setFieldsValue({ province: ville[0].provinceId })

      setProvinces(props.params.provinces.filter(p => p.regionId === ville[0].regionId));
      setVilles(props.params.villes.filter(v => v.provinceId === ville[0].provinceId))
    }
  }

  const onChangeIdentity = e => {
    setIdentity(e)
  }


  return (
    <Card >
      <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }} align="middle">
        <Space direction="vertical" size="middle" className="w-100">
          <Col span={24} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }}>
            <Form form={form}
              name="registration-personal-Data"
              style={{ width: '100%' }}
              onFinish={onFinish}
              size='small'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              scrollToFirstError
              initialValues={getInitData()}
            >
              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel forceFr={true} form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="nom" label={Utils.staticInternationalization.nomFR} errorMessage={t('component.personal-data.form.nom.label')} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="nom_ar" label={Utils.staticInternationalization.nomAR} errorMessage={t('component.personal-data.form.nom.label')} />
                </Col>
              </Row>
              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel forceFr={true} form={form} type={Utils.TEXT_TYPE.NAME_WITH_SPACE} name="prenom" label={Utils.staticInternationalization.prenomFR} errorMessage={t('component.personal-data.form.prenom.label')} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="prenom_ar" label={Utils.staticInternationalization.prenomAR} errorMessage={t('component.personal-data.form.prenom.label')} />
                </Col>
              </Row>

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24}>
                  <RadioWithDoubleLabel name="genre" label={t('component.personal-data.form.genre.label')} errorMessage={t('component.personal-data.form.genre.error-message')} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel form={form} type="anyLanguageWithSpace" name="nationalite" label={t('component.personal-data.form.nationalite.label')} placeholder={t('component.personal-data.form.nationalite.placeholder')} errorMessage={t('component.personal-data.form.nationalite.error-message')} />
                </Col>
              </Row>

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputCNIEWithTypeIdentity
                    cnieName="cnie"
                    typeIdentityName="type_identite"
                    typeIdentitValue={identity}
                    label={t(`component.personal-data.form.${identity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.label`)}
                    typeIdentityErrorMessage={t('component.personal-data.form.typeIdentite.error-message')}
                    errorMessage={t(`component.personal-data.form.${identity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.error-message`)} options={Utils.identites}
                    onChangeAction={onChangeIdentity} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24}>
                  <SimpleDateWithDoubleLabel type="text" name="date_expiration" label={t(`component.personal-data.form.date_expiration_${identity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.label`)} placeholder={t(`component.personal-data.form.date_expiration_${identity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.placeholder`)} errorMessage={t(`component.personal-data.form.date_expiration_${identity === Utils.TYPE_IDENTITIES.CNIE ? 'cnie' : 'sejour'}.error-message`)} />
                </Col>
              </Row>

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24}>
                  <BirthDayDateWithDoubleLabel controlAge={artisanType === 'ARTISAN_AVEC_DIPLOME' ? 16 : 18} type="text" name="date_naissance" label={t('component.personal-data.form.date-naissance.label')} placeholder={t('component.personal-data.form.date-naissance.placeholder')} errorMessage={t('component.personal-data.form.date-naissance.error-message')} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24}>
                  <InputWithDoubleLabel form={form} type="anyLanguageWithSpace" name="lieu_de_naissance" label={t('component.personal-data.form.lieu-de-naissance.label')} placeholder={t('component.personal-data.form.lieu-de-naissance.placeholder')} errorMessage={t('component.personal-data.form.lieu-de-naissance.error-message')} />
                </Col>
              </Row>

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24}>
                  <TextAreaWithDoubleLabel forceFr={true} form={form} type="text" name="adresse_personnelle" label={Utils.staticInternationalization.adressePersonnelleFR} errorMessage={t('component.personal-data.form.adresse-personnelle.label')} />
                </Col>
                <Col lg={11} md={24} sm={24} xs={24}>
                  <TextAreaWithDoubleLabel form={form} type={Utils.TEXT_TYPE.ARABIC} name="adresse_personnelle_ar" label={Utils.staticInternationalization.adressePersonnelleAR} errorMessage={t('component.personal-data.form.adresse-personnelle.label')} />
                </Col>
              </Row>

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <SeletWithDoubleLabel type="text" name="ville" label={t('component.personal-data.form.ville.label')} placeholder={t('component.personal-data.form.ville.placeholder')} options={villes} onChangeAction={onChangeVille} errorMessage={t('component.personal-data.form.ville.error-message')} />
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <SeletWithDoubleLabel type="text" name="province" label={t('component.personal-data.form.province.label')} placeholder={t('component.personal-data.form.province.placeholder')} options={provinces} onChangeAction={onChangeProvince} errorMessage={t('component.personal-data.form.province.error-message')} />
                </Col>
                <Col lg={7} md={24} sm={24} xs={24}>
                  <SeletWithDoubleLabel type="text" name="region" label={t('component.personal-data.form.region.label')} placeholder={t('component.personal-data.form.region.placeholder')} options={regions} onChangeAction={onChangeRegion} errorMessage={t('component.personal-data.form.region.error-message')} />
                </Col>
              </Row>

              <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                <Col lg={11} md={24} sm={24} xs={24} style={{ width: '100%', justifyContent: 'center' }}>
                  <InputPhoneNumberWithDoubleLabel type="text" name="numero_telephone" label={t('component.personal-data.form.numero-telephone.label')} placeholder={t('component.personal-data.form.numero-telephone.placeholder')} errorMessage={t('component.personal-data.form.numero-telephone.error-message')} disabled={true} />
                </Col>
                <Divider type="vertical" />
                <Col lg={11} md={24} sm={24} xs={24} style={{ width: '100%', justifyContent: 'center' }}>
                  <InputEmailWithDoubleLabel required={false} type="text" name="email" label={t('component.personal-data.form.email.label')} placeholder={t('component.personal-data.form.email.placeholder')} errorMessage={t('component.personal-data.form.email.error-message')} />
                </Col>
              </Row>

              <Col span={24} style={{ textAlign: 'right', padding: '21px', justifyContent: 'space-between' }}>
                <Button shape="round" type="primary" htmlType="submit" size={"middle"} >
                  {t('component.personal-data.form.submit.label')}
                </Button>

              </Col>
            </Form>
          </Col>
        </Space>
      </Row >
    </Card>
  );

};

export default PersonalData;
