import React from "react";
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import { Col, Row, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useTranslation } from 'react-i18next';

import Home from "./home/Home";
import RegistrationStep0 from "./Registration/RegistrationStep0";
import ChangerPass from "../ChangePasse/ChangerPass";
import CreateReclamation from "../Reclamation/CreateReclamation/CreateReclamation";
import ListeReclamation from "../Reclamation/ListeReclamation/ListeReclamation";
import ReclamationDetails from "../Reclamation/ReclamationDetails/ReclamationDetails";
import Registration from "./Registration/Registration";
import ArtisanStatus from "./ArtisanSatus/ArtisanSatus";
import ArtisanNotification from "./ArtisanNotification/ArtisanNotification";
import Certification from "./Certification/Certification"
import Attestation from "./Attestation/Attestation"
import DeleteAccount from "./DeleteAccount/DeleteAccount";

const ArtisanApp = props => {
  //const { path } = useRouteMatch();
  const path = '/app/artisan'
  const { t } = useTranslation();

  return (<>
    <Row>
      <Col lg={{ order: 0, span: 19 }} md={{ order: 1, span: 24 }} sm={{ order: 1, span: 24 }} xs={{ order: 1, span: 24 }}>
        <Content className="site-layout-background"
          style={{
            margin: "24px 20px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Switch>
            <Route exact path={`${path}/home`} component={Home} />
            <Route exact path={`${path}/certification`} component={Certification} />
            <Route exact path={`${path}/exercice`} component={Attestation} />
            <Route exact path={`${path}/changer-pass`} component={ChangerPass} />
            <Route exact path={`${path}/reclamations/ajouter-reclamation`} component={CreateReclamation} />
            <Route exact path={`${path}/reclamations/mes-reclamation`} component={ListeReclamation} />
            <Route exact path={`${path}/reclamations/mes-reclamation/:id`} component={ReclamationDetails} />
            <Route exact path={`${path}/souscrire`} component={RegistrationStep0} />
            <Route exact path={`${path}/delete-account`} component={DeleteAccount} />
            <Route exact path={[`${path}/souscrire/donnees`, `${path}/modification/donnees`]} component={Registration} />
            <Redirect to={`${path}/home`} />
          </Switch>
        </Content>
      </Col>
      <Col lg={{ order: 1, span: 5 }} md={{ order: 0, span: 24 }} sm={{ order: 0, span: 24 }} xs={{ order: 0, span: 24 }} style={{ paddingInline: 24 }}>
        {/* <Content className="site-layout-background"
         
        > */}
        <Space size="large" direction="vertical" style={{ width: '100%' }}>
          <Row gutter={[8, 8]}>

            <Col span="24" md={{ order: 0, span: 24 }} sm={{ order: 1, span: 24 }}>
              <ArtisanStatus />
            </Col>
            <Col span="24" md={{ order: 1, span: 24 }} sm={{ order: 0, span: 24 }}>
              <ArtisanNotification />
            </Col>
          </Row>
        </Space>
        {/* </Content> */}
      </Col>
      <Col span={24} md={{ order: 3, span: 24 }} sm={{ order: 3, span: 24 }} xs={{ order: 3, span: 24 }} type="flex" justify="center" align="center" style={{ margin: '0', justifyContent: "center", padding: "20px 0 20px 0" }} >
        <h4 style={{ color: "white" }}>{t('new-account.footer')}</h4>
      </Col>
    </Row>
    <Row>

    </Row>

  </>)


}


export default ArtisanApp;
