import React, { useEffect } from 'react';
import { Row, Col, Button, Alert, Typography } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import { connect, useSelector } from 'react-redux';
import { downloadCertification, clearMessages } from './Action'
import Title from 'antd/lib/typography/Title';
import SpinLoader from '../../../component/SpinLoader/SpinLaoder';
import { useTranslation } from 'react-i18next';
import Utils from '../../../utilities/Utils/Utils';
const { Text } = Typography;


const Certification = props => {
    const { artisanId, loading, success, error } = props;
    const { t } = useTranslation();

    const statut = useSelector(state => state.loginReducer?.artisan?.statut);
    const { ARTISANT_STATUS } = Utils;

    useEffect(() => {
        props.clearMessages();
    }, []);


    return (<>
        {loading ? <SpinLoader /> : <>
            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '10px' }} align="bottom" >
                <Title level={3}>{t("certification.titre")}</Title>
            </Row>

            <Row style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', padding: '20px' }} align="bottom" >
                {error &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={error}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {success &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={success}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }
                <Col span={18} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', paddingBottom: '30px' }}>
                    <Text>{t("certification.description")}</Text>
                </Col>
                {
                    statut && statut === ARTISANT_STATUS.VALIDE &&

                    <Col span={20} type="flex" justify="center" align="center" style={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }}>
                        <Button shape="round" type="primary" icon={<DownloadOutlined />} size="large" onClick={() => props.downloadCertification(artisanId)}>
                            {t("certification.submit.label")}
                        </Button>
                    </Col>
                }
            </Row >
        </>}

    </>)

}

const mapStateToProps = (state) => {
    return {
        artisanId: state.loginReducer.artisan.artisan_id,
        loading: state.certificationReducer.loading,
        success: state.certificationReducer.success,
        error: state.certificationReducer.error,
    };
};

const mapDispatchToProps = (dispatch) => ({
    downloadCertification: (artisanId) => dispatch(downloadCertification(artisanId)),
    clearMessages: () => dispatch(clearMessages())
});


export default connect(mapStateToProps, mapDispatchToProps)(Certification);
