import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  success: "",
  activiteBranche: [],
  activiteFiliere: [],
  activite: [],
};

const mapDataToActiviteBranchees = data => {
  const list = [];
  data.map(line => {
    list.push(
      {
        key: line.id,
        description: line.description,
        label_fr: line.labels.FR,
        label_ar: line.labels.AR,
        code: line.code
      })
  })
  return list;
}



const mapDataToActiviteFilieres = data => {
  const list = [];
  data.map(line => {
    list.push(
      {
        key: line.id,
        description: line.description,
        label_fr: line.labels.FR,
        label_ar: line.labels.AR,
        code: line.code,
        activite_branche_id: line.activite_branche_id
      })
  })
  return list;
}



const mapDataToActivites = data => {
  const list = [];
  data.map(line => {
    list.push(
      {
        key: line.id,
        description: line.description,
        label_fr: line.labels.FR,
        label_ar: line.labels.AR,
        label_reduit_fr: line.labels_reduits.FR,
        label_reduit_ar: line.labels_reduits.AR,
        code: line.code,
        activite_branche_id: line.activite_branche_id,
        activite_filiere_id: line.activite_filiere_id
      })
  })
  return list;
};



function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.CLEAR_MESSAGES: {
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      };
    }

    case (types.GET_ACTIVITE_BRANCHE_PENDING || types.ADD_ACTIVITE_BRANCHE_PENDING || types.UPDATE_ACTIVITE_BRANCHE_PENDING
      || types.GET_ACTIVITE_FILIERE_PENDING || types.ADD_ACTIVITE_FILIERE_PENDING || types.UPDATE_ACTIVITE_FILIERE_PENDING
      || types.GET_ACTIVITE_PENDING || types.ADD_ACTIVITE_PENDING || types.UPDATE_ACTIVITE_PENDING): {

        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        };
      }

    case types.GET_ACTIVITE_BRANCHE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        activiteBranche: mapDataToActiviteBranchees(data),
        loading: false,
      };
    }

    case types.ADD_ACTIVITE_BRANCHE_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "Activite Branchee créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_ACTIVITE_BRANCHE_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "Activite Branchee modifié avec succès",
        loading: false
      };
    }

    case types.GET_ACTIVITE_FILIERE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        activiteFiliere: mapDataToActiviteFilieres(data),
        loading: false,
      };
    }

    case types.ADD_ACTIVITE_FILIERE_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "Activite Filiere créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_ACTIVITE_FILIERE_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "Activite Filiere modifié avec succès",
        loading: false
      };
    }

    case types.GET_ACTIVITE_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        error: "",
        success: "",
        activite: mapDataToActivites(data),
        loading: false,
      };
    }


    case types.ADD_ACTIVITE_FULFILLED: {

      return {
        ...state,
        error: "",
        success: "Activite créé avec succès",
        loading: false,
      };
    }

    case types.UPDATE_ACTIVITE_FULFILLED: {
      return {
        ...state,
        error: "",
        success: "Activite modifié avec succès",
        loading: false
      };
    }

    case (types.GET_ACTIVITE_BRANCHE_REJECTED || types.ADD_ACTIVITE_BRANCHE_REJECTED || types.UPDATE_ACTIVITE_BRANCHE_REJECTED
      || types.GET_ACTIVITE_FILIERE_REJECTED || types.ADD_ACTIVITE_FILIERE_REJECTED || types.UPDATE_ACTIVITE_FILIERE_REJECTED
      || types.GET_ACTIVITE_REJECTED || types.ADD_ACTIVITE_REJECTED || types.UPDATE_ACTIVITE_REJECTED): {

        const { message } = action.payload.response.data;
        return {
          ...state,
          error: message,
          success: "",
          loading: false,
        };
      }

    default:
      return state;
  }
}

export default reducer;
