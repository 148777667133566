import { Row, Col } from "antd";
import React, { useEffect } from "react"
import { getMinistereTitle } from './Action';
import { useDispatch, useSelector } from "react-redux";
import SpinLoader from '../../component/SpinLoader/SpinLaoder';
import rna_logo from '../../assets/logo.png'

const Logo = (props) => {

    const dispatch = useDispatch();
    const curerntLAnguage = localStorage.getItem('i18nextLng');
    const titleLabelsFR = useSelector(state => state.logoReducer?.titleLabelsFR);
    const titleLabelsAR = useSelector(state => state.logoReducer?.titleLabelsAR);

    useEffect(() => {
        dispatch(getMinistereTitle());
    }, []);


    return (
        <Row align="middle" justify="center" >
            {!titleLabelsFR || !titleLabelsAR ? <SpinLoader /> :
                <>
                    <Col span={8} style={{ marginBottom: "30px" }} order={curerntLAnguage === 'fr' ? "1" : "3"}>
                        {
                            titleLabelsFR.map(label => {
                                return (
                                    < p style={{
                                        margin: 0,
                                        fontWeight: "500",
                                        fontSize: "17px",
                                        height: props.height
                                    }} >{label}</p>
                                )
                            })
                        }
                    </Col>
                    <Col span={4} align="middle" style={{ alignItems: "end" }} order="2">
                        <img src={rna_logo} alt="RNA" width={"40%"} />
                    </Col>
                    <Col span={8} style={{ marginBottom: "30px" }} order={curerntLAnguage === 'fr' ? "3" : "1"}>
                        {
                            titleLabelsAR.map(label => {
                                return (
                                    < p style={{
                                        margin: 0,
                                        fontWeight: "500",
                                        fontSize: "17px",
                                        height: props.height
                                    }} > {label}</p>
                                )
                            })
                        }
                    </Col>
                </>
            }
        </Row >);
};

export default Logo;
