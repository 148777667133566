import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { CheckOutlined, CloseOutlined, SearchOutlined, FileOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Space, Table, Modal, Tooltip, Divider } from "antd";
import Highlighter from "react-highlight-words";
import { withRouter } from "react-router";
import RejectDeleteArtisanForm from "./RejectDeleteArtisanForm";
import ValidateDeleteArtisanForm from "./ValidateDeleteArtisanForm";
import VisualiseDocument from "./VisualiseDocument";
import { getDeleteDemandeDocument } from "../Action";

const { confirm } = Modal;
class DeleteArtisanAccountList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            activeArtisan: {},
            pagination: {
                current: 1,
                pageSize: 10,
            },
            showRejectionModal: false,
            showValidationModal: false,
            isFileOfTypePicture: false,
            loading: false,
            file: null,
            fileType: ''
        };
        this.validateRequest = this.validateRequest.bind(this);
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    validateRequest = record => {
        this.setState({ ...this.state, showValidationModal: true, activeArtisan: record });
    }

    rejectRequest = record => {
        this.setState({ ...this.state, showRejectionModal: true, activeArtisan: record });
    }

    visualiseDoc = record => {
        const artisanId = record?.artisanId;
        const demandeSuppressionId = record?.key;
        this.props.getDeleteDemandeDocument(artisanId, demandeSuppressionId).then(response => {
            const fileType = response.value.headers.x_file_extension;
            this.setState({ ...this.state, fileType: fileType });

            const base64 = response.value.data;
            var blob = fileType === 'pdf' ? new Blob([base64], { type: 'application/pdf' }) : base64;
            var blobURL = URL.createObjectURL(blob);
            this.setState({ ...this.state, file: blobURL });

            if (fileType === 'pdf') {
                window.open(blobURL);
            } else {
                this.setState({ ...this.state, isFileOfTypePicture: true, activeArtisan: record })
            }
        });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        shape="round"
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {this.props.t('agent.deleteArtisanAccount.filterDropdown.search')}
                    </Button>
                    <Button shape="round" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {this.props.t('agent.deleteArtisanAccount.filterDropdown.reset')}
                    </Button>
                    <Button
                        shape="round"
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        {this.props.t('agent.deleteArtisanAccount.filterDropdown.filter')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    renderTableColumns = () => {
        const { t } = this.props;
        const columns = []

        columns.push(
            {
                title: t('agent.deleteArtisanAccount.table.columns.action'),
                dataIndex: 'action',
                key: Math.random,
                render: (text, record, index) => (

                    <Row align='middle'>
                        {record.statut === 'INITIE' && <>
                            <Col span={8}>
                                <Tooltip title="Rejeter la Demande" >
                                    <a onClick={() => this.rejectRequest(record)}><CloseOutlined style={{ color: "#FF0000" }} />  </a>
                                </Tooltip>
                            </Col>

                            <Col span={8}>
                                <Tooltip title="Valider la demande" >
                                    <a onClick={() => this.validateRequest(record)}><CheckOutlined style={{ color: "#52c41a" }} /> </a>
                                </Tooltip>
                            </Col>
                        </>}
                        <Col span={8}>
                            <Tooltip title="Visualiser document" >
                                <a onClick={() => this.visualiseDoc(record)}><FileOutlined style={{ color: "#696969" }} />  </a>
                            </Tooltip>
                        </Col>
                    </Row>
                ),
            },
            {
                title: t('agent.deleteArtisanAccount.table.columns.statut'),
                dataIndex: 'statut',
                key: 'statut',
                ...this.getColumnSearchProps('statut'),
            },
            {
                title: t('agent.deleteArtisanAccount.table.columns.artisanTypeFR'),
                dataIndex: 'artisanTypeFR',
                key: 'artisanTypeFR',
                ...this.getColumnSearchProps('artisanTypeFR'),
            },
            // {
            //     title: t('agent.deleteArtisanAccount.table.columns.artisanTypeAR'),
            //     dataIndex: 'artisanTypeAR',
            //     key: 'artisanTypeAR',
            //     ...this.getColumnSearchProps('artisanTypeAR'),
            // },
            {
                title: t('agent.deleteArtisanAccount.table.columns.nom'),
                dataIndex: 'nom',
                key: 'nom',
                ...this.getColumnSearchProps('nom'),
            },
            {
                title: t('agent.deleteArtisanAccount.table.columns.prenom'),
                dataIndex: 'prenom',
                key: 'prenom',
                ...this.getColumnSearchProps('prenom'),
            },
            {
                title: t('agent.deleteArtisanAccount.table.columns.cnie'),
                dataIndex: 'cnie',
                key: 'cnie',
                ...this.getColumnSearchProps('cnie'),
            },
            {
                title: t('agent.deleteArtisanAccount.table.columns.telephone'),
                dataIndex: 'telephone',
                key: 'telephone',
                ...this.getColumnSearchProps('telephone'),
            },
            {
                title: t('agent.deleteArtisanAccount.table.columns.nomEntreprise'),
                dataIndex: 'nom_entreprise',
                key: 'nom_entreprise',
                ...this.getColumnSearchProps('nom_entreprise'),
            },
            {
                title: t('agent.deleteArtisanAccount.table.columns.nomCooperative'),
                dataIndex: 'nom_cooperative',
                key: 'nom_cooperative',
                ...this.getColumnSearchProps('nom_cooperative'),
            },
            {
                title: t('agent.deleteArtisanAccount.table.columns.motif'),
                dataIndex: 'motif',
                key: 'motif',
                ...this.getColumnSearchProps('motif'),
            },
            {
                title: t('agent.deleteArtisanAccount.table.columns.motifRejet'),
                dataIndex: 'motifRejet',
                key: 'motifRejet',
                ...this.getColumnSearchProps('motifRejet'),
            },
            {
                title: t('agent.deleteArtisanAccount.table.columns.createdAt'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                ...this.getColumnSearchProps('createdAt'),
            },
        );

        // if(record.statut !== 'INITIE'){
        //     columns.push(
        //         {
        //             title: t('agent.deleteArtisanAccount.columns.action'),
        //             dataIndex: 'action',
        //             key: Math.random,
        //             render: (text, record, index) => (

        //                 <Row align='middle'>

        //                     <Col span={12}>
        //                         <Tooltip title="Rejeter la Demande" >
        //                             <a onClick={() => this.rejectRequest(record)}><CloseOutlined style={{ color: "#FF0000" }} />  </a>
        //                         </Tooltip>
        //                     </Col>

        //                     <Col span={12}>
        //                         <Tooltip title="Valider la demande" >
        //                             <a onClick={() => this.validateRequest(record)}><CheckOutlined style={{ color: "#52c41a" }} /> </a>
        //                         </Tooltip>
        //                     </Col>
        //                 </Row>
        //             ),
        //         }
        //     )
        // }

        return columns;
    }

    handleOK = value => {
        const { handleRejectRequest } = this.props;
        const statut = this.state.activeArtisan?.statut;
        handleRejectRequest(this.state.activeArtisan?.artisanId, this.state.activeArtisan?.key, { statut: "REJETE", ...value });
        this.handleCancel();
    }

    handleOkValidateRequest = (record) => {
        const { handleValidateRequest } = this.props;
        handleValidateRequest(this.state.activeArtisan?.artisanId, this.state.activeArtisan?.key, { statut: "VALIDE" });
        this.handleCancel();
    }

    handleCancel = () => {
        this.setState({ ...this.state, showRejectionModal: false, showValidationModal: false, isFileOfTypePicture: false });
    }


    render() {
        const { t, data, loadingTable } = this.props;
        return (
            <>
                <Divider>
                    {t('agent.deleteArtisanAccount.table.title')}
                </Divider>
                <RejectDeleteArtisanForm visible={this.state.showRejectionModal} handleSubmit={this.handleOK} close={this.handleCancel} />
                <ValidateDeleteArtisanForm visible={this.state.showValidationModal} handleSubmit={this.handleOkValidateRequest} close={this.handleCancel} />
                <VisualiseDocument fileType={this.state.fileType} file={this.state.file} visible={this.state.isFileOfTypePicture} close={this.handleCancel} />
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Table columns={this.renderTableColumns()} scroll={{ x: 500 }} dataSource={data} loading={loadingTable} pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }} />
                </Col>
            </>)
    };
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => ({
    getDeleteDemandeDocument: (artisanId, demandeSuppressionId) => dispatch(getDeleteDemandeDocument(artisanId, demandeSuppressionId))
});

DeleteArtisanAccountList = connect(mapStateToProps, mapDispatchToProps)(DeleteArtisanAccountList);
export default withTranslation()(withRouter(DeleteArtisanAccountList));
