import { ActionType } from 'redux-promise-middleware';

const actionTypes = {
    GET_FILE: 'GET_FILE',
    GET_FILE_PENDING: `GET_FILE_${ActionType.Pending}`,
    GET_FILE_FULFILLED: `GET_FILE_${ActionType.Fulfilled}`,
    GET_FILE_REJECTED: `GET_FILE_${ActionType.Rejected}`,

    GET_FILES_LIST: 'GET_FILES_LIST',
    GET_FILES_LIST_PENDING: `GET_FILES_LIST_${ActionType.Pending}`,
    GET_FILES_LIST_FULFILLED: `GET_FILES_LIST_${ActionType.Fulfilled}`,
    GET_FILES_LIST_REJECTED: `GET_FILES_LIST_${ActionType.Rejected}`,

    UPLOAD_COMMITE_FILE: 'UPLOAD_COMMITE_FILE',
    UPLOAD_COMMITE_FILE_PENDING: `UPLOAD_COMMITE_FILE_${ActionType.Pending}`,
    UPLOAD_COMMITE_FILE_FULFILLED: `UPLOAD_COMMITE_FILE_${ActionType.Fulfilled}`,
    UPLOAD_COMMITE_FILE_REJECTED: `UPLOAD_COMMITE_FILE_${ActionType.Rejected}`,

    REMOVE_COMMITE_FILE: 'REMOVE_COMMITE_FILE',
    REMOVE_COMMITE_FILE_PENDING: `REMOVE_COMMITE_FILE_${ActionType.Pending}`,
    REMOVE_COMMITE_FILE_FULFILLED: `REMOVE_COMMITE_FILE_${ActionType.Fulfilled}`,
    REMOVE_COMMITE_FILE_REJECTED: `REMOVE_COMMITE_FILE_${ActionType.Rejected}`,
    
    GET_ALL_COMMITE_FILES: 'GET_ALL_COMMITE_FILES',
    GET_ALL_COMMITE_FILES_PENDING: `GET_ALL_COMMITE_FILES_${ActionType.Pending}`,
    GET_ALL_COMMITE_FILES_FULFILLED: `GET_ALL_COMMITE_FILES_${ActionType.Fulfilled}`,
    GET_ALL_COMMITE_FILES_REJECTED: `GET_ALL_COMMITE_FILES_${ActionType.Rejected}`,

    GET_ONE_COMMITE_FILE: 'GET_ONE_COMMITE_FILE',
    GET_ONE_COMMITE_FILE_PENDING: `GET_ONE_COMMITE_FILE_${ActionType.Pending}`,
    GET_ONE_COMMITE_FILE_FULFILLED: `GET_ONE_COMMITE_FILE_${ActionType.Fulfilled}`,
    GET_ONE_COMMITE_FILE_REJECTED: `GET_ONE_COMMITE_FILE_${ActionType.Rejected}`,

    GET_ACTIONS: 'GET_ACTIONS',
    GET_ACTIONS_PENDING: `GET_ACTIONS_${ActionType.Pending}`,
    GET_ACTIONS_FULFILLED: `GET_ACTIONS_${ActionType.Fulfilled}`,
    GET_ACTIONS_REJECTED: `GET_ACTIONS_${ActionType.Rejected}`,

    CHANGE_ARTISAN_PHONE_NUMBER: 'CHANGE_ARTISAN_PHONE_NUMBER',
    CHANGE_ARTISAN_PHONE_NUMBER_PENDING: `CHANGE_ARTISAN_PHONE_NUMBER_${ActionType.Pending}`,
    CHANGE_ARTISAN_PHONE_NUMBER_FULFILLED: `CHANGE_ARTISAN_PHONE_NUMBER_${ActionType.Fulfilled}`,
    CHANGE_ARTISAN_PHONE_NUMBER_REJECTED: `CHANGE_ARTISAN_PHONE_NUMBER_${ActionType.Rejected}`,

    REINITIALIZE_ARTISAN_ACCOUNT: 'REINITIALIZE_ARTISAN_ACCOUNT',
    REINITIALIZE_ARTISAN_ACCOUNT_PENDING: `REINITIALIZE_ARTISAN_ACCOUNT_${ActionType.Pending}`,
    REINITIALIZE_ARTISAN_ACCOUNT_FULFILLED: `REINITIALIZE_ARTISAN_ACCOUNT_${ActionType.Fulfilled}`,
    REINITIALIZE_ARTISAN_ACCOUNT_REJECTED: `REINITIALIZE_ARTISAN_ACCOUNT_${ActionType.Rejected}`,
};

export default actionTypes;