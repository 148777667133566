import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    GET_PUBLIC_PRIVE: 'GET_PUBLIC_PRIVE',
    GET_PUBLIC_PRIVE_PENDING: `GET_PUBLIC_PRIVE_${ActionType.Pending}`,
    GET_PUBLIC_PRIVE_FULFILLED: `GET_PUBLIC_PRIVE_${ActionType.Fulfilled}`,
    GET_PUBLIC_PRIVE_REJECTED: `GET_PUBLIC_PRIVE_${ActionType.Rejected}`,

    GET_DEPARTEMENT_FORMATION: 'GET_DEPARTEMENT_FORMATION',
    GET_DEPARTEMENT_FORMATION_PENDING: `GET_DEPARTEMENT_FORMATION_${ActionType.Pending}`,
    GET_DEPARTEMENT_FORMATION_FULFILLED: `GET_DEPARTEMENT_FORMATION_${ActionType.Fulfilled}`,
    GET_DEPARTEMENT_FORMATION_REJECTED: `GET_DEPARTEMENT_FORMATION_${ActionType.Rejected}`,

    ADD_DEPARTEMENT_FORMATION: 'ADD_DEPARTEMENT_FORMATION',
    ADD_DEPARTEMENT_FORMATION_PENDING: `ADD_DEPARTEMENT_FORMATION_${ActionType.Pending}`,
    ADD_DEPARTEMENT_FORMATION_FULFILLED: `ADD_DEPARTEMENT_FORMATION_${ActionType.Fulfilled}`,
    ADD_DEPARTEMENT_FORMATION_REJECTED: `ADD_DEPARTEMENT_FORMATION_${ActionType.Rejected}`,

    UPDATE_DEPARTEMENT_FORMATION: 'UPDATE_DEPARTEMENT_FORMATION',
    UPDATE_DEPARTEMENT_FORMATION_PENDING: `UPDATE_DEPARTEMENT_FORMATION_${ActionType.Pending}`,
    UPDATE_DEPARTEMENT_FORMATION_FULFILLED: `UPDATE_DEPARTEMENT_FORMATION_${ActionType.Fulfilled}`,
    UPDATE_DEPARTEMENT_FORMATION_REJECTED: `UPDATE_DEPARTEMENT_FORMATION_${ActionType.Rejected}`,
};

export default ActionTypes;
