module.exports = `<html>
<body>
<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article 1<sup>er</sup> : Présentation du site RNA<o:p></o:p></span></strong></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>
Le Ministère du Tourisme, </span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin'>de l’Artisanat et de l’Economie
Sociale et solidaire vous fournit son site Internet&nbsp;rna.gov.ma (intitulé
ci-après «&nbsp;<strong><span style='font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;font-weight:normal'>Registre National de l’Artisanat</span></strong>&nbsp;»),
y compris tous ses services, contenus et logiciels, en application des
conditions d’utilisation suivantes et de tous les documents mentionnés dans
celles-ci.<o:p></o:p></span></p>

<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article 2 : Accès et utilisation
du site RNA<o:p></o:p></span></strong></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>L’utilisation de la plateforme RNA est ouverte à toute personne
disposant d’une connexion Internet. L’accès à la Plateforme RNA qui est gratuit
et sans obligation d’achat, entraîne l’acceptation entière et sans réserve des
présentes Conditions Générales d’Utilisation (ci-après «&nbsp;<strong><span
style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;font-weight:
normal'>CGU</span></strong>&nbsp;»).<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Outre ces CGU l’utilisateur de la Plateforme RNA (ci-après
l’«&nbsp;<strong><span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
font-weight:normal'>Utilisateur</span></strong>&nbsp;») est tenu d’accepter
d’autres Conditions Générales dans le cadre des services particuliers
disponibles via la Plateforme numérique RNA.&nbsp;<o:p></o:p></span></p>

<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article&nbsp;3 : Propriété intellectuelle<o:p></o:p></span></strong></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>
Le site web RNA est la propriété exclusive du Ministère du Tourisme, de
l’Artisanat et de l’Economie Sociale et solidaire (Département de l’Artisanat
et de l’Economie Sociale et Solidaire).<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>La présentation et le contenu de la Plateforme RNA constituent une
œuvre originale protégée par les lois et les traités internationaux en vigueur
sur la propriété intellectuelle et&nbsp;en particulier par celles sur les
droits d'auteur et droits voisins, dessins, modèles et marques.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>La Plateforme RNA&nbsp;comporte des textes, images, photographies,
logos, marques qui sont la propriété du Ministère du Tourisme, de l’Artisanat
et de l’Economie Sociale et solidaire (Département de l’Artisanat et de
l’Economie Sociale et Solidaire). ou dont certains, font l’objet d’une
autorisation d’exploitation au profit du Ministère lorsqu’ils sont propriétés
des partenaires du Ministère. Ces éléments sont eux-mêmes protégés par le droit
de propriété industrielle et intellectuelle.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>La reproduction, la représentation, la diffusion ou l'exploitation
de manière générale, totale ou partielle, sous quelque forme que ce soit, de
tout ou d’une partie des éléments composant la Plateforme RNA&nbsp;qui y sont
proposés, sans l’autorisation expresse et préalable du Ministère et des titulaires
des droits, sont strictement interdites et constitueraient une contrefaçon sanctionnable
au titre des règles régissant la propriété intellectuelle.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Le Ministère se réserve le droit de poursuivre toute personne qui
contreviendrait au respect de son droit de propriété intellectuelle. Cette
infraction est susceptible d'être pénalement et civilement sanctionnée,
notamment au titre de la contrefaçon ou atteinte aux droits d’auteurs et droits
voisins entraînant le cas échéant le versement de dommages et intérêts.
&nbsp;<o:p></o:p></span></p>

<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article&nbsp;4 : Responsabilité du
Ministère <o:p></o:p></span></strong></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>
Le Ministère déploie ses meilleurs efforts afin que la Plateforme RNA soit
accessible 24h/24 et 7j /7 dans les meilleures conditions de confort
d’utilisation.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Le Ministère ne saurait garantir de fournir la Plateforme RNA&nbsp;exempt
de tout défaut, et de ce fait, le Site est susceptible de faire l’objet de bugs
informatiques ou de dysfonctionnements liés aux connexions du réseau et cela
peut conduire à l’indisponibilité temporaire de la Plateforme RNA.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Le Ministère pourra être amené, à tout moment, à modifier ou
interrompre temporairement ou pour une durée indéterminée tout ou partie de la
Plateforme RNA&nbsp;pour effectuer des opérations de maintenance et/ou
effectuer des améliorations et/ou des modifications sur celle-ci. <o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Par conséquent, en aucun cas la responsabilité du Ministère ne
pourra être retenue pour quelque dommage que ce soit qui résulterait d’une
interruption de la Plateforme RNA, altération de son fonctionnement ou de la
qualité des informations y livrées.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>En outre, le Ministère ne pourra être tenu pour responsable des
actes des Utilisateurs qui sont contraires aux règles visées aux présentes
C.G.U, ni de tout dommage direct ou indirect issu de l'utilisation de la Plateforme
RNA&nbsp;et ce, pour quelque raison que ce soit notamment, en cas d’erreurs
typographiques, et de problèmes et d’inexactitudes techniques tel un virus, un
bug informatique, une intrusion malveillante ou un dysfonctionnement de la
Plateforme indépendant de la volonté du Ministère. <o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>L’utilisation des informations disponibles sur la Plateforme RNA&nbsp;se
fait sous l’entière et seule responsabilité de l’Utilisateur.&nbsp;<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Les
Utilisateurs de la Plateforme RNA dégagent le Ministère de toute responsabilité
quant à tout dommage de quelque nature que ce soit, pouvant résulter
directement ou indirectement suite à leur utilisation de la Plateforme RNA.
&nbsp;<o:p></o:p></span></p>

<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article&nbsp;5 : Responsabilité de
l’Utilisateur<o:p></o:p></span></strong></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>
L’Utilisateur déclare bien connaître Internet, ses caractéristiques et ses
limites et reconnaît avoir connaissance de la nature du réseau Internet et en
particulier de ses performances techniques et des temps de réponse, pour
consulter, interroger ou transférer les données d'informations.

L’Utilisateur s'engage à :<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>- Fournir des informations exactes et à jour.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>- N’utiliser aucun dispositif, logiciel ou sous-programme pour
interférer ou essayer d'interférer sur le bon fonctionnement de la Plateforme
RNA.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>- S’interdire de stocker, télécharger ou envoyer toute donnée
prohibée, illicite, contraire, aux bonnes mœurs ou à l’ordre public et/ou
portant atteinte aux droits de tiers et notamment aux droits de propriété
intellectuelle appartenant à des tiers.&nbsp;&nbsp; &nbsp;<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>L’Utilisateur déclare reconnaître qu’Internet n’est pas sécurisé
et que le secret des correspondances transmises n’y est pas garanti. Aussi,
l’Utilisateur est informé que la communication de toute information
confidentielle ou non est faite par ce dernier à ses risques et périls et qu’il
lui appartient de se prémunir contre les risques d’attaque virale contre ses
données et/ou logiciels.<o:p></o:p></span></p>

<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article&nbsp;6&nbsp;:
Cookies<o:p></o:p></span></strong></p>
<span style='font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;font-weight:normal'><o:p></o:p></span></strong></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>
L’Utilisateur est informé que, lors de ses visites <s>sur </s>de la Plateforme RNA,
un cookie peut s’installer automatiquement sur son navigateur.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Le cookie est un fichier texte qui s’installe sur le disque dur de
l’Utilisateur. Il permet notamment de recueillir et de stocker des données sur
le comportement de navigation à partir du poste connecté. Un cookie ne permet
pas d’identifier l’Utilisateur&nbsp;; en revanche, il enregistre des
informations relatives à sa navigation sur un site notamment les pages
consultées, la date et l'heure de la consultation.

Le Ministère du Tourisme, de l’Artisanat et de l’Economie Sociale et solidaire (Département
de l’Artisanat et de l’Economie Sociale et Solidaire). utilise ces informations
sous forme globale, afin de comprendre comment les Utilisateurs utilisent la
Plateforme numérique RNA et de pouvoir l’améliorer. Aucune donnée à caractère
personnel identifiable n’est révélée à cette occasion.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Par ailleurs, l’Utilisateur peut s’opposer à l’enregistrement de
cookies en configurant son navigateur. Cependant, cette désactivation pourrait
avoir un impact sur la facilité de navigation et de restitution d’information
sur la Plateforme RNA.<o:p></o:p></span></p>

<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article&nbsp;7 : Liens hypertextes
et virus<o:p></o:p></span></strong></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Le contenu mis à disposition sur la Plateforme numérique RNA est
fourni à titre informatif.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>L'existence d'un lien de la Plateforme numérique RNA vers un autre
site ne constitue pas une validation de ce site ou de son contenu. Il
appartient à l’Utilisateur d'utiliser ces informations avec discernement et
esprit critique. La responsabilité de l'éditeur ne saurait être engagée quant
aux informations, opinions et recommandations formulées par ces tiers. <o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Il est expressément rappelé que le Ministère n'a aucun contrôle ni
aucune responsabilité quant à la création de liens vers des sites extérieurs et
quant au contenu desdits sites.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Aussi, toute connexion à un autre site, par l'intermédiaire d'un
lien hypertexte, est un choix personnel de l’Utilisateur et est, de ce fait,
effectuée sous sa seule responsabilité. En conséquence, tout préjudice direct
ou indirect résultant de cet accès à un autre site relié par un lien hypertexte
ne pourrait engager la responsabilité du Ministère.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Il appartient à l’Utilisateur de prendre les précautions
nécessaires pour s'assurer que le site sélectionné n'est pas infesté de virus
ou de tout autre parasite de nature destructive.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>En aucun cas et d´une manière générale, le Ministère ne pourra
être tenue responsable des dommages directs ou indirects résultant de l'usage
de sa Plateforme RNA&nbsp;ou d'autres sites qui lui sont liés.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>La création de liens hypertextes vers la Plateforme numérique RNA doit
faire l’objet d’une autorisation.<o:p></o:p></span></p>



<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article&nbsp;8 : Sécurité de la
Plateforme RNA&nbsp;<o:p></o:p></span></strong></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Pour assurer la sécurité de la Plateforme numérique RNA, le
Ministère prend toutes les mesures nécessaires et adéquates visant à protéger
par un éventail de technologies et procédures sécurisées les Données
Personnelles contre toute perte, utilisation détournée, accès non autorisé,
altération ou destruction et ce conformément aux dispositions de la loi 09-08
sur la protection des personnes physiques à l’égard du traitement des données à
caractère personnel. Cependant le Ministère ne saurait offrir une garantie absolue
de sécurité, dans la mesure où Internet est un réseau ouvert, sensible par
nature à de tels risques.
<b>&nbsp;<o:p></o:p></b></span></p>

<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article&nbsp;9 : Données
Personnelles<o:p></o:p></span></strong></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Le <span style='mso-spacerun:yes'> </span>responsable du
traitement est le Ministère du Tourisme, de l’Artisanat et de l’Economie
Sociale et solidaire (Département de l’Artisanat et de l’Economie Sociale et
Solidaire).<o:p></o:p></span></p>

<p style='text-align:justify'><span style='mso-spacerun:yes'> </span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Les
informations recueillies sur le site </span><a href="http://rna.gov.ma"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:windowtext;text-decoration:none;text-underline:none'>rna.gov.ma</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'> font
l’objet d’un traitement destiné à déterminer les différentes catégories
d’acteurs en activité dans le secteur, afin de mieux augmenter la capacité du
ciblage des programmes de développement économique et social en faveur du
secteur (Appui technique, promotion des produits, protection sociale <span
class=GramE>… )</span> ; <o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Les destinataires des données sont : Les directions régionales et
provinciales du ministère.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'><span style='mso-spacerun:yes'> </span>Conformément à la loi n°
09-08 promulguée par le Dahir 1-09-15 du 18 février 2009, relative à la
protection des personnes physiques à l’égard du traitement des données à
caractère personnel, vous bénéficiez d’un droit d’accès et de rectification aux
informations qui vous concernent, que vous pouvez exercer en vous adressant au
lien </span><a href="http://rna.gov.ma"><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin'>rna.gov.ma</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>
ou à M. Abdelilah YERROU </span><a href="mailto:ayerrou@artesnet.gov.ma"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>ayerrou@artesnet.gov.ma</span></a>.
<span style='background:yellow;mso-highlight:yellow'><o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Vous pouvez également, pour des motifs légitimes, vous opposer à
ce que les données qui vous concernent fassent l’objet d’un traitement. Ce
traitement a été notifié et autorisé par la CNDP au titre de l’autorisation
n° A-PO-486/2021 du 20/01/2021.</span></span><b>.<o:p></o:p></b></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Conformément aux dispositions de la loi n°09-08 relative à la
protection des personnes physiques à l’égard du traitement des données à
caractère personnel, l’Utilisateur reconnaît et accepte que les données à
caractère personnel (nom, prénom, adresse, etc.) déclarées par lui ou
disponibles chez le Fournisseur lors de l’utilisation du Site et/ou de
l’Application sont destinées au Fournisseur et font l’objet d’opérations de
traitement pour les besoins de l’exécution de sa commande et du contrat
correspondant telles que :</span><b><o:p></o:p></b></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>- la collecte ;<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>- l’enregistrement ;<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>- le stockage sous différentes formes et quelle qu’en soit la
durée ;<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>- tout autre traitement en général y compris tout transfert par le
Fournisseur à ses <span class=SpellE>&#64257;liales</span>, ses partenaires
commerciaux, ses conseils, ses sous-traitants, aux autorités judiciaires.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Le traitement de ces données a des <span class=SpellE>&#64257;nalités</span>
: techniques, d’information sur les services souscrits et/ou produits acquis,
d’adaptation des services souscrits et de gestion, en général, du contrat.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>L’Utilisateur reconnaît expressément et sans réserve, avoir été
amplement informé préalablement des <span class=SpellE>&#64257;nalités</span>
pour lesquelles le traitement de ses données à caractère personnel sont
destinées et de tous les droits que lui confère la loi n°09-08 et de toutes les
informations devant être communiquées préalablement, conformément à la loi
susvisée, lesquelles sont indiquées sur le Site et/ou sur l’Application.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>L’Utilisateur donne expressément et sans réserve son consentement
libre, <span class=SpellE>spéci&#64257;que</span> et informé aux <span
class=SpellE>&#64257;ns</span> desdits traitements et notamment pour qu’il soit
prospecté directement par automate d’appel, télécopieur, courrier électronique
ou tout autre moyen employant une technologie de même nature <span
class=SpellE>a&#64257;n</span> de l’informer de ses services <span
style='mso-spacerun:yes'> </span>à ceux souscrits et/ou acquis dans les
présentes CGU.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>L’Utilisateur est informé que des cookies enregistrent certaines
informations et sont stockées dans la mémoire de son disque dur.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Ces informations servent à générer des statistiques d’audience du
Site pour le Fournisseur. Ces cookies ne contiennent pas d’informations <span
class=SpellE>con&#64257;dentielles</span> concernant l’Utilisateur.<o:p></o:p></span></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Les données à caractère personnel concernant l’Utilisateur peuvent
donner lieu à l’exercice d’un droit d’accès, de <span class=SpellE>recti&#64257;cation</span>
et d’opposition conformément aux dispositions de la loi 09-08.<o:p></o:p></span></p>

<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article&nbsp;10&nbsp;:
Modifications<o:p></o:p></span></strong></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>
Les présentes CGU peuvent être modifiées et mises à jour, à tout moment et sans
préavis, notamment pour les adapter aux modifications législatives et
réglementaires, ou modifier les fonctionnalités proposées dans le cadre de la
Plateforme<span style='mso-spacerun:yes'>  </span>RNA si les circonstances
l'exigeraient. Lesdites modifications et mise à jour entrent en vigueur dès leur
publication sur la Plateforme RNA. &nbsp;<o:p></o:p></span></p>

<p><strong><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black'>Article
11 : Loi applicable et attribution de compétence<o:p></o:p></span></strong></p>

<p style='text-align:justify'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>
Les présentes CGU sont soumises au droit marocain. A défaut d'accord amiable,
le Tribunal de Commerce de Casablanca est seul compétent pour connaître de tout
litige à l’occasion de l'application ou de l'interprétation des présentes CGU.<b><o:p></o:p></b></span></p>
</body>

</html>`;
