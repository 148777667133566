import { Button, Col, Row, Alert, Space, Typography } from "antd";
import React, { useEffect, useState } from "react"
import ReferentielList from "./components/ReferentielList/ReferentielList";
import { getDiplomeDistinct } from './Action';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Utils from "../../../../../utilities/Utils/Utils";
import history from "../../../../../history";
import ReferentielDetail from "./components/ReferentielDetail/ReferentielDetail";
import { useTranslation } from "react-i18next";
import './ReferentielDiplomeDistinct.less'
import { get } from "jquery";
import SpinLoader from "../../../../../component/SpinLoader/SpinLaoder";
const { Title } = Typography;

const ReferentielDiplomeDistinct = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { refType } = useParams();

    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [visible, setVisible] = useState(false);
    const [successeMessage, setSuccesseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [activeReferenciel, setActiveReferenciel] = useState({});
    const [action, setAction] = useState('');

    const data = useSelector(state => state.referentielParamsReducer?.referentielDiplomeDistinctReducer?.diplomeDistinct);

    useEffect(() => {
        loadDataOrRedirect();
    }, []);

    const loadDataOrRedirect = () => {
        setLoading(true);
        dispatch(getDiplomeDistinct())
            .then((res) => {
                setLoading(false)
            })
            .catch((err) => setLoading(false));

    }

    const handleDetails = (record) => {
        setActiveReferenciel(record)
        setVisible(true)
        setAction('CONSULTATION')
    }

    const handleAdd = () => {
        setActiveReferenciel({})
        setVisible(true)
        setLoadingTable(true)
        setAction('ADD')
    }

    const handleUpdates = (record) => {
        setActiveReferenciel(record)
        setVisible(true)
        setLoadingTable(true)
        setAction('UPDATE')
    }

    const hideModal = () => {
        setVisible(false);
        setLoadingTable(false)
        setActiveReferenciel({})

    };

    const getTitle = () => t('agent.referentiel.title.diplomeDistinct');

    const getAddButtonTitle = () => t('agent.referentiel.add.diplomeDistinct');



    return (<>

        <Space size="large" direction="vertical" style={{ width: '100%' }}  >


            {loading ? <SpinLoader /> : <>

                <div>
                    <Title span={3} className="aligne-left">
                        {getTitle()}
                    </Title>
                    <Button shape='round' type="primary" htmlType="submit" onClick={() => handleAdd()} className="aligne-right">
                        {getAddButtonTitle()}
                    </Button>
                </div>
                {errorMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={errorMessage}
                                type="error"
                                closable
                            />
                        </Col>
                    </Row>
                }
                {successeMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={successeMessage}
                                type="success"
                                closable
                            />
                        </Col>
                    </Row>
                }
                <div className="search-result-list">
                    <ReferentielList loadingTable={loadingTable} data={data} refType={refType} handleUpdates={handleUpdates} handleDetails={handleDetails} />
                    {visible &&
                        <ReferentielDetail handleClose={hideModal} activeReferenciel={activeReferenciel} action={action} refType={refType}
                            loadDataOrRedirect={loadDataOrRedirect} setErrorMessage={setErrorMessage} setSuccesseMessage={setSuccesseMessage} />
                    }

                </div>
            </>}
        </Space>
    </>);
}

export default ReferentielDiplomeDistinct;