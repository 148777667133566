import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {
    RESET_MESSAGES: 'RESET_MESSAGES',

    GET_USERS_BY_SEARCH_CRITERIA: 'GET_USERS_BY_SEARCH_CRITERIA',
    GET_USERS_BY_SEARCH_CRITERIA_PENDING: `GET_USERS_BY_SEARCH_CRITERIA_${ActionType.Pending}`,
    GET_USERS_BY_SEARCH_CRITERIA_FULFILLED: `GET_USERS_BY_SEARCH_CRITERIA_${ActionType.Fulfilled}`,
    GET_USERS_BY_SEARCH_CRITERIA_REJECTED: `GET_USERS_BY_SEARCH_CRITERIA_${ActionType.Rejected}`,

    GET_USER_BY_ID: 'GET_USER_BY_ID',
    GET_USER_BY_ID_PENDING: `GET_USER_BY_ID_${ActionType.Pending}`,
    GET_USER_BY_ID_FULFILLED: `GET_USER_BY_ID_${ActionType.Fulfilled}`,
    GET_USER_BY_ID_REJECTED: `GET_USER_BY_ID_${ActionType.Rejected}`,

    ADD_USER: 'ADD_USER',
    ADD_USER_PENDING: `ADD_USER_${ActionType.Pending}`,
    ADD_USER_FULFILLED: `ADD_USER_${ActionType.Fulfilled}`,
    ADD_USER_REJECTED: `ADD_USER_${ActionType.Rejected}`,

    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_PENDING: `UPDATE_USER_${ActionType.Pending}`,
    UPDATE_USER_FULFILLED: `UPDATE_USER_${ActionType.Fulfilled}`,
    UPDATE_USER_REJECTED: `UPDATE_USER_${ActionType.Rejected}`,

    DELETE_USER: 'DELETE_USER',
    DELETE_USER_PENDING: `DELETE_USER_${ActionType.Pending}`,
    DELETE_USER_FULFILLED: `DELETE_USER_${ActionType.Fulfilled}`,
    DELETE_USER_REJECTED: `DELETE_USER_${ActionType.Rejected}`,

    DEACTIVATE_USER: 'DEACTIVATE_USER',
    DEACTIVATE_USER_PENDING: `DEACTIVATE_USER_${ActionType.Pending}`,
    DEACTIVATE_USER_FULFILLED: `DEACTIVATE_USER_${ActionType.Fulfilled}`,
    DEACTIVATE_USER_REJECTED: `DEACTIVATE_USER_${ActionType.Rejected}`,
};

export default ActionTypes;