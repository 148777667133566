import React from 'react';
import { Table , Progress } from 'antd';


export class HttpRequestMetrics extends React.Component {

nanToZero = (input) => isNaN(input) ? 0 : Math.round(input);

  render() {
    const { requestMetrics } = this.props;

    const columns = [
        {
          title: 'Code',
          dataIndex: 'code',
          key: 'code',
        },
        {
          title: 'Count',
          dataIndex: 'count',
          key: 'count',
          render : (count) => {
            return (<Progress percent={count} size="small" status="active" strokeColor="#52c41a" />);
          }
        },
        {
          title: 'Mean',
          dataIndex: 'mean',
          key: 'mean',
        },
        {
          title: 'Max',
          dataIndex: 'max',
          key: 'max',
        },
      ]

      const getData = () => {
        const data = [];
        Object.keys(requestMetrics.percode).map((key, index) => (
            data.push({
                "code" : key,
                "count" : this.nanToZero(requestMetrics.percode[key].count*100/requestMetrics.all.count),
                "mean" : this.nanToZero(requestMetrics.percode[key].mean),
                "max" : this.nanToZero(requestMetrics.percode[key].max)
              })
          ));
        return data;
      }

    return (
      <div>
        <h3>HTTP requests (time in milliseconds)</h3>
        <p>
          <span>Total requests:</span>{' '}
          <b>
            {requestMetrics.all.count}
          </b>
        </p>

        <Table columns={columns} dataSource={getData()}  pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}/>
      </div>
    );
  }
}
