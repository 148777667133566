import { store } from '../../../configureStore';
import types from './ActionTypes';

const initialeState = {
  loading: false,
  error: "",
  documentTypes: [],
  artisantData: {},
  documents: [],
  documentIds: [],
  otherDocumentIds: []
};


const mapDocumentId = (data) => {
  return {
    id: data.id,
    typeId: data.document_type_id,
    filename: data.original_file_name
  }
}

function reducer(state = initialeState, action) {
  switch (action.type) {

    case types.GET_DOCUMENT_TYPES_PENDING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case types.GET_DOCUMENT_TYPES_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        documentTypes: data,
        error: "",
      };
    }

    case types.GET_DOCUMENT_TYPES_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }
    case types.REGISTER_ARTISAN_EDUCATIONAL_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case types.REGISTER_ARTISAN_EDUCATIONAL_DATA_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        error: "",
      };
    }

    case types.REGISTER_ARTISAN_EDUCATIONAL_DATA_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }
    case types.REGISTER_ARTISAN_PERSONNAL_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case types.REGISTER_ARTISAN_PERSONNAL_DATA_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        error: "",
      };
    }

    case types.REGISTER_ARTISAN_PERSONNAL_DATA_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }
    case types.REGISTER_ARTISAN_PROFESSIONAL_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case types.REGISTER_ARTISAN_PROFESSIONAL_DATA_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        error: "",
      };
    }

    case types.REGISTER_ARTISAN_PROFESSIONAL_DATA_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }
    case types.REGISTER_ARTISAN_REPRESENTANT_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case types.REGISTER_ARTISAN_REPRESENTANT_DATA_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        error: "",
      };
    }
    case types.REGISTER_ARTISAN_REPRESENTANT_DATA_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }
    case types.UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }
    case types.UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case types.UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        error: "",
      };
    }

    case types.UPDATE_REGISTER_ARTISAN_EDUCATIONAL_DATA_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }
    case types.UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case types.UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        error: "",
      };
    }

    case types.UPDATE_REGISTER_ARTISAN_PERSONNAL_DATA_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }
    case types.UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case types.UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        error: "",
      };
    }

    case types.UPDATE_REGISTER_ARTISAN_PROFESSIONAL_DATA_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }
    case types.UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case types.UPDATE_REGISTER_ARTISAN_REPRESENTANT_DATA_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        error: "",
      };
    }

    case types.UPLOAD_ARTISAN_FILE_PENDING: {
      return {
        ...state,
        error: '',
        loading: false
      };
    }

    case types.UPLOAD_ARTISAN_FILE_FULFILLED: {
      let payload = action.payload.data.data;
      let uploadedDoc = mapDocumentId(payload);
      if (uploadedDoc.typeId === '9999') {
        let ids = state.otherDocumentIds;
        ids.push(uploadedDoc);
        return {
          ...state,
          loading: false,
          error: "",
          otherDocumentIds: ids
        };
      } else {
        let ids = state.documentIds;
        ids.push(uploadedDoc);
        return {
          ...state,
          loading: false,
          error: "",
          documentIds: ids
        };
      }
    }

    case types.REMOVE_ARTISAN_FILE_FULFILLED: {
      let payload = action.payload.data;
      let removedDoc = mapDocumentId(payload);

      if (removedDoc.typeId === '9999') {
        let ids = state.otherDocumentIds.filter(doc => doc.id !== removedDoc.id);
        return {
          ...state,
          loading: false,
          error: "",
          otherDocumentIds: ids
        };
      } else {
        let ids = state.documentIds.filter(doc => doc.id !== removedDoc.id);;
        return {
          ...state,
          loading: false,
          error: "",
          documentIds: ids
        };
      }
    }

    case types.GET_ARTISANT_FILES_LIST_PENDING: {
      return {
        ...state,
        loading: false,
        error: "",
      };
    }
    case types.GET_ARTISANT_FILES_LIST_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        documents: data,
        documentIds: data.filter(doc => doc.document_type_id !== '9999').map(d => mapDocumentId(d)),
        otherDocumentIds: data.filter(doc => doc.document_type_id === '9999').map(d => mapDocumentId(d)),
        error: ""
      };
    }

    case types.UPLOAD_ARTISAN_FILE_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        loading: false,
        error: message,
      };
    }

    case types.FINALIZE_SUBSCRIPTION_PENDING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case types.FINALIZE_SUBSCRIPTION_TYPES_FULFILLED: {
      const data = action.payload.data.data;
      return {
        ...state,
        loading: false,
        error: "",
      };
    }

    case types.FINALIZE_SUBSCRIPTION_TYPES_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }

    case types.GET_ARTISANT_BY_ID_PENDING: {
      return {
        ...state,
        loading: true,
        artisantData: {},
        error: "",
      };
    }
    case types.GET_ARTISANT_BY_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        error: "",
        artisantData: action.payload.data.data,
      };
    }
    case types.GET_ARTISANT_BY_ID_REJECTED: {
      const { message } = action.payload.response.data;
      return {
        ...state,
        error: message,
        loading: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
