import { ActionType } from 'redux-promise-middleware';

const ActionTypes = {

  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  DELETE_ACCOUNT_PENDING: `DELETE_ACCOUNT_${ActionType.Pending}`,
  DELETE_ACCOUNT_FULFILLED: `DELETE_ACCOUNT_${ActionType.Fulfilled}`,
  DELETE_ACCOUNT_REJECTED: `DELETE_ACCOUNT_${ActionType.Rejected}`,

  CLEAR_DELETE_ACCOUNT_MESSAGES: 'CLEAR_DELETE_ACCOUNT_MESSAGES',
}

export default ActionTypes;
