import React, { Component } from 'react';
import { getArtisanFilesList, uploadArtisanFile, removeArtisanFile, getDocumentTypeByArtisanId, finalizeSubscription, getArtisanDataById } from './Action'
import { getGlobalParam } from '../../GlobalParams/Action';
import { getReferentiels, getDiplome } from '../../Referentiels/Action';
import { finalizeArtisanStatus } from '../../login/Action'
import { connect } from 'react-redux';
import { Empty, Row } from 'antd';
import ArtisanP1 from './ArtisanP1/ArtisanP1';
import ArtisanP2 from './ArtisanP2/ArtisanP2';
import ArtisanP3 from './ArtisanP3/ArtisanP3';
import ArtisanP4 from './ArtisanP4/ArtisanP4'
import ArtisanP5 from './ArtisanP5/ArtisanP5';
import Utils from './../../../utilities/Utils/Utils';
import SpinLoader from '../../../component/SpinLoader/SpinLaoder';


class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
        this.handleRemoveArtisanFile = this.handleRemoveArtisanFile.bind(this);
    }

    componentDidMount() {
        const { getDocumentTypeByArtisanId, artisanId, getArtisanDataById, getGlobalParam, getReferentiels, getArtisanFilesList, getDiplome } = this.props;
        getGlobalParam()
            .then(result => getDiplome())
            .then(result => getReferentiels())
            .then(result => getDocumentTypeByArtisanId(artisanId))
            .then(result => getArtisanDataById(artisanId))
            .then(result => getArtisanFilesList(artisanId))
            .then(result => this.setState({ ...this.state, isLoading: false }))

    }

    handleRemoveArtisanFile(artisanId, file) {
        return this.props.removeArtisanFile(artisanId, file)
    }

    renderArtisanForm() {
        const { artisanId, artisantData, documents, uploadArtisanFile, removeArtisanFile, artisanType, finalizeSubscription, params, referentiel, finalizeArtisanStatus } = this.props;

        switch (artisanType) {
            case Utils.ARTISANT_TYPE.ARTISAN_AVEC_DIPLOME:
                return <ArtisanP1 params={params} referentiel={referentiel} artisanId={artisanId} artisanType={artisanType} finalizeArtisanStatus={finalizeArtisanStatus} artisantData={artisantData} documents={documents} uploadArtisanFile={uploadArtisanFile} removeArtisanFile={this.handleRemoveArtisanFile} finalizeSubscription={finalizeSubscription} />
            case Utils.ARTISANT_TYPE.ARTISAN_AVEC_EXPERIENCE:
                return <ArtisanP2 params={params} referentiel={referentiel} artisanId={artisanId} artisanType={artisanType} finalizeArtisanStatus={finalizeArtisanStatus} artisantData={artisantData} documents={documents} uploadArtisanFile={uploadArtisanFile} removeArtisanFile={this.handleRemoveArtisanFile} finalizeSubscription={finalizeSubscription} />
            case Utils.ARTISANT_TYPE.ARTISAN_MAALAM:
                return <ArtisanP3 params={params} referentiel={referentiel} artisanId={artisanId} artisanType={artisanType} finalizeArtisanStatus={finalizeArtisanStatus} artisantData={artisantData} documents={documents} uploadArtisanFile={uploadArtisanFile} removeArtisanFile={this.handleRemoveArtisanFile} finalizeSubscription={finalizeSubscription} />
            case Utils.ARTISANT_TYPE.COOPERATIVE_ARTISANAT:
                return <ArtisanP4 params={params} referentiel={referentiel} artisanId={artisanId} artisanType={artisanType} finalizeArtisanStatus={finalizeArtisanStatus} artisantData={artisantData} documents={documents} uploadArtisanFile={uploadArtisanFile} removeArtisanFile={this.handleRemoveArtisanFile} finalizeSubscription={finalizeSubscription} />
            case Utils.ARTISANT_TYPE.ENTREPRISE_ARTISANAT:
                return <ArtisanP5 params={params} referentiel={referentiel} artisanId={artisanId} artisanType={artisanType} finalizeArtisanStatus={finalizeArtisanStatus} artisantData={artisantData} documents={documents} uploadArtisanFile={uploadArtisanFile} removeArtisanFile={this.handleRemoveArtisanFile} finalizeSubscription={finalizeSubscription} />
            default:
                return <Empty />
        }
    }
    render() {
        return (
            this.state.isLoading
                ? <SpinLoader />
                : <>  {this.props.artisantData && this.renderArtisanForm()} </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        artisanId: state.loginReducer.artisan.artisan_id,
        artisanType: state.loginReducer.artisan.artisan_type.code,
        userType: state.loginReducer.type,
        loading: state.registration.loading,
        artisantData: state.registration.artisantData,
        documents: state.registration.documents,
        params: state.globalParamReducer.params,
        referentiel: state.referentielReducer.params
    };
};

const mapDispatchToProps = (dispatch) => ({
    getGlobalParam: () => dispatch(getGlobalParam()),
    getReferentiels: () => dispatch(getReferentiels()),
    getArtisanFilesList: (artisanId) => dispatch(getArtisanFilesList(artisanId)),
    uploadArtisanFile: (artisanId, file) => dispatch(uploadArtisanFile(artisanId, file)),
    removeArtisanFile: (artisanId, file) => dispatch(removeArtisanFile(artisanId, file)),
    getDocumentTypeByArtisanId: (artisanId) => dispatch(getDocumentTypeByArtisanId(artisanId)),
    finalizeSubscription: (artisanId) => dispatch(finalizeSubscription(artisanId)),
    getArtisanDataById: (artisanId) => dispatch(getArtisanDataById(artisanId)),
    finalizeArtisanStatus: () => dispatch(finalizeArtisanStatus()),
    getDiplome: () => dispatch(getDiplome())
});


export default connect(mapStateToProps, mapDispatchToProps)(Registration);
