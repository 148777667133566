import React, { useState } from "react";
import { Form, Button, Row, Col, Space, Alert, Upload } from 'antd';
import { UserDeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from '@ant-design/icons';
import { useForm } from "antd/lib/form/Form";
import { TextAreaWithDoubleLabel, UpaloadFile } from "../../../utilities/FormFields/FormFields";
import { deleteArtisanAccount } from './Action'
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";

const DeleteAccount = props => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const artisanId = useSelector(state => state.loginReducer?.artisan?.artisan_id);
    const errorCode = useSelector(state => state.deleteAccountReducer?.error);
    const [form] = useForm();
    const [validationMessage, setValidationMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [file, setFile] = useState();
    const maxFileSize = process.env.REACT_APP_FILE_SIZE;

    const onFinish = (payload) => {
        
        const fmData = new FormData();
        fmData.append('motif', payload?.motif);

        if(file && payload.file){
            fmData.append('file', file);
            const isNotGoodSize = file.size / 1024 / 1024 > maxFileSize;
            if (!['image/png', 'image/jpeg', 'application/pdf'].includes(file.type)) {
                setErrorMessage(t('upload-file.error-type'));
            } else if (isNotGoodSize) {
                setErrorMessage(state => t('upload-file.error-size', { size: maxFileSize }));
            } 
        }

        dispatch(deleteArtisanAccount(artisanId, fmData)).then(res => {
            setValidationMessage(state => t('DELETE_ARTISAN_REQUEST_SUCCESS'));
            form.resetFields();
        }).catch(err => {
            setErrorMessage(state => t(errorCode || 'DELETE_ARTISAN_REQUEST_REJECTED'));
        })
        

    };

    const uploadProps = {
        onRemove: (file) => {
            setFile(null);
        },
        beforeUpload: (file) => {
            setFile(file);
            return false;
        },
        maxCount: 1,
        file,
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };


    return (
        <>
            <Title>{t('deleteAccount.title')}</Title>
            <Space size="middle" direction="vertical" style={{ width: '100%' }}>

                <Text> {t('deleteAccount.description')}</Text>
                {errorMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={errorMessage}
                                type="error"
                                closable
                                onClose={() => setErrorMessage("")}
                            />
                        </Col>
                    </Row>
                }
                {validationMessage &&
                    <Row justify="center" style={{ padding: '20px 0 10px 0', width: '90%' }}>
                        <Col>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={validationMessage}
                                type="success"
                                closable
                                onClose={() => setValidationMessage("")}
                            />
                        </Col>
                    </Row>
                }
                <Form form={form} name="delete-artisan-account" style={{ width: '100%' }} onFinish={onFinish}>

                    <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <TextAreaWithDoubleLabel form={form} type="text" name="motif" label={t('deleteAccount.form.motif.label')} placeholder={t('deleteAccount.form.motif.placeholder')} errorMessage={t('deleteAccount.form.motif.error-message')} maxLength={3000} />
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <UpaloadFile name="file" required={false} label={t('deleteAccount.form.file.label')} formEvent={normFile} uploadProps={uploadProps}
                                errorMessage={t('deleteAccount.form.file.error-message')} />
                        </Col>
                    </Row>
                    <Row >
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button shape='round' type="primary" htmlType="submit" size='large' >
                                {t('deleteAccount.form.submit.label')}
                            </Button>

                        </Col>
                    </Row>
                </Form>
            </Space>
        </>
    )
}

export default DeleteAccount;