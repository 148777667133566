import types from "./ActionTypes"

const initialState = {
  loading: false,
  errorMessage: null,
  logs: {
    loggers: [],
  },
  health: {},
  metrics: {},
  threadDump: [],
  configuration: {
    configProps: {},
    env: {},
  },
  tracker: {
    activities: [],
  },
  totalItems: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
function reducer (state = initialState, action) {
  switch (action.type) {
    case (types.ADMINISTRATION_FETCH_METRICS_PENDING || types.ADMINISTRATION_FETCH_THREAD_DUMP_PENDING || types.ADMINISTRATION_FETCH_LOGS_PENDING 
      || types.ADMINISTRATION_FETCH_CONFIGURATIONS_PENDING || types.ADMINISTRATION_FETCH_ENV_PENDING || types.ADMINISTRATION_FETCH_HEALTH_PENDING): {
        return {
          ...state,
          errorMessage: null,
          loading: true,
        };
      }
    case (types.ADMINISTRATION_FETCH_METRICS_REJECTED || types.ADMINISTRATION_FETCH_THREAD_DUMP_REJECTED || types.ADMINISTRATION_FETCH_LOGS_REJECTED
      || types.ADMINISTRATION_FETCH_CONFIGURATIONS_REJECTED || types.ADMINISTRATION_FETCH_ENV_REJECTED || types.ADMINISTRATION_FETCH_HEALTH_REJECTED): {
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
      }
    case types.ADMINISTRATION_FETCH_METRICS_FULFILLED:
      return {
        ...state,
        loading: false,
        metrics: action.payload.data,
      }
    case types.ADMINISTRATION_FETCH_THREAD_DUMP_FULFILLED:
      return {
        ...state,
        loading: false,
        threadDump: action.payload.data,
      }
    case types.ADMINISTRATION_FETCH_LOGS_FULFILLED:
      return {
        ...state,
        loading: false,
        logs: {
          loggers: action.payload.data.loggers,
        },
      }
    case types.ADMINISTRATION_FETCH_CONFIGURATIONS_FULFILLED:
      return {
        ...state,
        loading: false,
        configuration: {
          ...state.configuration,
          configProps: action.payload.data,
        },
      };
    case types.ADMINISTRATION_FETCH_ENV_FULFILLED:
      return {
        ...state,
        loading: false,
        configuration: {
          ...state.configuration,
          env: action.payload.data,
        },
      };
    case types.ADMINISTRATION_FETCH_HEALTH_FULFILLED:
      return {
        ...state,
        loading: false,
        health: action.payload.data,
      };

    case types.ADMINISTRATION_WEBSOCKET_ACTIVITY_MESSAGE: {
      // filter out activities from the same session
      const uniqueActivities = state.tracker.activities.filter(activity => activity.sessionId !== action.payload.sessionId);
      // remove any activities with the page of logout
      const activities = [...uniqueActivities, action.payload].filter(activity => activity.page !== 'logout');
      return {
        ...state,
        tracker: { activities },
      };
    }
    default:
      return state;
  }
};

export default reducer;
